import React, {useState, Fragment} from "react";
import { Collapse } from "react-bootstrap";

const Footer = () => {
	const [open, setOpen] = useState(false);

	return (
	<Fragment>
	<div
	onDoubleClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
	className="footer"
	>
    		<p>Mostrar Estadistica General</p>
		<Collapse in={open}>
			<div>
				Gráficos
				<p>DOS</p>
			</div>
		</Collapse>
  	</div>
	</Fragment>
	)
};

export default Footer;
