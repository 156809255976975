import React, {Fragment} from "react";
import { Table, Button } from "react-bootstrap";

import '../../table_report.css';
import {campos_tr_analitico} from "../../datos/columns";

const TablaInf2_Analitico = ({datos_Inf2_Analitico, columnas}) => {
  
	const crearHeaderAnalitico = (columnas) => {
		if (columnas !== null) {
			return columnas.map((columna, index) => {
				return <th key={index} >{columna}</th>
			});
		}
	}

	const changeTrueFalse = (registro) =>{
		if(registro === "true"){
			return "Si"
		} else if(registro === "false"){
			return "No"
		} else {
			return ""
		}
	}

	const formatearFecha = (dato) => {

		let _fecha = null;
		_fecha = new Date(dato)
		_fecha.setMinutes(_fecha.getMinutes() + _fecha.getTimezoneOffset())
	
		return _fecha.toLocaleString().split(',')[0];
	}
	
	const formatearFecha_2 = (dato) => {
	
		//para revertir fecha, por ejemplo (2024-06-12) a (12/06/2024)
		let _fecha = dato.split("-").reverse().join("/");
		
		return _fecha	
	}

	const crearRowsDataAnalitico = (datosA)=>{
		if (datosA !== null) {
			console.log(datosA);
			return datosA.map((registro, index) => {

				//MEJORA***29-jun-2024
				let regA = registro;
				let reg_cspU = registro.array_csp[0];
				let union = Object.assign(regA, reg_cspU);			

				let a = campos_tr_analitico.map((campo, index) => {

					if (campo !== 'array_csp') { // lo que viene en array_csp ya lo manejo con la union
						let c = union[campo];

						const regexFecha = /T00:00:00.000Z/;
						const busqueda = regexFecha.test(c);

						const regexFecha_2 = /\d{4}-\d{1,2}-\d{1,2}/;
						const busqueda_2 = regexFecha_2.test(c)

						if (c === "true" || c === "false") {
							let b = changeTrueFalse(c)
							return <td>{b}</td>

						} else if (busqueda === true) {
							if(campo !== 'año_emision'){
								let d = formatearFecha(c)
								return <td>{d}</td>
							} else {
								return <td>{c}</td>
							}
							
						} else if (busqueda_2 === true) {
							if(campo !== 'año_emision'){
								let f = formatearFecha_2(c)
								return <td>{f}</td>
							} else {
								return <td>{c}</td>
							}
						} else {
							return <td>{c}</td>
						}

					}

				})

				return <tr> {a} </tr>;				
			})
		}

	}


	if (datos_Inf2_Analitico === null) {
		return (
			<p>Cargar Analitico...</p>
		)
	} else if (datos_Inf2_Analitico !== null) {
		return (
			<Fragment>
				<h4></h4>
				<p className="subtitulos">Informe analítico</p>
				<div>
					<Table id="table-analitico-to-xls" className="tableExp" stripped bordered hover responsive="md" >
						<thead>
							<tr style={{ backgroundColor: "rgb(0,174,195)", color: "white" }}>{crearHeaderAnalitico(columnas)}</tr>
							{/*<tr style={{backgroundColor:"rgb(0,174,195)", color:"white"}}>
                <th>Total lotes</th>
                <th>Total proyectos</th>
              </tr>*/}
						</thead>
						<tbody>
							{crearRowsDataAnalitico(datos_Inf2_Analitico)}
						</tbody>
					</Table>
				</div>
			</Fragment>
		)
	}
};

export default TablaInf2_Analitico;
