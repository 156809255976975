import React, {Fragment, useState } from 'react';
import Select from 'react-select';

import dotenv from "dotenv";
import LeafletMap from "./map";

const baseURL = process.env.REACT_APP_API_URL;

const SelectT = () => {
	//console.log('cargar actions');
	const actions = [
		{ value:"", label: "Seleccionar Programa..."},
  		{ value: 1, label: "Lotes con Servicios", url: `${baseURL}api/layers/layer` },// 3001
  		{ value: 2, label: "Plan Familia Propietaria", url: `${baseURL}api/layers/layerQ` },
		{ value: 3, label: "Integración Sociourbana", url: `${baseURL}api/layers/layerDU` },
	];

	const [datosS, setDatos] = useState({features: []});

	const onchangeSelect = (item) => {
		//console.log(item);
		//console.log(item.value);
		var url = item.url;
		//setCurrentCountry(null);
		//setRegion(item);

		document.getElementById("customT").style.display = 'block';

		getDatos(url);
		/*useEffect(() => {
               		getDatos();
        	},[items]);*/

	};

        const getDatos = async (url) => {
                //console.log("ejecutar getDatos");
		//console.log(url);

                const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token }

                //const res = await fetch("http://localhost:3001/api/layers/layer",
		const res = await fetch(url,
                { headers })
                .then(res => res.json())
                .then(res => setDatos(res))
                .catch(err => console.log(err));
	
		//console.log(datosS);
        }

	        //getDatos();
        /*useEffect(() => {
                getDatos();
        },[]);*/

	return (
	<Fragment>
	<div className="container">
    		<div className="row">
      			<div className="col-md-4"></div>
      			<div className="col-md-4">
        			<Select 
					options={ actions }
					onChange={onchangeSelect}
					datosS={datosS} 
				/>
      			</div>
      			<div className="col-md-4"></div>
    		</div>
  	</div>
	<div>
		<LeafletMap datosS={datosS}/>
	</div>
	</Fragment>
	);
}
 
export default SelectT;
