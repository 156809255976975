import React, { useState, Fragment } from "react";
import Navigation from "./navigation";
import Footer from "./footer";

import Metabase from "./monitor/metabase";


const Graficos =  ({ setAuth, setRole }) => {

  const [role, setUno] = useState("a1");
  const [activo, setActivo] = useState("graficosA"); //15-06-2022

  return (
    <Fragment>

      <Navigation setAuth={setAuth} role={role} setRole={setRole} activo={activo}/>
      <h4 className="mt-5 text-center">Gráficos</h4>
      <div>
        <Metabase />
      </div>
      <Footer/>
          
    </Fragment>
  );
}

export default Graficos;