import React, { Fragment, useState, useEffect, useContext } from "react";
import { FaExclamationTriangle} from 'react-icons/fa';
import { SessionContext } from '../context/sessionContext';

import { useHistory, useLocation } from "react-router-dom";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import { Collapse, Form, Button } from "react-bootstrap";

import Navigation from "./navigation";
import Footer from "./footer";

import DatosExp from "./datosExp";
import TramiteExp from "./tramiteExp";
import Pestaña3_Exp from "./pestaña3_Exp";

import { toast } from "react-toastify";

const Expediente = ({ setAuth, ...props }) => {

	const { getProfile, roleA, original, cambioOriginal, sesionT, geom, cambioGeom } = useContext(SessionContext);
	
	const name = roleA.username;
	const role = roleA.roleA;  	

	const [show, setShow] = useState(false); //para ventana modal

	const history = useHistory();
	const location = useLocation();

	const volverLogin = () =>{
		localStorage.clear();
		let path = `login`;
		history.push(path);
	}	

	const [geomB, setGeomB]=useState(false);

	useEffect(()=>{
		setInputs({ ...inputs, datos_22_geom: JSON.stringify(geom) });
		setGeomB(true);	
	}, [geom])

	useEffect(() => {
		const pagina = 'acceso_pagina';
		
		getProfile(pagina);
		if(sesionT === 'no_token'){
			console.log('#82 - volver a loguear');
		} else if( sesionT === 'Token valido'){
			console.log("#71--> ","token valido" );
		}
		
	}, []);
	//*** cierra chequear token ****//24-06-2022
	
  	//***Guardar y Salir
	const redirect = async e => {
		try{
			const x = 'nuevo';
			//getProfile(x);
		if(sesionT === 'no_token'){
			alert('volver a loguear');
		} else if( sesionT === 'Token valido'){
			//console.log("92--> ","token valido" );
			saveExit(e);// si el token es válido, ejecutar saveExit
		}

		} catch(err){
			console.error(err);
		}
	}

  	//***Guardar y Continuar*** 06/10/2022
	const redirect_Cont = async e => {
		e.preventDefault();
		try{
			const x = 'nuevo';
			if(sesionT === 'no_token'){
				alert('volver a loguear');
			} else if( sesionT === 'Token valido'){
				saveSeguir(e);// si el token es válido, ejecutar saveExit
			}
		} catch(err){
			console.error(err);
		}		
	}

	const [activo, setActivo] = useState("expediente"); //15-06-2022

	const [estadoExp, setEstadoExp]=useState("nuevo");

	const [inputs, setInputs] = useState({
		nombre_proyecto: "",//1
		expediente:"",//2
		fecha_carga: new Date(),//3 .toLocaleDateString('es-AR')
		fecha_caratulacion: null,//4
		fecha_actualizacion: null,
		//encargado:"",//5
		datos_05_responsable:"",//5
		tramite_prioritario:false,//6
		programa:"",//7
		estado:"",//8
		tipo:"",//9
		partido:0,// estado inicial tiene que ser un numero para que no de error en la base. Espera un integer//**10
		localidad:"",//11
		cantidad_lotes:null,//12
		cantidad_lotes_adjudicables:null,//13
		porc_lotes_municipales: null,//14
		lotes_adquiridos_municipio: null,//15
		datos_18_sintesis_nomenclatura:"",//18
		titular_tierra:"",//19
		plano_oficial:"",//20
		area:"",//21
		zonificacion:"",//22
		nro_ordenanza_vigente:"",//23
		decreto_promulgatorio:"",//24
		sistema_agua:"",//16
		eliminacion_excretas:"",//17
		observaciones_generales:"",//25
		id_exp_prueba:"",
		name: name,
		avanzados: false,

		datos_19_partido_nomencla:0,
		datos_20_circ_nomencla:"",
		datos_21_secc_nomencla:"",
		datos_22_geom : JSON.stringify(geom)		

	});
	
	//ESTADO HACER CALCULO
	const [result, setResult] = useState("");
	//console.log(result);

	//**TAMBIEN PASAR FUNCTION
	const handle_result = (newResult) =>{
		setResult(newResult)
	}

	const [formRows, setFormRows] = useState([{
		nomenclatura_catastral:"000000000000000000000000000000000000000000",
		partida_inmobiliaria:"",//"000000",
		inscripcion_dominial:"",//"",
		nomencla_partido:"",//"000",
		nomencla_circ:"",//"00",
		nomencla_secc:"",//"00",
		nomencla_chacra_nro:"", nomencla_chacra_l:"",//"000",
		nomencla_quinta_nro:"", nomencla_quinta_l:"",//"000",
		nomencla_fracc_nro:"", nomencla_fracc_l:"",//"000",
		nomencla_manz_nro:"", nomencla_manz_l:"",//"000",
		nomencla_parc_nro:"", nomencla_parc_l:"",//"0000",
		solicitado:""
	 }]);

	const handleChangeRow = (newRow) =>{
		setFormRows(newRow)
	}

	//**Estados de TRAMITES
	const [inputsTram, setInputsTram] = useState({

		solicitud_adhesion_t:false, //101 AGREGAR checkbox
		certif_urbanistico_municipal_t:false, //102
		certif_no_inundabilidad_t:false, //103
		anteproyecto_urbano_t:false, //104
		titulo_propiedad_t:false, //105

		informe_dominio_t:false, //106
		anotaciones_personales_t:false, //107
		admisibilidad_inicial_t:false, //108
		prefactibilidad_urbanistica_t:false, //109
		prefactibilidad_hidraulica_t:false, //110

		memoria_descriptiva_obras_t:false, //111
		energia_electrica_t:false, // 112

		agua_potable_t:false, //113
		eliminacion_excretas_t:false, //114
		apertura_calles_t:false, //115
		acta_constitutiva_t:false, //116
		certif_vigencia_persona_juridica_t:false, //117
		plano_mensura_t:false, //118
		convenio_urbanistico_t:false, //119
		ordenanza_municipal_t:false, //120
		memoria_descriptiva_procedimientos_t:false, //121

		certif_tecnico_urbanistico_t:false, //122
		resolucion_afectacion_t:false, //123
		visado_plano_t:false, //124

		observaciones_internas_t:"", //125
		observaciones_reporte_t:"", //126

		t_127_tramitacion_ada_prefac_h:"", //127
		fecha_envio_prefac_h_t: null, //128 - 05-04-22 - CAMBIO para PREFACTIBILIDAD HIDRÁULICA
		expediente_prefac_h_t: "", //129 - volver a agregar
		fecha_emision_prefac_h_t: null, //130 - 05-04-22 - CAMBIO para PREFACTIBILIDAD HIDRÁULICA
		t_131_observaciones_prefact_h:"", //131

		t_132_tramitacion_resol:null, //132

		fecha_envio_resol_t: null, //133 - CAMBIO 05-04-22
		fecha_emision_resol_t: null, //134 - CAMBIO 05-04-22
		numero_especial_resol_t:"", //135
		t_136_observaciones_resol:"", //136

		t_137_tramitacion_visado:"", //137
		fecha_visado_plano_t: null, //138 - CAMBIO 05-04-22
		t_139_fecha_entrega: null, //139 - ADD 20-09-2022
		t_140_observaciones_tramitacion:"", //140 - ADD 20-09-2022
		t_144_proyecto_hidraulico: false, //144 -- add 22-12-2022
		t_145_cpu_tramitacion:"", //145 --add 14-JUN-2024
		t_146_cpu_fecha_envio: null, //146 --add 14-JUN-2024
		t_147_cpu_fecha_emision: null, //147 --add 14-JUN-2024
		t_148_cpu_nro_gdeba:"", //148 --add 14-JUN-2024
		t_149_cpu_observaciones:"" //149 --add 14-JUN-2024
	});

	//**crear estado para datos de la Pestaña 3 (Seguimiento)
	//**primero los inputs CSP
	const [inputsCSP, setInputsCSP] = useState({

		csp_1_tamaño_medio_lotes_residenciales : null,
		csp_2_actor_encargado_obras_infra : "",
		csp_3_financiamiento_publico_obras_infra: false, 	//check
		csp_4_jurisdiccion_infra : "",
		csp_5_organismo_financiamiento_infra : "",

		csp_6_fecha_control_infra : null,//date
		csp_7_descripcion_obras_infra : "",

		csp_8_financiamiento_publico_obras_viv: false, 	//check
		csp_9_jurisdiccion_viv : "",
		csp_10_organismo_financiamiento_viv : "",

		csp_11_fecha_control_viv: null, //date
		csp_12_descripcion_obras_viv : "",

		csp_13_financiamiento_publico_obras_equip: false, 	//check
		csp_14_jurisdiccion_equip : "",
		csp_15_organismo_financiamiento_equip : "",

		csp_16_fecha_control_equip: null, //date

		csp_17_descripcion_obras_equip : "",
		csp_18_pl_mensura_estado : "",
		csp_19_pl_mensura_partido : "",
		csp_20_pl_mensura_orden : "",
		csp_21_pl_mensura_año : "",

		csp_22_pl_mensura_fecha: null, //date

		csp_23_registracion_legajo : null,
		csp_24_registracion_folio : null,

		csp_25_registracion_fecha: null, //date
		csp_26_fecha_control: null, //date

		csp_27_observaciones_csp : "",

		csp_28_presencia_apertura_calles:"",
		csp_29_parc_edificadas:null,
		csp_30_porcentaje_parc_edificadas:null,
		csp_31_observ_seguimiento_obras_y_edificacion : "",
		csp_32_fecha_control_seguimiento_obras:null //date
	});

	//funciones para mover DAtosExp  a otro componente¨***
	const cargaExpediente = (newExpediente) => {
		setInputs(newExpediente)
	}

	const cargaTramite = (newTramite) => {
		setInputsTram(newTramite)
	}

	const cargarCSP = (newCSP) => {
		setInputsCSP(newCSP);
	}

	//**10jul24 */
	const [errorD, setErrorD] = useState(true); //para nuevo exp siempre inicia como true

	const recibirErrorD = (newError)=>{
		setErrorD(newError)
	}

	const [errorCSP, setErrorCSP] = useState(false); //para nuevo exp es false

	const recibirErrorCSP = (newError)=>{
		setErrorCSP(newError)
	}

	const [resultError, setResultError] = useState();

	useEffect(()=>{
		if(errorD === true || errorCSP === true){
			setResultError(true)
		} else {
			setResultError(false)
		}

	}, [errorD, errorCSP])
	//--cierra 10jul24

	const {
		id_exp_prueba,
		nombre_proyecto, 
		fecha_carga,
		fecha_caratulacion,
		fecha_actualizacion, //add 25-abr-2024
		datos_05_responsable, tramite_prioritario, avanzados, expediente, programa, estado,
		tipo, partido, localidad, cantidad_lotes, cantidad_lotes_adjudicables,
		lotes_adquiridos_municipio, porc_lotes_municipales,
		datos_18_sintesis_nomenclatura,
		titular_tierra, plano_oficial,
		area, zonificacion, nro_ordenanza_vigente, decreto_promulgatorio, sistema_agua, eliminacion_excretas,
		observaciones_generales,
		datos_19_partido_nomencla, datos_20_circ_nomencla, datos_21_secc_nomencla,
		datos_22_geom
	} = inputs;
	
	const {
		solicitud_adhesion_t,
		certif_urbanistico_municipal_t, certif_no_inundabilidad_t, anteproyecto_urbano_t,
		titulo_propiedad_t, memoria_descriptiva_obras_t, energia_electrica_t, prefactibilidad_urbanistica_t,
		agua_potable_t, eliminacion_excretas_t, apertura_calles_t, acta_constitutiva_t, certif_vigencia_persona_juridica_t,
		plano_mensura_t, convenio_urbanistico_t, ordenanza_municipal_t, memoria_descriptiva_procedimientos_t, informe_dominio_t,
		anotaciones_personales_t, admisibilidad_inicial_t, prefactibilidad_hidraulica_t, certif_tecnico_urbanistico_t,
		resolucion_afectacion_t, visado_plano_t,
		observaciones_internas_t,//125
		observaciones_reporte_t,//126
		fecha_envio_prefac_h_t, expediente_prefac_h_t, fecha_emision_prefac_h_t,
		fecha_visado_plano_t, fecha_envio_resol_t, fecha_emision_resol_t, numero_especial_resol_t,
		t_127_tramitacion_ada_prefac_h,
		t_131_observaciones_prefact_h,
		t_132_tramitacion_resol,
		t_136_observaciones_resol,
		t_137_tramitacion_visado,
		t_139_fecha_entrega, //139 - ADD 20-09-2022
		t_140_observaciones_tramitacion, //140 - ADD 20-09-2022
		t_144_proyecto_hidraulico, //144 -- add 22-12-2022
		t_145_cpu_tramitacion, //145 --add 14-JUN-2024
		t_146_cpu_fecha_envio, //146 --add 14-JUN-2024
		t_147_cpu_fecha_emision, //147 --add 14-JUN-2024
		t_148_cpu_nro_gdeba, //148 --add 14-JUN-2024
		t_149_cpu_observaciones //149 --add 14-JUN-2024
	}= inputsTram;
	
	const saveExit = async e => {
		e.preventDefault();

		try {
			const body = {
				id_exp_prueba,//05-08-2022
				estadoExp, //SOlo manda una vez ("nuevo"), despues sale
				nombre_proyecto,
				fecha_carga, //estado es siempre "nuevo", calcula la fecha actual en el server y luego vuelve a inicio
				fecha_caratulacion,
				datos_05_responsable, expediente, tramite_prioritario, avanzados, //02-12-2022--> avanzados
				programa, estado, tipo, partido, localidad,
				formRows,
				titular_tierra, plano_oficial,
				cantidad_lotes, cantidad_lotes_adjudicables,
				porc_lotes_municipales, lotes_adquiridos_municipio,
				area, zonificacion, nro_ordenanza_vigente,
				decreto_promulgatorio, sistema_agua, eliminacion_excretas, observaciones_generales,
				name,//ADD 20-09-2022 para identificar usuario

				datos_18_sintesis_nomenclatura,//nuevo Draw - 17abr23
				datos_19_partido_nomencla, //nuevo Draw - 17abr23
				datos_20_circ_nomencla, //nuevo Draw - 17abr23
				datos_21_secc_nomencla, //nuevo Draw - 17abr23
				datos_22_geom, //nuevo Draw - 17abr23

				//Tramitacion
				solicitud_adhesion_t, certif_urbanistico_municipal_t, certif_no_inundabilidad_t, anteproyecto_urbano_t,
				titulo_propiedad_t, memoria_descriptiva_obras_t, energia_electrica_t, prefactibilidad_urbanistica_t,
				agua_potable_t, eliminacion_excretas_t, apertura_calles_t, acta_constitutiva_t,
				certif_vigencia_persona_juridica_t, plano_mensura_t, convenio_urbanistico_t, ordenanza_municipal_t,
				memoria_descriptiva_procedimientos_t, informe_dominio_t, anotaciones_personales_t, admisibilidad_inicial_t,
				prefactibilidad_hidraulica_t, certif_tecnico_urbanistico_t, resolucion_afectacion_t, visado_plano_t,
				observaciones_internas_t, observaciones_reporte_t,
				t_127_tramitacion_ada_prefac_h,
				fecha_envio_prefac_h_t, expediente_prefac_h_t, fecha_emision_prefac_h_t,//AGEGADO 06-04-2022
				t_131_observaciones_prefact_h, //131
				t_132_tramitacion_resol, //132
				fecha_envio_resol_t, fecha_emision_resol_t, numero_especial_resol_t,
				t_136_observaciones_resol,
				t_137_tramitacion_visado,
				fecha_visado_plano_t,
				t_139_fecha_entrega, //139 - ADD 20-09-2022
				t_140_observaciones_tramitacion, //140 - ADD 20-09-2022
				t_144_proyecto_hidraulico, //144 -- add 22-12-2022
				t_145_cpu_tramitacion, //145 --add 14-JUN-2024
				t_146_cpu_fecha_envio, //146 --add 14-JUN-2024
				t_147_cpu_fecha_emision, //147 --add 14-JUN-2024
				t_148_cpu_nro_gdeba, //148 --add 14-JUN-2024
				t_149_cpu_observaciones, //149 --add 14-JUN-2024

				//Caracteristicas
				inputsCSP
			};

			const response = await fetch(
				`/api/authentication/new_expediente`, 
				{
					method: "POST",
					headers: {
							"Content-type": "application/json"
					},
					body: JSON.stringify(body)
				}
			);

			const parseRes = await response.json();
			
			if (parseRes.id === undefined){ //cuando el estado es 'nuevo' y se ingresa un expediente que ya existe
				toast.error(`El expediente ya existe, intente nuevamente.`);
			} else if (parseRes.id !== undefined && parseRes.id === parseRes.id_exp_prueba){
				toast.success(`Expediente ${parseRes.expediente} creado correctamente!!`);
				cambioGeom({type:"FeatureCollection",features:[{"type":"Feature","properties":{},"geometry":{}}]})
				history.replace('/inicio'); //si la respuesa es OK, ahora salir
			} else if (parseRes.id !== parseRes.id_exp_prueba && parseRes.id !== 'error'){
				setEstadoExp("nuevo");
				toast.success(`Expediente ${parseRes.expediente} actualizado correctamente!!`);
				history.replace('/inicio');
			} else if (parseRes.id=== 'error' && parseRes.message === 'Ha ocurrido un error al insertar expediente'){//viene de nuevo expediente
				toast.error(`Ocurrió un error al crear el expediente, por favor intente nuevamente. Si el problema continua comunicarse con un administrador`);

			} else if (parseRes.id=== 'error' && parseRes.message === 'Ha ocurrido un error'){
				toast.error(`Ocurrió un error, por favor intente nuevamente. Si el problema continua comunicarse con un administrador.`);

			} else {
				toast.error(`Ocurrió un problema al crear el expediente, intente nuevamente.`);
			}

		} catch (err) {
			console.error(err.message);
		}
	};

	const saveSeguir = async e => {
		e.preventDefault();

		try {
			const body = {
				id_exp_prueba,
				estadoExp, //La primera vez enviar "nuevo", despues cambiar a "edicion"
				nombre_proyecto,
				fecha_carga,
				fecha_caratulacion,
				fecha_actualizacion,//estado "nuevo" no manda nada. Cuando estado "edicion" envia la fecha actual (luego ver cambios a registrar)
				datos_05_responsable, expediente, tramite_prioritario, avanzados,
				programa, estado, tipo, partido, localidad,
				formRows,
				titular_tierra, plano_oficial,
				cantidad_lotes, cantidad_lotes_adjudicables,
				porc_lotes_municipales, lotes_adquiridos_municipio,
				area, zonificacion, nro_ordenanza_vigente,
				decreto_promulgatorio, sistema_agua, eliminacion_excretas, observaciones_generales,
				name,

				datos_18_sintesis_nomenclatura,
				datos_19_partido_nomencla,//nuevo Draw - 12abr23
				datos_20_circ_nomencla, //nuevo Draw - 12abr23
				datos_21_secc_nomencla, //nuevo Draw - 12abr23
				datos_22_geom, //nuevo Draw - 12abr23

				//Tramitacion
				solicitud_adhesion_t, certif_urbanistico_municipal_t, certif_no_inundabilidad_t, anteproyecto_urbano_t,
				titulo_propiedad_t, memoria_descriptiva_obras_t, energia_electrica_t, prefactibilidad_urbanistica_t,
				agua_potable_t, eliminacion_excretas_t, apertura_calles_t, acta_constitutiva_t,
				certif_vigencia_persona_juridica_t, plano_mensura_t, convenio_urbanistico_t, ordenanza_municipal_t,
				memoria_descriptiva_procedimientos_t, informe_dominio_t, anotaciones_personales_t, admisibilidad_inicial_t,
				prefactibilidad_hidraulica_t, certif_tecnico_urbanistico_t, resolucion_afectacion_t, visado_plano_t,
				observaciones_internas_t, observaciones_reporte_t,

				t_127_tramitacion_ada_prefac_h,
				fecha_envio_prefac_h_t, expediente_prefac_h_t, fecha_emision_prefac_h_t,//AGEGADO 06-04-2022
				t_131_observaciones_prefact_h, //131
				t_132_tramitacion_resol, //132
				fecha_envio_resol_t, fecha_emision_resol_t, numero_especial_resol_t,
				t_136_observaciones_resol,
				t_137_tramitacion_visado,
				fecha_visado_plano_t,
				t_139_fecha_entrega, //139 - ADD 20-09-2022
				t_140_observaciones_tramitacion, //140 - ADD 20-09-2022
				t_144_proyecto_hidraulico, //144 -- add 22-12-2022
				t_144_proyecto_hidraulico, //144 -- add 22-12-2022
				t_145_cpu_tramitacion, //145 --add 14-JUN-2024
				t_146_cpu_fecha_envio, //146 --add 14-JUN-2024
				t_147_cpu_fecha_emision, //147 --add 14-JUN-2024
				t_148_cpu_nro_gdeba, //148 --add 14-JUN-2024
				t_149_cpu_observaciones, //149 --add 14-JUN-2024

				//Caracteristicas
				inputsCSP				
			};

			const response = await fetch(				
            	`/api/authentication/new_expediente`, 
					{
						method: "POST",
						headers: {
							"Content-type": "application/json"
						},
						body: JSON.stringify(body)
						//body2: JSON.stringify(body2)
					}
				);

			const parseRes = await response.json();

			if (parseRes.id === undefined){ //cuando el estado es 'nuevo' y se ingresa un expediente que ya existe
				toast.error(`El expediente ya existe, intente nuevamente.`);

			} else if (parseRes.id !== undefined && parseRes.id === parseRes.id_exp_prueba){
				setEstadoExp("edicion");
				setInputs({	...inputs, id_exp_prueba : parseRes.id_exp_prueba, fecha_actualizacion: parseRes.fecha_actualizacion});
				toast.success(`Expediente ${parseRes.expediente} creado correctamente!!`);
				cambioOriginal(0);

			} else if (parseRes.id !== parseRes.id_exp_prueba && parseRes.id !== 'error'){
				toast.success(`Expediente ${parseRes.expediente} actualizado correctamente!!`);
				cambioOriginal(0);

			} else if (parseRes.id=== 'error' && parseRes.message === 'Ha ocurrido un error al EDITAR expediente'){
				toast.error(`Ocurrió un error al editar el expediente.`);
			} else if (parseRes.id=== 'error' && parseRes.message === 'Ha ocurrido un error'){
				toast.error(`Ocurrió un error, por favor intente nuevamente. Si el problema continua comunicarse con un administrador`);
			} else {
				toast.error(`Ocurrió un problema al crear el expediente, por favor intente nuevamente.`);
			}
		} catch (err) {
			console.error(err.message);
		}
	};

	const handleTramite = () => {
		setOpenE(false);
		setOpenT(true);
		setOpenP3(false);
	}

	const handleExpediente = () => {
		setOpenE(true);
		setOpenT(false);
		setOpenP3(false);
  	}

	const handlePestaña3 = () => {
		setOpenE(false);
		setOpenT(false);
		setOpenP3(true);
	}

	//NOTA 12-08-2022: Se simplifica funcion según state
	const submButton = () => {
		return (
			<Fragment>
				<Button
					variant="secondary" onClick={handleExpediente}
					size="sm" type="info"
					aria-controls="example-collapse-textT"
					aria-expanded={openExpediente}
					disabled={openExpediente ? true : false}
				>
					DATOS GENERALES
				</Button>

				<Button
					variant="secondary" onClick={handleTramite}
					size="sm" type="info"
					aria-controls="example-collapse-textEx"
					aria-expanded={openTramite}
					style={{ alignSelf: "flex-end" }}
					disabled={openTramite ? true : false}
				>
					TRAMITACIÓN DEL EXPEDIENTE
				</Button>

				<Button
					variant="secondary" onClick={handlePestaña3}
					size="sm" type="info"
					aria-controls="example-collapse-textP3"
					aria-expanded={openPestaña3}
					disabled= {openPestaña3 ? true : false}
				>
					CARACTERÍSTICAS Y SEGUIMIENTO DEL PROYECTO
				</Button>
			</Fragment>
		)
	}

	const [openExpediente, setOpenE] = useState(true);
	const [openTramite, setOpenT] = useState(false);
	const [openPestaña3, setOpenP3] = useState(false);

  	return (
		<Fragment>
			
			<Navigation className="" setAuth={setAuth} activo={activo} /> {/*name={name} role={role} setRole={setRole}*/}

			<div className="main" >

				<div style={{width:"100%", height:"130px", border: "0px solid yellow", position: "sticky", top: "0px" }} className="div_stick">

					<div  style={{borderTop: "1px solid white", background: "", height: "130px", position: "sticky", top: "10px"}} className="">{/*position:"sticky", top:"0px",*/}

						<h4 className="mt-5 text-center">Nuevo Expediente</h4>

						<div className="d-flex">
							<div style={{position:"absolute", bottom:"5px"}} className="align-right-menu">
								{submButton()}
							</div>

						</div>{/* cierra d-flex*/}
					</div> {/* cierra linea 587*/}
				</div> {/* cierra div_stick*/}

				<div className="">
				<Form id='my-form'>
					{/* Abre el div pestaña Datos Generales- probar llamar componente desde otro archivo */}

					{/* Pestaña Datos del expediente */}
					{/*setOriginal={cambiarOriginal}*/}
					<div>{/*className="position-fixed" style={{ top:"500px" }}*/}
						<Collapse in={openExpediente} dimension="width">
							<div style={{marginTop:"20px", marginBottom:"10px"}} id="example-collapse-textEx">
								<DatosExp
									name={name}
									inputs={inputs} setInputs={cargaExpediente}
									setErrorD = {recibirErrorD} 
									formRows={formRows} setFormRows={handleChangeRow}
								/>
							</div>
						</Collapse>
					</div>

					{/* Pestaña Tramitacion del expediente */}
					{/*setOriginal={cambiarOriginal}*/}
					<div className="" >
					<Collapse in={openTramite} dimension="width">
						<div style={{marginTop:"20px", marginBottom:"10px"}} id="example-collapse-textT">
							<TramiteExp
								inputsTram={inputsTram} setInputsTram={cargaTramite}
								inputs={inputs} setInputs={cargaExpediente}
							/>
						</div>
					</Collapse>
					</div>
					{/* div pestaña3 colapsada - importar component */}
					{/*setOriginal={cambiarOriginal}*/}
					<div className="" >
					<Collapse in={openPestaña3} dimension="width">
						<div style={{marginTop:"20px", marginBottom:"10px"}} id="example-collapse-textP3">
							<Pestaña3_Exp
								inputsCSP={inputsCSP} setInputsCSP={cargarCSP} 
								setErrorCSP={recibirErrorCSP}
								partidoDG ={partido} lotesAdjudicables={cantidad_lotes_adjudicables}
							/>
						</div>
					</Collapse>
					</div>

					<div style={{background: "white", width:"100%", height:"45px", borderBottom: "4px solid white", position: "sticky", marginTop:"", bottom: "0px"}} className="div_stick">{/*13-08-22*/}
						{/*<div style={{borderTop: "0px solid red", background: "", height: "50px", position: "sticky", bottom: "1px"}} className="">*/}
						<div className="flexbox-container">
							{/* <Button form='my-form' type="submit" className="btn btn-success btn-block"  >Guardar y Continuar</Button> ***era Submit */}
							<Button form='my-form' type="button" disabled={ role ==="visualizador" || role==="user" || original ===0 || sesionT ==='no_token' || resultError===true ? true : false } className="btn btn-success btn-block button_verde" onClick={redirect_Cont}>Guardar y Continuar</Button> {/*era Submit */}
							<Button type="button" id="but_new_save_e2" disabled={ role ==="visualizador" || role==="user" || original ===0 || sesionT ==='no_token' || resultError===true ? true : false } className="btn btn-success btn-block button_verde" onClick={redirect}>Guardar y Salir</Button>
							{ resultError===true 
							?
							<p><Button type="button" id="but_new_save_e2" className="btn btn-success btn-block button_rojo" ><FaExclamationTriangle /></Button>
							<a align="left" style={{ height: "auto", fontSize: "0.9em", color: "red" }}> 
							{` - Verificar datos`}
							{errorD === true && errorCSP === false ? ` en pestaña: Datos generales`: ``}
							{errorD === false && errorCSP === true ? ` en pestaña: Características y seguimiento del proyecto`: ``}
							{errorD === true && errorCSP === true ? ` en pestañas: Datos generales - Características y seguimiento del proyecto`: ``}</a>
							</p>
							:
							<a></a>}
						</div>
					</div>

				</Form>
				</div>
			</div> {/* cierra el Main */}

			<Footer/>				
		</Fragment>
  	);
};

export default Expediente;
