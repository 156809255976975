import React, { Fragment } from "react";

//import { toast } from "react-toastify";
//import dotenv from "dotenv";

import Navigation from "./navigation";
import Footer from "./footer";
//import Results from "./results";
import SelectT from "./select";

//const baseURL = process.env.REACT_APP_API_URL;

const Dashboard = ({ setAuth, role, setRole }) => {
	//console.log(setAuth);

  /*const getProfile = async () => {
	//console.log("Dashboard");
    try {
      const res = await fetch("http://localhost:5003/dashboard/", { //5000
        method: "POST",
        headers: { jwt_token: localStorage.token }
      });

      const parseData = await res.json();
      setName(parseData.username);
      //console.log(name);
    } catch (err) {
      console.error(err.message);
    }
  };*/

  /*const logout = async e => {
    e.preventDefault();
    try {
      localStorage.removeItem("token");
      setAuth(false);
      toast.success("Logout successfully");
    } catch (err) {
      console.error(err.message);
    }
  };*/

	/*useEffect(() => {
		getProfile();
	}, []);*/

  return (
    <Fragment>
      <Navigation setAuth={setAuth} role={role} setRole={setRole} />
      <div className="main">
        <h2 className="mt-5">Dashboard</h2>
        {/* <h3>Bienvenido {name}</h3>	*/}
        <h5>Bienvenido</h5>

        <SelectT />
      </div>
      <Footer />
    </Fragment>
  );
};

export default Dashboard;
