import React, {Fragment, useState, useEffect, useContext } from 'react';
import { SessionContext } from '../../context/sessionContext';
import { Table, Row, Col, Button} from "react-bootstrap";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Modal from 'react-bootstrap/Modal';

import Select from 'react-select';
import SelectInput from "../selectInput";

import {optionsEstado_Todos, optionsTramitacionCPU_Todos, optionsTramitacionCPU } from "../../datos/options";

import { toast } from "react-toastify";

const Select_6_cpu = ({ openReporte_6_cpu }) => {

	const { roleA, sesionT } = useContext(SessionContext);
	const name = roleA.username;

	//**18-jun-2024 */
	const [datosS, setDatos] = useState(null);// ESTADO para recibir datos de la consulta
	console.log("DATOSSSS CPU--->", datosS);

	const [datosOriginal, setDatosOriginales] = useState(null); //Inmutable: aplicar filtro sobre este array guardarlo en datosS
	//console.log(datosOriginal);

	const url = `/api/api_serv/reportes/reporte6_CPU/`;

	useEffect(() => {
		if (openReporte_6_cpu === true && datosS === null) {
			console.log("VISADO es true?", openReporte_6_cpu) //mover todo acá???	
			getDatos(url);	//console.log("_____________________LLAMANDO DATOS RESOLUCION");			
	
		} else if (openReporte_6_cpu === false) {
			console.log("NO SE DIO CLICK - es false??", openReporte_6_cpu)
		}		
	},[openReporte_6_cpu]); // POR AHORA UNA SOLA VEZ, //NO  ---> SOLO CUANDO CAMBIO EL estado LLAMAR NUEVAMENTE // 

	//**consultar los datos para el reporte2
	const getDatos = async (urlM) => {

		const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token }

		const res = await fetch(urlM, { headers })
			.then(res => res.json())
			.then(res => {
				setDatos(res)
				setDatosOriginales(res)
			})
			.catch(err => console.log(err));
	}

	//---cierra 18-jun-2024 */

	//******23-08-2022
	const onChange = (e) => {
		setEditRegistro({...editRegistro, [e.target.name] : e.target.value});
		setOriginal(1);
	}

	const handleFecha1 = (item) => {
		
		const fecha = item.value;
		
		setEditRegistro({ ...editRegistro, [item.name]: fecha });
		setOriginal(1);
	}

	const [original, setOriginal] = useState(0); //estado original en 0; si cambia a uno habilitar el boton de guardar

	const [show, setShow] = useState(false);

  	const handleClose = () => {
		setShow(false);
		setOriginal(0);
	};

	//1er - crear un estado con null o "" por default. Splo para los 5 (nuevos) campos de Tramitacion CPU
	const [editRegistro, setEditRegistro] = useState({
		id_exp_pruebaE : "",
		t_145_cpu_tramitacion_E: "", //145 --add 14-JUN-2024
		t_146_cpu_fecha_envio_E: null, //146 --add 14-JUN-2024
		t_147_cpu_fecha_emision_E: null, //147 --add 14-JUN-2024
		t_148_cpu_nro_gdeba_E: "", //148 --add 14-JUN-2024
		t_149_cpu_observaciones_E: "" //149 --add 14-JUN-2024
	});
	console.log("REGISTRO", editRegistro);

	const {
		id_exp_pruebaE,
		t_145_cpu_tramitacion_E, t_146_cpu_fecha_envio_E,
		t_147_cpu_fecha_emision_E, t_148_cpu_nro_gdeba_E,
		t_149_cpu_observaciones_E
	} = editRegistro;

	const guardarEditRegistro = async e => {
		e.preventDefault();
		
		try {
			const body = {
				editRegistro,
				name
			}

			const response = await fetch(
				`/api/authentication/reporteCPU_edit_expediente`, //`${baseURL}authentication/reporteADA_edit_expediente`
				{
				method: "POST",
				headers: {
					"Content-type": "application/json"
				},
				body: JSON.stringify(body)
				}
			);

			const parseRes = await response.json();

			if (parseRes.expediente){
				toast.success(`Expediente ${parseRes.expediente} actualizado!!`);
					setShow(false);
					getDatos(url);//30-08-2022: si hubo cambio, volver a llamar
					//setEdit(false); //20-04-2022
			} else {
				//console.log("error");
				toast.error(parseRes.message);
			}

		} catch(err){
			console.error(err.message);
		}
	}

	//*****cierra 23-08-2022

	

	const [filtro, setFiltro] = useState({ //PROBAR con 2 filtros
		t_127_tramitacion_ada_prefac_h: "",
		expediente: "",
		partido: 0,
		nombre_proyecto: "",
		expediente_prefac_h_t: "",
		fecha_envio_prefac_h_t: "",
		fecha_emision_prefac_h_t: "",
		t_131_observaciones_prefact_h: ""
	});
	const {t_127_tramitacion_ada_prefac_h, expediente, partido, nombre_proyecto, expediente_prefac_h_t, fecha_envio_prefac_h_t, fecha_emision_prefac_h_t, t_131_observaciones_prefact_h} = filtro;
	

	

	useEffect(() => {
		//console.log("_____________________LLAMANDO DATOS ADA");
		getDatos(url);
	},[]); // POR AHORA UNA SOLA VEZ, //NO  ---> SOLO CUANDO CAMBIO EL estado LLAMAR NUEVAMENTE // COMENTAR filtro.estado	
	
	const [arrayBusqueda, setArrayBusqueda]= useState({
		//"partidoB" : 0,
		"tramitacionCPU_B": "",
		"estadoExp_B": ""		
	});
	console.log("BUSQUEDA CPU-->",arrayBusqueda)

	const [searchEstado, setSearchEstado] = useState("");

	//**SELECCIONAR campos tabla madre***

	const [municipioMadre, setMunicipioMadre] = useState("");

	const [datosMadre, setDatosMadre] = useState(null);

	//activo: true son los que mostraria inicialmente.
	const [camposInicial, setCamposInicial] = useState([
		{label:'Partido', activo:'true', campo:'partido_nombre'},
		{label:'Expediente', activo:'true', campo:'expediente'},
		{label:'Estado', activo:'false', campo:'estado'},
		{label:'Alias', activo:'true', campo:'nombre_proyecto'},
		{label:'Programa', activo:'false', campo:'programa'},
		{label:'Tipo', activo:'false', campo:'tipo'},
		{label:'Titular', activo:'false', campo:'titular_tierra'},
		{label:'Cantidad lotes', activo:'false', campo:'cantidad_lotes'},
		{label:'Lotes captados municipio', activo:'false', campo:'lotes_adquiridos_municipio'},
		{label:'Nomenclatura catastral', activo:'false', campo:'datos_18_sintesis_nomenclatura'},
	]);

	//***TODO PARA SEARCH***********
	//****************************04-05***CADA VEZ que CAMBIA arrayBusqueda

	useEffect(()=>{

		if(datosS !== null){

			var regexTramitCPU = new RegExp(`${arrayBusqueda.tramitacionCPU_B}$`, 'i');
			var regexEstado = new RegExp(arrayBusqueda.estadoExp_B, 'i');
			
			const filtradoP = datosOriginal.filter((item, index) =>{				

				return regexTramitCPU.test(item.t_145_cpu_tramitacion) && regexEstado.test(item.estado) 
				
			})
			setDatos(filtradoP);
		}

	},[arrayBusqueda, datosOriginal]);		

	

	//***Tramitacion CPU*** POR AHORA NO FILTRAR POR ALIAS 19-06-2024

	const handleOnChangeTramitacionCPU = (e) => {
		if(e.value){
			if(e.value !== null){
				setArrayBusqueda({...arrayBusqueda,  "tramitacionCPU_B":e.value} )
			}
		} else if( e.value === ""){
			setArrayBusqueda({...arrayBusqueda,  "tramitacionCPU_B":e.value} )

		} else if (e.target.value){
			if(e.target.value !== null){
				setArrayBusqueda({...arrayBusqueda,  "tramitacionCPU_B":e.target.value} )
			}
		} else {
			setArrayBusqueda({...arrayBusqueda,  "tramitacionCPU_B":""} )
		}
	};

	const handleOnChangeEstado = (e) =>{
		console.log(e.target.value)
		console.log(e.target.name)
		
		if(e.target.value){
			if(e.target.value !== null){
				setArrayBusqueda({...arrayBusqueda, "estadoExp_B":e.target.value})
			}
		}
	}

	const columnas = ["Expediente","Partido","Alias", "Tramitación CPU", "Fecha envío", "Fecha emisión", "Nro. GDEBA", "Observaciones"];

	const getHeader = (columnas)=>{

			return columnas.map((columna, index) => {
				if (columna === "Expediente"){
					return <th> {columna} </th>
				} else if (columna === "Partido") {
					return <th>
						{columna}
					</th>				
				} else if (columna === "Alias") {
					return <th style={{ width:"100px"}}> {columna} </th>
				} else {
					return <th>{columna}</th>
				}
			})	
	}

	const getRowsData = (datosS)=> {
		if(datosS !== null){

			return datosS.map((registro, index )=> {

				var soloFechaEnvio = '';
				var dateEdicionEnvio = '';

				if(registro.t_146_cpu_fecha_envio !== null){

					let date = registro.t_146_cpu_fecha_envio;				
					let newFecha = new Date(date);
					newFecha.setMinutes(newFecha.getMinutes() + newFecha.getTimezoneOffset())

					let fecha = newFecha.toLocaleString().split(',')[0];

					//****PARA la tabla;
					soloFechaEnvio= fecha;
					//***PARA el formulario de edicion
					dateEdicionEnvio = new Date(date);
					dateEdicionEnvio.setMinutes(dateEdicionEnvio.getMinutes() + dateEdicionEnvio.getTimezoneOffset())

				} else {
					var soloFechaEnvio = '';
					var dateEdicionEnvio = '';
				}

				var soloFechaEmision = '';
				var dateEdicionEmision = '';

				if(registro.t_147_cpu_fecha_emision !== null){

					let date = registro.t_147_cpu_fecha_emision;
					let newFecha = new Date(date);
					newFecha.setMinutes(newFecha.getMinutes() + newFecha.getTimezoneOffset())

					let fecha = newFecha.toLocaleString().split(',')[0];

					//****PARA la tabla;
					soloFechaEmision = fecha;
					//***PARA el formulario de edicion
					dateEdicionEmision =  new Date(date);
					dateEdicionEmision.setMinutes(dateEdicionEmision.getMinutes() + dateEdicionEmision.getTimezoneOffset())

				} else {
					var soloFechaEmision = '';
					var dateEdicionEmision = '';
				}

				
				var expediente = registro.expediente;
				var estado = registro.estado;
				var nombre_proyecto = registro.alias;//nombre_proyecto
				var tramitacion_cpu = registro.t_145_cpu_tramitacion;
				var fecha_envio = soloFechaEnvio; ////***USADO para la tabla
				var fecha_emision = soloFechaEmision; //***USADO para la tabla
				var nro_gdeba = registro.t_148_cpu_nro_gdeba;
				var observaciones_cpu = registro.t_149_cpu_observaciones;

				var rowClick = (e)=>{
					e.preventDefault();

					//console.log('double click row - ABRIR VENTANA EDICION');
					setShow(true);
					//2do - cambiar estado
					setEditRegistro({
						...editRegistro,
						id_exp_pruebaE: registro.id_exp_prueba,						

						t_145_cpu_tramitacion_E: tramitacion_cpu, //145 --add 14-JUN-2024
						t_146_cpu_fecha_envio_E: dateEdicionEnvio, //146 --add 14-JUN-2024
						t_147_cpu_fecha_emision_E: dateEdicionEmision, //147 --add 14-JUN-2024
						t_148_cpu_nro_gdeba_E: nro_gdeba, //148 --add 14-JUN-2024
						t_149_cpu_observaciones_E: observaciones_cpu //149 --add 14-JUN-2024
					})
				}

				return <tr id={index} onDoubleClick={rowClick} >					
					<td>{expediente}</td>
					<td>{registro.partido_nombre}</td>
					<td>{nombre_proyecto}</td>
					<td>{tramitacion_cpu}</td>
					<td>{fecha_envio}</td>
					<td>{fecha_emision}</td>
					<td>{nro_gdeba}</td>	
					<td>{observaciones_cpu}</td>
				</tr>;
			})

		}
	}

	if (datosS === null) {

		return(
			<Fragment>
				<p>INICIO hacer consulta</p>

				<div id="tabla_TODO" style={{ padding:"2px", width:"100%" }} className="">
					<div className="" style={{ width:"100%", overflow:"scroll"}} >
						{/*<Table stripped bordered hover responsive="md" >
							<thead >
								<tr>{getHeader(columnas)}</tr>
							</thead>
						</Table>*/}



						<Table stripped bordered hover responsive="md" >
							<thead >
								<tr id={0} style={{backgroundColor:"rgb(0,174,195)", color:"white"}}>{getHeader(columnas)}</tr>
							</thead>
							<tbody>
								{ getRowsData(datosS) }
							</tbody>
						</Table>

					</div>
				</div>

			</Fragment>
		)
	} else {
		//console.log("YA NO ES NULL");
		return (
			<Fragment>
				<br/>

				<div className="" style={{ width:"100%" }}> {/*div A, border:"2px solid #333" */}
					<div>
						<ReactHTMLTableToExcel
							//id="uno-table-xls-button"
							className="btn btn-info text_button"
							table="cpu-to-xls"
							filename="tramitaciones_cpu"
							sheet="tablexls"
							buttonText="Descargar XLS"
						/>
					</div>
					{/*`buscar: Partido:{partido || '____'}--Estado:{estado  || '____'} -- priorit:{prioritario  || '____'}-- Programa:{programa || '____'}--  Tipo:{tipo  || '____'}-- `*/}
				</div>{/* cierra div A*/}


				<div id="tabla_TODO" style={{ padding:"2px", width:"100%" }} className=""> {/*, border:"2px solid blue" */}
					<div className="" style={{ width:"100%", overflow:"scroll"}} > {/*border:"2px solid red",*/}

						<div className="container_left">

							<div className="input-container" style={{ width: "25%" }}>
								<SelectInput
									name="tramitacion_CPU"
									id="id_tramitacion_CPU"
									options={optionsTramitacionCPU_Todos}
									onChange={e => handleOnChangeTramitacionCPU(e)}
								/>
								<label alt="Tramitación CPU" for="id_tramitacion_CPU">Tramitación CPU</label>
							</div>
							<div className="input-container" style={{ width: "1%" }} />
							<div className="input-container" style={{ width: "25%" }}>
								<SelectInput
									name="estadoExp"
									id="id_estadoExp"
									options={optionsEstado_Todos}
									onChange={e => handleOnChangeEstado(e)}
								/>
								<label alt="Estado" for="id_tramitacion_CPU">Estado</label>
							</div>

						</div>{/*cierra container_left*/}


						<Table className="tableExp" id="cpu-to-xls" stripped bordered hover responsive="md" >
							<thead >
								<tr style={{backgroundColor:"rgb(0,174,195)", color:"white"}}>{getHeader(columnas)}</tr>
							</thead>
							<tbody>
								{ getRowsData(datosS) }
							</tbody>
						</Table>

					</div>
				</div>

				<Modal
					id="id_modal_1"
					show={show}
					onHide={handleClose}
					backdrop="static"
					keyboard={false}
					className="id_modal_1"
					style={{top: "200"}}
				>
					<Modal.Header style={{background: "rgb(0,174,195)", color:"white"}} closeButton>
						<Modal.Title>Edición {/*id_exp_pruebaE*/}</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						<div>
							<div className="container_left">
								<h6 className="mt-4 text-left subtitulos">TRAMITACIÓN CPU</h6>
							</div>

							<div className="container_left">
								<div className="input-container">
									<SelectInput
										name="t_145_cpu_tramitacion_E"
										id="id_t_145_cpu_tramitacion"
										defaultValue = {t_145_cpu_tramitacion_E}
										onChange={e => onChange(e)}
										options={optionsTramitacionCPU_Todos}
									/>
									<label alt="Tramitación CPU" for="id_t_145_cpu_tramitacion">Tramitación CPU</label>
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}

							<div className="container_left">
								<div className="input-container">
									<DatePicker
										title="Fecha de envío"
										name="t_146_cpu_fecha_envio_E"
										id="id_t_146_cpu_fecha_envio"
										dateFormat="dd/MM/yyyy"
										className="form-control"
										selected={t_146_cpu_fecha_envio_E}
										onChange={ (date) => handleFecha1({ name: "t_146_cpu_fecha_envio_E", value: date })}
									/>
									<label className="label-fecha" alt="Fecha de envío" for="id_t_146_cpu_fecha_envio">Fecha de envío</label>
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}
							
							<div className="container_left">
								<div className="input-container">
									<DatePicker
										title="Fecha de emisión"
										name="t_147_cpu_fecha_emision_E"
										id="id_t_147_cpu_fecha_emision_E"
										dateFormat="dd/MM/yyyy"
										className="form-control"
										selected={t_147_cpu_fecha_emision_E}
										onChange={ (date) => handleFecha1({ name: "t_147_cpu_fecha_emision_E", value: date })}
									/>
									<label alt="Fecha de emisión" for="id_t_147_cpu_fecha_emision_E">Fecha de emisión</label>
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}

							<div className="container_left">
								<div className="input-container">
									<input
										title="Nro. GDEBA"
										type="text"
										id="id_t_148_cpu_nro_gdeba"
										maxLength="35"
										value = {t_148_cpu_nro_gdeba_E}
										name="t_148_cpu_nro_gdeba_E"
										onChange={e => onChange(e)}
									/>
									<label alt="Nro. GDEBA" for="id_t_148_cpu_nro_gdeba">Nro. GDEBA</label> {/* 128 */}
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}


							<div className="container_left" style={{ height: "auto"}}>
								<div className="input-container">
									<textarea
										title="Observaciones tramitación CPU"
										type="text"
										id="id_t_149_cpu_observaciones_E"
										maxLength="500"
										name="t_149_cpu_observaciones_E"
										value={t_149_cpu_observaciones_E}
										onChange={e => onChange(e)}
									/>
									<label alt="Observaciones tramitación CPU" for="id_t_149_cpu_observaciones_E">Observaciones tramitación CPU </label>
									{/*<p align="right" style={{ fontSize: "0.9em"}}>Caracteres restantes:</p>*/}  {/*maxObservTramADA*/}
								</div>{/*cierra ---> input-container */}
							</div>

						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className="btn btn-success btn-block button_verde" onClick={guardarEditRegistro} disabled={original ===0 ? true : false }>	Guardar </Button>
						<Button className="btn btn-success btn-block button_verde" onClick={handleClose}> Cancelar </Button>
					</Modal.Footer>
				</Modal>

			</Fragment>
		);
	}
}

export default Select_6_cpu;
