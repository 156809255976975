import React, {Fragment} from "react";
import { Table, Button } from "react-bootstrap";

import '../../table_report.css';
import {campos_tr_actuaciones} from "../../datos/columns";

const TablaInf2_Actuaciones= ({datos_Inf2_Actuaciones, columnas}) => {	
  
	const crearHeaderActuaciones = (columnas) => {
		if (columnas !== null) {
			return columnas.map((columna, index) => {
				return <th key={index} >{columna}</th>
			});
		}
	}

	//CAMBIO 28jun24: nueva function para cambiar true/false a si/no
	const changeTrueFalse = (registro) =>{
		if(registro === "true"){
			return "Si"
		} else if(registro === "false"){
			return "No"
		} else {
			return ""
		}
	}

	const formatearFecha = (dato) => {

		let _fecha = null;
		_fecha = new Date(dato)
		_fecha.setMinutes(_fecha.getMinutes() + _fecha.getTimezoneOffset())
	
		return _fecha.toLocaleString().split(',')[0];
	}
	
	const formatearFecha_2 = (dato) => {
	
		//para revertir fecha, por ejemplo (2024-06-12) a (12/06/2024)
		let _fecha = dato.split("-").reverse().join("/");
		
		return _fecha	
	}

	const crearRowsDataActuaciones = (datosA) => {
		if (datosA !== null) {
			return datosA.map((registro, index) => {

				//MEJORA***29-jun-2024
				let regA = registro;
				//let keys = Object.keys(regA); // devuelve un array				

				let a = campos_tr_actuaciones.map((campo, index)=>{

					let c = regA[campo];

					const regexFecha = /T00:00:00.000Z/;
					const busqueda = regexFecha.test(c);

					const regexFecha_2 = /\d{4}-\d{1,2}-\d{1,2}/;
					const busqueda_2 = regexFecha_2.test(c)

					if (c === "true" || c === "false") {
						let b = changeTrueFalse(c)
						return <td>{b}</td>

					} else if (busqueda === true) {
						let d = formatearFecha(c)
						return <td>{d}</td>
					} else if (busqueda_2 === true) {
						let f = formatearFecha_2(c)
						return <td>{f}</td>
					} else {
						return <td>{c}</td>
					}
				})

				return <tr> {a} </tr>;				
			})
		}
	}

	if (datos_Inf2_Actuaciones === null) {
		return (
			<p>Cargar Actuaciones...</p>
		)
	} else if (datos_Inf2_Actuaciones !== null) {
		return (
			<Fragment>
				<h4></h4>
				<p className="subtitulos">Informe actuaciones</p>
				<div>
					<Table id="table-actuaciones-to-xls" className="tableExp" stripped bordered hover responsive="md" >
						<thead>
							<tr style={{ backgroundColor: "rgb(0,174,195)", color: "white" }}>{crearHeaderActuaciones(columnas)}</tr>
						</thead>
						<tbody>
							{crearRowsDataActuaciones(datos_Inf2_Actuaciones)}
						</tbody>
					</Table>
				</div>
			</Fragment>
		)
	}
};

export default TablaInf2_Actuaciones;
