import React, { Fragment } from "react";

import SelectInf_Detallado from "./select_inf_detallado";

const Informe_2_detallado =  ({name}) => {
  return (
    <Fragment>

      <div style={{ width:"100%" }}>                
        <SelectInf_Detallado name={name}/>
      </div>
      {/* <LeafletMapProyectos /> */}
    </Fragment>
  );
}

export default Informe_2_detallado;
