import React, { Fragment, useState, useEffect, useContext } from "react";
import { FaEye, FaEyeSlash} from 'react-icons/fa';
import { SessionContext } from '../context/sessionContext';
import { Link } from "react-router-dom";

import { toast } from "react-toastify";

//import Header from "./header";
import Footer_login from "./footer_login";

import {List} from "./ui/list";
import {posts} from "../datos/list_documents";

const Login = ({  setAuth, ...props }) => { //role, setRole,

	const { roleA, setRoleA, setCodValidez, setUsuario } = useContext(SessionContext);

	const [activo, setActivo] = useState(0);

	const [inputs, setInputs] = useState({
		email: "",
		password: ""
	});

  	const { email, password } = inputs;

	const [lista, setLista] = useState([]);

	useEffect(() => {
		setLista(posts)
	}, []);	

	//**ADD 31-agos-2023 */
	const [passwordType, setPasswordType] = useState("password");
	
    const togglePassword =()=>{
		console.log("click")
		if(passwordType==="password")
		{
		setPasswordType("text")
		return;
		}
		setPasswordType("password")
    }
	//**---cierra ADD */

	const onChange = e => {
  	setInputs({ ...inputs, [e.target.name]: e.target.value });
	}

	const onSubmitForm = async e => {
		e.preventDefault();
		setActivo(1);

		try {
			const body = { email, password };
			const response = await fetch(
				`/api/authentication/login`, // #Df1 ---> Ver mail Dockerizacion SUBSE
				{
					method: "POST",
					headers: {
							"Content-type": "application/json"
					},
					body: JSON.stringify(body)
				}
			);			

			const parseRes = await response.json();

			if (parseRes.jwtToken) {
				localStorage.setItem("tokenT", parseRes.jwtToken); //jwtToken
				localStorage.setItem("roleT", parseRes.role);
				localStorage.setItem("username", parseRes.username);
				localStorage.setItem("validezT", parseRes.validez );
				localStorage.setItem("tokenActualizacion", parseRes.refreshJwtToken)

				setCodValidez(parseRes.validez);// **add 24-agost-2023 -pasar a sessionContext
				setUsuario(parseRes.usuario);// **add 24-agos-2023 -pasar a sessionContext

				setRoleA({roleA: parseRes.role, username: parseRes.username});
				
				setAuth(true);
				toast.success("Logged in Successfully");
			} else {
				setActivo(0);
				setAuth(false);
				toast.error(parseRes);
			}
		} catch (err) {
			console.error(err.message);
			setActivo(0);
			setAuth(false);
		}
	};

	const fontStyles = {color: 'cyan', fontSize: '20px'};

  return (
    <Fragment>
		{/*<Header />*/}
		<div className="container_login">
		<h1 className="mt-5 text-center">GESTIÓN DE PROYECTOS</h1>
			<h2 className="mt-5 text-center">Dirección Provincial de Producción de Hábitat</h2>

			<div className="container_row">
				<div id="login_col1" className="container_div_column">
					<div style={{ paddingLeft: "100px", paddingRight: "100px" }}>

						<h6 className="mt-5 text-left" style={{ color: 'white'}}>DOCUMENTOS DE LA DIRECCIÓN</h6>
						<p></p>
						<List id="id_ul" lista={lista}></List>						

					</div>					
				</div>

				<div id="login_col2" className="container_div_column">
					
					<form style={{ paddingLeft: "100px", paddingRight: "100px" }} onSubmit={onSubmitForm}>
						<h6 className="mt-5 text-left" style={{ color: 'white'}}>LOGIN</h6>
						<div className="input-wrapper">
							<input
								type="text"
								name="email"
								value={email}
								placeholder="Ingresar con email o usuario"
								onChange={e => onChange(e)}
								className="form-control my-3"
							/>
						</div>
						<div className="input-wrapper">
							<input
								name="password"
								value={password}
								type={passwordType}
								//onChange={handlePasswordChange}
								//value={passwordInput}
								placeholder="Ingresar contraseña"
								onChange={e => onChange(e)}
								className="form-control my-3"
							/>
							{/*<FaEye className="input-icon password" onClick={togglePassword}/>*/}
							{passwordType === "password" ? <FaEye className="input-icon password" onClick={togglePassword} /> : <FaEyeSlash className="input-icon password" onClick={togglePassword} />}
						</div>
						<div className="input-wrapper">
							<Link style={{ color: 'white', textDecoration: 'none' }} to="/forgotpassword"> Olvidé mi contraseña </Link>
						</div>
						<button className="btn btn-success btn-block" disabled={activo === 0 ? false : true} >Ingresar</button>
					</form>
				</div>

			</div>			
			
		</div>
		{/*<Footer_login/>*/}
    </Fragment>
  );
};

export default Login;
