import React, {Fragment} from "react";
import { toast } from "react-toastify";
import L from 'leaflet';// importar para poder manejar el mapa de Leaflet

const Table = ({data, mapa, layer})=> {
	console.log({data});
	console.log(mapa);
	console.log(layer);

	var geojsonLayer;

	var data = {data};
	const x = document.getElementById('map');
	console.log(x);
	//console.log(x._layers);
	//console.log(mapRef.current);
	/*if (x.hasLayer(geoJsonRef)){
		console.log('true')
	} else {console.log('false')}
	console.log(geoJsonRef);*/
	const getKeys = (data) => {
		var data = data;
		/*console.log(data.features);
		data.features.map((row) => (
			console.log(row.features)
		))*/
		//var llaves = Object.keys(data[0]);
		var llaves = ["uno","dos"];
		console.log(llaves);
		return llaves;
 	}	
 
 	const getHeader = (data) => {
		var datA = data;
		//console.log(datA);
 		//var keys = getKeys(data);
		//console.log(keys)

		if(datA.data.features.length > 0){
			var primer_row= datA.data.features[0].properties;

			var llaves = Object.keys(primer_row);
			console.log(llaves);
		
			return llaves.map((key, index)=>{
 				return <th key={key}>{key.toUpperCase()}</th>
 			})
		}
 	}
 
 	const getRowsData = (data) => {
 		var datA = data;
		if(datA.data.features.length > 0){

			var primer_row= datA.data.features[0].properties;
			var key = Object.keys(primer_row);

			var array = datA.data.features.map((row, index) => {
			//return datA.data.features.map((row, index) => {
				console.log(row.properties);
				console.log(index);

				return row.properties;

			});
			//console.log(array);

			//https://dev.to/boxofcereal/how-to-generate-a-table-from-json-data-with-es6-methods-2eel
			return  array
    				.map((row, index) => {
					//console.log(row);
					var idTr2 = row.id;
					//console.log(idTr2);
					var idTr = index;
					//console.log('array map');
      					var tds = key.map(col => {
						//console.log(col);
						//console.log(row[col]);
						var valor_td = row[col];
						return <td>{valor_td}</td>
						//return `<td>{valor_td}</td>` //*** no funcionaron las backticks no el ${valor_td}---> ???
						//ejemplo https://dev.to/abdulbasit313/an-easy-way-to-create-a-customize-dynamic-table-in-react-js-3igg
						//return <th key={index}>{key.toUpperCase()}</th>

					});
					//console.log(tds);
					var rowClick = (e)=>{
						console.log('click row');
						console.log(e.target);
						const idSelectTR = e.target.parentElement.id;
						//console.log(idSelectTR);
						selectRow(idSelectTR);
					}
					
      					return <tr id={idTr2} onClick={rowClick}>{tds}</tr>;
    				});//el código d eejemplo usaba .join(""); ---> no funcionaba, lo quite
			
		}
		
		function selectRow(tr){
			//console.log(tr);
			var trId= tr;
			//mapa.setView([-27.8419, -65.0315], 12);	
			//console.log(mapa._layers);
			//console.log(data.data);

			data.data.features.map((item) => {

				//console.log(item.properties.id);
				var id= item.properties.id;
				//console.log(id);
				//console.log(trId);
				if(id == trId){
					//var geojsonLayer;
					if (geojsonLayer != undefined) {
			  			mapa.removeLayer(geojsonLayer);
					};
					console.log(id);
					console.log(trId);
					//mapa._layers[id].fire('click');

					var selPts= [];
					selPts.push(item);

					//console.log(layerS);

					geojsonLayer = L.geoJson(selPts, {
						pointToLayer: function(feature, latlng) {
							return L.circleMarker(latlng, {
								radius: 4, //expressed in pixels circle size
								color: "green", 
								stroke: true,
								weight: 7,//outline width  increased width to look like a filled circle.
								fillOpcaity: 1
							});
						}
					});
					mapa.addLayer(geojsonLayer);
					geojsonLayer.bringToFront();

					var layer = geojsonLayer._layers;
					console.log(layer);
					var layerO = Object.keys(layer);
					//console.log(layerO);
					var coords = layer[layerO]._latlng;
					//console.log(coords);
					var zoomLevel = mapa.getZoom();
					console.log(zoomLevel);
					if(zoomLevel < 13){
						mapa.setView(coords, 13);
					} else {
						mapa.setView(coords, zoomLevel+1);
					}
				}
				
			})
		}
 	}
	return (
		 <Fragment>
          		<div>
            			<table id="tableId">
            				<thead>
              					<tr>{getHeader(data)}</tr>
            				</thead>
            				<tbody>
              					{getRowsData(data)}
            				</tbody>
            			</table>
          		</div>
		 </Fragment>        
        );

}

export default Table;
