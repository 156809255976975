import React, { Fragment } from "react";

//import SelectReport from "./select_report";
import Date_Report from "./date_report"
//import LeafletMapProyectos from "./map_proyectos";

const Informe_1_sintesis =  () => {
  return (
    <Fragment>

      {/*<div id="id_date_report" style={{border: "2px solid yellow"}}>*/}
        <Date_Report />
      {/*</div>*/}

      {/* <LeafletMapProyectos /> */}
    </Fragment>
  );
}

export default Informe_1_sintesis;
