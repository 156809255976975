import React, {useState, Fragment} from "react";
import { Collapse, Form, Button } from "react-bootstrap";
//import Navigation from "./navigation";

const Metabase = ({ setAuth, ...props }) => {

    const [activo, setActivo] = useState("metabase");
    const [openTablero_1, setOpenTablero_1] = useState(true);
    const [openTablero_2, setOpenTablero_2] = useState(false);
    const [openTablero_3, setOpenTablero_3] = useState(false);
    //const [openTablero_4, setOpenTablero_4] = useState(false);

    const handleTablero_1 = () => {
        setOpenTablero_1(true);
        setOpenTablero_2(false);
        setOpenTablero_3(false);
        //setOpenTablero_4(false);        		
	}

    const handleTablero_2 = () => {
        setOpenTablero_1(false);
        setOpenTablero_2(true);
        setOpenTablero_3(false);
        //setOpenTablero_4(false);        		
	}

    const handleTablero_3 = () => {
        setOpenTablero_1(false);
        setOpenTablero_2(false);
        setOpenTablero_3(true);
        //setOpenTablero_4(false);       		
	}

    /*const handleTablero_4 = () => {
        setOpenTablero_1(false);
        setOpenTablero_2(false);
        setOpenTablero_3(false);
        setOpenTablero_4(true);       		
	}*/
    //http://66.97.42.220:3030/public/dashboard/0b8ae9c4-4641-4923-97c9-ebee34094a87
    //http://66.97.42.220:3030/public/dashboard/58cfb770-8a7e-4858-a622-ebd9aa1f6aee
    //http://66.97.42.220:3030/public/dashboard/664698e5-f347-4325-8c94-75f0235d66f3
    //http://66.97.42.220:3030/public/dashboard/9c3f3724-3b75-4a33-831e-27cde8290853


    return (
        <Fragment>
            {/*<Navigation className="" setAuth={setAuth} activo={activo} />*/} 
            
            <div style={{background: "white", width:"100%", height:"45px", borderBottom: "4px solid white", position: "sticky", marginTop:"", bottom: "0px"}} className="div_stick">{/*13-08-22*/}
                <div className="flexbox-container">
                    
                    <Button
                        variant="secondary"
                        onClick={handleTablero_1}
                        size="sm" type="info"
                        aria-controls="example-collapse-textT"
                        aria-expanded={openTablero_1}
                        style={{ alignSelf: "flex-end" }}
                        disabled={openTablero_1 ? true : false}
                    >
                    Producción de suelo urbano 2014-2027
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={handleTablero_2}
                        size="sm" type="info"
                        aria-controls="example-collapse-textT"
                        aria-expanded={openTablero_2}
                        style={{ alignSelf: "flex-end" }}
                        disabled={openTablero_2 ? true : false}
                    >
                    Producción de suelo urbano 2020-2027
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={handleTablero_3}
                        size="sm" type="info"
                        aria-controls="example-collapse-textT"
                        aria-expanded={openTablero_3}
                        style={{ alignSelf: "flex-end" }}
                        disabled={openTablero_3 ? true : false}
                    >
                    Procesos en gestión (año vigente)
                    </Button>                    
                    
                </div>
            </div>
            <div className="">
                <Form id='id_reportes' style={{ width:"100%" }}>
                    <div className="" style={{ width:"100%" }}>

                    <Collapse in={openTablero_1} dimension="width">
                        <div id="example-collapse-textReporte1">                        
                            <iframe
                                src="https://dpph.sstuv.gba.gov.ar/metabase/public/dashboard/7c003922-4675-4a8a-a034-f2114c0bb57a"
                                frameborder="0"
                                width="100%"
                                height="800"
                                allowtransparency
                            ></iframe> 
                        </div>
                    </Collapse>

                    <Collapse in={openTablero_2} dimension="width">
                        <div id="example-collapse-textReporte2">
                            <iframe
                                src="https://dpph.sstuv.gba.gov.ar/metabase/public/dashboard/dce1c892-a53f-4e5c-ba48-b9aa63529c20"
                                frameborder="0"
                                width="100%"
                                height="600"
                                allowtransparency
                            ></iframe>                        
                        </div>
                    </Collapse>

                    <Collapse in={openTablero_3} dimension="width">
                        <div id="example-collapse-textReporte3">
                            <iframe
                                src="https://dpph.sstuv.gba.gov.ar/metabase/public/dashboard/b8989105-402b-413b-b303-93f0ecf581cc"
                                frameborder="0"
                                width="100%"
                                height="600"
                                allowtransparency
                            ></iframe> 
                        </div>
                    </Collapse>

                    </div>
                </Form>
            </div>            

        </Fragment>        
    )
}

export default Metabase;