import React, {Fragment,  useState, useEffect} from "react";
import { Table, Button } from "react-bootstrap";

import '../../table_report.css';

const TablaInf2_Seleccionados = ({datosS, columnas}) => {
  //console.log("CARGAR datos FINALIZADOS en tabla");
  //console.log(datos_Inf2_finalizadosTotal);

  //console.log(columnas);

	const getHeader = (columnas) => {

		return columnas.map((columna, index) => {

			if (columna === "Partido") {
				return <th key={index} >
					{columna}

				</th>
			} else if (columna === "Expediente") {
				//return <th key={index} > {columna}<input id="search" type="text" name="search" onChange={handleSearch} value={search || ''} /> </th>
				return <th key={index} > {columna} </th>
			} else if (columna === "Estado") {
				return <th key={index} >
					{columna}
				</th>
			} else if (columna === "Alias") {
				//return <th key={index} > {columna}<input id="searchA" type="text" name="alias" onChange={handleSearchAlias} value={searchAlias || ''} /> </th>
				return <th key={index} style={{ width: "100px" }}> {columna} </th>
			} else if (columna === "Cantidad lotes") {
				return <th key={index} > {columna} </th>
			} else if (columna === "Programa") {
				return <th key={index} >
					{columna}
				</th>
			} else if (columna === "Tipo") {
				return <th key={index} >
					{columna}
				</th>
			} else {
				return <th key={index} >{columna}</th>
			}
		})
	}

	const getRowsData = (datosS)=> {
		//console.log("EJECUTAR AL INICIO -- REPORTE 3");
		if(datosS !== null){

			return datosS.map((registro, index )=> {
				var partido = registro.partido_nombre;
				var expediente = registro.expediente;
				var estado = registro.estado;
				var nombre_proyecto = registro.nombre_proyecto;//nombre_proyecto
				var cantidad_lotes = registro.cantidad_lotes;
				var programa = registro.programa;
				var tipo = registro.tipo;
				//var titular = registro.titular_tierra;
				//var lotes_captados_municipio = registro.lotes_captados_municipio;
				var nomenclatura_catastral = registro.datos_18_sintesis_nomenclatura;

				return <tr id={index}>  {/* () => rowClick(registro, index)*/}
					<td>{partido}</td>
					<td>{expediente}</td>
					<td>{estado}</td>
					<td>{nombre_proyecto}</td>
					<td>{cantidad_lotes}</td>
					<td>{programa}</td>
					<td>{tipo}</td>
					{/*<td>{titular}</td>*/}
					{/*<td>{lotes_captados_municipio}</td>*/}
					<td>{nomenclatura_catastral}</td>
				</tr>;
			})

		}
	}  

	if (datosS === null) {
		return (
			<p>Cargar general (campos seleccionados)</p>
		)
	} else if (datosS !== null) {
		return (
			<Fragment>
				<div>
					<Table id="table-seleccionados-to-xls" className="tableExp" stripped bordered hover responsive="md" >
						<thead>
							<tr style={{ backgroundColor: "rgb(0,174,195)", color: "white" }}>{getHeader(columnas)}</tr>							
						</thead>
						<tbody>
							{getRowsData(datosS)}
						</tbody>
					</Table>
				</div>
			</Fragment>
		)
	}
};

export default TablaInf2_Seleccionados;
