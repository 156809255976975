//CAMBIO 27jun24: crear columnas no como estado
//columnas tabla general (campos seleccionados) - la unica visible
const columnas = ["Partido", "Expediente", "Estado", "Alias", "Cantidad lotes", "Programa", "Tipo", "Nomenclatura catastral"];

const columnasMadre = [
    "Id_Exp","Alias", "Expediente","Fecha de carga","Fecha de caratulación","Última actualización","Responsable","Prioritario","Avanzado", //NO estaba, agregado 05-jul-2024
    "Programa","Estado","Tipo",
    "Partido","Localidad","Cant. de lotes","Cant. de lotes adjudicables","Porc. lotes municipales","Lotes adquiridos por el municipio",
    "Sistema de agua","Eliminación de excretas",
    //ACA TODA LA NOMENCLATURA
    "Datos catastrales y dominiales - Síntesis","Partido_nomencla", "Circ.", "Secc.",
    "Titular de la tierra","Plano oficial",
    //LOCALIZACION
    "Area","Zonificación","Nro ordenanza vigente","Decreto promulgatorio", "Observaciones generales",
    //Pestaña Tramitaciones
    //ETAPA 1
    "Nota solicitud adhesión al programa","Informe urbanístico municipal","Certif. de no inundabilidad del predio",
    "Anteproyecto urbano","Título de propiedad y/o datos de inscripción dominial",    
    //ETAPA 2
    "Memoria descriptiva de las obras a ejecutar","Prefactibilidad de energía eléctrica","Prefactibilidad de agua potable por red",
    "Prefactibilidad de red cloacal","Factibilidad apertura de calles","Acta constitutiva persona jurídica",
    "Certificado de vigencia de Persona Jurídica","Plano de mensura y división","Convenio urbanístico","Ordenanza municipal",
    "Memoria descriptiva del procedimiento de adjudicación", "Proyecto hidráulico",
    //Tramitaciones de la DPPH
    "Informe de dominio","Anotaciones personales","Admisibilidad inicial","Prefactibilidad urbanística","Prefactibilidad hidráulica y permisos - ADA",
    "Certificado técnico urbanístico","Resolución de afectación","Visado de plano",
    //observaciones --> no es subtitulo
    "Observaciones para reporte al municipio", "Observaciones internas",
    //PREFACTIBILIDAD HIDRÁULICA
    "Tramitación ADA","Fecha de envío","Expediente ADA","Fecha de emisión","Observaciones tramitación ADA",
    //TRAMITACION CPU
    "Tramitación CPU", "Fecha de envío", "Fecha de emisión","Nro. GDEBA", "Observaciones tramitación CPU",
    //VISADO
    "Tramitación visado","Fecha de visado",//T138
    "Fecha de entrega",//T139
    "Observaciones tramitación visado",//T140
    //RESOLUCIÓN
    "Tramitación Resolución","Fecha de envío","Fecha de emisión","Número especial",//T135
    "Observaciones tramitación resolución",    

    //**PESTAÑA 3 - CSP
    //CARACTERÍSTICAS DEL PROYECTO
    "Tamaño medio de lotes residenciales",
    //PLANO DE MENSURA Y DIVISION
    "Estado de la tramitación",
    //"Número de plano"
    "Partido","Orden","Año","Fecha",
    //registracion
    "Legajo","Folio","Fecha",
    "Fecha de control","Observaciones sobre el seguimiento de los proyectos",
    //FINANCIAMIENTO DE INFRAESTRUCTURAS
    "Actor encargado de las obras",
    //Financiamiento público de las obras de infraestructura
    "Financiamiento público de las obras de infraestructura",//checkbox csp_3_financiamiento_publico_obras_infra
    "Jurisdicción","Organismo de financiamiento","Fecha de control","Descripción obras de infraestructura financiadas",
    //Financiamiento de viviendas
    "Financiamiento público de viviendas",
    "Jurisdicción","Organismo de financiamiento","Fecha de control","Descripción obras de infraestructura financiadas",
    //Financiamiento de Equipamientos Comunitarios
    "Financiamiento público de equipamientos comunitarios",
    "Jurisdicción","Organismo de financiamiento","Fecha de control","Descripción obras de infraestructura financiadas",//17CSP
    
    //SEGUIMIENTO DE OBRAS Y EDIFICACIÓN
    "Presencia de apertura de calles", "Parcelas edificadas", "Porcentaje parcelas edificadas",
    "Observaciones sobre el seguimientos de obras y edificación", "Fecha de control",

    "Periodo", // CHEQUEAR SI TIENE QUE ESTAR
    "Región"
];

const campos_tr_madre = [
    "id_exp_prueba","nombre_proyecto","expediente", "fecha_carga", "fecha_caratulacion", "fecha_actualizacion", "datos_05_responsable", "tramite_prioritario", "avanzados",//NO estaba, se agrega 05-jul-2024
    "programa", "estado", "tipo", 
    "partido_nombre", "localidad", "cantidad_lotes", "cantidad_lotes_adjudicables", "porc_lotes_municipales", "lotes_adquiridos_municipio",
    "sistema_agua", "eliminacion_excretas",
    //ACA TODA LA NOMENCLATURA
    "datos_18_sintesis_nomenclatura","partido_nomencla",//CHECK por ahora usamos el mismo nombre pzrz el partido
    "datos_20_circ_nomencla", "datos_21_secc_nomencla",
    "titular_tierra", "plano_oficial",
    //LOCALIZACION
    "area", "zonificacion", "nro_ordenanza_vigente", "decreto_promulgatorio", "observaciones_generales",
    //Pestaña Tramitaciones
    //ETAPA 1
    "solicitud_adhesion_t", "certif_urbanistico_municipal_t", "certif_no_inundabilidad_t", 
    "anteproyecto_urbano_t", "titulo_propiedad_t",    
    //ETAPA 2
    "memoria_descriptiva_obras_t", "energia_electrica_t", "agua_potable_t", 
    "eliminacion_excretas_t", "apertura_calles_t", "acta_constitutiva_t",
    "certif_vigencia_persona_juridica_t", "plano_mensura_t", "convenio_urbanistico_t", "ordenanza_municipal_t", "memoria_descriptiva_procedimientos_t",
    "t_144_proyecto_hidraulico",
    //Tramitaciones de la DPPH
    "informe_dominio_t", "anotaciones_personales_t", "admisibilidad_inicial_t", "prefactibilidad_urbanistica_t", "prefactibilidad_hidraulica_t",
    "certif_tecnico_urbanistico_t", "resolucion_afectacion_t", "visado_plano_t", 
    //observaciones --> no es subtitulo
    "observaciones_reporte_t", "observaciones_internas_t",
    //PREFACTIBILIDAD HIDRÁULICA
    "t_127_tramitacion_ada_prefac_h", "fecha_envio_prefac_h_t", "expediente_prefac_h_t", "fecha_emision_prefac_h_t", "t_131_observaciones_prefact_h",
    //TRAMITACION CPU
    "t_145_cpu_tramitacion", "t_146_cpu_fecha_envio", "t_147_cpu_fecha_emision", "t_148_cpu_nro_gdeba", "t_149_cpu_observaciones",
    //VISADO
    "t_137_tramitacion_visado", "fecha_visado_plano_t", "t_139_fecha_entrega", "t_140_observaciones_tramitacion",
    //RESOLUCION
    "t_132_tramitacion_resol", "fecha_envio_resol_t", "fecha_emision_resol_t", "numero_especial_resol_t", "t_136_observaciones_resol",    

    //CSP
    //CARACTERÍSTICAS DEL PROYECTO
    //"array_csp",
    "csp_1_tamaño_medio_lotes_residenciales" ,
    //PLANO DE MENSURA Y DIVISION
    "csp_18_pl_mensura_estado" ,
    //"Número de plano"
    "csp_19_pl_mensura_partido" ,"csp_20_pl_mensura_orden" ,"csp_21_pl_mensura_año" ,"csp_22_pl_mensura_fecha" ,
    //registracion
    "csp_23_registracion_legajo", "csp_24_registracion_folio", "csp_25_registracion_fecha", "csp_26_fecha_control", "csp_27_observaciones_csp",
    //FINANCIAMIENTO DE INFRAESTRUCTURAS    
    "csp_2_actor_encargado_obras_infra",
    //Financiamiento público de las obras de infraestructura
    "csp_3_financiamiento_publico_obras_infra" ,"csp_4_jurisdiccion_infra" ,
    "csp_5_organismo_financiamiento_infra" ,"csp_6_fecha_control_infra" ,"csp_7_descripcion_obras_infra",
    //Financiamiento de viviendas    
    "csp_8_financiamiento_publico_obras_viv" ,
    "csp_9_jurisdiccion_viv" ,"csp_10_organismo_financiamiento_viv" ,"csp_11_fecha_control_viv" ,"csp_12_descripcion_obras_viv",
    //Financiamiento de Equipamientos Comunitarios
    "csp_13_financiamiento_publico_obras_equip" ,
    "csp_14_jurisdiccion_equip" ,"csp_15_organismo_financiamiento_equip" ,"csp_16_fecha_control_equip" ,"csp_17_descripcion_obras_equip",    
    //SEGUIMIENTO DE OBRAS Y EDIFICACIÓN
    "csp_28_presencia_apertura_calles" ,"csp_29_parc_edificadas","csp_30_porcentaje_parc_edificadas",
    "csp_31_observ_seguimiento_obras_y_edificacion" ,"csp_32_fecha_control_seguimiento_obras",
    "periodo", //CHEQUEAR SI TIENE QUE ESTAR
    "region"
]


const columnasAnalitico = [
    "Alias", "Expediente","Programa","Tipo","Partido","Cant. de lotes","Cant. de lotes adjudicables","Porc. lotes municipales",
    "Lotes adquiridos por el municipio", "Datos catastrales y dominiales - Síntesis",
    "Sistema de agua","Eliminación de excretas",
    "Año de emisión","Periodo",

    "Tamaño medio de lotes residenciales","Actor encargado de las obras",
    "Financiamiento público de las obras de infraestructura",
    "Jurisdicción","Organismo de financiamiento",
    //***Financiamiento de viviendas
    "Financiamiento público de viviendas",
    "Jurisdicción","Organismo de financiamiento",
    //***Financiamiento de Equipamientos Comunitarios
    "Financiamiento público de equipamientos comunitarios",
    "Jurisdicción","Organismo de financiamiento",
    //***Plano de mensura y división
    "Plano - estado de la tramitación",
    "Región"
];

const campos_tr_analitico = ["alias", "expediente", "programa", "tipo", "partido_nombre",
"cantidad_lotes", "cantidad_lotes_adjudicables", "porc_lotes_municipales", 
"lotes_adquiridos_municipio", "datos_18_sintesis_nomenclatura",
"sistema_agua", "eliminacion_excretas", 
 "año_emision", "periodo",// no usar (fecha_emision_resol_t)- as Periodo en la base de datos 
//desde array_csp
"csp_1_tamaño_medio_lotes_residenciales", //Tamaño medio de lotes residenciales
"csp_2_actor_encargado_obras_infra", // Actor encargado de las obras
"csp_3_financiamiento_publico_obras_infra", //"Financiamiento público de las obras de infraestructura"
"csp_4_jurisdiccion_infra", //Jurisdicción
"csp_5_organismo_financiamiento_infra", // Organismo de financiamiento

//***Financiamiento de viviendas
"csp_8_financiamiento_publico_obras_viv", // Financiamiento público de viviendas
"csp_9_jurisdiccion_viv", // Jurisdicción
"csp_10_organismo_financiamiento_viv", // Organismo de financiamiento

//***Financiamiento de Equipamientos Comunitarios
"csp_13_financiamiento_publico_obras_equip", // Financiamiento público de equipamientos comunitarios
"csp_14_jurisdiccion_equip", // Jurisdicción
"csp_15_organismo_financiamiento_equip", // Organismo de financiamiento

//***Plano de mensura y división
"csp_18_pl_mensura_estado", // Plano - estado de la tramitación

"region"

]

const columnasActuaciones = ["Alias", "Expediente",
    "F. Carga",// Fecha de carga
    "F. Caratulación", //Fecha de caratulación
    "Resp.", //Responsable
    "Av.", // Avanzado
    "Pr.", // Prioritario
    "Programa", "Estado", "Tipo","Partido",
    "Lotes", // Cant. de lotes
    "L. Adj.", //Cant. de lotes adjudicables
    "% DPM", // Porc. lotes municipales
    "S. Agua", // Sistema de agua
    "S. E. Exc.", //Eliminación de excretas
    "Datos catastrales", // Datos catastrales y dominiales - sintesis
    "P. O.", //Plano oficial

    //*** ETAPA 1
    "N. Sol.", //Nota solicitud adhesion al programa --> solicitud_adhesion_t
    "MDP", //Informe urbanístico municipal ---> certif_urbanistico_municipal_t
    "C.N.I.", //Certif. de no inundabilidad del predio
    "Ant. U.", //Anteproyecto urbano
    "Título", //Título de propiedad y/o datos de inscripción dominial
   
    //**** ETAPA 2
    "M.D.O", //Memoria descriptiva de las obras a ejecutar
    "P.E.E.", //Prefactibilidad de energía eléctrica
    "P. Agua", //Prefactibilidad de agua potable por red
    "P.R.C.", //Prefactibilidad de red cloacal
    "F.A.C.", //Factibilidad apertura de calles
    "Acta C.", //Acta constitutiva persona jurídica
    "C. Vig.", //Certificado de vigencia de Persona Jurídica
    "Plano", //Plano de mensura y división
    "Conv.", //Convenio urbanístico
    "Ord.", //Ordenanza municipal
    "Adj.", //Memoria descriptiva del procedimiento de adjudicación
    "P.H.", //Proyecto hidráulico

    //**** Tramitaciones de la DPPH
    "I. Dom", //Informe de dominio
    "C. A. P.", //Anotaciones personales
    "Admis.",//Admisibilidad inicial
    "C.P.U.",//Prefactibilidad urbanística
    "A.D.A.",//Prefactibilidad hidráulica y permisos - ADA
    "C. Tec.", //Certificado técnico urbanístico
    "Reso", //Resolución de afectación
    "Vis.", //Visado de plano 

    //observaciones --> no es subtitulo
    "Observaciones para reporte al municipio", "Observaciones internas", 
    //PREFACTIBILIDAD HIDRÁULICA
    "T. ADA" //Tramitación ADA
];

// los nombre tienen que coincidir con el resultado de las querys
// no afecta si tiene mas campos que los definidos en el array. En el array poner los que completan la tabla, tiene que coincidir con la cantidad de columnas.
const campos_tr_actuaciones = ["alias", "expediente",
    "fecha_carga", //Fecha de carga
    "fecha_caratulacion", //Fecha de caratulación
    "datos_05_responsable", //Responsable
    "avanzados", //"Av.", // Avanzado
    "tramite_prioritario", //"Pr.", // Prioritario
    "programa", "estado", "tipo", "partido_nombre",
    "cantidad_lotes", "cantidad_lotes_adjudicables", "porc_lotes_municipales",
    "sistema_agua", "eliminacion_excretas",
    "datos_18_sintesis_nomenclatura", // Datos catastrales y dominiales - sintesis
    "plano_oficial", //"P. O.", //Plano oficial

    //ETAPA 1
    "solicitud_adhesion_t", //Nota solicitud adhesion al programa
    "certif_urbanistico_municipal_t", //Informe urbanístico municipal
    "certif_no_inundabilidad_t", // Certif. de no inundabilidad del predio
    "anteproyecto_urbano_t", //Anteproyecto urbano
    "titulo_propiedad_t", //Título de propiedad y/o datos de inscripción dominial
    
    //ETAPA 2
    "memoria_descriptiva_obras_t", //Memoria descriptiva de las obras a ejecutar
    "energia_electrica_t",  //Prefactibilidad de energía eléctrica
    "agua_potable_t", //Prefactibilidad de agua potable por red
    "eliminacion_excretas_t", //Prefactibilidad de red cloacal
    "apertura_calles_t", //Factibilidad apertura de calles
    "acta_constitutiva_t", //Acta constitutiva persona jurídica
    "certif_vigencia_persona_juridica_t", //Certificado de vigencia de Persona Jurídica
    "plano_mensura_t", //Plano de mensura y división
    "convenio_urbanistico_t", //Convenio urbanístico
    "ordenanza_municipal_t", //Ordenanza municipal
    "memoria_descriptiva_procedimientos_t", //Memoria descriptiva del procedimiento de adjudicación
    "t_144_proyecto_hidraulico", //Proyecto hidráulico

    //Tramitaciones de la DPPH
    "informe_dominio_t", //Informe de dominio
    "anotaciones_personales_t", //Anotaciones personales
    "admisibilidad_inicial_t", //Admisibilidad inicial
    "prefactibilidad_urbanistica_t", //Prefactibilidad urbanística
    "prefactibilidad_hidraulica_t", //Prefactibilidad hidráulica y permisos - ADA
    "certif_tecnico_urbanistico_t", //Certificado técnico urbanístico
    "resolucion_afectacion_t", //Resolución de afectación
    "visado_plano_t", //Visado de plano

    //observaciones --> no es subtitulo
    "observaciones_reporte_t", //Observaciones para reporte al municipio
    "observaciones_internas_t", //"Observaciones internas"

    //PREFACTIBILIDAD HIDRÁULICA
    "t_127_tramitacion_ada_prefac_h", //Tramitación ADA -----------: ORDENAR!!!

];

export {columnas, columnasMadre, columnasAnalitico, columnasActuaciones, campos_tr_madre, campos_tr_analitico, campos_tr_actuaciones};