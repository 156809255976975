import React, { useState, useEffect, useContext } from "react";
import { SessionContext } from '../context/sessionContext';

import { Button, Row, Col } from "react-bootstrap";

import SelectInput from "./selectInput";

import {optionsSolicitado } from "../datos/options";

//HIJO****
const TablaNew = ({formRows, setFormRows}) => {
	//const [formRows, setFormRows] = useState([{ nomenclatura:"", partida:"", inscripcion:"" }]);//valor inicial, un solo row

	//console.log(formRows);
	//console.log(setFormRows);
	const { cambioOriginal } = useContext(SessionContext);

	//Lo que recibe en formRows
	const {
		nomenclatura_catastral,
		partida_inmobiliaria, inscripcion_dominial,
		nomencla_partido,
		nomencla_circ,
		nomencla_secc,
		nomencla_chacra_nro,
		nomencla_chacra_l,
		nomencla_quinta_nro,
		nomencla_quinta_l,
		nomencla_fracc_nro,
		nomencla_fracc_l,
		nomencla_manz_nro,
		nomencla_manz_l,
		nomencla_parc_nro,
		nomencla_parc_l,
		solicitado
	} = formRows[0];
	//console.log(nomenclatura_catastral);

	//console.log(nomencla_circ);
  	useEffect(() => {
			//console.log("chequear longitud partido");
    	//console.log("formRows: ", formRows);
  	}, [nomencla_partido]);

	/**** NO USAR ---ver version original*****
	const [currentRow, setCurrentRow] = useState({});

	useEffect(() => {
                //actualizarRows();
        },[currentRow]);

	const actualizarRows = () => {
		//console.log("deberia actualizar el estado de formRows");
		//console.log(currentRow);
		//console.log(currentRow.id);

		const newArray = [...formRows];

		const indice = formRows.map((from, index) => {
			if(from.id === currentRow.id){

				//console.log(index);
				newArray[index]= currentRow;
			}

		});
		//console.log(newArray);

		setFormRows(newArray);
		//console.log(formRows);
	}*/

	const onChange = (e, index) => {
		//cambia el valor de a === 1, cambia el estado global para activar los buttons
		const a = cambioOriginal(1);//chequear token al cargar pagina expediente
    //console.log(a);

		const { name, value } = e.target;
    const list = [...formRows,];
    list[index][name] = value;

		//console.log(name);
		//console.log(list);
		const nomencla = list[index]["nomenclatura_catastral"];

		// **Partido**
		if(name === "nomencla_partido"){
			const result = value.replace(/\D/g, ''); //ADD -cambio para validacion 15-08-2022

			const posiciones_partido = "000";
			const len_partido = result.length; //value.length ----cambio para validacion 15-08-2022
			//console.log(len_partido);

			if(len_partido > 0){
				const new_posiciones = posiciones_partido.slice(0, - len_partido);
				var new_partido = new_posiciones.concat(result); //concat(value) ----cambio para validacion 15-08-2022
			} else {
				var new_partido =posiciones_partido;
			}
			//console.log(new_partido);

      //const start_nomencla = nomencla.slice(0, 3); //NO HACE FALTA cortar al inicio
      const end_nomencla = nomencla.slice(-39);
      const new_nomencla = new_partido.concat(end_nomencla);

      //list[index]["nomencla_partido"]= new_partido;
      list[index]["nomenclatura_catastral"]= new_nomencla;
			list[index]["nomencla_partido"]= result;//ADD - cambio 15-08-2022. Para almacenar en state

		// **Circ**
		} else if(name === "nomencla_circ"){
			const result = value.replace(/\D/g, '');
			const posiciones_circ = "00";
			const len_circ = result.length;//cambio validacion 15-08-2022

			if(len_circ > 0){
				const new_posiciones = posiciones_circ.slice(0, - len_circ);
				//console.log(new_posiciones);
				var new_circ = new_posiciones.concat(result);//cambio validacion 15-08-2022
				//console.log(new_circ);
			} else {
				var new_circ = posiciones_circ;
			}

			const start_nomencla = nomencla.slice(0, 3);
			const end_nomencla = nomencla.slice(-37);
			const new_nomencla = start_nomencla.concat(new_circ, end_nomencla);

			//list[index]["nomencla_circ"]= new_circ;
			list[index]["nomenclatura_catastral"]= new_nomencla;
			list[index]["nomencla_circ"]= result;//ADD - cambio 15-08-2022. Para almacenar en state

		//**Secc**
		} else if(name === "nomencla_secc"){
			const soloLetras = value.replace(/\d/g, '');
			const result = soloLetras.toUpperCase();
			const posiciones_secc = "00";
      const len_secc = result.length;

			if(len_secc > 0){
	    	const new_posiciones = posiciones_secc.slice(0, - len_secc);
	    	//console.log(new_posiciones);
	    	var new_secc = new_posiciones.concat(result);
	    	//console.log(new_secc);
			} else {
				var new_secc = posiciones_secc;
			}

      const start_nomencla = nomencla.slice(0, 5);
      const end_nomencla = nomencla.slice(-35);
      const new_nomencla = start_nomencla.concat(new_secc, end_nomencla);

			//list[index]["nomencla_secc"]= new_secc;
      list[index]["nomenclatura_catastral"]= new_nomencla;
			list[index]["nomencla_secc"]= result;

		//** Chacra Numero **
		} else if(name === "nomencla_chacra_nro"){
			const result = value.replace(/\D/g, '');//ADD - cambio 15-08-2022.
			const posiciones_ch_nro = "0000";
			const len_ch_nro = result.length;// cambio validacion 15-08-2022

			if(len_ch_nro > 0){
				const new_posiciones = posiciones_ch_nro.slice(0, - len_ch_nro);
				//console.log(new_posiciones);
				var new_ch_nro = new_posiciones.concat(result);//cambio validacion 15-08-2022
				//console.log(new_ch_nro);
			} else {
				var new_ch_nro = posiciones_ch_nro;
			}

			const start_nomencla = nomencla.slice(0, 7);
			const end_nomencla = nomencla.slice(-31);
			const new_nomencla = start_nomencla.concat(new_ch_nro, end_nomencla);

			//list[index]["nomencla_chacra_nro"] = new_ch_nro;
			list[index]["nomenclatura_catastral"]= new_nomencla;
			list[index]["nomencla_chacra_nro"]= result;//ADD - cambio 15-08-2022. Para almacenar en state

		//**Chacra Letra**
		} else if(name === "nomencla_chacra_l"){
			//const nomencla = "0000000 000X PPP (hasta aca 14) X000000 0000000 0000000 0000000"
			const soloLetras = value.replace(/\d/g, '');
			const result = soloLetras.toUpperCase();
			const posiciones = "000";
			const len_chacra_l = result.length;
			//console.log(len_chacra_l);

			if( len_chacra_l > 0){
				const new_posiciones = posiciones.slice(0, -len_chacra_l);
				//console.log(new_posiciones);
				var new_chacra_l = new_posiciones.concat(result);
			} else {
				var new_chacra_l = posiciones;
			}
			// nomenclatura = "0000000 0000000 0000000 0000000 0000000 0000000"
			// 0000000 0000000 0000000 0000000 0000000 0000000 0000000
			// 078020D 0400000 0000000 0002000 0000000 000500a
			// 078020D 0400000 0000000 0002000 0000000 000500a -----> 6 x 7 = 42
			// 0000000 0000000 0000000 0000000 0000000 0000000 0000000

			const start_nomencla = nomencla.slice(0, 11);
			//console.log(start_nomencla);
			const end_nomencla = nomencla.slice(-28);
			//console.log(end_nomencla);

			const new_nomencla = start_nomencla.concat(new_chacra_l, end_nomencla);

			//console.log(new_nomencla);

			//list[index]["nomencla_chacra_l"] = new_chacra_l;
			list[index]["nomenclatura_catastral"]= new_nomencla;
			list[index]["nomencla_chacra_l"]= result;

		//**Quinta Numero**
		} else if(name === "nomencla_quinta_nro"){
			// 0000000 0000000 XXXX000 - 0000000 0000000 0000000
			const result = value.replace(/\D/g, '');//ADD - cambio 15-08-2022.
      const posiciones_quinta_nro = "0000";
      const len_quinta_nro = value.result;//cambio 15-08-2022

			if( len_quinta_nro > 0){
	    	const new_posiciones = posiciones_quinta_nro.slice(0, - len_quinta_nro);
	    	var new_quinta_nro = new_posiciones.concat(result);//cambio 15-08-2022
			} else {
				var new_quinta_nro = posiciones_quinta_nro;
			}

      const start_nomencla = nomencla.slice(0, 14);
      const end_nomencla = nomencla.slice(-24);
      const new_nomencla = start_nomencla.concat(new_quinta_nro, end_nomencla);

      //list[index]["nomencla_quinta_nro"] = new_quinta_nro;
      list[index]["nomenclatura_catastral"]= new_nomencla;
			list[index]["nomencla_quinta_nro"]= result;//ADD - cambio 15-08-2022. Para almacenar en state

		//**Quinta letra**
		} else if(name === "nomencla_quinta_l"){
			// 0000000 0000000 0000XXX - 0000000 0000000 0000000
			const soloLetras = value.replace(/\d/g, '');
			const result = soloLetras.toUpperCase();
			const posiciones_quinta_l = "000";
			const len_quinta_l = result.length;

			if( len_quinta_l > 0 ) {
				const new_posiciones = posiciones_quinta_l.slice(0, - len_quinta_l);
				var new_quinta_l = new_posiciones.concat(result);
			} else {
				var new_quinta_l = posiciones_quinta_l;
			}

			const start_nomencla = nomencla.slice(0, 18);
			const end_nomencla = nomencla.slice(-21);
			const new_nomencla = start_nomencla.concat(new_quinta_l, end_nomencla);

			//list[index]["nomencla_quinta_l"] = new_quinta_l;
			list[index]["nomenclatura_catastral"]= new_nomencla;
			list[index]["nomencla_quinta_l"]= result;

		//**Fracc. nro**
		} else if(name === "nomencla_fracc_nro"){
			// 0000000 0000000 0000000 - XXXX000 0000000 0000000
			const result = value.replace(/\D/g, '');//ADD - cambio 15-08-2022.
      const posiciones_fracc_nro = "0000";
      const len_fracc_nro = result.length;//cambio 15-08-2022

      if( len_fracc_nro > 0){
        const new_posiciones = posiciones_fracc_nro.slice(0, - len_fracc_nro);
        var new_fracc_nro = new_posiciones.concat(result);//cambio 15-08-2022
      } else {
        var new_fracc_nro = posiciones_fracc_nro;
      }

      const start_nomencla = nomencla.slice(0, 21);
      const end_nomencla = nomencla.slice(-17);
      const new_nomencla = start_nomencla.concat(new_fracc_nro, end_nomencla);

      //list[index]["nomencla_fracc_nro"] = new_fracc_nro;
      list[index]["nomenclatura_catastral"]= new_nomencla;
			list[index]["nomencla_fracc_nro"]= result;// ADD cambio 15-08-202

		//**Fracc. letra**
		} else if(name === "nomencla_fracc_l"){
			// 0000000 0000000 0000000 - 0000XXX 0000000 0000000
				const soloLetras = value.replace(/\d/g, '');
				const result = soloLetras.toUpperCase();
	      const posiciones_fracc_l = "000";
	      const len_fracc_l = result.length;

	      if( len_fracc_l > 0 ) {
		      const new_posiciones = posiciones_fracc_l.slice(0, - len_fracc_l);
		      var new_fracc_l = new_posiciones.concat(result);
	      } else {
	        var new_fracc_l = posiciones_fracc_l;
	      }

	      const start_nomencla = nomencla.slice(0, 25);
	      const end_nomencla = nomencla.slice(-14);
	      const new_nomencla = start_nomencla.concat(new_fracc_l, end_nomencla);

	      //list[index]["nomencla_fracc_l"] = new_fracc_l;
	      list[index]["nomenclatura_catastral"]= new_nomencla;
				list[index]["nomencla_fracc_l"]= result;

		//**Manz. nro**
		} else if(name === "nomencla_manz_nro"){
			// 0000000 0000000 0000000 - 0000000 XXXX000 0000000
			const result = value.replace(/\D/g, '');//ADD - cambio 15-08-2022.
	    const posiciones_manz_nro = "0000";
	    const len_manz_nro = result.length;//cambio 15-08-2022

	    if( len_manz_nro > 0){
	      const new_posiciones = posiciones_manz_nro.slice(0, - len_manz_nro);
	      var new_manz_nro = new_posiciones.concat(result);//cambio 15-08-2022
	    } else {
	    	var new_manz_nro = posiciones_manz_nro;
	    }

	    const start_nomencla = nomencla.slice(0, 28);
	    const end_nomencla = nomencla.slice(-10);
	    const new_nomencla = start_nomencla.concat(new_manz_nro, end_nomencla);

	    //list[index]["nomencla_manz_nro"] = new_manz_nro;
	    list[index]["nomenclatura_catastral"]= new_nomencla;
			list[index]["nomencla_manz_nro"]= result;// ADD cambio 15-08-202

		//**Manz Letra**
		} else if(name === "nomencla_manz_l"){
			// 0000000 0000000 0000000 - 0000000 0000XXX 0000000
			const soloLetras = value.replace(/\d/g, '');
			const result = soloLetras.toUpperCase();
	    const posiciones_manz_l = "000";
	    const len_manz_l = result.length;

	    if( len_manz_l > 0 ) {
	      const new_posiciones = posiciones_manz_l.slice(0, - len_manz_l);
	      var new_manz_l = new_posiciones.concat(result);
	    } else {
	      var new_manz_l = posiciones_manz_l;
	    }

	    const start_nomencla = nomencla.slice(0, 32);
	    const end_nomencla = nomencla.slice(-7);
	    const new_nomencla = start_nomencla.concat(new_manz_l, end_nomencla);

	    //list[index]["nomencla_manz_l"] = new_manz_l;
	    list[index]["nomenclatura_catastral"]= new_nomencla;
			list[index]["nomencla_manz_l"]= result;

		//Parc.
		} else if(name === "nomencla_parc_nro"){
			// 0000000 0000000 0000000 - 0000000 0000000 XXXX000
			const result = value.replace(/\D/g, '');//ADD - cambio 15-08-2022.
	    const posiciones_parc_nro = "0000";
	    const len_parc_nro = result.length;//cambio 15-08-2022.

	    if( len_parc_nro > 0){
	      const new_posiciones = posiciones_parc_nro.slice(0, - len_parc_nro);
	      var new_parc_nro = new_posiciones.concat(result);//cambio 15-08-2022
	    } else {
        var new_parc_nro = posiciones_parc_nro;
	    }

	    const start_nomencla = nomencla.slice(0, 35);
	    const end_nomencla = nomencla.slice(-3);
	    const new_nomencla = start_nomencla.concat(new_parc_nro, end_nomencla);

	    //list[index]["nomencla_parc_nro"] = new_parc_nro;
	    list[index]["nomenclatura_catastral"]= new_nomencla;
			list[index]["nomencla_parc_nro"]= result;//ADD cambio 15-08-2022

		} else if(name === "nomencla_parc_l"){
			// 0000000 0000000 0000000 - 0000000 0000000 0000XXX
			const soloLetras = value.replace(/\d/g, '');
			const result = soloLetras.toUpperCase();
	    const posiciones_parc_l = "000";
	    const len_parc_l = result.length;

	    if( len_parc_l > 0 ) {
	      const new_posiciones = posiciones_parc_l.slice(0, - len_parc_l);
	      var new_parc_l = new_posiciones.concat(result);
	    } else {
        var new_parc_l = posiciones_parc_l;
	    }

	    const start_nomencla = nomencla.slice(0, 39);
	    //const end_nomencla = nomencla.slice(-7); //NO HACE FALTA cortar al final
	    const new_nomencla = start_nomencla.concat(new_parc_l);

	    //list[index]["nomencla_parc_l"] = new_parc_l;
	    list[index]["nomenclatura_catastral"]= new_nomencla;
			list[index]["nomencla_parc_l"]= result;
		}

		//list[index]["nomenclatura"]= new_nomencla;
    setFormRows(list);

		//changeValue(index, value, list);

	}

	const changeValue = (i, v, l) =>{
		//console.log("cambio value");
		//console.log(i);
		//console.log(v);
		//console.log(l);
		//console.log(l[i]);

		const list2 = [...formRows,];
		list2[i]["nomenclatura"] =  "nueva_nomencla";
		setFormRows(list2);
	}

	const handleRemoveClick = index => {
		//console.log(index);
		const id_row_hide = "row_".concat(index);

    let list = [...formRows];
		//console.log(list);
		//console.log(formRows.length);
    list.splice(index, 1);
    //console.log("list");

    setFormRows(list);
		//document.getElementById(id_row_hide).style.display = "none";

		//const list = formRows.filter((row) => row.index !== index);
		//console.log(formRows);
  };


	const [oldsForm, setOldsForm]= useState({
		oldCirc : "",
		oldSecc: "",
	});

	const {oldCirc, oldSecc} = oldsForm;
	//console.log(oldCirc);

	const calcularOldForm = (len) =>{

		const old_list = [...formRows, ];

		if(len > 0){
			const old_circ =  old_list[len-1]["nomencla_circ"];
			//console.log(old_circ);
			setOldsForm({ ...oldsForm, ["oldCirc"]: old_circ });
		}
	}

	//NOTA en un objeto ---> nomencla_circ: nomencla_circ es lo mismo que ----> nomencla_circ
    const handleAddClick = () => {

		//console.log(formRows.length);
		const len_form = formRows.length;

		//calcularOldForm(len_form); // ===========> Por AHORA NO FUNCIONO :(

    /*setFormRows([...formRows, { nomenclatura:"0000000000000000000000000000000000000000000000000", partida:"000000", inscripcion:"", nomencla_partido: formRows[len_form-1]["nomencla_partido"] , nomencla_circ: "" , nomencla_secc:"", nomencla_chacr_nro:"", nomencla_chacra_l:"", nomencla_quinta_nro:"", nomencla_quinta_l:"", nomencla_fracc_nro:"", nomencla_fracc_l:"", nomencla_manz_nro:"", nomencla_manz_l:"", nomencla_parc_nro:"", nomencla_parc_l:"" }]);*/

		setFormRows([ ...formRows, {
			nomenclatura_catastral: formRows[len_form-1]["nomenclatura_catastral"],
			partida_inmobiliaria:formRows[len_form-1]["partida_inmobiliaria"],
			inscripcion_dominial:formRows[len_form-1]["inscripcion_dominial"],
			nomencla_partido: formRows[len_form-1]["nomencla_partido"],
			nomencla_circ: formRows[len_form-1]["nomencla_circ"],
			nomencla_secc: formRows[len_form-1]["nomencla_secc"],
			nomencla_chacra_nro: formRows[len_form-1]["nomencla_chacra_nro"],
			nomencla_chacra_l: formRows[len_form-1]["nomencla_chacra_l"],
			nomencla_quinta_nro: formRows[len_form-1]["nomencla_quinta_nro"],
			nomencla_quinta_l: formRows[len_form-1]["nomencla_quinta_l"],
			nomencla_fracc_nro: formRows[len_form-1]["nomencla_fracc_nro"],
			nomencla_fracc_l: formRows[len_form-1]["nomencla_fracc_l"],
			nomencla_manz_nro: formRows[len_form-1]["nomencla_manz_nro"],
			nomencla_manz_l: formRows[len_form-1]["nomencla_manz_l"],
			nomencla_parc_nro: formRows[len_form-1]["nomencla_parc_nro"],
			nomencla_parc_l: formRows[len_form-1]["nomencla_parc_l"],
			solicitado: formRows[len_form-1]["solicitado"]
		}]);
    };


	return (
		<div>
			{formRows.map((row, i) => {
			const id_row = "row_".concat(i);
				//console.log(row.nomencla_circ, i);
				return (
					<div style={{}}>{/* border: "1px rgb(131,131,131) solid", marginBottom: "10px" */}

						<div className="container_left">
							<div className="input-container">
								<input
									disabled
									type="text"
									id="id_nomencla"
									name=" "
									value={row.nomenclatura_catastral}
									//className="form-control my-3"
									onChange={e => onChange(e, i)}
									required
								>
								</input>
								<label for="id_nomencla">Nomenclatura catastral</label>
							</div>
						</div>

						<div className="container_left">{/* PARA PArtido ------> validar solo nùmeros*/}
							<div style={{width: "24%"}} className="input-number">
								<input
									name="nomencla_partido"
									id= "id_nomencla_partido"
									maxLength="3"
									//style={{ width:"100px" }}
									value={row.nomencla_partido}
									placeholder="000"
									onChange={e => onChange(e, i)}
								>
								</input>
								<label for="id_nomencla_partido">Cod. Partido</label>
							</div>
						</div>

						<div className="container_left">{/* PARA circ, secc, chac nro y letra ------> validar solo nùmeros*/}
							<div style={{width: "12%"}} className="input-number">
								<input name="nomencla_circ" id="id_nomcla_circ" maxLength="2" value={row.nomencla_circ} placeholder="00" onChange={e => onChange(e, i)}></input>
								<label for="id_nomcla_circ">C.</label>
							</div>
							<div style={{width: "1%"}}/>
							<div style={{width: "12%"}} className="input-number">
								<input name="nomencla_secc" id="id_nomencla_secc" maxLength="2" value={row.nomencla_secc} placeholder="00" onChange={e => onChange(e, i)}></input>
								<label for="id_nomencla_secc" >S.</label>
							</div>
							<div style={{width: "1%"}}/>
							<div style={{width: "12%"}} className="input-number">
								<input name="nomencla_chacra_nro" id="id_nomencla_chacra_nro" maxLength="4" value={row.nomencla_chacra_nro} placeholder="0000" onChange={e => onChange(e, i)}></input>
								<label for="id_nomencla_chacra_nro">CHn.</label>
							</div>
							<div style={{width: "1%"}}/>
							<div style={{width: "12%"}} className="input-number">
								<input name="nomencla_chacra_l" id="id_nomencla_chacra_l" maxLength="3" value={row.nomencla_chacra_l} placeholder="000" onChange={e => onChange(e, i)}></input>
								<label for="id_nomencla_chacra_l">CHl.</label>
							</div>

							{/* PARA quinta nro y letra, fracc nro y letra ------> validar solo nùmeros*/}
							<div style={{width: "1%"}}/>
							<div style={{width: "12%"}} className="input-number">
								<input name="nomencla_quinta_nro" id="id_nomencla_quinta_nro" maxLength="4" value={row.nomencla_quinta_nro} placeholder="0000" onChange={e => onChange(e, i)}></input>
								<label for="id_nomencla_quinta_nro">Qn.</label>
							</div>
							<div style={{width: "1%"}}/>
							<div style={{width: "12%"}} className="input-number">
								<input name="nomencla_quinta_l" id="id_nomencla_quinta_l" maxLength="3" value={row.nomencla_quinta_l} placeholder="000" onChange={e => onChange(e, i)}></input>
								<label id="id_nomencla_quinta_l">Ql.</label>
							</div>
							<div style={{width: "1%"}}/>
							<div style={{width: "12%"}} className="input-number">
								<input name="nomencla_fracc_nro" id="id_nomencla_fracc_nro" maxLength="4" value={row.nomencla_fracc_nro} placeholder="0000" onChange={e => onChange(e, i)}></input>
								<label for="id_nomencla_fracc_nro">Fn.</label>
							</div>
							<div style={{width: "1%"}}/>
							<div style={{width: "12%"}} className="input-number">
								<input name="nomencla_fracc_l" id="id_nomencla_fracc_l" maxLength="3" value={row.nomencla_fracc_l} placeholder="000" onChange={e => onChange(e, i)}></input>
								<label for="id_nomencla_fracc_l">Fl.</label>
							</div>
						</div>

						<div className="container_left">{/* PARA MANZ nro y letra, PARC nro y letra ------> validar solo nùmeros*/}
							<div style={{width: "12%"}} className="input-number">
								<input name="nomencla_manz_nro" id="id_nomencla_manz_nro" maxLength="4" value={row.nomencla_manz_nro} placeholder="0000" onChange={e => onChange(e, i)}></input>
								<label for="id_nomencla_manz_nro">Mn.</label>
							</div>
							<div style={{width: "1%"}}/>
							<div style={{width: "12%"}} className="input-number">
								<input name="nomencla_manz_l" id="id_nomencla_manz_l" maxLength="3" value={row.nomencla_manz_l} placeholder="000" onChange={e => onChange(e, i)}></input>
								<label for="id_nomencla_manz_l">Ml.</label>
							</div>
							<div style={{width: "1%"}}/>
							<div style={{width: "12%"}} className="input-number">
								<input name="nomencla_parc_nro" id="id_nomencla_parc_nro" maxLength="4" value={row.nomencla_parc_nro} placeholder="0000" onChange={e => onChange(e, i)}></input>
								<label for="id_nomencla_parc_nro">Pn.</label>
							</div>
							<div style={{width: "1%"}}/>
							<div style={{width: "12%"}} className="input-number">
								<input name="nomencla_parc_l" id="id_nomencla_parc_l" maxLength="3" value={row.nomencla_parc_l} placeholder="000" onChange={e => onChange(e, i)}></input>
								<label for="id_nomencla_parc_l">Pl.</label>
							</div>

							{/* PARA partida inmobi e inscripcion   ------> validar solo nùmeros*/}
							<div style={{width: "1%"}}/>
							<div style={{width: "24%"}} className="input-number">
								<input name="partida_inmobiliaria" id="id_partida_inmobiliaria" maxLength="12" value={row.partida_inmobiliaria} placeholder="000000000000" onChange={e => onChange(e, i)}></input>
								<label for="id_partida_inmobiliaria">Partida</label>
							</div>
							<div style={{width: "1%"}}/>
							<div style={{width: "24%"}} className="input-number">
								<input name="inscripcion_dominial" id="id_inscripcion_dominial" maxLength="12" value={row.inscripcion_dominial} placeholder="000000000000" onChange={e => onChange(e, i)}></input>
								<label for="id_inscripcion_dominial">Inscripción</label>
							</div>
						</div>

						<div className="container_left">
							<div className="input-container">
								<SelectInput
									name="solicitado"
									id="id_solicitado"
									defaultValue={row.solicitado}
									onChange={e => onChange(e, i)}
									options={optionsSolicitado}
									className="form-control my-3"
								/>
								<label for="id_solicitado">Solicitado</label>
							</div>
						</div>

						<div className="">
								{formRows.length !== 1 && <Button alt="Eliminar" style={{background: "red"}} variant="secondary" onClick={() => handleRemoveClick(i)}>Eliminar</Button>}

								{formRows.length - 1 === i && <Button variant="secondary" onClick={handleAddClick}>+</Button>}


						</div>



					</div>
				)
			})}
		</div>
	);
};

export default TablaNew;
