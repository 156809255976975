import React, { Fragment, useState, useEffect } from "react";
import Encabezado from './encabezado_A4.png';
import ImagePie from './pie_hoja_A4.png'

const VistaWeb = ({ informeTitle, registro }) => {
  //console.log(informeTitle);
  //console.log(registro);

  const {expediente, programa, area,
    solicitud_adhesion_t, certif_urbanistico_municipal_t, certif_no_inundabilidad_t,
    anteproyecto_urbano_t, titulo_propiedad_t,
    memoria_descriptiva_obras_t, energia_electrica_t, agua_potable_t,
    eliminacion_excretas_t, apertura_calles_t, acta_constitutiva_t,
    certif_vigencia_persona_juridica_t, plano_mensura_t,
    convenio_urbanistico_t, ordenanza_municipal_t, memoria_descriptiva_procedimientos_t, t_144_proyecto_hidraulico,
    observaciones_reporte_t,
    titular_tierra, tipo,
    sistema_agua, eliminacion_excretas
  }= registro;
  console.log("PROYECTO HIDRAULICO ---->", t_144_proyecto_hidraulico);
  console.log("AREA ---->", area);
  //console.log(certif_urbanistico_municipal_t);

  const [estadoPrograma, setEstadoPrograma] = useState("................");
  useEffect(()=>{
    if(programa === "LOTES CON SERVICIOS"){
      setEstadoPrograma("Programa Lotes con Servicios");
    } else if(programa === "PLAN FAMILIA PROPIETARIA"){
      setEstadoPrograma("Plan Familia Propietaria");
    } else if(programa === "REGIMEN DE INTEGRACIÓN SOCIOURBANA"){
      setEstadoPrograma("Régimen de Integración Sociourbana");
    }
  }, [programa])

  const [regRecibido, setRegRecibido] = useState([]);
  //console.log(regRecibido);

  const [estadoActa, setEstadoActa ] = useState(false); //ESTE estado depende del valor que tenga titular_tierra
  //console.log(estadoActa);
  const [estadoCertVigencia, setCertVigencia ] = useState(false); //ESTE estado depende del valor que tenga certif_vigencia_persona_juridica_t
  //console.log(estadoCertVigencia);

  //si area--> Urbana, entonces Proyecto hidraulico no tienen que aparecer
  const [estadoArea, setEstadoArea]= useState(true);
  console.log("ESTADO AREA--->",estadoArea);

  //SOLO TIENEN QUE APARECER SI estadoTipo es true.
  const [estadoTipo, setEstadoTipo] = useState(false);
  //console.log(estadoTipo);

  const [estadoAgua, setEstadoAgua] = useState(false);
  //console.log(estadoAgua);

  const [estadoExcretas, setEstadoExcretas] = useState(false);
  //console.log(estadoExcretas);

  useEffect(()=>{
    if(area==="COMPLEMENTARIA" || area==="RURAL" || area==="OTRO" || area === null || area === ""){
      setEstadoArea(true)
    } else {
      setEstadoArea(false);
    }

  }, [area])

  useEffect(()=>{
    if(
      titular_tierra === "" ||
      titular_tierra === "MUNICIPIO" ||
      titular_tierra === "PROVINCIA (IVBA u otros)" ||
      titular_tierra === "ESTADO NACIONAL" ||
      titular_tierra === "PRIVADOS PARTICULARES"||
      titular_tierra === "CONDOMINIO"||
      titular_tierra === "BANCO HIPOTECARIO" ||
      titular_tierra === "OTROS" ||
      titular_tierra === "SIN DATO"
    ){
      setEstadoActa(false);
      setCertVigencia(false);
      //console.log("ACTA y CERT. VIGENCIA ESTA DISABLED");
    } else {
      setEstadoActa(true);
      setCertVigencia(true);
      //console.log("ACTA y CERT. VIGENCIA ESTA HABILITADO");
    }
  },[titular_tierra]);

  useEffect(()=>{
    if( tipo === "" || tipo === "INMUEBLES DEL ESTADO"){ // 0, 1
      setEstadoTipo(false);
      //console.log("TIPO ESTA DISABLED");
    } else {
      setEstadoTipo(true);
      //console.log("TIPO ESTA HABILITADO");
    }
  }, [tipo]);

  //estadoAgua
  useEffect(()=>{
    if(sistema_agua !== "RED" ){
      setEstadoAgua(false);
    } else {
      setEstadoAgua(true);
    }
  }, [sistema_agua]);

  //estadoExcretas
  useEffect(()=>{
    if(eliminacion_excretas !== "RED"){
      setEstadoExcretas(false);
    } else {
      setEstadoExcretas(true);
    }
  }, [eliminacion_excretas])

  useEffect(()=>{
    setRegRecibido([
      {"name": "Nota de solicitud de adhesión al programa", "value": solicitud_adhesion_t},
      {"name": "Informe urbanístico municipal", "value":certif_urbanistico_municipal_t},
      {"name": "Certificado de no inundabilidad del predio", "value":certif_no_inundabilidad_t},
      {"name": "Anteproyecto urbano", "value":anteproyecto_urbano_t},
      {"name": "Título de propiedad y/o datos de inscripción dominial", "value":titulo_propiedad_t},

      {"name": "Memoria descriptiva de las obras a ejecutar", "value":memoria_descriptiva_obras_t},
      {"name": "Prefactibilidad de energía eléctrica", "value":energia_electrica_t},
      {"name": "Prefactibilidad de agua potable por red", "value":agua_potable_t},
      {"name": "Prefactibilidad de red cloacal", "value":eliminacion_excretas_t},
      {"name": "Factibilidad apertura de calles", "value":apertura_calles_t},
      {"name": "Acta constitutiva persona jurídica", "value":acta_constitutiva_t},
      {"name": "Certificado de vigencia de Persona Jurídica", "value":certif_vigencia_persona_juridica_t},
      {"name": "Plano de mensura y división", "value":plano_mensura_t},
      {"name": "Convenio urbanístico", "value":convenio_urbanistico_t},
      {"name": "Ordenanza municipal", "value":ordenanza_municipal_t},
      {"name": "Memoria descriptiva del procedimiento de adjudicación", "value":memoria_descriptiva_procedimientos_t},
      {"name": "Proyecto hidráulico", "value":t_144_proyecto_hidraulico}
    ])
  }, [registro]);

  //const [localidad, setLocalidad] = useState("");

  const localidadVacio = "........................";

  const tab = '\u00A0';

  const fecha = new Date().toLocaleDateString('es-AR');


  const texto3 = `${tab}${tab}${tab}${tab}${tab}${tab}${tab}${tab} Vienen las actuaciones por las cuales se solicita la afectación al ${estadoPrograma} de un proyecto de urbanización a desarrollarse en el inmueble de la localidad de
  ${registro.localidad ? registro.localidad : localidadVacio}, partido de ${registro.partido_nombre ? registro.partido_nombre : localidadVacio}, identificado catastralmente como
  ${registro.datos_18_sintesis_nomenclatura ? registro.datos_18_sintesis_nomenclatura : "..."}`;

  const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";

  const sub1 = "El Municipio presentó la siguiente documentación:";
  const sub2 = "Documentación faltante a presentar:";
  const sub3 ="Observaciones";

  const sub_pie = "Subsecretaría de Hábitat de la Comunidad";
  const texto1_pie = `Dirección Provincial de Producción de Hábitat`;
  const texto2_pie = `Diag. 73 Nº 1568 esq 56 - 4º Piso`;
  const texto3_pie = `La Plata (1900), Buenos Aires`;
  const texto4_pie = `dproducciónhabitat@gmail.com`;
  const texto5_pie = `Tel. (0221) 427-2266 Int. 107`;
  const texto6_pie = `gba.gob.ar`;

//className="container_actuaciones"

  return (
    <>
    <div className="container_actuaciones">
    <img style={{width: "100%", marginBottom: 30, marginTop: 20}} src={Encabezado} />
    {/*---cierrar header -----*/}

    <div className="flex-informe-right">
      <p></p>
    </div>

    <div className="flex-informe-center">
      <h6 className="subtitulos" style={{fontSize: "20px"}}> {/*Titulo Estado de las actuaciones*/}
        {informeTitle ? <b>{informeTitle.title}</b> : "..."}
      </h6>
    </div>
    <div className="flex-informe-left">
      {registro.nombre_proyecto} - {registro.partido_nombre}
    </div>
    {/*f*/}
    <div className="flex-informe-right">
      <p>{informeTitle ? <i>Referente al Expediente</i> : "NO TENGO"} {expediente}</p>
      {/*<p>Por {informe ? informe.poet.name : "..."}</p>*/}
    </div>

    {/*ACÀ texto principal del informe ---> justificar*/}
    <div className="flex-informe-justify">
      <text style={{ maxWidth: "80ch" }}>{registro ? texto3 : "NO TENGO"}</text>
      <br />
      <br />
    </div>

    <div class="d-flex flex-column">
      <div class="p-2">
        {/*<h6 className="subtitulos">
          {<b>PRESENTO</b>}
        </h6>
        {<text>- Nota de solicitud de adhesión al programa{`----> ${solicitud_adhesion_t}\n`}</text>}{<br />}
        {<text>- Informe urbanístico municipal {`----> ${certif_urbanistico_municipal_t}\n`}</text>}{<br />}
        {<text>- Certificado de no inundabilidad del predio {`----> ${certif_no_inundabilidad_t}\n`}</text>}{<br />}
        {<text>- Anteproyecto urbano {`----> ${anteproyecto_urbano_t}\n`}</text>}{<br />}
        {<text>- Título de propiedad y/o datos de inscripción dominial {`----> ${titulo_propiedad_t}\n`}</text>}{<br />}
        {<text>- Memoria descriptiva de las obras a ejecutar {`----> ${memoria_descriptiva_obras_t}\n`}</text>}{<br />}
        {<text>- Prefactibilidad de energía eléctrica {`----> ${energia_electrica_t}\n`}</text>}{<br />}
        {<text>- Prefactibilidad de agua potable por red {`//----> ${agua_potable_t}\n`}</text>}{<br />}
        {<text>- Prefactibilidad de red cloacal {`//----> ${eliminacion_excretas_t}\n`}</text>}{<br />}
        {<text>- Factibilidad apertura de calles {`----> ${apertura_calles_t}\n`}</text>}{<br />}
        {<text>- Acta constitutiva persona jurídica {`//----> ${acta_constitutiva_t}\n`}</text>}{<br />}
        {<text>- Certificado de vigencia de persona jurídica {`//----> ${certif_vigencia_persona_juridica_t}\n`}</text>}{<br />}
        {<text>- Plano de mensura y división {`----> ${plano_mensura_t}\n`}</text>}{<br />}
        {<text>- Convenio urbanístico {`//----> ${convenio_urbanistico_t}\n`}</text>}{<br />}
        {<text>- Ordenanza municipal {`----> ${ordenanza_municipal_t}\n`}</text>}{<br />}
        {<text>- Memoria descriptiva del procedimiento de adjudicación {`----> ${memoria_descriptiva_procedimientos_t}\n`}</text>}{<br />}*/}

        <h6 className="subtitulos">
          {registro ? <b>{sub1}</b> : "..."}
        </h6>

        {solicitud_adhesion_t==="true" ? <text>- Nota de solicitud de adhesión al programa</text> : "" }
        {solicitud_adhesion_t==="true" ?<br />: ""}

        {certif_urbanistico_municipal_t==="true" ? <text>- Informe urbanístico municipal</text> : "" }
        {certif_urbanistico_municipal_t==="true" ?<br />: ""}

        {certif_no_inundabilidad_t==="true" ? <text>- Certificado de no inundabilidad del predio</text> : "" }
        {certif_no_inundabilidad_t==="true" ?<br />: ""}

        {anteproyecto_urbano_t==="true" ? <text>- Anteproyecto urbano</text> : "" }
        {anteproyecto_urbano_t==="true" ?<br />: ""}

        {titulo_propiedad_t==="true" ? <text>- Título de propiedad y/o datos de inscripción dominial</text> : "" }
        {titulo_propiedad_t==="true" ?<br />: ""}

        {memoria_descriptiva_obras_t==="true" ? <text>- Memoria descriptiva de las obras a ejecutar</text> : "" }
        {memoria_descriptiva_obras_t==="true" ?<br />: ""}

        {energia_electrica_t==="true" ? <text>- Prefactibilidad de energía eléctrica</text> : "" }
        {energia_electrica_t==="true" ?<br />: ""}

        { estadoAgua === true && agua_potable_t==="true" ? <text>- Prefactibilidad de agua potable por red</text> : ""}
        { estadoAgua === true && agua_potable_t==="true" ? <br />:""}

        { estadoExcretas === true && eliminacion_excretas_t==="true"? <text>- Prefactibilidad de red cloacal</text> : ""}
        { estadoExcretas === true && eliminacion_excretas_t==="true" ? <br />:""}

        { apertura_calles_t==="true" ? <text>- Factibilidad apertura de calles</text> : "" }
        { apertura_calles_t==="true" ? <br /> : ""}

        { estadoActa === true && acta_constitutiva_t==="true" ? <text>- Acta constitutiva persona jurídica</text> : ""}
        { estadoActa === true && acta_constitutiva_t==="true" ? <br />:""}

        { estadoCertVigencia === true && certif_vigencia_persona_juridica_t==="true" ? <text>- Certificado de vigencia de persona jurídica</text> : ""}
        { estadoCertVigencia === true && certif_vigencia_persona_juridica_t==="true" ? <br />:""}

        { plano_mensura_t==="true" ? <text>- Plano de mensura y división</text> : "" }
        { plano_mensura_t==="true" ? <br /> : ""}

        { estadoTipo === true && convenio_urbanistico_t==="true" ? <text>- Convenio urbanístico</text> : ""}
        { estadoTipo === true && convenio_urbanistico_t==="true" ? <br />:""}

        { ordenanza_municipal_t==="true" ? <text>- Ordenanza municipal</text> : "" }
        { ordenanza_municipal_t==="true" ? <br /> : ""}

        { memoria_descriptiva_procedimientos_t==="true" ? <text>- Memoria descriptiva del procedimiento de adjudicación</text> : "" }
        { memoria_descriptiva_procedimientos_t==="true" ? <br /> : ""}

        {/*t_144_proyecto_hidraulico==="true" ? <text>- Proyecto hidráulicoOO</text> : "" */}
        { estadoArea === true && t_144_proyecto_hidraulico==="true" ? <text>- Proyecto hidráulico</text> : ""}
        { estadoArea === true && t_144_proyecto_hidraulico==="true" ? <br /> : ""}

        {/*
        <text>{memoria_descriptiva_procedimientos_t==="true" ? "- Memoria descriptiva del procedimiento de adjudicación" : "" }</text><br />*/}
        {/*<ul>
          {regRecibido.filter(item => item.value === 'true').map(filteredReg => (
            <li>
              {filteredReg.name}
            </li>
          ))}
        </ul>
        */}
      </div>

      <div class="p-2">
        <h6 className="subtitulos"> {registro ? <b>{sub2}</b> : "..."} </h6>
				 {solicitud_adhesion_t==="false" || solicitud_adhesion_t===null ? <text>- Nota de solicitud de adhesión al programa</text> : "" }
        {solicitud_adhesion_t==="false" || solicitud_adhesion_t===null ?<br />: ""}

        {certif_urbanistico_municipal_t==="false" || certif_urbanistico_municipal_t === null? <text>- Informe urbanístico municipal</text> : "" }
        {certif_urbanistico_municipal_t==="false" || certif_urbanistico_municipal_t === null ? <br />: ""}

        {certif_no_inundabilidad_t==="false" || certif_no_inundabilidad_t===null ? <text>- Certificado de no inundabilidad del predio</text> : "" }
        {certif_no_inundabilidad_t==="false" || certif_no_inundabilidad_t===null ?<br />: ""}

        {anteproyecto_urbano_t==="false" || anteproyecto_urbano_t===null ? <text>- Anteproyecto urbano</text> : "" }
        {anteproyecto_urbano_t==="false" || anteproyecto_urbano_t===null ? <br />: ""}

        {titulo_propiedad_t==="false" || titulo_propiedad_t===null ? <text>- Título de propiedad y/o datos de inscripción dominial</text> : "" }
        {titulo_propiedad_t==="false" || titulo_propiedad_t===null ? <br />: ""}

        {memoria_descriptiva_obras_t==="false" || memoria_descriptiva_obras_t=== null ? <text>- Memoria descriptiva de las obras a ejecutar</text> : "" }
        {memoria_descriptiva_obras_t==="false" || memoria_descriptiva_obras_t=== null ? <br />: ""}

        {energia_electrica_t==="false" || energia_electrica_t === null ? <text>- Prefactibilidad de energía eléctrica</text> : "" }
        {energia_electrica_t==="false" || energia_electrica_t === null ? <br />: ""}


        { estadoAgua === true && agua_potable_t==="false" ? <text>- Prefactibilidad de agua potable por red</text> : ""}
        { estadoAgua === true && agua_potable_t=== null ? <text>- Prefactibilidad de agua potable por red</text> : ""}
        { estadoAgua === true && agua_potable_t==="false" ? <br />:""}
        { estadoAgua === true && agua_potable_t=== null ? <br />:""}


        { estadoExcretas === true && eliminacion_excretas_t==="false" ? <text>- Prefactibilidad de red cloacal</text> : ""}
        { estadoExcretas === true && eliminacion_excretas_t=== null ? <text>- Prefactibilidad de red cloacal</text> : ""}
        { estadoExcretas === true && eliminacion_excretas_t==="false" ? <br />:""}
        { estadoExcretas === true && eliminacion_excretas_t=== null ? <br />:""}


        { apertura_calles_t==="false" || apertura_calles_t===null ? <text>- Factibilidad apertura de calles</text> : "" }
        { apertura_calles_t==="false" || apertura_calles_t===null ? <br /> : ""}

        { estadoActa === true && acta_constitutiva_t==="false" ? <text>- Acta constitutiva persona jurídica</text> : ""}
        { estadoActa === true && acta_constitutiva_t===null ? <text>- Acta constitutiva persona jurídica</text> : ""}
        { estadoActa === true && acta_constitutiva_t==="false" ? <br />:""}
        { estadoActa === true && acta_constitutiva_t===null ? <br />:""}

        { estadoCertVigencia === true && certif_vigencia_persona_juridica_t==="false" ? <text>- Certificado de vigencia de persona jurídica</text> : ""}
        { estadoCertVigencia === true && certif_vigencia_persona_juridica_t===null ? <text>- Certificado de vigencia de persona jurídica</text> : ""}
        { estadoCertVigencia === true && certif_vigencia_persona_juridica_t==="false" ? <br />:""}
        { estadoCertVigencia === true && certif_vigencia_persona_juridica_t===null ? <br />:""}

        { plano_mensura_t==="false" || plano_mensura_t===null ? <text>- Plano de mensura y división</text> : "" }
        { plano_mensura_t==="false" || plano_mensura_t===null ? <br /> : ""}

        { estadoTipo === true && convenio_urbanistico_t==="false" ? <text>- Convenio urbanístico</text> : ""}
        { estadoTipo === true && convenio_urbanistico_t===null ? <text>- Convenio urbanístico</text> : ""}
        { estadoTipo === true && convenio_urbanistico_t==="false" ? <br />:""}
        { estadoTipo === true && convenio_urbanistico_t===null ? <br />:""}

        { ordenanza_municipal_t==="false" || ordenanza_municipal_t===null ? <text>- Ordenanza municipal</text> : "" }
        { ordenanza_municipal_t==="false" || ordenanza_municipal_t===null ? <br /> : ""}

        { memoria_descriptiva_procedimientos_t==="false" || memoria_descriptiva_procedimientos_t===null ? <text>- Memoria descriptiva del procedimiento de adjudicación</text> : "" }
        { memoria_descriptiva_procedimientos_t==="false" || memoria_descriptiva_procedimientos_t===null ? <br /> : ""}

        { /*t_144_proyecto_hidraulico==="false" || t_144_proyecto_hidraulico===null ? <text>- Proyecto hidráulicoOO</text> : "" */}
        { estadoArea === true && t_144_proyecto_hidraulico==="false" ? <text>- Proyecto hidráulico</text> : ""}
        { estadoArea === true && t_144_proyecto_hidraulico===null ? <text>- Proyecto hidráulico</text> : ""}
        
        { estadoArea === true || t_144_proyecto_hidraulico==="false" ? <br /> : ""}
        { estadoArea === true || t_144_proyecto_hidraulico===null ? <br /> : ""}

        {/*<ul>
          {regRecibido.filter(item => item.value === 'false' || item.value === null || item.value === '' ).map(filteredReg => (
            <li>
              {filteredReg.name}
            </li>
          ))}
        </ul>*/}
      </div>

      <div className="flex-informe-justify">
        <h6 className="subtitulos"> {registro ? <b>{sub3}</b> : "..."} </h6>
        <text style={{ whiteSpace:"pre-wrap", wordWrap:"break-word", overflowWrap:"break-word", maxWidth: "80ch" }}>{registro ? observaciones_reporte_t : "............"}</text>
      </div>

    </div>

    <div class="d-flex flex-row footer_actuaciones" >
      {/*<img
        src="https://picsum.photos/600/400"
        alt="random image"
        style={{ maxWidth: "600px", maxHeight: "400" }}
      />*/}
      <div style={{paddingTop: 20, width: "30%", alignItems: 'flex-start'}}>
        <text style={{fontSize: 10, paddingTop: 3}}><b>Subsecretaría de Hábitat de la Comunidad</b></text><br />
        <text style={{fontSize: 9, paddingTop: 3}}>Dirección Provincial de Producción de Hábitat</text><br />
        <text style={{fontSize: 9, paddingTop: 3}}>Diag. 73 Nº 1568 esq 56 - 4º Piso</text><br />
        <text style={{fontSize: 9, paddingTop: 3}}>La Plata (1900), Buenos Aires</text><br />
        <text style={{fontSize: 9, paddingTop: 3}}>dproduccionhabitat@gmail.com</text><br />
        <text style={{fontSize: 9, paddingTop: 3}}>Tel. (0221) 427-2266 Int. 129 /// gba.gob.ar</text><br />        
      </div>
      <div style={{width: "70%"}}>
        <img style={{width: "90%", height: "80%", marginBottom: 30, marginTop: 20}} src={ImagePie} />
      </div>
    </div>

    </div>
    </>
  );
};

export default VistaWeb;
//**CAMBIOS
//24-07-2022: cambiar imagen encabezado por encabezado_A4 (descargado de hoja membretada)
