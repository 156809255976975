import React, { Fragment, useState, useEffect, useRef, useContext } from "react";
import { SessionContext } from '../context/sessionContext';

import { Collapse, Form, Row, Col, Button } from "react-bootstrap";

import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';

import "react-datepicker/dist/react-datepicker.css";

import SelectInput from "./selectInput";

import TableNew from "./newTable";

import Map_Expedientes from "./map_expedientes";

import {optionsResponsable, optionsPrograma, optionsEstado, optionsTipo, optionsPartido, optionsTitular, optionsPlano, optionsArea, optionsSistemaAgua, optionsEliminacionExcret } from "../datos/options";
import {partidoInicio} from "../datos/options_inicio";

const DatosExp = ({ name, inputs, setInputs, formRows, setFormRows, setErrorD }) => {

	const nameLogin = name;
	
	const { original, cambioOriginal, defaultValue, geom, cambioGeom } = useContext(SessionContext);
	
	const {
		nombre_proyecto,
		fecha_carga,
		fecha_caratulacion,
		fecha_actualizacion,
		datos_05_responsable, expediente,
		tramite_prioritario, avanzados,
		programa, estado, tipo, partido, localidad,
		cantidad_lotes, cantidad_lotes_adjudicables, porc_lotes_municipales, lotes_adquiridos_municipio,
		datos_18_sintesis_nomenclatura,
		titular_tierra, plano_oficial, area, zonificacion,
		nro_ordenanza_vigente, decreto_promulgatorio, sistema_agua, eliminacion_excretas, observaciones_generales,
		datos_19_partido_nomencla,
		datos_20_circ_nomencla,
		datos_21_secc_nomencla,
		datos_22_geom
	} = inputs;

	const [fechaHoy, setFechaHoy] = useState(new Date('2014-01-01'));
	
	let _fechaHoy = null;
	if (fecha_actualizacion===null) {
		_fechaHoy = null;
	} else if (fecha_actualizacion !== null || fecha_actualizacion !== ""){
		_fechaHoy = new Date(fecha_actualizacion);
		_fechaHoy.setMinutes(_fechaHoy.getMinutes() + _fechaHoy.getTimezoneOffset())
	}

	let _fechaCarga = null;
	if (fecha_carga=== null) {
		_fechaCarga = null;
	} else if (fecha_carga !== null || fecha_carga !== ""){
		_fechaCarga = new Date(fecha_carga);
		_fechaCarga.setMinutes(_fechaCarga.getMinutes() + _fechaCarga.getTimezoneOffset())
	}

	const textAreaNomenclaSintesis = useRef(null);
  	const textAreaObsGrales = useRef(null);
	
	//***PARA cuadro de texto
	const [valNomencSintesis, setValNomencSintesis] = useState(datos_18_sintesis_nomenclatura);
  	const [valObserGrales, setValObservGrales] = useState(observaciones_generales);

	const [valGeom, setValGeom] = useState(datos_22_geom);

	//***PARA herramienta dibujo - carga de nomencla */
	const [modalPartido, setModalPartido]=useState('0');	
	const [modalCirc, setModalCirc]=useState('00');	
	const [modalSecc, setModalSecc]=useState('00');

	const [openCatastro, setOpenC] = useState(false);

	//State para div localizacion
	const [open, setOpen] = useState(false);

	//--->30mar2023 - Estados para ventana modal (DRAW)
	const [showDraw, setShowDraw] = useState(false);//cambiar luego a false para que esté apagada por default
	const [circS, setCirc] = useState([]); //
	const [seccS, setSecc] = useState([]);

	const [nombre_partido, setPartido]=useState([]);

	const optionsNull = [{ value: "", label:"" }];
	
	useEffect(() => {
		getCirc();
	}, [partido]);

	//***** add 07-agos-2023 */
	const [partidoGEO, setPartidoGEO]= useState([]);
	const [geomPartidoEnviar, setGeomPartidoEnviar]=useState(null);
	
	const urlPartido = `/api/api_serv/query/partidos/?partidoNro=${modalPartido}`;

	const getDatosPartido = async (url) => {

		const headers = {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'			
		};
		const res = await fetch(url,{ headers })
		.then(res => res.json())
        .then(res=>{
            setPartidoGEO(res)            
        })		
		.catch(err => console.log(err));
	}
	//***7-agosto-2023 */

	const [mensajeErrorPrograma, setMensajeErrorPrograma]=useState(true);
	const [mensajeErrorEstado, setMensajeErrorEstado]=useState(true);
	const [mensajeErrorTipo, setMensajeErrorTipo]=useState(true);
	const [mensajeErrorPartido, setMensajeErrorPartido]=useState(true); //valor inicial, mostrar un mensaje para evitar que quede vacio

	useEffect(() =>{
		if (programa === "" || estado === "" || tipo === "" ) {
			setErrorD(true)
		} else if (partido === 0 || partido === '0'){
			setErrorD(true)

		} else{
			setErrorD(false)
		}
	}, [programa, estado, tipo, partido])

	useEffect(()=>{
		if(programa !== ""){
			setMensajeErrorPrograma(false)
		} else {
			setMensajeErrorPrograma(true)
		} 
	}, [programa])

	useEffect(()=>{
		if(estado !== ""){
			setMensajeErrorEstado(false)
		} else {
			setMensajeErrorEstado(true)
		} 
	}, [estado])

	useEffect(()=>{
		if(tipo !== ""){
			setMensajeErrorTipo(false)
		} else {
			setMensajeErrorTipo(true)
		} 
	}, [tipo])

	useEffect(()=>{		
		if(partido === 0){
			setMensajeErrorPartido(true)
		} else if(partido !== '0'){
			setMensajeErrorPartido(false)
		} else if(partido === '0'){
			setMensajeErrorPartido(true)
		}
	}, [partido])

	useEffect(() => {
		if(modalPartido !== 0){
			getDatosPartido(urlPartido)
		}
	}, [modalPartido]);

	useEffect(()=>{
        if(partidoGEO === null || partidoGEO.length === 0){
        } else {

            let geojson = partidoGEO[0].geojson;

            let geomHard = JSON.parse(geojson);           

            let datos = geomHard;
            
            setGeomPartidoEnviar(datos);          
        }
        
    }, [partidoGEO])	

	useEffect(() => {		
		setInputs({ ...inputs, "datos_21_secc_nomencla": '' });		
	}, [datos_20_circ_nomencla]);

	useEffect(()=>{
		getSecc();
	}, [datos_20_circ_nomencla]);

	useEffect(()=>{
		console.log("PARTIDO", partido)
		
		let partido_name = optionsPartido.filter(partidoN =>{ 
			return partidoN.value == partido;
		});
		setPartido(partido_name[0].label);		

	}, [partido]) // cuando CAMBIE el dato de partido reucperar el dato de la etiqueta 

	const getPartido = async () => {
		
		const url = `/api/api_serv/nomencla/partido?partido=${partido}`;
		const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token }

		const res = await fetch(url, {headers} )
		
		.then(res => res.json())
		.then(res => {
			setPartido(res);
      		
		}) //*** asigna la respuesta a datosS
		.catch(err => console.log(err))
		.finally();
	}
	
	const getCirc = async () => {

		const url = `/api/api_serv/nomencla/circ?partido=${partido}`;
		const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token }

		const res = await fetch(url, {headers} )
		
		.then(res => res.json())
		.then(res => {
			setInputs({ ...inputs, "datos_20_circ_nomencla": '', "datos_21_secc_nomencla": '' });
			setCirc([  ])
			setSecc([]);
			setCirc ([...optionsNull, ...res])
      		
		}) //*** asigna la respuesta a datosS
		.catch(err => console.log(err))
		.finally();
	}

	const getSecc = async () => {

		const url = `/api/api_serv/nomencla/secc?partido=${partido}&circ=${datos_20_circ_nomencla}`;
		console.log(url);
		const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token }

		const res = await fetch(url, {headers} )
		
		.then(res => res.json())
		.then(res => { setSecc([  ])
			{ setSecc ([...optionsNull, ...res])}
      		
		}) //*** asigna la respuesta a datosS
		.catch(err => console.log(err))
		.finally();
	}

	const handleClose = () => {
		cambioGeom({type: 'FeatureCollection', features: []});
		setShowDraw(false);		
	};

	const guardarEditRegistro = () => {
		setShowDraw(false);
	}


	/* AGREGAR contadores */
	//** CONTADOR Nomenclatura Sintesis maxNomenclaS**/
	const [maxNomenclaS, setMaxNomenclaS] = useState(500);
	useEffect(()=>{
		const maxNomS = 500;
		if(datos_18_sintesis_nomenclatura){
			if(datos_18_sintesis_nomenclatura !== "" || datos_18_sintesis_nomenclatura !== null){
				
				var restantesR = maxNomS - datos_18_sintesis_nomenclatura.length;
				setMaxNomenclaS(restantesR);
			}
		} else {
			setMaxNomenclaS(maxNomS);
		}
	}, [datos_18_sintesis_nomenclatura]);
	//** cierra contador Nomencla Sintesis **/

	//****CONTADOR zonificacion****
	{/* const [maxZonif, setmaxZonif] = useState(500);
	useEffect(()=>{
		const maxZonif = 50;
		if(zonificacion){
			if(zonificacion !== "" || zonificacion !== null){
				var restantesR = maxZonif - zonificacion.length;
				setmaxZonif(restantesR);
			}
		} else {
			setmaxZonif(maxZonif);
		}
	}, [zonificacion]); */}
	//----cierra zonificacion---

	//*** CONTADOR Localidades ****
	{/*const [maxLocalidad, setMaxLocalidad] = useState(100);
	useEffect(()=>{
		const maxLoc = 100;
		if(localidad){
			if(localidad !== "" || localidad !== null){
				var restantesR = maxLoc - localidad.length;
				setMaxLocalidad(restantesR);
			}
		} else {
			setMaxLocalidad(maxLoc);
		}
	}, [localidad]);*/}

	//------CIERRA Localidades -----

	//**CONTADOR OBSERV Generales
	const [maxObservGen, setMaxObservGen] = useState(500);
	useEffect(()=>{
		const maxOG = 500;
		if(observaciones_generales){
			if(observaciones_generales !== "" || observaciones_generales !== null){
				var restantesR = maxOG - observaciones_generales.length;
				setMaxObservGen(restantesR);
			}
		} else {
			setMaxObservGen(maxOG);
		}
	}, [observaciones_generales]);
	//---CIERRA-----

	const handleChangeRow = (newRow) =>{
		setFormRows(newRow)
	}	

	const onChange = e => {
		//setOriginal(1);
		cambioOriginal(1);

		const {name, value, type} = e.target;

		if(type === 'textarea' || type === 'text' || type === 'select-one' ){
			setInputs({ ...inputs, [e.target.name]: e.target.value });

			if(e.target.name === 'datos_18_sintesis_nomenclatura'){
				setValNomencSintesis(e.target.value);
			}

			if(name === 'partido'){ 				
				setModalPartido(value);
				setModalCirc('00'); //restablece al valor original si cambia el dato de partido
				setModalSecc('00');
			}
			if(name === 'datos_20_circ_nomencla'){
				setModalCirc(value);
			} 
			if(name === 'datos_21_secc_nomencla'){
				setModalSecc(value);
			}
			if(name === 'datos_22_geom'){
				//setInputs({ ...inputs, datos_22_geom: JSON.stringify(geom) });
			}

			if(e.target.name === 'observaciones_generales'){
				setValObservGrales(e.target.value);
			}

			e.target.style.height = 'auto';
			e.target.style.height = `${e.target.scrollHeight}px`;

		} else if(type === 'number'){

			if(e.target.name === 'cantidad_lotes'){

				var value_cant_lotes=e.target.value;
				if(e.target.value ===null || e.target.value===""){
					value_cant_lotes=0;
				}
				setInputs({ ...inputs, cantidad_lotes: value_cant_lotes }); //e.target.value 16-02-2023

			} else if(name === 'cantidad_lotes_adjudicables' ) {

				var value_lotes_adj=e.target.value;
				if(e.target.value ===null || e.target.value===""){
					value_lotes_adj=0;
				}

				setInputs({
					...inputs,
					[name]: value_lotes_adj, //e.target.value 16-02-2023
					"porc_lotes_municipales" : 0,
					"lotes_adquiridos_municipio" : 0
				});
				document.getElementById("input_A").value = 0;
				document.getElementById("input_B").value = 0;

			} else if(name === 'lotes_adquiridos_municipio') {

				var value_lotes=e.target.value;
				if(e.target.value ===null || e.target.value===""){
					value_lotes=0;
				}

				setInputs({
					...inputs,
					[name]: value_lotes,
					porc_lotes_municipales : parseInt((value_lotes * 100)/cantidad_lotes_adjudicables) //e.target.value 16-02-2023
				});
			} else if(name === 'porc_lotes_municipales'){
				var value_porc = e.target.value;
				if(e.target.value ===null || e.target.value===""){
					value_porc = 0;
				} else if (e.target.value > 0){
					value_porc = Math.trunc(e.target.value);
				}

				setInputs({
					...inputs,
					[name]: value_porc, //value (16-feb-2023)
					lotes_adquiridos_municipio : (value_porc * cantidad_lotes_adjudicables)/100 //solo_entero (16-feb-2023)
				});
			}

		} else if(e.target.type === 'checkbox'){ //Hay un solo checkbox
			setInputs({ ...inputs, [e.target.name]: e.target.checked });

		} else {			
			//console.log(e);
		}
	}

	var buttonClick = (e)=>{
		e.preventDefault();
		
		setShowDraw(true);		
	}

	useEffect(()=>{
		document.getElementById("input_A").value = porc_lotes_municipales;
	}, [lotes_adquiridos_municipio]);

	useEffect(()=>{
		document.getElementById("input_B").value = lotes_adquiridos_municipio;
	}, [porc_lotes_municipales]);


	const handleChangeDate = (item)=>{
		//setOriginal(1);
		cambioOriginal(1);
		const fecha = item.value;
		setInputs({ ...inputs, [item.name]: fecha });
	}

	const onChangeConsulta = e => {
		const {name, value} = e.target;
		if(value === "calcular_cantidad"){
			setInicial(false);
		} else if (value === "calcular_porcentaje"){
			setInicial(true);
		}
	}
	
	const [inicial, setInicial] = useState(true);

	return (
		<Fragment>

			<div className="container_row">
				<div id="datos_col1" className="container_div_column">{/*columna1*/}
				<div id="E_exp1">{/*  gENERALES  */}

				<div style= {{ height:'30px'}} >
					<h6 className="mt-4 text-left subtitulos">CARACTERÍSTICAS GENERALES</h6>
				</div>

					<div className="container_left">
						<div className="input-container" >
							<input
								title="Nombre proyecto"
								type="text"
								maxLength="100"
								id="nombre_proyecto"
								name="nombre_proyecto"
								//value={nombre_proyecto}
								onChange={e => onChange(e)}
								required
							/>
							<label alt="Nombre proyecto" for="nombre_proyecto">Nombre proyecto</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left">
						<div className="input-container">
							<input
								title="Expediente"
								type="text"
								maxLength="35"
								id="expediente"
								name="expediente"
								onChange={e => onChange(e)}
								required
							/>
							<label alt="Expediente" for="expediente" placeholder="Expediente">Expediente</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left">

						<div style={{width: "32.5%"}} className="input-container">
							<DatePicker
								disabled
								className="form-control"
								placeholder=""
								title="fecha_carga"
								name="fecha_carga"
								id="fecha_carga"
								dateFormat="dd/MM/yyyy"
								wrapperClassName="datePicker"
								selected={_fechaCarga}
								//onChange={ (date) => handleChangeDate({ name: "fecha_carga", value: date })}
								required
							/>
							<label className="label-fecha" alt="Fecha carga" for="fecha_carga" placeholder="Fecha carga">Fecha carga</label>
						</div>{/*cierra ---> input-container */}

						<div style={{width: "1%"}}/>

						<div style={{width: "33%"}} className="input-container">
							<DatePicker
								className="form-control"
								placeholder=""								
								title="fecha_caratulacion"
								name="fecha_caratulacion"
								id="fecha_caratulacion"
								dateFormat="dd/MM/yyyy"
								wrapperClassName="datePicker"
								selected={fecha_caratulacion}
								onChange={ (date) => handleChangeDate({ name: "fecha_caratulacion", value: date })}
								required
							/>
							<label className="label-fecha" alt="Fecha caratulación" for="fecha_caratulacion">Fecha de caratulación</label>
						</div>{/*cierra ---> input-container */}

						<div style={{width: "1%"}}/>

						<div style={{width: "32.5%"}} className="input-container">
							<DatePicker
								disabled
								className="form-control"
								placeholder=""
								title="fecha_actualizacion"
								name="fecha_actualizacion"
								id="fecha_actualizacion"
								dateFormat="dd/MM/yyyy"
								wrapperClassName="datePicker"
								selected={_fechaHoy} //PARA la primer carga no tiene fecha de actualizacion
								//onChange={ (date) => handleChangeDate({ name: "fecha_carga", value: date })}
								required
							/>
							<label className="label-fecha" alt="Última actualización" for="fecha_actualizacion">Última actualización</label>
						</div>{/*cierra ---> input-container */}

					</div>{/*cierra ---> container_left */}
			

					<div className="container_left">
						<div className="input-container">
							{/*<span className="span_selectEdit">{datos_05_responsable ? "Responsable": ""}</span>*/}
							<SelectInput
								name="datos_05_responsable"
								id="id_datos_05"
								defaultValue = {datos_05_responsable}
								onChange={e => onChange(e)}
								options={optionsResponsable}
								//className="form-control my-3"
							/>
							<label alt="Responsable" for="id_datos_05">Responsable</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<Row>
						<Col>
							<div className="container_left" style={{height:"50px"}}>
								<Form.Check type="checkbox">
									<Form.Check.Input name="tramite_prioritario" defaultChecked={tramite_prioritario} type="checkbox" onChange={e => onChange(e)}/>
									<Form.Check.Label>{`Trámite prioritario`}</Form.Check.Label>
								</Form.Check>
							</div>
						</Col>
						<Col>
							<div className="container_left" style={{height:"50px"}}>
								<Form.Check type="checkbox">
									<Form.Check.Input name="avanzados" defaultChecked={avanzados} type="checkbox" onChange={e => onChange(e)}/>
									<Form.Check.Label>{`Avanzado`}</Form.Check.Label>
								</Form.Check>
							</div>
						</Col>
					</Row>

					<a align="left" style={mensajeErrorPrograma===true?{ height: "auto", fontSize: "0.9em", color: "red" }:{display:'none'}}>(*) Requerido</a>
					<div className="container_left">
						<div className="input-container">
							{/*<span className="span_selectEdit">{programa ? "Programa": ""}</span>*/}
							<SelectInput
								name="programa"
								id="id_programa"
								defaultValue = {programa}
								onChange={e => onChange(e)}
								options={optionsPrograma}
								//className="form-control my-3"
							/>
							<label alt="Programa" for="id_programa">Programa</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<a align="left" style={mensajeErrorEstado===true?{ height: "auto", fontSize: "0.9em", color: "red" }:{display:'none'}}>(*) Requerido</a>
					<div className="container_left">
						<div className="input-container">
							{/* <span className="span_selectEdit">{estado ? "Estado": ""}</span> */}
							<SelectInput
								name="estado"
								id="id_estado"
								defaultValue={estado}
								onChange={e => onChange(e)}
								options={optionsEstado}
								className="form-control my-3"
							/>
							<label alt="Estado" for="id_estado">Estado</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<a align="left" style={mensajeErrorTipo===true?{ height: "auto", fontSize: "0.9em", color: "red" }:{display:'none'}}>(*) Requerido</a>
					<div className="container_left">
						<div className="input-container">
							{/* <span className="span_selectEdit">{tipo ? "Tipo": ""}</span> */}
							<SelectInput
								name="tipo"
								id="id_tipo"
								defaultValue={tipo}
								onChange={e => onChange(e)}
								options={optionsTipo}
								//className="form-control my-3"
							/>
							<label alt="Tipo" for="id_tipo">Tipo</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<a align="left" style={mensajeErrorPartido===true?{ height: "auto", fontSize: "0.9em", color: "red" }:{display:'none'}}>(*) Requerido</a>
					<div className="container_left">
						<div className="input-container">
							{/* <span className="span_selectEdit">{partido != 0 ? "Partido": ""}</span> */}
							<SelectInput
								name="partido"
								id="id_partido"
								defaultValue={partido}
								onChange={e => onChange(e)}
								options={optionsPartido}
								//className="form-control my-3"
							/>
							<label alt="Partido" for="id_partido">Partido</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left">
						<div className="input-container">
							<input
								title="Localidad"
								type="text"
								name="localidad"
								maxLength="100"
								id="localidad"
								onChange={e => onChange(e)}
								required
							/>
							<label alt="Localidad" for="localidad" placeholder="Localidad">Localidad</label>
							{/*<p style={{color:"rgb(131,131,131)"}} align="right">Caracteres restantes: {maxLocalidad}</p>*/}
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}


					<div className="container_left">
						<div className="input-number-45">
							<input title="Cantidad de lotes" type="number" name="cantidad_lotes" id="id_cant_lotes"  onChange={e => onChange(e)} /> {/*value={cantidad_lotes}*/}
							<label alt="Cantidad de lotes" for="id_cant_lotes">Cantidad de lotes</label>
						</div>{/*cierra ---> input */}

						<div style={{width: "4%"}}></div>

						<div className="input-number-45">
							<input
								title="Cantidad de Lotes Adjudicables"
								type="number"
								id="id_cant_lotes_adj"
								name="cantidad_lotes_adjudicables"
								onChange={e => onChange(e)} //USAR una sola función
							/>
							<label alt="Cantidad de lotes adjudicables" for="id_cant_lotes_adj">Cantidad de lotes adjudicables</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}


					<div className="container_left">
						<div className="input-number-45">
							<input
								title="Porcentaje Lotes Municipales"
								id="input_A"
								type="number"
								min="0"
								max="100"
								name="porc_lotes_municipales"
								step="1"
								//defaultValue={porc_lotes_municipales}
								onChange={e => onChange(e)}
							/>
							<label alt="Porcentaje lotes municipales" for="input_A">Porcentaje lotes municipales</label>
						</div>{/*cierra ---> input-number-45 */}

						<div style={{width: "4%"}}></div>

						<div className="input-number-45">
							<input
								title="Lotes Adquiridos por Municipio"
								id="input_B"
								type="number"
								name="lotes_adquiridos_municipio"
								step="0.01"
								//defaultValue={lotes_adquiridos_municipio}
								onChange={e => onChange(e)}
							/>
							<label alt="Lotes adquiridos por municipio" for="input_B">Lotes adquiridos por municipio</label>
						</div>{/*cierra ---> input-number-45 */}
					</div>{/*cierra ---> container_left */}


					<div className="container_left">
						<div className="input-container">
							{/*<span className="span_selectEdit">{sistema_agua ? "Sistema de agua": ""}</span>*/}
							<SelectInput
								name="sistema_agua"
								id="id_sistema_agua"
								//defaultValue={sistema_agua}
								onChange={e => onChange(e)}
								options={optionsSistemaAgua}
							/>
							<label alt="Sistema de agua" for="id_sistema_agua" >Sistema de agua</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left">
						<div className="input-container">
							{/*<span className="span_selectEdit">{eliminacion_excretas ? "Eliminación de excretas": ""}</span> */}
							<SelectInput
								name="eliminacion_excretas"
								//defaultValue={eliminacion_excretas}
								onChange={e => onChange(e)}
								options={optionsEliminacionExcret}
							/>
							<label alt="Eliminación de excretas" for="id_eliminacion_excretas" >Eliminación de excretas</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

				</div>{/* cierra id="E_exp1" */}
				</div>{/*cierra columna1*/}

				<div id="datos_col2" className="container_div_column">{/*columna2 col DOS*/}
					<div id="E_exp2">{/*  Catastrales  */}
						<div
							style= {{ height:'30px'}}
							onDoubleClick={() => setOpenC(!openCatastro)}
							aria-controls="example-collapse-textC"
							aria-expanded={openCatastro}
						>
							<h6 className="mt-4 text-left subtitulos">DATOS CATASTRALES Y DOMINIALES {openCatastro ? "(doble click para cerrar...)" : "(doble click para abrir...)"}</h6>
						</div>

						{/*<div style={nameLogin==="javi1"||nameLogin==="Pablo González"?{ height: "auto"}:{display:'none'}}></div>*/}
						<div>
							<div className="container_left" style={{ height: "auto"}} >
								<div style={{width: "60%"}} className="input-number" disabled>
									<input name="partido_nombre" value={nombre_partido}></input>
									<label for="id_nomcla_circ">Partido</label>
								</div>
								<div style={{width: "4%"}}></div>
							
								<div style={{width: "25%"}} className="input-number">
									<SelectInput
										name="datos_20_circ_nomencla"
										id="id_circ_T"
										defaultValue={datos_20_circ_nomencla}//circX
										onChange={e => onChange(e)}
										options={circS}										
										//className="form-control my-3"
									/>
									<label alt="Circ." for="id_circ_T">Circ.</label>
								</div>

								<div style={{width: "2%"}}></div>

								<div style={{width: "25%"}} className="input-number">
									<SelectInput
										name="datos_21_secc_nomencla"
										id="id_secc_T"
										defaultValue={datos_21_secc_nomencla}
										onChange={e => onChange(e)}
										options={seccS}
										className="form-control my-3"
									/>
									<label alt="Secc." for="id_secc_T">Secc.</label>
								</div>
								<div style={{width: "2%"}}></div>					

								<div style={{width: "10%"}}>									
									<Button form='my-form' type="button" disabled={modalPartido ==='0' ? true : false}  title="EN REVISION" className="btn btn-success btn-block button_verde" onClick={buttonClick}>+</Button> 
								</div>								
							
							</div>{/*cierra ---> container_left */}
							

							<div className="input-container" style={nameLogin==="javi1" ?{ height: "auto"}:{display:'none'}}>
								<textarea
									type="text"
									//maxLength="500"
									name="datos_22_geom"//sintesis_nomencla
									value={JSON.stringify(geom)}
									//ref={textGeom}
									onChange={e => onChange(e)}
									rows={5}
								/>
								<label>Datos catastarales - geojson--</label>								
							</div>{/*cierra ---> input-container */}
						</div>

						<div className="container_left" style={{ height: "auto"}}>
							<div className="input-container">
								<textarea
									type="text"
									maxLength="500"
									name="datos_18_sintesis_nomenclatura"//sintesis_nomencla
									ref={textAreaNomenclaSintesis}
									//defaultValue={datos_18_sintesis_nomenclatura}
									onChange={e => onChange(e)}
									rows={5}
									//className="form-control my-3"
								/>
								<label>Datos catastrales y dominiales - Síntesis</label>
								<p align="right" style={{height: "5px", fontSize: "0.9em"}}>Caracteres restantes: {maxNomenclaS}</p>
							</div>{/*cierra ---> input-container */}
						</div>{/*cierra ---> container_left */}


						<Collapse in={openCatastro}>
							<div id="example-collapse-textC">
								{/*<TablaEdit  formRows={formRows} setFormRows={handleChangeRow}/>*/}
								<TableNew  formRows={formRows} setFormRows={handleChangeRow}/>
							</div>
						</Collapse>
					</div>{/*cierra E_exp2 */}

					<div className="container_left">
						<div className="input-container">
							<SelectInput
								name="titular_tierra"
								id="id_titular_tierra"
								//defaultValue={titular_tierra}
								onChange={e => onChange(e)}
								options={optionsTitular}
							/>
							<label alt="Titular de la tierra" for="id_titular_tierra">Titular de la tierra</label>
						</div>{/*cierra container_left*/}
					</div>{/* cierra input-container */}

					<div className="container_left">
						<div className="input-container">
							<SelectInput
								name="plano_oficial"
								//defaultValue={plano_oficial}
								onChange={e => onChange(e)}
								options={optionsPlano}
							/>
							<label alt="Plano oficial" for="id_plano_oficial">Plano oficial</label>
						</div>{/*cierra container_left*/}
					</div>{/* cierra input-container */}

					<div className="container_left">
						<h6 className="mt-4 text-left subtitulos">LOCALIZACIÓN SEGÚN ORDENANZA VIGENTE</h6>
					</div>

					<div className="container_left">
						<div className="input-container">
							{/* <span className="span_selectEdit">{area ? "Area": ""}</span> */}
							<SelectInput
								style= {{height:'100%'}}
								name="area"
								id="id_area"
								//defaultValue={area}
								onChange={e => onChange(e)}
								options={optionsArea}
								className="form-control my-3"
							/>
							<label alt="Area" for="id_area">Área</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left">
						<div className="input-container">
							<input
								//placeholder="Zonificacion"
								id="zonificacion"
								type="text"
								maxLength="50"
								name="zonificacion"
								//value={zonificacion}
								onChange={e => onChange(e)}
								//className="form-control my-3"
								//onClick={e=> onClick(e)}
								required
							/>
							<label alt="Zonificación" for="zonificacion">Zonificación</label>
							{/*<p align="right">Caracteres restantes: {maxZonif}</p>*/}
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}
					{/* <p style={{color:"rgb(131,131,131)"}} align="right">Caracteres restantes: {maxZonif}</p> */}

					<div className="container_left">
						<div className="input-container">
							<input
								title="Nro. ordenanza vigente"
								type="text"
								maxLength="200"
								id="id_nro_ord_vigente"
								name="nro_ordenanza_vigente"
								//value={nro_ordenanza_vigente}
								onChange={e => onChange(e)}
							/>
							<label alt="Nro. ordenanza vigente" for="id_nro_ord_vigente">Nro. ordenanza vigente</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left">
						<div className="input-container">
							<input
								title="Decreto Promulgatorio"
								type="text"
								maxLength="200"
								id="id_decreto_prom"
								name="decreto_promulgatorio"
								//defaultValue={decreto_promulgatorio}
								onChange={e => onChange(e)}
							/>
							<label alt="Decreto promulgatorio" for="id_decreto_prom">Decreto promulgatorio</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					{/*<hr />*/}
					<div className="container_left" style={{height: "auto"}}>
						<div className="input-container">
							<textarea
								type="text"
								maxLength="500"
								name="observaciones_generales"
								ref={textAreaObsGrales}
								onChange={e => onChange(e)}
								rows={5}
							/>
							<label alt="Observaciones generales" for="observaciones_generales" placeholder="Observaciones generales">Observaciones generales</label>
							<p style={{height: "5px", fontSize: "0.9em"}} align="right">Caracteres restantes: {maxObservGen}</p>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

				</div>{/*cierra columna2*/}

			</div>{/*****cierra 1er DIV container-row*******/}

			<Modal
				id="id_modal_map"
				show={showDraw}
				//onHide={handleClose}
				backdrop="static"
				keyboard={false}
				className="id_modal_map"
				style={{top: "200"}}
				size="fullscreen"
			>
				<Modal.Header style={{background: "rgb(0,174,195)", color:"white"}} >
					<Modal.Title>Nuevo polígono {/*id_exp_pruebaE*/}</Modal.Title>
				</Modal.Header>
				<Modal.Body><Map_Expedientes partido={partido} circ={datos_20_circ_nomencla} secc={datos_21_secc_nomencla} partidoGEO={geomPartidoEnviar}/></Modal.Body>
				<Modal.Footer>
					<Button className="btn btn-success btn-block button_verde" onClick={guardarEditRegistro} disabled="">Salir</Button>
					{/*<Button className="btn btn-success btn-block button_verde" onClick={handleClose}> Cancelar </Button>*/}
				</Modal.Footer>
			</Modal>

		</Fragment>
	)
};

export default DatosExp;
