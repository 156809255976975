import React, {Fragment} from "react";
import { Table, Button } from "react-bootstrap";

import '../../table_report.css';
import {campos_tr_madre} from "../../datos/columns";

const TablaInf2_Madre = ({datosMadre, columnas}) => {

	const crearHeaderMadre = (columnas) => {
		if (columnas !== null) {
			return columnas.map((columna, index) => {
				return <th key={index} >{columna}</th>
			});
		}
	}	

	const changeTrueFalse = (registro) => {
		if (registro === "true") {
			return "Si"
		} else if (registro === "false") {
			return "No"
		} else {
			return ""
		}
	}

	const formatearFecha = (dato) => {

		let _fecha = null;
		_fecha = new Date(dato)
		_fecha.setMinutes(_fecha.getMinutes() + _fecha.getTimezoneOffset())

		return _fecha.toLocaleString().split(',')[0];
	}

	const formatearFecha_2 = (dato) => {

		//console.log(dato);
		//para revertir fecha (2024-06-12) a (12/06/2024)
		let _fecha = dato.split("-").reverse().join("/");

		//NO FUNCIONO
		//let _fecha = null
		//_fecha = new Date(dato)
		//_fecha.setMinutes(_fecha.getMinutes() + _fecha.getTimezoneOffset())

		return _fecha
	}

	const crearRowsDataMadre = (datosT) => {

		//En lugar de difinir una por una las columnas automatizar con una function
		if (datosT !== null) {
			//console.log(datosT);
			return datosT.map((registro, index) => {

				let regA = registro;
				let reg_cspU = registro.array_csp[0];

				let union = Object.assign(regA, reg_cspU)								

				let a = campos_tr_madre.map((campo, index) => {

					if (campo !== 'array_csp') {
						let c = union[campo];

						const regexFecha = /T00:00:00.000Z/;
						const busqueda = regexFecha.test(c);

						const regexFecha_2 = /\d{4}-\d{1,2}-\d{1,2}/;
						const busqueda_2 = regexFecha_2.test(c)

						if (c === "true" || c === "false") {
							let b = changeTrueFalse(c)
							return <td>{b}</td>

						} else if (busqueda === true) {
							let d = formatearFecha(c)
							return <td>{d}</td>
						} else if (busqueda_2 === true) {
							let f = formatearFecha_2(c)
							return <td>{f}</td>
						} else {
							return <td>{c}</td>
						}

					}
				})		

				return <tr id={index}> {a} </tr>;
			})
		}		
	}

	if (datosMadre === null) {
		return (
			<p>Carga datos madre...</p>
		)
	} else if (datosMadre !== null) {
		return (
			<Fragment>
				<h4></h4>
				<p className="subtitulos">Informe todos los campos - Table Madre</p>
				<div>
					<Table id="table-madre-to-xls" className="tableExp" stripped bordered hover responsive="md" >
						<thead>							
							<tr style={{ backgroundColor: "rgb(0,174,195)", color: "white" }}>{crearHeaderMadre(columnas)}</tr>
						</thead>
						<tbody>
							{crearRowsDataMadre(datosMadre)}
						</tbody>
					</Table>
				</div>
			</Fragment>
		)
	}
};

export default TablaInf2_Madre;
