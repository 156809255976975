import React, { Fragment } from "react";

import Select_6_cpu from "./select_6_cpu";

const Reporte_6_cpu = ({openReporte_6_cpu}) => {
  return (
    <Fragment>
      <div style={{ width:"100%" }}>
        {/*<h5 className="mt-5 text-center">Tramitación ADA</h5>*/}
        
        <Select_6_cpu openReporte_6_cpu= {openReporte_6_cpu}/>
      </div>
    </Fragment>
  );
}

export default Reporte_6_cpu;
