import React, { useState, Fragment } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";//PDFViewer
import DocuPDF from "./docuPDF";
import VistaWeb from "./vistaWeb";

import { renderToString } from "react-dom/server";

import { Nav,  Navbar, NavDropdown, Container, Form, Button } from "react-bootstrap";

//import LeafletMapProyectos from "./map_proyectos";

const Informe =  ({registro}) => {
  //console.log(registro);

  const [informe, setInforme] = React.useState("");
  //console.log(informe);
  const [verWeb, setVerWeb] = React.useState(true);
  const [verPDF, setVerPDF] = React.useState(false);

  /*
  function fetchinforme() {
    fetch("https://www.poemist.com/api/v1/randompoems")
      .then((response) => response.json())
      .then((data) => {
        setInforme(data[0]);
        //console.log(data[0]);
      });
  }

  React.useEffect(() => {
    fetchinforme();
  }, []);
  */

  /*
  function fetchinforme() {
    fetch(registro)
      .then((response) => response.json())
      .then((data) => {
        setInforme(data[0]);
        //console.log(data);
      });
  }

  React.useEffect(() => {
    fetchinforme();
  }, []);*/


  /*const texto3 = `Vienen las actuaciones por las cuales se solicita la afectación al Programa Lotes con
  Servicios de un proyecto de urbanización a desarrollarse en el inmueble de la localidad de ${loc}`;*/

  const informeTitle = {
    "title":"Estado de las actuaciones",
    "referente": "Referente al Expediente",
    //"texto3": texto3,
  }
  //console.log(informeTitle);

  const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";

  const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};
const colstyle = {
  width: "30%"
};
const tableStyle = {
  width: "100%"
};

  const Prints = () => (
  <div>
    <h3>Time & Materials Statement of Work (SOW)</h3>
    <h4>General Information</h4>
    <table id="tab_customers" class="table table-striped" style={tableStyle}>
      <colgroup>
        <col span="1" style={colstyle} />
        <col span="1" style={colstyle} />
      </colgroup>
      <thead>
        <tr class="warning">
          <th>SOW Creation Date</th>
          <th>SOW Start Date</th>
          <th>Project</th>
          <th>Last Updated</th>
          <th>SOW End Date</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Dec 13, 2017</td>
          <td>Jan 1, 2018</td>
          <td>NM Connect - NMETNMCM</td>
          <td>Dec 13, 2017</td>
          <td>Dec 31, 2018</td>
        </tr>
      </tbody>
    </table>
    <p>
      This is a Time and Materials Statement of Work between Northwestern Mutual
      Life Insurance Company and Infosys with all general terms and conditions
      as described in the current Master Agreement and its related documents
    </p>
  </div>
);
const alias = registro.nombre_proyecto
const partido = registro.partido_nombre;
//console.log(partido);
const nombreFile= alias+"-"+partido+" - Informe.pdf"
//console.log(nombreFile);

  const Menu = () => (
    <nav
      style={{
        display: "flex",
        borderBottom: "1px solid black",
        paddingBottom: "5px",
        justifyContent: "space-around",
      }}
    >
      {/*<Button
        variant="dark"
        onClick={() => {
          setVerWeb(!verWeb);
          setVerPDF(false);
        }}
      >
        {verWeb ? "Ocultar Web" : "Ver Web"}
      </Button>
      <Button
        variant="dark"
        onClick={() => {
          setVerPDF(!verPDF);
          setVerWeb(false);
        }}
      >
        {verPDF ? "Ocultar PDF" : "Ver PDF"}
      </Button> */}
      <PDFDownloadLink
        document={<DocuPDF informeTitle={informeTitle} registro={registro} />}
        fileName={nombreFile}
      >
        <Button style={{color: "white",}} variant="info">Descargar PDF</Button>
      </PDFDownloadLink>
    </nav>
  );

  return (
    <Fragment>
      <Menu /> {/*Se ARMA mas arriba +++++*/}

      {registro ? ( //informe --->original: NO llamar por fetch
        <>
          {/*verWeb ? <VistaWeb informe={informe} registro={registro} /> : null*/}
          <div className="container" style={{ width: "70%" }}>
            {verWeb ? <VistaWeb informeTitle={informeTitle} registro={registro} /> : null}
          </div>
          {/*verPDF ? (
            <PDFViewer style={{ width: "100%", height: "90vh" }}>
              {registro ? <DocuPDF informeTitle={informeTitle} registro={registro} /> : "Loading..."}
            </PDFViewer>
          ) : null*/}
        </>
      ) : null}
    </Fragment>
  );
}

export default Informe;
