import React, {Fragment, useState, useEffect} from 'react';
//import ReactDOM from 'react-dom';
//import { useFetch } from './hookFetch';
import SelectGobLocal from './selectGobLocal';

import dotenv from "dotenv";
//import { toast } from "react-toastify"; 

const baseURL = process.env.REACT_APP_API_URL;

const InputBusqueda = () => {

	const [search, setSearch] = useState(null);
	const [data, setDatos] = useState([]); // data se recorre luego con map para armar la lista
	const [loading, setLoading] = useState(true);

	//enviar al otro componente**********
	const [prov_id, set_provId]= useState("");
	const [prov_nombre, set_provNombre]= useState("");

	const [depto_id, set_deptoId]= useState("");
        const [depto_nombre, set_deptoNombre]= useState("");

	const [gobLocal_id, set_gobLocalId]= useState("");
	const [gobLocal_nombre, set_gobLocalNombre]= useState("");

	//**********************************

	const handleChange = e => {
		//console.log(e);
		//console.log(e.target);
		//console.log(e.target.value);
    		setSearch(e.target.value);
  	}; 
	//console.log(search);// **Cada vez que cambie el estado de 'search' se tiene que ejecutar el useEffect


	//const clickLi = (id, gl) => {
	const clickLi = (prov_id, prov_nombre, depto_id, depto_nombre, gob_local_id, gob_local_nombre) => {
		//console.log(e.target);
		//console.log(prov_id);
		//console.log(gob_local_id);

		set_provId(prov_id);
		set_provNombre(prov_nombre);

		set_deptoId(depto_id);
		set_deptoNombre(depto_nombre);

		set_gobLocalId(gob_local_id);
		set_gobLocalNombre(gob_local_nombre);

	}

	const getDatosF = async() => {

		//const res = await fetch(`http://localhost:5003/api/layers/layerP/?texto=${search}`,
		const res = await fetch(`${baseURL}api/layers/layerP/?texto=${search}`,
                )
                .then(res => res.json())
                .then(res => setDatos(res))
		.then(res => setLoading(false))
                .catch(err => console.log(err));
		
	}

	useEffect(()=>{
		//console.log({search});
		//console.log('effect');
		//const textoB = document.querySelectorAll('#id_div_input input').search;
		//console.log(textoB);

		getDatosF();
		
	}, [search]); //solo cuando cambia el valor de "search" hay que llamar a la function getDatosF
	

	return (
		<Fragment>
    		<div id="id_div_input">
      			<input
        			type="text"
        			placeholder="Search"
				className="form-control"
        			value={search}
        			onChange={handleChange}
				//onChange={(e) => setSearch(e.target.value)}
      			/>
			{
      			
        			<ul>
          				{loading ? (
        					"Loading..."
      					) : (
						data.map((item, index) => (
                                        		<li key={index} onClick={() => clickLi(item.prov_id, item.prov_nombre, item.depto_id, item.depto_nombre, item.gob_local_id, item.gob_local_nombre) }>
                                                	<span>{item.prov_id}, {item.nombre_geografico}</span>
                                        		</li>
                                		))
					/*data.map(({ id, title, url }) => (
            					<li key={`photo-${id}`}>
              					<img alt={title} src={url} />
            					</li>
          				))*/
					)}
        			</ul>
      			}
    		</div>
		<SelectGobLocal 
			prov_id={prov_id}
			prov_nombre={prov_nombre}
			depto_id={depto_id}
			depto_nombre= {depto_nombre}
			gobLocal_id= {gobLocal_id}
			gobLocal_nombre= {gobLocal_nombre}	
		/>
		</Fragment>
	);
};

export default InputBusqueda;
