//v10 - commit inicial
import React, { useEffect, useState, Fragment, useContext } from "react";
import { SessionContext } from '../context/sessionContext';
import { Row, Col} from "react-bootstrap";

//import { initiateSocketConnection, escucharPing } from "../context/socket";
//import { initiateSocketConnection, escucharUsuariosConectados, escucharPing, subscribeToChat } from "../context/socket"; // prueba sin escucharPing
import dotenv from "dotenv";

import { initiateSocketConnection, disconnectSocket } from "../context/socket";

//import {SocketContext, socket } from "../context/socket";

//import Header from "./header";
import Navigation from "./navigation";
//import TablaR from "./table";
import TablaExp from "./tablaExp";
import Footer from "./footer";
//import EditExpediente from "./editExpediente";
import SelectInput from "./selectInput";

import { Collapse } from "react-bootstrap";

//import {optionsResponsable, optionsPrioritario, optionsPrograma, optionsEstado, optionsTipo, optionsPartido} from "../datos/options";
import { partidoInicio, responsableInicio, prioritarioInicio, programaInicio, estadoInicio, tipoInicio, avanzadosInicio } from "../datos/options_inicio";

const baseURL = process.env.REACT_APP_API_URL;

const Inicio =  ({ setAuth, ...props }) => { //setAuth, role, setRole

	const { getProfile, roleA, setRoleA, cambioOriginal, sesionT } = useContext(SessionContext); //llamo el objeto roleA y la funcion que cambia el estado
	//console.log('ROLE --->', roleA);
	//console.log(sesionT);//no usar el return traer directamente el valor de sesionT

	//guardar desde este punto en repo remoto - 05-marzo-2022
  	//console.log(setAuth);

	const [edit, setEdit] = useState(false);
	const [viewInforme, setViewInforme] = useState(false);
  	const [search, setSearch] = useState('');

	//estado para filtrar datos (sin llmar nuevamente desde el servidor - add 31-07-2022)
	const [arrayBusqueda, setArrayBusqueda]= useState({
		"datos_05_responsable": "",
		"tramite_prioritario":"TODOS",
		"partido" : 0,
		"programa" : "",
		"tipo" : "",
		"estado" : "",
		"searchI" : "", //tiene que cambiar al tipear en el input de busqueda
		"avanzados": ""
	});
	//console.log(arrayBusqueda);
  	//console.log(arrayBusqueda.searchI);

  	const [filtro, setFiltro] = useState({ //PROBAR con 2 filtros
		prioritario:"TODOS",
		programa:"",
		partido:0,
		tipo:"",
		estado:"",
		datos_05_responsable: "",
    	avanzados: ""
	});
	//console.log(filtro);

  useEffect(() => {
    const pagina = 'acceso_pagina';
    getProfile(pagina); //lamar function via useContext

    if(sesionT === 'no_token'){
      //console.log('volver a loguear');
    } else if( sesionT === 'Token valido'){
      //console.log("98--> ","token valido" );
      cambioOriginal(0); //para que al volver a inicio, el valor de original sea 0
    }
    
  }, []);

	const [datosS, setDatos] = useState({features: []});
	//console.log(datosS);
	const [datosOriginal, setDatosOriginales]= useState({features: []});
	//console.log(datosOriginal);

	const [loading, setLoading] = useState(true);
  	const [idEdit, setId] = useState("");

	//const [conectados, setConectados] = useState([]);
	//const [usuarios, setUsuarios] = useState(); //**COMENTADO 06-10-2022

	//const [username, setUsername] = (""); //**COMENTADO 06-10-2022

	const handleNameChange = (newName)=>{
		setDatos(newName);
	};

	const handleChangeInforme = (newInforme)=>{
		//console.log("CHANGE INFORME");
		setViewInforme(newInforme);
	}

	//escucharUsuariosConectados();

	/*useEffect(() => {
		if (name !== ""){
			initiateSocketConnection(name); // pasar dato del usuario conectado
			//console.log(usuarios);
			//setConectados([...name, name]);
			//setUsuarios(name);
			return () => {
      				disconnectSocket();
    			}
		}
        }, [name]);*/

	/*
	useEffect(() => {
		escucharPing();
	}, []);

	useEffect(() => {
                subscribeToChat((error, data) =>{
			//console.log(data);
		})
        }, []);*/




  //comentado 31-07-2022
	/*const [responsableB, setResponsableB] = useState("");
	//console.log(responsableB);

	const handleOnChangeResponsable = (e) =>{
		if(e.value){
			//console.log("value RESPONSABLE");
		} else if(e.target.value){
			//console.log("Target RESPONSABLE");
			setResponsableB(e.target.value);
		} else if(e.target.value === ""){
			//console.log("sin valor REPONSABLE");
			setResponsableB("");
		}
	}*/

	const onChange = e => {
    setArrayBusqueda({ ...arrayBusqueda, [e.target.name]: e.target.value });
		setFiltro({ ...filtro, [e.target.name]: e.target.value });
	}

  //***TODO PARA SEARCH***********
	//****************************04-05***CADA VEZ que CAMBIA arrayBusqueda

  //console.log("HACER BUSQUEDA CON MULTIPLES VARIABLES"); //sobre datos Originales
	useEffect(()=>{
    filtrarDatosOriginales(datosOriginal);
	},[arrayBusqueda]);

  const filtrarDatosOriginales = (datosOriginal) =>{
    //console.log("LLAMANDO desde useEffect");
    if(datosOriginal.features.length > 0){

			const regexExp = new RegExp(arrayBusqueda.exp, 'i');

			var rxPartido = /\d{1}/ ;

			var xF = parseInt(arrayBusqueda.partido);
			//console.log(typeof(xF));
			//console.log(`${xF}`);
			//console.log(xF);
			var zF = `${xF}`;

			var regexPartido =  new RegExp(`^${arrayBusqueda.partido}{1,3}`); //   /^d{1,3}/ ;
			if(xF === 0 ){
				regexPartido = /./;
			} else if(xF > 0 && xF < 10){
				const arrayB1 = `${arrayBusqueda.partido}`;
				regexPartido = new RegExp(`^${arrayB1}{1,3}`);
			} else if(xF > 9 && xF < 99) {
				const arrayB2 = `${arrayBusqueda.partido}`;
				regexPartido =  new RegExp(`^${arrayB2}{1,3}`);
			} else {
				regexPartido = new RegExp(`^${arrayBusqueda.partido}{1,3}`);
			}
			//console.log(regexPartido);

      const regexResponsable = new RegExp(arrayBusqueda.datos_05_responsable, 'i');

      var regexPrioritario= new RegExp(arrayBusqueda.tramite_prioritario, 'i');
      if(arrayBusqueda.tramite_prioritario==="TODOS"){
        regexPrioritario = /./;
      } else {
        regexPrioritario= new RegExp(arrayBusqueda.tramite_prioritario, 'i');
      }
      //console.log(regexPrioritario);

			const regexEstado = new RegExp(arrayBusqueda.estado, 'i');
			const regexPrograma= new RegExp(arrayBusqueda.programa, 'i');
			const regexTipo= new RegExp(arrayBusqueda.tipo, 'i');

      const regexAvanzados = new RegExp(arrayBusqueda.avanzados, 'i');
      //console.log(arrayBusqueda.avanzados);
      //console.log(regexAvanzados);

      //agregar search
      const terminoBusqueda =arrayBusqueda.searchI;
      //console.log(terminoBusqueda.length);
      const regexTerminoBusqueda= new RegExp(terminoBusqueda, 'i');

      //return regexTerminoBusqueda.test(item.expediente) || regexTerminoBusqueda.test(item.nombre_proyecto)

			const filtradoP = datosOriginal.features[0].filter((item, index) =>{
				//VERIFICAR solo donde sean iguales para evitar que tome por ejemplo 1 para (todos los que empiezan con 1)1, 11, 120....
				if(parseInt(arrayBusqueda.partido) ===0){
					return regexResponsable.test(item.datos_05_responsable) && regexPrioritario.test(item.tramite_prioritario) && /./.test(item.partido) &&  regexPrograma.test(item.programa) && regexTipo.test(item.tipo) && regexEstado.test(item.estado) && regexAvanzados.test(item.avanzados)
				} else if(item.partido === parseInt(arrayBusqueda.partido)){
					return regexResponsable.test(item.datos_05_responsable) && regexPrioritario.test(item.tramite_prioritario) && regexPartido.test(item.partido) &&  regexPrograma.test(item.programa) && regexTipo.test(item.tipo) && regexEstado.test(item.estado) && regexAvanzados.test(item.avanzados)
				}
			})
			//console.log("----->filtradoP", filtradoP);

      if(terminoBusqueda.length>0){
        //console.log("SI TIENE BUSQUEDA text");
        const busqueda = filtradoP.filter((item, index)=>{
          return regexTerminoBusqueda.test(item.expediente) || regexTerminoBusqueda.test(item.nombre_proyecto)
        });
        //console.log(busqueda);
        setDatos({features: [busqueda]});

      } else {
        setDatos({features: [filtradoP]});
      }
		}
  }
  //CIERRA filtrar segun cambios en arrayBusqueda cambio 31-07-22

  const handleSearch = (event) =>{
    setSearch(event.target.value);
    //filtrar(event.target.value); //---> NO PONER funciones dentro de la funcion que se ejecuta con el onChange
  }

  useEffect(()=>{
    // PRUEBA 2
    buscarTermino(datosOriginal); //llamar funcion desde dentro del useEffect
  }, [search]);

  const buscarTermino=(datosOriginal)=>{
    //console.log(terminoBusqueda)
    const terminoBusqueda =search;

    if(datosOriginal.features.length > 0 ){//&& terminoBusqueda.length > 2
      const regexTerminoBusqueda= new RegExp(terminoBusqueda, 'i');

      const busqueda = datosOriginal.features[0].filter((item, index)=>{

        if(terminoBusqueda!==null){
          return regexTerminoBusqueda.test(item.expediente) || regexTerminoBusqueda.test(item.nombre_proyecto)
        }
      });
      //console.log(busqueda);
      setDatos({features: [busqueda]}); //despues de filtrar cambiar el estado
    }
  }//--cierra filtrar

	const {prioritario, programa, partido, tipo, estado, datos_05_responsable, avanzados } = filtro;
	//console.log("valor filtross");

	useEffect(() => {
    //console.log("CAMBIO EL filtro LLAMAR NUEVAMENTE A getDatos");
  	getDatos();
  }, []); //CAMBIO 31-07-22: LLamar solo al inicio
  //},[filtro]); // SI CAMBIO EL filtro LLAMAR NUEVAMENTE A getDatos

	useEffect(() => {
    //alert("CAMBIO edit---> cargar datos")
    getDatos();
  },[edit]);

	useEffect(() => {
		if(idEdit !== ""){
		} else {
			setId(false);
		}
		//console.log("cuando carga la pagina");
  }, [idEdit]);

	const getDatos = async () => {
		//console.log('------------->>>> CONSULTAR datos url');
		//console.log(prioritario);
		//const url = `http://localhost:5003/api/expediente/todos/?prioritario=${prioritario}&programa=${programa}&partido=${partido}&tipo=${tipo}&estado=${estado}`;

		const url = `/api/api_serv/expediente/todos/?prioritario=${prioritario}&programa=${programa}&partido=${partido}&tipo=${tipo}&estado=${estado}&datos_05_responsable=${datos_05_responsable}&avanzados=${avanzados}`;

		/*if(value_filtroPrograma === ""){
		//console.log("esta url")
		const url = `http://localhost:5003/api/expediente/todos/?${nombre_filtro}=${filtroPrior}`;
		//const url = "http://localhost:5003/api/expediente/todos/?prioritario=true";
		} else {
		//console.log("o Esta url")
		const url = `http://localhost:5003/api/expediente/todos/?${nombre_filtro}=${filtroPrior}&${filtro_Programa}=${value_filtroPrograma}`;
		}*/

		const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token }

		const res = await fetch(url, {headers} )
		//const res = await fetch('http://localhost:5003/api/expediente/todos')
		//const res = await fetch(url)
		.then(res => res.json())
		.then(res => { setDatos( {features:[res]} )
      		setDatosOriginales({features:[res]})
			//setDatosOriginales(res) //NO USAR POR AHORA
		}) //*** asigna la respuesta a datosS
		.catch(err => console.log(err))
		.finally(() => setLoading(false));

	}

  /*const logout = async e => {
          e.preventDefault();
          try {
                  localStorage.removeItem("token");
                  setAuth(false);
                  toast.success("Logout successfully");
          } catch (err) {
                  console.error(err.message);
          }
  };*/

	useEffect(() => {
		//console.log(viewInforme);
		if (edit === true || viewInforme=== true){
			//setOpen(false)
			setOpen(!open)
			//console.log("deberia colapsar");

		} else if (edit === false){
			setOpen(true)
			//console.log("deberia abrir filtro");
		}
	}, [edit, viewInforme]);

	/*
	useEffect(() => {
		if (viewInforme=== true) {

			setOpen(!open)
			//console.log("deberia colapsar");

		} else if (edit === false){
			setOpen(true)
			//console.log("deberia abrir filtro");
		}
	}, [viewInforme]);*/


	const [open, setOpen] = useState(true);
	const [activo, setActivo] = useState("inicio"); //15-06-2022

	const estadoEdit = (estado)=> {
  	setEdit(estado)
  }

	return (
		<Fragment>
			{/* <Header /> */}
			{/*<div>  10-05***QUITAR <div className="card">*/}

			{/*<Navigation className="card-header" setAuth={setAuth} role={role} setRole={setRole} /> */}
      {/* manejarlo con useContext---> name={name} role={role} setRole={setRole} */}
			<Navigation setAuth={setAuth}  activo={activo}/>
			{/* </div> */}
			{/*inicia div  - Este div se colapsa cuando esté editando*/}
			{/*<div > "quité la className container. Desde acá seria el main de la App " */}

			<div className="main">  {/* abre el Main */}

			{/* <div id="username">{username}</div> */}

			<Collapse in={open}>
				<div id="column_A" className="container_principal">

					<div id="row_B1" className="flexbox-row">

						<div className="container_inicio">
              <div style={{width:"80%", alignItems:"left"}}>
                <p style={{align:"left"}} className="subtitulos">Responsable</p>
              </div>
              {/*<label style={{align:"left"}} htmlFor="id_responsable" className="subtitulos" >ResponsablE</label>*/}
							<SelectInput
                id="id_responsable"
								name="datos_05_responsable"
								options={responsableInicio}
								onChange={e => onChange(e)}
							/>
						</div>

						<div className="container_inicio">
              <div style={{width:"80%", alignItems:"left" }}>
                <p className="subtitulos">Trámite prioritario</p>
              </div>
							<SelectInput
								name="tramite_prioritario"
								options={prioritarioInicio}
								onChange={e => onChange(e)}
							/>
						</div>

						<div className="container_inicio">
              <div style={{width:"80%", alignItems:"left"}}>
                <p className="subtitulos">Partido</p>
              </div>
			        <SelectInput
			          name="partido"
			          options={partidoInicio}
			          onChange={e => onChange(e)}
			        />
		        </div>
					</div>

					<div id="row_B2" className="flexbox-row">
						<div className="container_inicio">
              <div style={{width:"80%", alignItems:"left"}}>
			      	    <p className="subtitulos">Programa</p>
              </div>
			      	<SelectInput
			        	name="programa"
			        	options={programaInicio}
			        	onChange={e => onChange(e)}
			      	/>
						</div>

						<div className="container_inicio">
              <div style={{width:"80%", alignItems:"left"}}>
			           <p className="subtitulos">Tipo operatoria</p>
              </div>
			        <SelectInput
			          name="tipo"
			          options={tipoInicio}
			          onChange={e => onChange(e)}
			          //className = "col-xs-6"
			        />
		        </div>

						<div className="container_inicio">
              <div style={{width:"80%", alignItems:"left"}}>
			           <p className="subtitulos">Estado</p>
              </div>
			        <SelectInput
			          name="estado"
			          options={estadoInicio}
			          onChange={e => onChange(e)}
			          //className = "col-xs-6"
			        />
		        </div>
					</div>{/*cierra row_B2*/}

          <div id="row_A1_search" className="flexbox-row">

            <div className="container_inicio">
              <div style={{width:"80%", alignItems:"left"}}>
                 <p className="subtitulos">Avanzado</p>
              </div>
              <SelectInput
			          name="avanzados"
			          options={avanzadosInicio}
			          onChange={e => onChange(e)}
			        />

            </div>

            <div className="container_inicio">
              <div style={{width:"80%", alignItems:"left"}}>
                  <p className="subtitulos">Búsqueda</p>
              </div>
              <div style={{width:"80%"}} className="input-container" >
                <input type="text" name="searchI" onChange={e => onChange(e)} /> {/*handleSearch*/}
              </div>
            </div>

            <div className="container_inicio"></div>
          </div>

				</div>
			</Collapse>

			{/* ***SI NO ESTÁ EDITANDO MOSTRAR LA TABLA (tabla y formulario vienen desde el mismo comoponente*** */}
      {/* role={role} */}
			<div className="container_principal">
				<TablaExp
					id={idEdit}
					edit={edit} setEdit={estadoEdit}
					loading={loading}
					datosS={datosS} setDatos={handleNameChange}
					viewInforme={viewInforme} setViewInforme={handleChangeInforme}
				/>
			</div>


			</div> {/* cierra el Main */}

		{/*</div>  cierra el div con classname card */}
		<Footer/>

		</Fragment>
    	);
}

export default Inicio;

//**CAMBIOS
//24-07-22: class container ----> container_inicio

//***NO usar Footer por ahora
//<Footer/>
//<TablaExp  id={idEdit} edit={edit} setEdit={setEdit} loading={loading} datosS={datosS} />
//


//****Quitar Button Logout de inicio, agregado en Navbar
/*
                                <div className="flexbox-container">
                                        <div>
                                                <p> Inicio - logueado como usuario: {name}</p>
                                        </div>
                                        <div></div><div></div><div></div>
                                        <div>
                                                <button onClick={e => logout(e)} className="btn btn-primary">
                                                Logout
                                                </button>
                                        </div>
                                </div>

*/

/*USUARIO agregado a Navbar
				<div className="flexbox-container">
                                        <div>
                                                <p> Inicio - logueado como usuario: {name}</p>
                                        </div>
                                        <div></div><div></div><div></div>
                                </div>
*/
