import React, {Fragment, useState, useEffect, useContext } from 'react';
import { SessionContext } from '../../context/sessionContext';
import { Table, Row, Col, Button} from "react-bootstrap";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Modal from 'react-bootstrap/Modal';

import Select from 'react-select';
import SelectInput from "../selectInput";

import {optionsRP2_Todos, optionsPrograma, optionsEstado, optionsTipo, optionsTitular, optionsTramitacionResolucion } from "../../datos/options";

import { toast } from "react-toastify";

const Select_4_Resolucion = ({ openReporte_4_res }) => {

	const { roleA, sesionT } = useContext(SessionContext);

	const url = `/api/api_serv/reportes/reporte3_Resolucion/`;//${baseURL}

	useEffect(() => {
		if (openReporte_4_res === true && datosS === null) {
			console.log("RES es true?", openReporte_4_res) //mover todo acá???	
			getDatos(url);	//console.log("_____________________LLAMANDO DATOS RESOLUCION");		
	
		} else if (openReporte_4_res === false) {
			console.log("NO SE DIO CLICK - es false??", openReporte_4_res)
		} // se puede quitar la parte del false
		
	},[openReporte_4_res]);

  	const name = roleA.username;

	const [datosS, setDatos] = useState(null);// ESTADO para recibir datos de la consulta
	//console.log("datosS---->",datosS);

	//***30-08-2022: abre estados para editar desde reporte2

	const onChange = (e) => {
		setEditRegistro({...editRegistro, [e.target.name] : e.target.value});
		setOriginal(1);
	}

	const handleFecha1 = (item) => {

		const fecha = item.value;

		//console.log("cambio DATE Fecha Reporte ADA");
		//setFecha_T1(fecha);
		setEditRegistro({ ...editRegistro, [item.name]: fecha });
		setOriginal(1);
	}

	const [original, setOriginal] = useState(0); //estado original en 0; si cambia a uno habilitar el boton de guardar

	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
		setOriginal(0);
	};

	const [editRegistro, setEditRegistro] = useState({
		id_exp_pruebaE: "",
		t_132_tramitacion_resol_E: "",
		fecha_envio_resol_t_E: "",
		fecha_emision_resol_t_E: "",
		numero_especial_resol_t_E: "",
		t_136_observaciones_resol_E: "",
		estado_E: ""
	});
	//console.log(editRegistro);

	const {
		id_exp_pruebaE,
		t_132_tramitacion_resol_E,
	 	fecha_envio_resol_t_E, fecha_emision_resol_t_E,
		numero_especial_resol_t_E,
	 	t_136_observaciones_resol_E,
		estado_E
	} = editRegistro;
	//console.log("estado_E ------>",estado_E);

	const guardarEditRegistro = async e => {
		e.preventDefault();

		try {
			const body = {
				editRegistro,
				name
			}

			const response = await fetch(
				`/api/authentication/reporteResolucion_edit_expediente`, //${baseURL}
				{
				method: "POST",
				headers: {
					"Content-type": "application/json"
				},
				body: JSON.stringify(body)
				}
			);

			const parseRes = await response.json();

			if (parseRes.expediente){
				toast.success(`Expediente ${parseRes.expediente} actualizado!!`);
				setShow(false);
				getDatos(url);//30-08-2022: si hubo cambio, volver a llamar
			} else {
				//console.log("error");
				toast.error(parseRes.message);
			}

		} catch(err){
			console.error(err.message);
		}
	}


	//---cierra 30-08-2022----estados para editar desde reporte

	const [filtro, setFiltro] = useState({ //PROBAR con 2 filtros
		t_132_tramitacion_resol:"",
		expediente:"",
		partido:0,
		nombre_proyecto:"",
		//expediente_prefac_h_t:"",
		fecha_envio_resol_t:"",
		//fecha_emision_prefac_h_t:"",
		t_136_observaciones_resol:""
	});
	const {t_132_tramitacion_resol, expediente, partido, nombre_proyecto, fecha_envio_resol_t, t_136_observaciones_resol} = filtro;
	//console.log(filtro);
	//console.log(estado);

	const [datosOriginal, setDatosOriginales] = useState(null);
	//console.log(datosOriginal);

	const [datosFiltrado, setDatosFiltrado] = useState(null);

	const [search, setSearch] = useState(""); //posible borrar
	const [searchPartido, setSearchPartido] = useState(0);
	//const [searchAlias, setSearchAlias] = useState("");
	const [searchEstado, setSearchEstado] = useState("");
	//console.log(searchEstado);
	const [searchPrograma, setSearchPrograma] = useState("");
	const [searchTipo, setSearchTipo] = useState("");
	//const [searchTitular, setSearchTitular] = useState("");

	//const [campoBusqueda, setCampoBusqueda] = useState([]);
	//console.log(campoBusqueda);

	const [arrayBusqueda, setArrayBusqueda]= useState({
		"expB" : "",
		"partidoB" : 0,
		"estadoB" : "",
		"aliasB" : "",
		"programaB" : "",
		"tipoB" : "",
		//"titularB" : "",
	});
	//console.log(arrayBusqueda);

	//**SELECCIONAR campos tabla madre***

	const [municipioMadre, setMunicipioMadre] = useState("");

	const [datosMadre, setDatosMadre] = useState(null);

	//activo: true son los que mostraria inicialmente.
	const [camposInicial, setCamposInicial] = useState([
		{label:'Partido', activo:'true', campo:'partido_nombre'},
		{label:'Expediente', activo:'true', campo:'expediente'},
		{label:'Estado', activo:'false', campo:'estado'},
		{label:'Alias', activo:'true', campo:'nombre_proyecto'},
		{label:'Programa', activo:'false', campo:'programa'},
		{label:'Tipo', activo:'false', campo:'tipo'},
		{label:'Titular', activo:'false', campo:'titular_tierra'},
		{label:'Cantidad lotes', activo:'false', campo:'cantidad_lotes'},
		{label:'Lotes captados municipio', activo:'false', campo:'lotes_adquiridos_municipio'},
		{label:'Nomenclatura catastral', activo:'false', campo:'datos_18_sintesis_nomenclatura'},
	]);

	//const [columnasMadre, setColumnasMadre] = useState(["Partido", "Expediente", "Estado", "Alias", "Programa", "Tipo", "Titular", "Cantidad lotes", "Lotes captados municipio", "Nomenclatura catastral"]);
	//console.log(columnasMadre);

	//---campos tabla madre------
	//**04-05 --> Funciona pero NO USAR
	/*
	useEffect(()=>{
		//console.log("ARRAY de CAMPOS BUSQUEDA");
		//console.log(campoBusqueda);
	}, [campoBusqueda])
	*/


	//***TODO PARA SEARCH***********
	//****************************04-05***CADA VEZ que CAMBIA arrayBusqueda

	useEffect(()=>{

		if(datosS !== null){

			const regexExp = new RegExp(arrayBusqueda.expB, 'i');
			//console.log(regexExp);
			//const REGEX = /a|b/g;
			//const REGEX = /[0-59]/g;
			//console.log(REGEX);

			var rxPartido = /\d{1}/ ;
			//console.log(arrayBusqueda.partidoB);
			//console.log(typeof(arrayBusqueda.partidoB));

			var xF = parseInt(arrayBusqueda.partidoB);
			//console.log(typeof(xF));
			//console.log(`${xF}`);
			//console.log(xF);
			var zF = `${xF}`;

			//const regexPartido = new RegExp(arrayBusqueda.partidoB{2});
			const uno = /^d/;
			//console.log(uno);
			//const regexPartido = new RegExp(/^`${arrayBusqueda.partidoB}/);
			//const regexPartido = new RegExp(arrayBusqueda.partidoB); //  /^d/
			var regexPartido =  new RegExp(`^${arrayBusqueda.partidoB}{1,3}`); //   /^d{1,3}/ ;
			//let uno = new RegExp(`^${arrayBusqueda}{${nro}}`);


			if(xF === 0 ){
				regexPartido = /./;
			} else if(xF > 0 && xF < 10){
				//console.log("PARA Alsina");
				const arrayB1 = `${arrayBusqueda.partidoB}`;
				regexPartido = new RegExp(`^${arrayB1}{1,3}`);
			} else if(xF > 9 && xF < 99) {
				//console.log("PARA Benito Juarez");
				const arrayB2 = `${arrayBusqueda.partidoB}`;
				regexPartido =  new RegExp(`^${arrayB2}{1,3}`);
			} else {
				regexPartido = new RegExp(`^${arrayBusqueda.partidoB}{1,3}`);
			}

			//console.log(regexPartido);


			//const rxPartido = /\arrayBusqueda.partidoB{2}/;
			//const regexPartido =  /[0-9]{1,3}/ ; //     /\d{1,3};
			//const regexPartido = /\d{1,3}/g;
			//const partidoBusqueda = arrayBusqueda.partidoB

			//const regexPartido = /partidoBusqueda/;

			const regexEstado = new RegExp(arrayBusqueda.estadoB, 'i');
			//console.log(regexEstado);
			const regexAlias= new RegExp(arrayBusqueda.aliasB, 'i');
			//console.log(regexAlias);
			const regexPrograma= new RegExp(arrayBusqueda.programaB, 'i');
			//console.log(regexPrograma);
			const regexTipo= new RegExp(arrayBusqueda.tipoB, 'i');
			//console.log(regexTipo);
			const regexTitular= new RegExp(arrayBusqueda.titularB, 'i');
			//console.log(regexTitular);


			const filtradoP = datosOriginal.filter((item, index) =>{
				//console.log("____PARTIDO");
				//console.log(item.partido);

				//VERIFICAR solo donde sean iguales para evitar que tome por ejemplo 1 para (todos los que empiezan con 1)1, 11, 120....
				if(parseInt(arrayBusqueda.partidoB) ===0){
					//console.log("PARTIDO es VALUE CERO _____  DENTRO del primer IF");
					//console.log(regexPartido.test(item.partido));
					//console.log(/./.test(item.partido));

					return regexExp.test(item.expediente) && /./.test(item.partido) && regexEstado.test(item.estado) && regexAlias.test(item.alias)  && regexPrograma.test(item.programa) && regexTipo.test(item.tipo) && regexTitular.test(item.titular_tierra)

				} else if(item.partido === parseInt(arrayBusqueda.partidoB)){

					//console.log("partido y array son iguales");
					//console.log(item.partido);
					//console.log(parseInt(arrayBusqueda.partidoB));
					//console.log(regexPartido.test(item.partido));

					//console.log(/arrayBusqueda.partidoB{2}/.test(item.partido));
					//console.log(rxPartido.test(item.partido));
					return regexExp.test(item.expediente) && regexPartido.test(item.partido) && regexEstado.test(item.estado) && regexAlias.test(item.alias)  && regexPrograma.test(item.programa) && regexTipo.test(item.tipo) && regexTitular.test(item.titular_tierra)
					//return regexExp.test(item.expediente) && /arrayBusqueda.partidoB{2}/.test(item.partido) && regexEstado.test(item.estado) && regexAlias.test(item.alias)  && regexPrograma.test(item.programa) && regexTipo.test(item.tipo) && regexTitular.test(item.titular_tierra)

				}
			})
			//console.log(filtradoP);
			setDatos(filtradoP);
		}

	},[arrayBusqueda, datosOriginal]);


	//***Partido***
	const handleOnChangePartido = (e) => {

		if(e.value){
			//console.log(e.value);

			if(e.value !== null){
				setSearchPartido(e.value);
				setArrayBusqueda({...arrayBusqueda,  "partidoB":e.value} )
				setFiltro({...filtro,  "partido":e.value} )
			}
		} else if( e.value === ""){
			setSearchPartido(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			setArrayBusqueda({...arrayBusqueda,  "partidoB":e.value} )
			setFiltro({...filtro,  "partido":e.value} )

		} else if (e.target.value){
			//console.log(e.target);
			if(e.target.value !== null){
				//console.log("VALUE PARTIDO");
				//console.log(e.target.value);
				if(e.target.value === 0){
					setSearchPartido(e.target.value);
					setArrayBusqueda({...arrayBusqueda,  "partidoB":0} )
					setFiltro({...filtro,  "partido":0} )
				} else {
					setSearchPartido(e.target.value);
					setArrayBusqueda({...arrayBusqueda,  "partidoB":e.target.value} )
					setFiltro({...filtro,  "partido":e.target.value} )
				}

			}
		} else {
			setSearchPartido(/./);
			setArrayBusqueda({...arrayBusqueda,  "partidoB":0} )
			setFiltro({...filtro,  "partido":0} )
		}
	}
	//---Partido---

	//***Programa*** //ver diferencia si usa <select> o <SelectInput>
	const handleOnChangePrograma = (e) => {

		if(e.value){

			if(e.value !== null){
				setSearchEstado(e.value);
				setArrayBusqueda({...arrayBusqueda,  "programaB":e.value} )
				setFiltro({...filtro,  "programa":e.value} )
			}
		} else if( e.value === ""){
			setSearchEstado(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			setArrayBusqueda({...arrayBusqueda,  "programaB":e.value} )
			setFiltro({...filtro,  "programa":e.value} )

		} else if (e.target.value){
			if(e.target.value !== null){

				if(e.target.value === 0){
					setSearchPrograma(e.target.value);
					setArrayBusqueda({...arrayBusqueda,  "programaB":""} )
					setFiltro({...filtro,  "programa":""} )
				} else {
					setSearchPrograma(e.target.value);
					setArrayBusqueda({...arrayBusqueda,  "programaB":e.target.value} )
					setFiltro({...filtro,  "programa":e.target.value} )
				}
			}
		} else {
			setSearchEstado(/./);
			setArrayBusqueda({...arrayBusqueda,  "programaB":""} )
			setFiltro({...filtro,  "programa":""} )
		}
	} //---cierra handleOnChangePrograma -----

	/*
	useEffect(()=>{ //***** NO EJECUTAR
		if(datosS !== null){
			const regex = new RegExp(searchPrograma, 'i');

			const filterDatos = datosOriginal.filter((item)=>{
				return regex.test(item.programa);
			});
			setDatos(filterDatos);
		}
	}, [searchPrograma]);*/
	//---Programa---

	//***Tipo***
	const handleOnChangeTipo = (e) => {

		if(e.value){
			if(e.value !== null){
				setSearchTipo(e.value);
				setArrayBusqueda({...arrayBusqueda,  "tipoB":e.value} )
				setFiltro({...filtro,  "tipo":e.value} )
			}
		} else if( e.value === ""){
			setSearchTipo(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			setArrayBusqueda({...arrayBusqueda,  "tipoB":e.value} )
			setFiltro({...filtro,  "tipo":e.value} )

		} else if (e.target.value){
			if(e.target.value !== null){

				if(e.target.value === 0){
					setSearchTipo(e.target.value);
					setArrayBusqueda({...arrayBusqueda,  "tipoB":""} )
					setFiltro({...filtro,  "tipo":""} )
				} else {
					setSearchTipo(e.target.value);
					setArrayBusqueda({...arrayBusqueda,  "tipoB":e.target.value} )
					setFiltro({...filtro,  "tipo":e.target.value} );
				}

			}
		} else {
			setSearchTipo(/./);
			setArrayBusqueda({...arrayBusqueda,  "tipoB":""} )
			setFiltro({...filtro,  "tipo":""} )
		}
	}

	/*
	useEffect(()=>{ //**** NO EJECUTAR
		if(datosS !== null){
			const regex = new RegExp(searchTipo, 'i');

			const filterDatos = datosOriginal.filter((item)=>{
				return regex.test(item.tipo);
			});
			setDatos(filterDatos);
		}
	}, [searchTipo]);*/
	//---Tipo---

	//***Titular*** //NO USAR 04-06-2022
	/*
	const handleOnChangeTitular = (e) => {
		if(e.value){
			if(e.value !== null){
				setSearchTitular(e.value);
				setArrayBusqueda({...arrayBusqueda,  "titularB":e.value} )
			}
		} else if( e.value === ""){
			setSearchTitular(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			setArrayBusqueda({...arrayBusqueda,  "titularB":e.value} )

		} else if (e.target.value){
			if(e.target.value !== null){
				setSearchTitular(e.target.value);
				setArrayBusqueda({...arrayBusqueda,  "titularB":e.target.value} )
			}
		} else {
			setSearchTitular(/./);
			setArrayBusqueda({...arrayBusqueda,  "titularB":/./} )
		}
	}*/
	//---Titular---

	//**Hacer QUERY, mostrar tabla sintesis***
	/*
	const onchangeSelectMunicipio = (item) => {

		const filterMunicipio = item.value;
		setMunicipioMadre(filterMunicipio);

		var url = item.url;
		const urlM = `${baseURL}api/expediente/todos/?prioritario=${prioritario}&programa=${programa}&partido=${partido}&tipo=${tipo}&estado=${estado}&datos_05_responsable=${datos_05_responsable}`;
		//const urlM = `${baseURL}api/reportes/reporte2_municipios/?filterMunicipio=${filterMunicipio}`;
		getDatos(urlM);
	};*/

	//**consultar los datos para el reporte2
	const getDatos = async (urlM) => {

		const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token }

		const res = await fetch(urlM, { headers })
		.then(res => res.json())
		.then(res => {
			setDatos(res)
			setDatosOriginales(res)
		})
		//.then(res => setDatosOriginales(res))
		.catch(err => console.log(err));
	}

	const columnas = ["Expediente","Partido","Alias", "Fecha envío", "Observaciones"];

	const getHeader = (columnas)=>{

			return columnas.map((columna, index) => {
				if(columna === "Tramitación ADA"){
					return <th>
						{columna}
					</th>
				} else if (columna === "Expediente"){
					//return <th key={index} > {columna}<input id="search" type="text" name="search" onChange={handleSearch} value={search || ''} /> </th>
					return <th> {columna} </th>
				} else if (columna === "Partido") {
					return <th>
						{columna}
					</th>
				
				} else if (columna === "Alias") {
					return <th style={{ width:"100px"}}> {columna} </th>
				} else if (columna === "Programa") {
					return <th>
						{columna}
						<SelectInput
							name="programa"
							options={optionsPrograma}
							onChange={e => handleOnChangePrograma(e)}
						/>
					</th>
				} else if (columna === "Tipo") {
					return <th>
						{columna}
						<SelectInput
							name="tipo"
							options={optionsTipo}
							onChange={e => handleOnChangeTipo(e)}
						/>
					</th>
				} else {
					return <th>{columna}</th>
				}
			})
	}

	const getRowsData = (datosS)=> {
		//console.log("EJECUTAR AL INICIO -- REPORTE 3");
		if(datosS !== null){

			return datosS.map((registro, index )=> {
				
				var soloFechaEnvio = '';
				var dateEdicionEnvio = '';

				if(registro.fecha_envio_resol_t !== null){

					let date = registro.fecha_envio_resol_t;
					let newFecha = new Date(date); //**se quita .toLocaleString()
					newFecha.setMinutes(newFecha.getMinutes() + newFecha.getTimezoneOffset())

					let fecha = newFecha.toLocaleString().split(',')[0]; //** se agrega .toLocaleString()

					//****PARA la tabla;
					soloFechaEnvio= fecha;
					//***PARA el formulario de edicion
					dateEdicionEnvio = new Date(date);
					dateEdicionEnvio.setMinutes(dateEdicionEnvio.getMinutes() + dateEdicionEnvio.getTimezoneOffset())

				} else {
					var soloFechaEnvio = "";
					var dateEdicionEnvio = '';
				}

				var soloFechaEmision = '';
				var dateEdicionEmision = '';

				if(registro.fecha_emision_resol_t !== null){

					let date = registro.fecha_emision_resol_t;
					let newFecha = new Date(date); //se quita .toLocaleString()
					newFecha.setMinutes(newFecha.getMinutes() + newFecha.getTimezoneOffset())

					let fecha = newFecha.toLocaleString().split(',')[0];

					//****PARA la tabla;
					soloFechaEmision = fecha;
					//***PARA el formulario de edicion
					dateEdicionEmision =  new Date(date);
					dateEdicionEmision.setMinutes(dateEdicionEmision.getMinutes() + dateEdicionEmision.getTimezoneOffset())

				} else {
					var soloFechaEmision = '';
					var dateEdicionEmision = '';
				}

				var tramitacion_resol = registro.t_132_tramitacion_resol;
				var expediente = registro.expediente;
				var nombre_proyecto = registro.alias;//nombre_proyecto
				var fecha_envio_resol_t = soloFechaEnvio; //***USADO para la tabla ----- registro.fecha_envio_resol_t;
				var fecha_emision_resol_t = soloFechaEmision;//***USADO para la tabla
				var numero_especial_resol = registro.numero_especial_resol_t;
				var observaciones_resol = registro.t_136_observaciones_resol;
				var estado_D = registro.estado;

				var rowClick = (e)=>{
					e.preventDefault();
					setShow(true);

					setEditRegistro({
						...editRegistro,
						id_exp_pruebaE: registro.id_exp_prueba,
						t_132_tramitacion_resol_E : tramitacion_resol,
						fecha_envio_resol_t_E: dateEdicionEnvio,
						fecha_emision_resol_t_E : dateEdicionEmision,
						numero_especial_resol_t_E: numero_especial_resol,
						t_136_observaciones_resol_E: observaciones_resol,
						estado_E : estado_D,
					})

				}

				return <tr id={index} onDoubleClick={rowClick} >
					<td>{expediente}</td>
					<td>{registro.partido_nombre}</td>
					<td>{nombre_proyecto}</td>
					<td>{fecha_envio_resol_t}</td>
					<td>{observaciones_resol}</td>
				</tr>;
			})

		}
	}

	if (datosS === null) {

		return(
			<Fragment>
				<p>INICIO hacer consulta</p>
				<div id="tabla_TODO" style={{ padding:"2px", width:"100%" }} className=""> {/*border:"2px solid blue"*/}
					<div className="" style={{ width:"100%", overflow:"scroll"}} >	{/*border:"2px solid red",*/}

						<Table stripped bordered hover responsive="md" >
							<thead >
								<tr style={{backgroundColor:"rgb(0,174,195)", color:"white"}}>{getHeader(columnas)}</tr>
							</thead>
							<tbody>
								{ getRowsData(datosS) }
							</tbody>
						</Table>

					</div>
				</div>

			</Fragment>
		)
	} else {
		//console.log("YA NO ES NULL");
		return (
			<Fragment>
				<br/>

				<div className="" style={{ width:"100%" }}> {/* div A, border:"2px solid #333" */}
					<div>
						<ReactHTMLTableToExcel
							//id="uno-table-xls-button"
							className="btn btn-info text_button"
							table="resolucion-to-xls"
							filename="tramitaciones_resolucion"
							sheet="tablexls"
							buttonText="Descargar XLS"
						/>
					</div>
					{/*`buscar: Partido:{partido || '____'}--Estado:{estado  || '____'} -- priorit:{prioritario  || '____'}-- Programa:{programa || '____'}--  Tipo:{tipo  || '____'}-- `*/}
				</div>{/*cierra div A*/}

				<div id="tabla_TODO" style={{ padding:"2px", width:"100%" }} className=""> {/*, border:"2px solid blue" */}
					<div className="" style={{ overflow:"scroll"}} > {/*width:"100%",  border:"2px solid red" */}

						<div className="container_left">

							<div className="input-container" style={{width: "25%"}}>
								<SelectInput
									name="partido"
									options={ optionsRP2_Todos }
									onChange={e=>handleOnChangePartido(e)}
								/>
								<label alt="Partido" for="id_partido">Partido</label>
							</div>
						</div>{/*cierra container_left*/}

						<Table className="tableExp" id="resolucion-to-xls" stripped bordered hover responsive="md" >
							<thead >
								<tr style={{backgroundColor:"rgb(0,174,195)", color:"white"}}>{getHeader(columnas)}</tr>
							</thead>
							<tbody>
								{ getRowsData(datosS) }
							</tbody>
						</Table>

					</div>
				</div>

				<Modal
					id="id_modal_1"
					show={show}
					onHide={handleClose}
					backdrop="static"
					keyboard={false}
					className="id_modal_1"
					style={{top: "200"}}
				>
					<Modal.Header style={{background: "rgb(0,174,195)", color:"white"}} closeButton>
						<Modal.Title>Edición{/*id_exp_pruebaE*/}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<div className="container_left">
								<h6 className="mt-4 text-left subtitulos">CARACTERÍSTICAS GENERALES</h6>
							</div>

							<div className="container_left">
								<div className="input-container">
									{/*<span className="span_selectEdit">{t_132_tramitacion_resol ? "Tramitación Resolución": ""}</span>*/}
									<SelectInput
										name="estado_E"
										id="id_estado_E"
										defaultValue = {estado_E}
										onChange={e => onChange(e)}
										options={optionsEstado}
									/>
									<label alt="Estado" for="id_estado_E">Estado</label>
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}


							<div className="container_left">
								<h6 className="mt-4 text-left subtitulos">RESOLUCIÓN</h6>
							</div>

							<div className="container_left">
								<div className="input-container">
									{/*<span className="span_selectEdit">{t_132_tramitacion_resol ? "Tramitación Resolución": ""}</span>*/}
									<SelectInput
										name="t_132_tramitacion_resol_E"
										id="id_t_132_tramitacion_resol_E"
										defaultValue = {t_132_tramitacion_resol_E}
										onChange={e => onChange(e)}
										options={optionsTramitacionResolucion}
									/>
									<label alt="Tramitación Resolución" for="id_t_132_tramitacion_resol_E">Tramitación resolución</label>
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}

							<div className="container_left">
								<div className="input-container">
									<DatePicker
										//id="startDate"
										title="Fecha de envío"
										id="id_fecha_envio_resol_t"
										name="fecha_envio_resol_t_E"
										dateFormat="dd/MM/yyyy"
										className="form-control"
										selected={fecha_envio_resol_t_E}
										onChange={ (date) => handleFecha1({ name: "fecha_envio_resol_t_E", value: date })}
									/>
									<label alt="Fecha envío resolución" for="id_fecha_envio_resol_t">Fecha de envío</label>
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}

							<div className="container_left">
								<div className="input-container">
									<DatePicker
										type="dataPicker"
										title="Fecha de emisión"
										name="fecha_emision_resol_t_E"
										id="id_fecha_emision_resol_t"
										dateFormat="dd/MM/yyyy"
										className="form-control"
										selected={fecha_emision_resol_t_E}
										onChange={ (date) => handleFecha1({ name: "fecha_emision_resol_t_E", value: date })}
									/>
									<label alt="Fecha de emisión" for="id_fecha_emision_resol_t">Fecha de emisión</label>
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}

							<div className="container_left">
								<div className="input-container">
									<input
										title="Número especial"
										type="text"
										maxLength="35"
										value={numero_especial_resol_t_E}
										name="numero_especial_resol_t_E"
										id="id_numero_especial_resol_t_E"
										onChange={e => onChange(e)}
									/>
									<label alt="Número especial" for="id_numero_especial_resol_t_E">Número especial</label>
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}

							<div className="container_left" style={{ height: "auto"}}>
								<div className="input-container">
									<textarea
										title="Observaciones tramitación resolución"
										type="text"
										maxLength="100"
										name="t_136_observaciones_resol_E"
										id="id_t_136_observaciones_resol_E"
										value={t_136_observaciones_resol_E}
										onChange={e => onChange(e)}
									/>
									<label alt="Observaciones tramitación resolución" for="id_t_136_observaciones_resol_E">Observaciones tramitación resolución</label>
									{/*<p align="right" style={{fontSize: "0.9em"}}>Caracteres restantes: </p>*/} {/*maxObservResol*/}
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}

						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className="btn btn-success btn-block button_verde" onClick={guardarEditRegistro} disabled={original ===0 ? true : false }>	Guardar </Button>{/*guardarEditRegistro*/}
						<Button className="btn btn-success btn-block button_verde" onClick={handleClose}> Cancelar </Button>
					</Modal.Footer>
				</Modal>



			</Fragment>
		);
	}
}

export default Select_4_Resolucion;
//{datosS.filter(item => item.expediente.match(new RegExp("AD", "i")).map(filteredPerson => (
