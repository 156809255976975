import React, { Fragment, useState, useEffect, useContext } from "react";
import { FaEye, FaEyeSlash, FaSpinner} from 'react-icons/fa';

import { Link } from "react-router-dom";

import dotenv from  "dotenv";
import { toast } from "react-toastify";

import Footer_login from "./footer_login";

// #Df1 ---> Ver mail Dockerizacion SUBSE
//const baseURL = process.env.REACT_APP_API_URL;

const ResetPassword = (props) => { //role, setRole,

	//console.log(props.match.params.id); //Lo que recibe por las props
	//console.log(props.match);
	const propsToken = props.match.params.token;

	/****
	P1- Tomar el token y verificar que sea valido (contra el backend). Si expiró bloquear button de Send
	P2- Si es valido ---> verificar que la contraseña y la confirmacion sean iguales y mayor de 8 caracteres (En el cliente)
	P3- Usuario click en button Reset pass ---> envia al servidor la nueva Pass, el token y el id del usuario
	P4 - EN server: si el token corresponde al usuario (tambien biene en props) ---> Actualizar contraseña
	P5- IF todo bien: mensaje de Exito y redirigir a Login
	*/

	//**ADD 31-agos-2023 */

	const [activo, setActivo] = useState(0);//USAR solo para habilitar button de cambiar contraseña
	const [inputs, setInputs] = useState({ password: "", confirmPassword: "" });
	const [isLoading, setIsLoading] = useState(false); //01SET23 - para el button cuando se está cambiando el password

  	const { password, confirmPassword } = inputs;
	const [passwordType, setPasswordType] = useState("password");
	const [passwordTypeC, setPasswordTypeC] = useState("password");
	
    const togglePassword =()=>{
		console.log("click")
		if(passwordType==="password")
		{
		setPasswordType("text")
		return;
		}
		setPasswordType("password")
    }

	const togglePasswordC =()=>{
		console.log("click")
		if(passwordTypeC==="password")
		{
		setPasswordTypeC("text")
		return;
		}
		setPasswordTypeC("password")
    }
	//**---cierra ADD */

	/* PASO1  */
	const [token, setToken]=useState(null);
	console.log(token);
	const [tokenExp, setTokenExp] = useState('valido');
	const [idUser, setIdUser] = useState(0);
	console.log(idUser);

	useEffect(()=>{
		if(propsToken !== null){
			setToken(propsToken)	
		}
		setToken(propsToken)

	}, []) //solo la primera vez

	const checkAuthenticated = async () => {
		try {		
			const res = await fetch(`/api/authentication/verifyToken`, { // #Df1 ---> Ver mail Dockerizacion SUBSE
				method: "POST",
				headers: { 'Authorization': token }
			});

			const parseRes = await res.json();
			
			//parseRes.msg === 'Token is not valid - authorize.js'
			if(parseRes.error){ 
				toast.error(parseRes.error)//('El token no es valido.');
				//**NOTA: revisar que pasa cuando se cambia el token del link */
				setTokenExp('no valido');
				//setActivo(0);
			} else if(parseRes.id>0){
				console.log("Pasar este ID con la nueva contraseña y el token")
				setIdUser(parseRes.id)// agregamos un useEffect para manejar este cambio de estado??
			}

		} catch (err) {
			console.error(err.message);
		}
	};
	useEffect(()=>{
		if(token !== null){
			console.log("token is !== null ---> ejecutar checkAuthenticated")
			checkAuthenticated();
		}
	}, [token])

	useEffect(()=>{
		console.log("?????")
	}, [idUser])

	/* ---cierra paso 1---- */
	

	const onChange = e => {
  		setInputs({ ...inputs, [e.target.name]: e.target.value });
	}

	/** PAS0 2 */
	useEffect(()=>{
		console.log("CHEQUEAR - PASO 2")

		if(password === confirmPassword ){
			console.log("SON IGUALES")
			if(confirmPassword.length>7){
				setActivo(1)
			} else {
				setActivo(0)
			}				
			
		} else {
			console.log("SON DISTINTOS")
			setActivo(0)
		}

	}, [password, confirmPassword])

	/**cierra paso 2 */

	/**PASO 3 */
	const onSubmitForm = async e => {
		e.preventDefault();
		setActivo(0);
		setIsLoading(true);

		//setActivo(1);		
		try {
			const body = { confirmPassword, token, idUser };
			const response = await fetch(
			`/api/authentication/resetpassword`,// #Df1 ---> Ver mail Dockerizacion SUBSE
				{
					method: "POST",
					headers: {
							"Content-type": "application/json"
					},
					body: JSON.stringify(body)
				}
			);
			const parseRes = await response.json();
			//console.log(parseRes)

			if (parseRes.ok) {								
				toast.success(parseRes.ok);
				setIsLoading(false);

			} else {
				//setActivo(0);
				toast.error(parseRes);
			}
		} catch (err) {
			console.error(err.message);
			//setActivo(0);
		}
	};

  return (
    <Fragment>
		{/*<Header />*/}
		<div className="container_login">
		<h1 className="mt-5 text-center">GESTIÓN DE PROYECTOS</h1>
			<h2 className="mt-5 text-center">Dirección Provincial de Producción de Hábitat</h2>
			<form style={{ paddingLeft:"300px", paddingRight:"300px" }} onSubmit={onSubmitForm}>
				<div className="input-wrapper">
					<input
						type={passwordType}
						name="password"
						value={password}
						placeholder="Nueva contraseña"
						onChange={e => onChange(e)}
						className="form-control my-3"
					/>
					{/*<FaEye className="input-icon password" onClick={togglePassword}/>*/}
					{ passwordType==="password"? <FaEye className="input-icon password" onClick={togglePassword}/> :<FaEyeSlash className="input-icon password" onClick={togglePassword}/> }
				</div>
				<div className="input-wrapper">
					<input
						type={passwordTypeC} 
						name="confirmPassword"
						value={confirmPassword}
						placeholder="Confirmar contraseña"
						onChange={e => onChange(e)}
						className="form-control my-3"
					/>
					{/*<FaEye className="input-icon password" onClick={togglePasswordC}/>*/}
					{ passwordTypeC==="password"? <FaEye className="input-icon password" onClick={togglePasswordC}/> :<FaEyeSlash className="input-icon password" onClick={togglePasswordC}/> }
				</div>
				<div className="input-wrapper">
					{isLoading
						?	
						<button className="btn btn-success btn-block" disabled={activo ===0 ? true : false } >
							Aguarde un instante...  <FaSpinner icon="spinner" className="spinner" />
						</button>						
						:
						<button className="btn btn-success btn-block" disabled={tokenExp === 'no valido' || activo ===0 ? true : false } >							
							Recuperar contraseña 
						</button>						
					}
					{/*<button className="btn btn-success btn-block" disabled={tokenExp === 'valido' && activo ===1 ? false : true } >Recuperar contraseña</button>*/}
				</div>
				<div className="input-wrapper">
					{tokenExp === 'no valido'
						?
						<Link style={{color:'white', textDecoration: 'none'}} to="/forgotpassword">Volver a solicitar</Link>
						:
						<Link style={{color:'white', textDecoration: 'none'}} to="/login">Ir a login</Link>
					}					
				</div>		
				
			</form>
			{/*<Link to="/register">register</Link>*/}
		</div>
		{/*<Footer_login/>*/}
    </Fragment>
  );
};

export default ResetPassword;
