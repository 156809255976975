import React, { Fragment, useState, useContext } from "react";
import { SessionContext } from '../context/sessionContext';
import { Link } from "react-router-dom";

import dotenv from  "dotenv";
import { toast } from "react-toastify";

//const baseURL = process.env.REACT_APP_API_URL;
//console.log(baseURL);

const Login_form = ({ show, setShow, ...props }) => { {/*setAuth, role, setRole,*/}

	const { roleA, setRoleA, setCodValidez, setUsuario, getProfile } = useContext(SessionContext);
	//console.log("14 --sesionT", sesionT);
	const [activo, setActivo] = useState(0);

	const [inputs, setInputs] = useState({
    		email: "",
    		password: ""
	});

  const { email, password } = inputs;

	const onChange = e => {
  	setInputs({ ...inputs, [e.target.name]: e.target.value });
	}

	const onSubmitForm = async e => {
		e.preventDefault();
		//console.log("LOGIN");
		setActivo(1);
		try {
			const body = { email, password };
			const response = await fetch(
				`/api/authentication/login`,  			
  				{
    				method: "POST",
    				headers: {
      					"Content-type": "application/json"
    				},
    				body: JSON.stringify(body)
  				}
			);
			//console.log("respuesta al loguearme");
			//console.log(response);

			const parseRes = await response.json();
			//console.log("respuesta desde ");
			//console.log(parseRes);
			//console.log(parseRes.jwtToken);

				if (parseRes.jwtToken) {
					localStorage.setItem("tokenT", parseRes.jwtToken); //jwtToken
					localStorage.setItem("roleT", parseRes.role);
					localStorage.setItem("username", parseRes.username);
					localStorage.setItem("validezT", parseRes.validez );
					localStorage.setItem("tokenActualizacion", parseRes.refreshJwtToken)

					setCodValidez(parseRes.validez);// **add 24-agost-2023 -pasar a sessionContext
					setUsuario(parseRes.usuario);// **add 24-agos-2023 -pasar a sessionContext

					//setRole({roleT: parseRes.role, username: parseRes.username});
					setRoleA({roleA: parseRes.role, username: parseRes.username});
					setShow(false);
					const pagina = "login form";
					getProfile(pagina);
					//setAuth(true);

					//const role = parseRes.role;
	  				toast.success("Logged in Successfully");
				} else {
					setActivo(0);
	  				//setAuth(false);
	  				toast.error(parseRes);
				}
		} catch (err) {
				console.error(err.message);
		}
	};

  return (
    <Fragment>
		{/*<Header />*/}
		<div className="container_form_login" style={{height: '80%'}} >
		{/*<h2 className="mt-5 text-center">GESTIÓN DE PROYECTOS</h2>*/}
			<h4 className="mt-5 text-center">Dirección Provincial de Producción de Hábitat</h4>
			<form style={{ paddingLeft:"100px", paddingRight:"100px" }} onSubmit={onSubmitForm}>
				<input
					type="text"
					name="email"
					value={email}
					placeholder="Usuario"
					onChange={e => onChange(e)}
					className="form-control my-3"
				/>
				<input
					type="password"
					name="password"
					value={password}
					placeholder="Contraseña"
					onChange={e => onChange(e)}
					className="form-control my-3"
				/>
				<button className="btn btn-success btn-block" disabled={activo ===0 ? false : true } >Ingresar</button>
			</form>
		{/*<Link to="/register">register</Link>*/}
		</div>

    </Fragment>
  );
};

export default Login_form;
