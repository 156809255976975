import React, {Fragment} from 'react';
//import { FaFileDownload} from 'react-icons/fa';

//import Pdf from '../../documents/uno.pdf'

export const List = ({lista, ...props}) => {
    //console.log(lista);

    return(
       <>      
            <ul style={{listStyle: 'none', paddingLeft: "0px"}}>
                {lista.map((item, index) => {
                    return (
                        <Fragment>
                        <li key={index} onClick="">
                            {item.icon}
                            <a style={{ color: 'white', textDecoration: 'none', paddingLeft: "10px"}}
                            href={process.env.PUBLIC_URL + item.path} target="_blank" rel="noopener noreferrer" download={item.document}>{item.title}
                            </a>
                            <p></p>                            
                        </li>
                        </Fragment>
                    );
                })}
            </ul>    
       </>
    )
}

export default List;