import React, { useRef, useState, useEffect } from 'react';
import L from 'leaflet';

import Toolbar from "./toolbar";

const style = {
  width: "100%",
  height: "300px"
};

const LeafletMap =({featuresP, featuresF, datosS}) => {

	//console.log(featuresP);
	//console.log(featuresF);
	//console.log(datosS);

	const [selectedFeature, setSelectedFeature] = useState(null)

	const mapRef = useRef(null);
	useEffect(() => {
		const osm = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
                );
		mapRef.current = L.map('map', {
                        center: [-27.8419, -65.0315],
                        zoom: 7,
                        layers: [ osm ]
		});
        }, [])// se ejeucta una sola vez
	
	const layerRef = useRef(null);

	useEffect(() => {
		layerRef.current = L.layerGroup().addTo(mapRef.current);
                //layerRef.current.bringToFront(mapRef.current);

                const overlayMaps = {"Point": layerRef.current};

                L.control.layers(overlayMaps).addTo(mapRef.current);
	}, [])

	const geoJsonRef = useRef(null)
	const toolbar = useRef(null)
	//const [geoJsonRef, toolbar] = useRef(null)

	//https://stackoverflow.com/questions/50736530/react-leaflet-stop-propagation-when-click-on-a-drawn-polygon
	//https://github.com/PaulLeCam/react-leaflet/issues/765
	/*useEffect(() => {
		
    		//if(toolbar.current) {
        		L.DomEvent.disableClickPropagation(toolbar.current);
        		L.DomEvent.disableScrollPropagation(toolbar.current);
    		//}
	});*/

	useEffect(()=>{
		L.polyline([[0, -180],[0, 180]], {
  			'dashArray': [10, 50]
		})
		.addEventListener('mouseover', function (e) {
    			e.target.setStyle({
      				color: 'red'
    			});
		})
		.on('mouseout', function (e) {
    			e.target.setStyle({
      				color: 'blue'
    			});
		})
		.addTo(mapRef.current);
	}, [])

	useEffect(() => {

		if(!document.getElementById('customT')){


        	} else {
                	var toolbar = document.getElementById('customT');
                	//console.log(toolbar);

			toolbar.onmouseover = function(e){
				L.DomEvent.disableClickPropagation(toolbar);
				//console.log(e)
			}
			//toolbar.onmouseout = onFuera;

			function onDentro(){
				//console.log('dentro');
				//mapRef.panning.disable();
				//stopPropagation();
			}
	
			function onFuera(){
				//console.log('fuera');
			}
        	}

		if(!document.getElementById('tableId')){

		} else {
			
		}

		var datos = datosS;
		//console.log('Cargar Geojson');
		//console.log(datos);

		function dehighlight (layer) {
		  	if (selected === null || selected._leaflet_id !== layer._leaflet_id) {
				 geoJsonRef.current.resetStyle(layer);
		  	}
		}

		function select (layer) {
			if (selected !== null) {
		    		var previous = selected;
		  	}
			mapRef.current.fitBounds(layer.getBounds());
			selected = layer;
			if (previous) {
			  dehighlight(previous);
			}
		}

		function styleEarthquakePoints(feature){
                	return {
                        	radius: 5,
                        	fillColor: "#ff7800",
                        	color: 'black',
                        	weight: 1,
                        	opacity: 0.8,
                        	fillOpacity: 0.6,
				interactive:true,
				//"pointer-events": "auto"
                	};
		}

		const onEachFeature = (feature, layer) => {
			//console.log(layer);
			//console.log(feature);
            		layer.on({
				/*'click', (e) => {
				//console.log('se dio click');
				//console.log(e.target); 
                		const name = feature.properties.name
                		setSelectedFeature(name)
                		//const centerSelectedFeature = getCentroid(feature.geometry.coordinates[0][0])
                		//setMarkerPosition({lat: centerSelectedFeature[1], lng: centerSelectedFeature[0]})
            			}*/
				'mouseover': function (e) {
  		      			//console.log(e.target);
  		    		},
  		    		'mouseout': function (e) {
  		      			//console.log(e.target);
  		    		},
  				'click': function (e) {
  				 //console.log(e.target.feature.properties.id);
				 //select(e.target);

				 let trID = e.target.feature.properties.id;
				 let select = document.getElementById(trID);
				 //console.log(select);
				 //mapRef.current._layers[select].fire('click');
  				}
			})
        	}

		if(geoJsonRef.current != null) {
            		geoJsonRef.current.clearLayers();
			//const bounds = geoJsonRef.current.getBounds();
			//console.log(bounds);
			//mapRef.current.fitBounds(getBounds(), bounds);
			//mapRef.current.fitBounds(L.latLngBounds(L.latLng(sw), L.latLng(ne)));
        	} else {
			//console.log("entra en else");
		}

		geoJsonRef.current = null;
		const selected = null; //****add for change style

		geoJsonRef.current = L.geoJSON(datos, {
                	pointToLayer: function (feature, latlng) {
                        	return L.circleMarker(latlng, styleEarthquakePoints(feature));
                	},
			onEachFeature: onEachFeature
			/*onEachFeature: function (feature, layer) {
				layer.on({
					'click': function (e) {
  				  		//console.log(e.target);
  					}
				});
			}*/
		}).on('click', function(){
			//console.log('click geojsonRef');
        	//}).addTo(layerRef.current);
		}).addTo(mapRef.current);

		//mapRef.current.zoomToFeature(geoJsonRef.current);
		//mapRef.current.fitBounds(geoJsonRef.current.getBounds());
		geoJsonRef.current.bringToFront();
		const bounds = geoJsonRef.current.getBounds();
		//console.log(bounds);
		//if(bounds.isValid()){mapRef.current.fitBounds(geoJsonRef.current.getBounds())}//***podria ir acá, pero uso function zoomTo..

		function zoomToFeature(layer){
			//console.log(layer);
			const bounds = layer.getBounds();
			//console.log(bounds);

			if (bounds.isValid()) {mapRef.current.fitBounds(layer.getBounds())}

			/*var fitBoundsParams = {
      				paddingTopLeft: [200,10],
      				paddingBottomRight: [10,10]
    			};
			mapRef.current.fitBounds(bounds, fitBoundsParams);*/
		}

		zoomToFeature(geoJsonRef.current);

		/*const lat_nE= bounds._northEast.lat;
		const lng_nE= bounds._northEast.lng;
                const lat_sW= bounds._southWest.lat;
                const lng_sW= bounds._southWest.lng;		

		//console.log(lng_sW);*/
		
		//console.log(geoJsonRef.current.getBounds());		
		//console.log(layerRef.current);
		//console.log(mapRef.current);

	}, [datosS, selectedFeature]);//[featuresF]5_jun_21

	return <div id='map' style={style}><Toolbar datosS={datosS} mapa={mapRef.current} layer={geoJsonRef}/></div>
}

export default LeafletMap;

