import React, { Fragment, useState, useEffect, useContext } from "react";
import { FaEye, FaEyeSlash} from 'react-icons/fa';
import { SessionContext } from '../context/sessionContext';
import { Link } from "react-router-dom";

import dotenv from  "dotenv";
import { toast } from "react-toastify";

//const baseURL = process.env.REACT_APP_API_URL;

const Change_passw = ({ showChangePass, setShowChangePass, ...props }) => { {/*setAuth, role, setRole,*/}

	//NOTA: codValidez, no mandar como propiedad, está en SessionContext ---> tomar de ahi y su funcion de cambio	

	const { roleA, setRoleA, sesionT, getProfile, codValidez, setCodValidez, usuario} = useContext(SessionContext);
	//console.log("USUARIO", usuario)
	//console.log("14 --codValidez", codValidez);
	const [activo, setActivo] = useState(0);

	const [inputs, setInputs] = useState({		
		password: "",
		passwordNew: "",
		passwordRepetNew: ""
	});

  	const { password, passwordNew, passwordRepetNew } = inputs;
	//console.log("INPUTS--->", inputs)
	//console.log("INPUTS PASS--->", passwordNew)

	const [passwordType, setPasswordType] = useState("password");
	const [passwordTypeNew, setPasswordTypeNew] = useState("password");
	const [passwordTypeNewR, setPasswordTypeNewR] = useState("password");
	
    const togglePassword =()=>{
		if(passwordType==="password")
		{
			setPasswordType("text")
			return;
		}
		setPasswordType("password")
    }

	const togglePasswordNew =()=>{
		if(passwordTypeNew==="password")
		{
			setPasswordTypeNew("text")
			return;
		}
		setPasswordTypeNew("password")
	}

	const togglePasswordNewR =()=>{
		if(passwordTypeNewR==="password")
		{
			setPasswordTypeNewR("text")
			return;
		}
		setPasswordTypeNewR("password")
	}


	const onChange = e => {
  		setInputs({ ...inputs, [e.target.name]: e.target.value });
	}

	useEffect(()=>{
		//console.log("CHEQUEAR")

		if(passwordNew === passwordRepetNew && passwordRepetNew.length>7){
			//console.log("SON IGUALES")
			
			if(password === passwordRepetNew){
				//console.log("NO PUEDE SER IGUAL QUE LA ACTUAL")
				setActivo(0)
			}else{
				//console.log("ES DISTINTA QUE LA ACTUAL")
				setActivo(1) //--------->
			}
		} else {
			//console.log("SON DISTINTOS")
			setActivo(0)
		}

	}, [password, passwordNew, passwordRepetNew])

	const onSubmitForm = async e => {
		e.preventDefault();
		//console.log("LOGIN");
		setActivo(1);
		try {
			const body = { password, passwordRepetNew, usuario };
			const response = await fetch(
				`/api/authentication/changePassword`,
				//"http://localhost:5003/authentication/login",
				{
					method: "POST",
					headers: {
						"Content-type": "application/json"
					},
					body: JSON.stringify(body)
				}
			);			

			const parseRes = await response.json();
			
			if (parseRes.jwtToken) {
				localStorage.setItem("tokenT", parseRes.jwtToken); //jwtToken
				localStorage.setItem("roleT", parseRes.role);
				localStorage.setItem("username", parseRes.username);
				
				setRoleA({roleA: parseRes.role, username: parseRes.username});
				//setShowChangePass(false);

				setCodValidez(parseRes.validez)
				//const pagina = "login form";
				//getProfile(pagina);				
				toast.success("Clave actualizada");
			} else {
				setActivo(0);
				toast.error(parseRes);
			}
		} catch (err) {
			console.error(err.message);
		}
	};

  return (
    <Fragment>
		{/*<Header />*/}
		<div className="container_change_login" style={{height: '90%'}} >
			{/*<h2 className="mt-5 text-center">GESTIÓN DE PROYECTOS</h2>*/}
			{/*<h4 className="mt-5 text-center">Dirección Provincial de Producción de Hábitat</h4>*/}
			<form style={{ paddingLeft:"100px", paddingRight:"100px" }} onSubmit={onSubmitForm}>
				<p>Por seguridad se recomienda el cambio de contraseña</p>
				<div className="input-wrapper">				
					<input					
						type={passwordType}
						name="password"
						value={password}
						placeholder="Contraseña actual"
						onChange={e => onChange(e)}
						className="form-control my-3"
					/>
					{ passwordType==="password"? <FaEye className="input-icon password" onClick={togglePassword}/> :<FaEyeSlash className="input-icon password" onClick={togglePassword}/> }
				</div>
				<div className="input-wrapper">				
					<input
						type={passwordTypeNew}
						name="passwordNew"
						value={passwordNew}
						placeholder="Nueva Contraseña"
						onChange={e => onChange(e)}
						className="form-control my-3"
					/>
					{ passwordTypeNew==="password"? <FaEye className="input-icon password" onClick={togglePasswordNew}/> :<FaEyeSlash className="input-icon password" onClick={togglePasswordNew}/> }
				</div>
				<div className="input-wrapper">
					<input
						type={passwordTypeNewR}
						name="passwordRepetNew"
						value={passwordRepetNew}
						placeholder="Repetir Contraseña"
						onChange={e => onChange(e)}
						className="form-control my-3"
					/>
					{ passwordTypeNewR==="password"? <FaEye className="input-icon password" onClick={togglePasswordNewR}/> :<FaEyeSlash className="input-icon password" onClick={togglePasswordNewR}/> }
				</div>
				<div className="input-wrapper-right" >
					<p align="right" style={{color:'gray'}}>La contraseña debe tener como mínimo 8 caracteres</p>
				</div>

				<button className="btn btn-success btn-block" disabled={activo ===0 ? true : false } >Enviar</button>
			</form>
		{/*<Link to="/register">register</Link>*/}
		</div>

    </Fragment>
  );
};

export default Change_passw;
