import React, {Fragment,  useState, useEffect} from "react";
import { Table, Button } from "react-bootstrap";

import '../../table_report.css';

const TablaInf2_Detallado = ({datos_Inf2_finalizadosTotal}) => {
  //console.log("CARGAR datos FINALIZADOS en tabla");
  //console.log(datos_Inf2_finalizadosTotal);

  if (datos_Inf2_finalizadosTotal === null) {
    return(
      <p>Seleccionar rango de fechas...</p>
    )
  } else if(datos_Inf2_finalizadosTotal !== null){
    return(
      <Fragment>
        <h4></h4>
        <p className="subtitulos">Cantidad de lotes y proyectos</p>
        <div>
          <Table className="tableExp" stripped bordered hover responsive="md" >
            <thead>
              <tr style={{backgroundColor:"rgb(0,174,195)", color:"white"}}>
                <th>Total lotes</th>
                <th>Total proyectos</th>
              </tr>
            </thead>
            <tbody>
              {datos_Inf2_finalizadosTotal.map((item, i)=>(
                <tr key={i}>
                  <td>{item.suma_lotes}</td>
                  <td>{item.suma_proyectos}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Fragment>
    )
  }
};

export default TablaInf2_Detallado;
