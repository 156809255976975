import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import { SessionProvider } from './context/sessionContext';
import './index.css';
import App from './App';
//import Header from './header';
//import Footer from './footer';


{/*ReactDOM.render(
	<React.StrictMode>
		<>
			<App />
		</>
	</React.StrictMode>,
	document.getElementById('root')
);*/}

//** comentar 05-10-2022 ----> ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(
    <SessionProvider>
      <App />
    </SessionProvider>,
  document.getElementById('root')
);
//React 18

{/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);*/}
