import React, {useState, createContext} from "react";
import { toast } from "react-toastify";

const SessionContext = createContext({});


const SessionProvider = ({ children }) => {

  const [userA, setUserA] = useState({ nameA: '', authA: true }); // definido para la prueba

  //POR AHORA interesa un valor global para ROLE (roleA) y USUARIO (username) dentro de la constante roleA (quizas deberia tener otro nombre)
  const [roleA, setRoleA] = useState({roleA:"user", username:""});
  //console.log("roleA--->", roleA)
  const [sesionT, setSesionT] = useState("Token valido");
  const [codValidez, setCodValidez] = useState(null);
  //console.log(codValidez)
  const [usuario, setUsuario] = useState("");
	  
  //const baseURL = process.env.REACT_APP_API_URL; // #Df1 ---> Ver mail Dockerizacion SUBSE

  //**NOTA: esta funcion se llama unicamente para validar que el token no haya expirado---> ver Expediente.js */
  const getProfile  = async (fuente) => { //fuente----> origen //async
    //console.log("session 1 - EJECUTAR--->getProfile");
    const url = `authentication/checkAuth`;
    
    let res = await fetch(`/api/authentication/checkAuth`, { //`${baseURL}authentication/checkAuth` // #Df1 ---> Ver mail Dockerizacion SUBSE
      method: "POST",
      headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.tokenT, roleT: localStorage.roleT, username:localStorage.username }
    });
      
    const parseRes = await res.json(); //await
    
    if( parseRes.msg==="Token is not valid - authorize.js"){

      //toast.error(`Extender sesión`);//Sesión expiró <------------01-dic-2023
      setRoleA({roleA:"", username:"sesion expirada"});
      setSesionT("no_token");

      //** PROBAR ACÁ como llamar a Refresh Token */
      let res2 = await fetch(`/api/authentication/generarNewToken`, {
        method: "POST",
        headers: {'Content-Type': 'application/json', 'Authorization': localStorage.tokenActualizacion }

      });
      const parseRes2 = await res2.json();

      if(parseRes2.msg === "El token de ACTUALIZACION no es válido"){
        toast.error(`La sesión finalizó. Por favor loguearse nuevamente`);
        setRoleA({roleA:"", username:"sesion expirada"});
        setSesionT("no_token");

        localStorage.removeItem("tokenT");
        localStorage.removeItem("roleT");
        localStorage.removeItem("username");
        localStorage.removeItem("validezT");
        localStorage.removeItem("tokenActualizacion");

      } else {
        localStorage.setItem("tokenT", parseRes2.jwtToken); //jwtToken      
        localStorage.setItem("tokenActualizacion", parseRes2.refreshJwtToken)
      }      
            
    } else {//if( parseRes.msg==="Token valido" )
      
      setRoleA({roleA: parseRes.role, username:parseRes.username});
      setSesionT("Token valido");      
    }      
  };

  //07-04-2023------------------  
  //NO!! const geojson = {"type":"GeometryCollection","geometries":[{"type":"Polygon","coordinates":[[[-58.234761687,-35.154374968],[-58.234639888,-35.156167462],[-58.232873788,-35.156034686],[-58.232609888,-35.154607331],[-58.234761687,-35.154374968]]]},{"type":"Polygon","coordinates":[[[-58.232102388,-35.15442476],[-58.231493388,-35.156250447],[-58.230397189,-35.154623928],[-58.232102388,-35.15442476]]]}]}
  //const geojson = {"type": "FeatureCollection","features":[{"type":"Feature","properties":{},"geometry": {"type":"GeometryCollection","geometries":[{"type":"Polygon","coordinates":[[[-58.234761687,-35.154374968],[-58.234639888,-35.156167462],[-58.232873788,-35.156034686],[-58.232609888,-35.154607331],[-58.234761687,-35.154374968]]]},{"type":"Polygon","coordinates":[[[-58.232102388,-35.15442476],[-58.231493388,-35.156250447],[-58.230397189,-35.154623928],[-58.232102388,-35.15442476]]]}]}}]};
  //const [geom, setGeom] = useState(geojson)

  //const [geom, setGeom] = useState({type:"FeatureCollection",features:[]})
  //map-A1
  const [geom, setGeom] = useState({type:"FeatureCollection",features:[{"type":"Feature","properties":{},"geometry":{}}]})
  //console.log("map-A1", geom);
  const [geomEdit, setGeomEdit]=useState({type:"FeatureCollection",features:[{"type":"Feature","properties":{},"geometry":{}}]})
  //console.log("EDITmap-A1", geomEdit);
  
  const cambioGeom=(geojson)=>{
    setGeom(geojson);
    return geom;
  }

  const cambioGeomEdit=(geojson)=>{
    setGeomEdit(geojson);
    return geomEdit;
  }

  const defaultValue = {
    geom,
    setGeom
  }

  const [original, setOriginal] = useState(0); // 12-0c-2022

  const cambioOriginal = (estado) => {
    if (estado === 1){
      setOriginal(1); // 12-0c-2022
    } else {
      setOriginal(0);
    }
    return original;
  }

  // Login updates the user data with a name parameter - SON DE PRUEBA
  const loginA = (nameA) => {
    setUserA((userA) => ({
      nameA: nameA,
      authA: true,
    }));
  };

  // Logout updates the user data to default - SON DE PRUEBA
  const logoutA = () => {
    setUserA((userA) => ({
      nameA: '',
      authA: false,
    }));
  };

  const loginPrueba = () => {
    alert("Name PRUEBA");
  }

  return (
    <SessionContext.Provider value={{ loginPrueba, getProfile, roleA, setRoleA, original, cambioOriginal,
    sesionT, defaultValue, geom, cambioGeom, geomEdit, cambioGeomEdit, codValidez, setCodValidez, usuario, setUsuario}}> {/*quitar ----> userA, loginA, logoutA,*/}
      {children}
    </SessionContext.Provider>
  );
}

export { SessionContext, SessionProvider }; //LayerContext, LayerContextProvider
