import React, { Fragment, useState, useEffect, useContext } from "react";
import { FaSpinner} from 'react-icons/fa';
import { Link } from "react-router-dom";

import dotenv from  "dotenv";
import { toast } from "react-toastify";

//import Header from "./header";
import Footer_login from "./footer_login";

// #Df1 ---> Ver mail Dockerizacion SUBSE
//const baseURL = process.env.REACT_APP_API_URL;

const ForgotPassword = ({  setAuth, ...props }) => { //role, setRole,
	//NOTA: **** solo manda email

	//0 --> disabled true
	//1 --> disabled false
	const [activo, setActivo] = useState(1); //para el button de Send--por defecto 
	const [isLoading, setIsLoading] = useState(false); //01SET23 - para el button de Send, cuando se está mandando el mail

	const [inputs, setInputs] = useState({
    	email: ""    		
	});
	console.log(inputs);		

  	const { email } = inputs;

	const onChange = e => {
  		setInputs({ ...inputs, [e.target.name]: e.target.value });
	}

	useEffect(()=>{
		if(email===""){
			setActivo(0)
		}else{
			setActivo(1)
		}
	}, [email])

	const onSubmitForm = async e => {
		e.preventDefault();
		setActivo(0);
		setIsLoading(true);
		
		try {
			const body = { email };
			const response = await fetch(
			//`${baseURL}authentication/forgotpassword`,	// #Df1 ---> Ver mail Dockerizacion SUBSE
			`/api/authentication/forgotpassword`,		
				{
					method: "POST",
					headers: {
							"Content-type": "application/json"
					},
					body: JSON.stringify(body)
				}
			);			

			const parseRes = await response.json();
			console.log(parseRes);

			if (parseRes.ok) {
				
				toast.success("Email enviado exitosamente. Revise su casilla de correo");
				setIsLoading(false);
				setInputs({ ...inputs, "email": "" })
			} else {
				//setActivo(0);
				//setAuth(false);
				toast.error(parseRes);
				setIsLoading(false);
			}
		} catch (err) {
			console.error(err.message);
			setActivo(0);
			//setAuth(false);
		}
	};

  return (
    <Fragment>
		{/*<Header />*/}
		<div className="container_login">
		<h1 className="mt-5 text-center">GESTIÓN DE PROYECTOS</h1>
			<h2 className="mt-5 text-center">Dirección Provincial de Producción de Hábitat</h2>
			<form style={{ paddingLeft:"300px", paddingRight:"300px" }} onSubmit={onSubmitForm}>
				<div className="input-wrapper">	
					<input
						type="text"
						name="email"
						value={email}
						placeholder="Ingrese email"
						onChange={e => onChange(e)}
						className="form-control my-3"
					/>
				</div>
				<div className="input-wrapper">
					{isLoading
						?	
						<button className="btn btn-success btn-block" disabled={activo ===0 ? true : false } >
							Aguarde un instante...  <FaSpinner icon="spinner" className="spinner" />
						</button>						
						:
						<button className="btn btn-success btn-block" disabled={activo ===0 ? true : false } >							
							Recibir link de recuperación 
						</button>						
					}									
				</div>				
				<div className="input-wrapper">
					<Link style={{color:'white', textDecoration: 'none'}} to="/login">Volver a login</Link>
				</div>
			</form>
			{/*<Link to="/register">register</Link>*/}
		</div>
		{/*<Footer_login/>*/}
    </Fragment>
  );
};

export default ForgotPassword;
