import React, { Fragment, useState, useEffect, useContext } from "react";
import { SessionContext } from './context/sessionContext';

import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

//import dotenv from  "dotenv";
import { toast } from "react-toastify";

//const baseURL = process.env.REACT_APP_API_URL;
//console.log(baseURL);
//components

import Login from "./components/login";
import Register from "./components/register";
import ForgotPassword from "./components/forgotPassword";
import ResetPassword from "./components/resetPassword";
import Inicio from "./components/inicio";
import Dashboard from "./components/dashboard";
import Page1 from "./components/page1";
import Page2 from "./components/page2";
import Expediente from "./components/expediente";
//import Metabase from "./components/metabase";
import Reports from "./components/reportes/reports";
import Monitor from "./components/monitor/monitor";
import Mapas from "./components/mapas";
import Graficos from "./components/graficos";
import Informes_partido from "./components/informes_partidos";

toast.configure();
// #Df1 ---> Ver mail Dockerizacion SUBSE
//const baseURL = process.env.REACT_APP_API_URL;

function App() {

  const {  roleA, setRoleA, codValidez, setCodValidez } = useContext(SessionContext); //userA, setAuthA, setSetAuthA  

  console.log("codValidez--->", codValidez)

  const checkAuthenticated = async () => {
    try {
      //const res = await fetch("http://localhost:5003/authentication/verify", {//ORIGINAL 8001
      const res = await fetch(`/api/authentication/verify`, {
      method: "POST",
      headers: { 'Authorization': localStorage.tokenT, roleT: localStorage.roleT, username:localStorage.username }
      });

      const parseRes = await res.json();

      if(parseRes.validez){
				setCodValidez(parseRes.validez)
			}
      //console.log("App --> res.json");
      //console.log(parseRes);

      parseRes === true ? setIsAuthenticated(true)  : setIsAuthenticated(false);
    } catch (err) {
      console.error(err.message);
    }
  };

	//la funcion en useEffect se ejecuta una sola vez ----> []
  	useEffect(() => {
    		checkAuthenticated();
  	}, []);

  	const [isAuthenticated, setIsAuthenticated] = useState(false);
    //console.log(isAuthenticated);

  	const setAuth = boolean => {
    		setIsAuthenticated(boolean);
  	};
    const [role, setRole] = useState({roleT:"user", username:""});
    //console.log(role);

    const handleRole = newRole =>{
      setRole(newRole)
    }

  {/*QUITAR DE Route(S) ---> role={role} setRole={handleRole}*/}

  return (
    <Fragment>
      <Router>
        <div className="container_todo">
          <Switch>
            <Route
              path="/login"
              render={ (props) =>
                !isAuthenticated ? ( <Login {...props} setAuth={setAuth} role={role} setRole={handleRole} /> ) : ( <Redirect to="/inicio" /> )
              }
            />
            <Route
              path="/register"
              render={props =>
                !isAuthenticated ? (
                  <Register {...props} setAuth={setAuth} />
                ) : (
                  <Redirect to="/inicio" />
                )
              }
            />
            <Route
              path="/forgotpassword"
              render={props =>
                !isAuthenticated ? (
                  <ForgotPassword {...props} setAuth={setAuth} />
                ) : (
                  <Redirect to="/inicio" />
                )
              }
            />
            <Route
              path="/resetpassword/:token"
              render={ (props) =>
                !isAuthenticated ? ( <ResetPassword {...props} setAuth={setAuth} /> ) : ( <Redirect to="/login" /> )
              }
            />
            
            <Route
              path="/inicio"
              render={props =>
              isAuthenticated ? ( <Inicio {...props} setAuth={setAuth} /> ) : (<Redirect to="/login" /> )
              }
            />
	          <Route
              exact
              path="/expediente"
              render={props =>
                isAuthenticated ? ( <Expediente {...props} setAuth={setAuth}  /> ) : (<Redirect to="/login" /> )
              }
            />
	          <Route
              path="/editExpediente"
              render={props =>
                isAuthenticated ? ( <Expediente {...props} setAuth={setAuth} /> ) : (<Redirect to="/login" /> )
                }
            />            
            <Route
              path="/dashboard"
              render={props =>
                isAuthenticated ? (
                  <Dashboard {...props} setAuth={setAuth} />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              path="/page1"
              render={props =>
                isAuthenticated ? (
                  <Page1 {...props} setAuth={setAuth} />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              path="/page2"
              role="admin"
              render={props =>
                isAuthenticated ? (
                  <Page2 {...props} setAuth={setAuth} />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route
              path="/monitor"
              render={props =>
                isAuthenticated ? ( <Monitor {...props} setAuth={setAuth} /> ) : (<Redirect to="/login" /> )
              }
            />
            <Route
              path="/tramitaciones"
              render={props =>
                isAuthenticated ? ( <Reports {...props} setAuth={setAuth} /> ) : (<Redirect to="/login" /> )
              }
            />

            <Route
              path="/mapas"
              render={props =>
                isAuthenticated ? ( <Mapas {...props} setAuth={setAuth} /> ) : (<Redirect to="/login" /> )
              }
            />

            <Route
              path="/graficos"
              render={props =>
                isAuthenticated ? ( <Graficos {...props} setAuth={setAuth} /> ) : (<Redirect to="/login" /> )
              }
            />
            <Route
              path="/informes_partido"
              render={props =>
                isAuthenticated ? ( <Informes_partido {...props} setAuth={setAuth} /> ) : (<Redirect to="/login" /> )
              }
            />
          </Switch>
        </div>
      </Router>
    </Fragment>
  );
}

export default App;
