import React, {Fragment} from 'react';
//NO ESTAMOS USANDO react-select

const SelectInput = ({onChange, name, label, options, value, defaultValue}) => {
	//console.log(name);
	//console.log(options);
	//console.log(defaultValue);

  	//const indiceE="Lotes con Servicios";
	if(options.partido){
		console.log(options.partido);
	}

	const opciones = (options) => {
		return options.map( item => {
			console.log(item.partido);
			//console.log(index);
			if(item.partido){
				return  <option value={item.partido}>{item.label} || {item.value}</option>
			} else {
				return 	<option value={item.value}>{item.label}</option>
			}
		})
	}
	/* opciones select
	Pablo Gonzalez; Lucía Matteucci; Teresita Isla; Alexis Goinhex; Nicolas Resa; Ricardo Albina; Estela Retta; Silvana Santinón; Otro
	*/

	if(name ==="partido" || name ==="partido_i"){ // partido seria para probar el input cargando datos desde la url via fetch
		return (
			<select name={name} defaultValue={defaultValue} style={{ background:"white", color:"rgb(131,131,131)", height:"45px" }} className="w-full border-indigo-500 mb-2 p-2 border rounded" onChange={e => onChange(e)} >
				{options.map(item => (<option value={item.value}>{item.value} -- {item.label}</option>))}
			</select>
		)
	} else {
		return (
			<>
    		<select
          name={name}
          value={value} //ADD 27-09-2022
          defaultValue={defaultValue} //ADD 27-09-2022
          style={{ background:"white", color:"rgb(131,131,131)", height:"45px" }}
          className="w-full border-indigo-500 mb-2 p-2 border rounded"
          onChange={e => onChange(e)}
        >
        	{options.map(item => (<option value={item.value}>{item.label}</option>))}
    		</select>
			</>
    		)
	}
};

export default SelectInput;
//Cambios
//23-07-22: quitar ancho fijo a select
//24-07-22: canbiar background a white

//<option value="uno">"Uno"</option>
//LLAMAR A ESTA FUNCION DA ERROR --> {opciones(options)}
// NO FUNCIONA ---> <select ....> {opciones}</select>
//LLAMAR ASI DA ERROR ---> {options.map(item => (<option value={item}>{item}</option>))}

//ASI OK --->  {options.map(item => (<option value={item.value}>{item.label}</option>))}

/*
<option value="">Seleccionar Encargado...</option>
                <option value="pablo gonzalez">Pablo Gonzalez</option>
                <option value="lucía matteucci">Lucía Matteucci</option>
                <option value="teresita isla">Teresita Isla</option>
                <option value="alexis goinhex">Alexis Goinhex</option>
                <option value="nicolas resa">Nicolas Resa</option>
                <option value="ricardo albina">Ricardo Albina</option>
                <option value="estela retta">Estela Retta</option>
                <option value="silvana santinón">Silvana Santinón</option>
                <option value="otro">Otro</option>


*/
