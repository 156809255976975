import React, { useState, useEffect, Fragment } from "react";
import Navigation from "./navigation";
import Footer from "./footer";

import Informe_2_detallado from "./monitor/informe_2_detallado";

//const baseURL = process.env.REACT_APP_API_URL;


const Mapas =  ({ setAuth, ...props }) => {

  //const [role, setUno] = useState("a1");
  const [activo, setActivo] = useState("mapasA"); //15-06-2022

  //***chequear token *** //24-06-2022

  const [name, setName] = useState("");
  const [role, setRole] = useState("user");

	const getProfile  = async  () => {
    try {
      const res = await fetch(`/api/authentication/checkAuth`, { //${baseURL}
      method: "POST",
      headers: { 'Authorization': localStorage.tokenT, roleT: localStorage.roleT, username:localStorage.username }
      });

      const parseRes = await res.json();      

      setName(parseRes.username);
			setRole(parseRes.role);
      

    } catch (err) {
    console.error(err.message);
    }
  };

  useEffect(() => {
      getProfile();
  }, []);
	//*** cierra chequear token ****//24-06-2022

  return (
    <Fragment>
      <Navigation setAuth={setAuth} name={name} role={role} setRole={setRole} activo={activo}/>
      <div className="main">
        <h4 className="mt-5 text-center">Mapas</h4>
        <div>
          <Informe_2_detallado name={name} />
        </div>
      </div>
      <Footer/>
    
    </Fragment>
  );
}

export default Mapas;