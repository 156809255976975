import React, { Fragment, useState, useEffect, useRef, useContext} from "react";
import { SessionContext } from '../context/sessionContext';

import { Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { optionsActorEncargadoObras, optionsJurisdiccionCSP, optionsEstadoTramitacionCSP, optionsSeguimientoCSP } from "../datos/options";

import SelectInput from "./selectInput";

const Pestaña3_Exp = ({ inputsCSP, setInputsCSP, partidoDG, lotesAdjudicables, setErrorCSP }) => {

	const { original, cambioOriginal } = useContext(SessionContext);

	//desctructuring 09-04-22
	const {
		csp_1_tamaño_medio_lotes_residenciales,
		csp_3_financiamiento_publico_obras_infra,
		csp_6_fecha_control_infra,
		csp_8_financiamiento_publico_obras_viv,
		csp_11_fecha_control_viv,
		csp_13_financiamiento_publico_obras_equip,
		csp_16_fecha_control_equip,
		csp_19_pl_mensura_partido,
		csp_20_pl_mensura_orden,
		csp_21_pl_mensura_año,
		csp_22_pl_mensura_fecha,
		csp_23_registracion_legajo, // 08-08-2022: para poder validar
		csp_24_registracion_folio, // 08-08-2022: para validar
		csp_25_registracion_fecha,
		csp_26_fecha_control,
		csp_27_observaciones_csp,
		csp_28_presencia_apertura_calles,
		csp_29_parc_edificadas,
		csp_30_porcentaje_parc_edificadas,
		csp_31_observ_seguimiento_obras_y_edificacion,
		csp_32_fecha_control_seguimiento_obras
	} = inputsCSP

	const [mensajeError, setMensajeError]=useState();

	useEffect(()=>{

		if(csp_29_parc_edificadas === null){
			return
		} else if(csp_29_parc_edificadas !== "" | csp_29_parc_edificadas !== null){

			if(csp_29_parc_edificadas > Number(lotesAdjudicables) ){
				setMensajeError(true)
				setInputsCSP({ ...inputsCSP, "csp_30_porcentaje_parc_edificadas": 0 })
				setErrorCSP(true);

			} else {

				setMensajeError(false)
				let porcentaje = null;
				let a = csp_29_parc_edificadas * 100;
				let b = lotesAdjudicables;
				let resto = a % b;
				if(resto === 0){
					porcentaje = parseFloat((csp_29_parc_edificadas * 100) / Number(lotesAdjudicables));
				} else {
					porcentaje = parseFloat((csp_29_parc_edificadas * 100) / Number(lotesAdjudicables)).toFixed(2);
				}
				setInputsCSP({ ...inputsCSP, "csp_30_porcentaje_parc_edificadas": porcentaje })
				setErrorCSP(false);

			}				
		}		
	}, [csp_29_parc_edificadas, lotesAdjudicables])

	const textAreaObs_csp_27 = useRef(null);
	const textAreaObs_csp_31 = useRef(null);

	//**CONTADOR OBSERV Generales 03-08-22
	const [maxObserv_27, setMaxObserv_27] = useState(500);
	const [maxObserv_31, setMaxObserv_31] = useState(500);

	useEffect(()=>{
		let max = 500;
		if(csp_27_observaciones_csp){
			if(csp_27_observaciones_csp !== "" || csp_27_observaciones_csp !== null){
				var restantesObs = max - csp_27_observaciones_csp.length;
				setMaxObserv_27(restantesObs);
			}
		} else {
			setMaxObserv_27(max);
		}
	}, [csp_27_observaciones_csp]);
	//---CIERRA-----

	//CONTADOR Observ seguimientos de obras - CSP
	useEffect(()=>{
		let max = 500;
		if(csp_31_observ_seguimiento_obras_y_edificacion){
			if(csp_31_observ_seguimiento_obras_y_edificacion!=="" || csp_31_observ_seguimiento_obras_y_edificacion !==null){
				let restantesObs = max - csp_31_observ_seguimiento_obras_y_edificacion.length;
				setMaxObserv_31(restantesObs);
			}
		} else {
			setMaxObserv_31(max)
		}
	}, [csp_31_observ_seguimiento_obras_y_edificacion])

	//---cierra contador CSP---

	//*********ocultar div *****
	const [divVisible, setDivVisible]=useState(false);//estado para div1
	const [divVisible2, setDivVisible2]=useState(false);//estado para div2 ---> csp_8_financiamiento_publico_obras_viv
	const [divVisible3, setDivVisible3]=useState(false);//estado para div3 ---> csp_13_financiamiento_publico_obras_equip


	useEffect(()=>{
			if(csp_3_financiamiento_publico_obras_infra === null || csp_3_financiamiento_publico_obras_infra === false || csp_3_financiamiento_publico_obras_infra === "false"){
				setDivVisible(false)
			} else {
				setDivVisible(true)
			}
	}, [csp_3_financiamiento_publico_obras_infra]);

	useEffect(()=>{
		if(csp_8_financiamiento_publico_obras_viv === null || csp_8_financiamiento_publico_obras_viv === false || csp_8_financiamiento_publico_obras_viv === "false"){
			setDivVisible2(false)
		} else {
			setDivVisible2(true)
		}
	}, [csp_8_financiamiento_publico_obras_viv]);

	useEffect(()=>{
		if(csp_13_financiamiento_publico_obras_equip === null || csp_13_financiamiento_publico_obras_equip === false || csp_13_financiamiento_publico_obras_equip === "false"){
			setDivVisible3(false)
		} else {
			setDivVisible3(true)
		}
	}, [csp_13_financiamiento_publico_obras_equip]);

	//--------cierra ocultar div---

	const [partido, setEstadoPartido]= useState(null);
	useEffect(()=>{
		setEstadoPartido(partidoDG)
	}, [partidoDG]);

	const onChange = e => {
		//setOriginal(1);
		cambioOriginal(1);

		const {name, value, type} = e.target;

		if(type === 'textarea' || type === 'text' || type === 'select-one' ){
			if(e.target.name === "csp_1_tamaño_medio_lotes_residenciales") {
				const result = e.target.value.replace(/\D/g, '');
				setInputsCSP({ ...inputsCSP, "csp_1_tamaño_medio_lotes_residenciales" : result });
			} else if(e.target.name === "csp_19_pl_mensura_partido"){
				const result = e.target.value.replace(/\D/g, '');
				setInputsCSP({ ...inputsCSP, "csp_19_pl_mensura_partido" : result });
			} else if(e.target.name === "csp_20_pl_mensura_orden"){
				const result = e.target.value.replace(/\D/g, '');
				setInputsCSP({ ...inputsCSP, "csp_20_pl_mensura_orden" : result });
			} else if( e.target.name === "csp_21_pl_mensura_año"){
				const result = e.target.value.replace(/\D/g, '');
				setInputsCSP({ ...inputsCSP, "csp_21_pl_mensura_año" : result });

			} else if(e.target.name === "csp_23_registracion_legajo"){
				//setValLegajo((v) => (e.target.validity.valid ? e.target.value : v))
				//https://bobbyhadz.com/blog/react-only-number-input
				//https://thewebdev.info/2021/09/26/how-to-only-allow-numbers-to-be-entered-in-an-input-in-react/
				const result = e.target.value.replace(/\D/g, '');
				setInputsCSP({ ...inputsCSP, "csp_23_registracion_legajo" : result });

			} else if(e.target.name === "csp_24_registracion_folio"){

				const result = e.target.value.replace(/\D/g, '');
				setInputsCSP({ ...inputsCSP, "csp_24_registracion_folio" : result });

			} else {//Acá entra por ejemplo csp_27_observaciones_csp y csp_31_observ_seguimiento_obras_y_edificacion

				setInputsCSP({ ...inputsCSP, [name]: value });
			} //----> NO OLVIDAR DESCOMENTAR
			
			e.target.style.height = 'auto';
			e.target.style.height = `${e.target.scrollHeight}px`;

		} else if(type === 'number'){
			//console.log("ESTE CAMBIO NO SE ESTABA EVALUANDO");
			//console.log(e.target.value);
			if(name === "csp_29_parc_edificadas" || name === "csp_30_porcentaje_parc_edificadas"){
				let value_valid=e.target.value;
				let name_valid=e.target.name;

				if(e.target.value ===null || e.target.value===""){
					value_valid=0;
				}
				setInputsCSP({ ...inputsCSP, [name_valid]: value_valid });

			} else {
				setInputsCSP({ ...inputsCSP, [name]: e.target.value });
			}

		} else if (e.target.type === 'checkbox') {
			//console.log(e.target.type);
			setInputsCSP({ ...inputsCSP, [name]: e.target.checked });
		}
	}
	
	const handleChangeDate = (item)=>{
		//setOriginal(1);
		cambioOriginal(1);
		const fecha = item.value;
		setInputsCSP({ ...inputsCSP, [item.name]: fecha });
	}

	/*
	const handleFecha_CSP6 = (date) => {  //console.log("cambio CSP6"); setFecha_CSP6(date) }
	const handleFecha_CSP11 = (date) => {  //console.log("cambio CSP11"); setFecha_CSP11(date) }
	const handleFecha_CSP16 = (date) => {  //console.log("cambio CSP16"); setFecha_CSP16(date) }
	const handleFecha_CSP22 = (date) => {  //console.log("cambio CSP22"); setFecha_CSP22(date) }
	const handleFecha_CSP25 = (date) => {  //console.log("cambio CSP25"); setFecha_CSP25(date) }
	const handleFecha_CSP26 = (date) => {  //console.log("cambio CSP26"); setFecha_CSP26(date) }
	*/

	return (
		<Fragment>
			<div className="container_row">
				<div id="csp_col1_1" className="container_div_column">{/*columna1*/}

					<h6 className="mt-4 text-left subtitulos">CARACTERÍSTICAS DEL PROYECTO</h6>

					<div className="container_left">{/*CL-A*/}
						<div className="input-number">
							<input
								title="Tamaño medio de lotes residenciales"
								type="text"
								name="csp_1_tamaño_medio_lotes_residenciales"
								id="id_csp_1"
								inputmode="numeric" pattern="\d*"
								maxLength="4"
								value={csp_1_tamaño_medio_lotes_residenciales}
								onChange={e => onChange(e)}
							/>
							<label alt="Tamaño medio de lotes residenciales" for="id_csp_1">Tamaño medio de lotes residenciales</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left CL-A*/}

					{/* -----PONER MENSURA cambios  ----*/}
					<div className="container_left">
						<h6 className="mt-4 text-left subtitulos">PLANO DE MENSURA Y DIVISION</h6>
					</div>

					<div className="container_left">
						<div className="input-container">
							<SelectInput
								name="csp_18_pl_mensura_estado"
								id="id_csp_18"
								onChange={e => onChange(e)}
								//defaultValue={csp_18_pl_mensura_estado}
								options={optionsEstadoTramitacionCSP}
							//className="form-control my-3"
							/>
							<label alt="Estado de la tramitación" for="id_csp_18">Estado de la tramitación</label>
						</div>
					</div>

					<div className="container_left" style={{ height: "30px", }}>{/**-------------------- **/}
						<label style={{ fontSize: "0.9em" }}>Número de plano:</label>
					</div>

					<div className="container_left">

						<div style={{ width: "24%" }} className="input-number">
							<input name="csp_19_pl_mensura_partido" type="text" id="id_csp_19" maxLength="3" inputmode="numeric" pattern="\d*" value={csp_19_pl_mensura_partido} onChange={e => onChange(e)}></input> {/* POR AHORA NO....value={partido}*/} {/*value={csp_19_pl_mensura_partido} */}
							<label alt="Partido" for="id_csp_19">Partido</label>
						</div>{/*cierra ---> input-container */}

						<div style={{ width: "1%" }} />

						<div style={{ width: "24%" }} className="input-number">
							<input name="csp_20_pl_mensura_orden" type="text" id="id_csp_20" maxLength="4" inputmode="numeric" pattern="\d*" value={csp_20_pl_mensura_orden} onChange={e => onChange(e)}></input>{/*value={csp_20_pl_mensura_orden}*/}
							<label alt="Orden" for="id_csp_20">Orden</label>
						</div>{/*cierra ---> input-container */}

						<div style={{ width: "1%" }} />

						<div style={{ width: "24%" }} className="input-number">
							<input name="csp_21_pl_mensura_año" type="text" id="id_csp_21" maxLength="4" inputmode="numeric" pattern="\d*" value={csp_21_pl_mensura_año} onChange={e => onChange(e)}></input>{/*value={csp_21_pl_mensura_año}*/}
							<label alt="Año" for="id_csp_21">Año</label>
						</div>{/*cierra ---> input-container */}

						<div style={{ width: "1%" }} />

						<div style={{ width: "24%" }} className="input-number">
							<DatePicker
								id="dp_control_csp_22"
								title="Fecha"
								name="csp_22_pl_mensura_fecha"
								dateFormat="dd/MM/yyyy"
								className="form-control input_data_picker"
								selected={csp_22_pl_mensura_fecha}
								onChange={(date) => handleChangeDate({ name: "csp_22_pl_mensura_fecha", value: date })}
							/>
							<label alt="Fecha" for="dp_control_csp_22">Fecha</label>
						</div>{/*cierra ---> input-number */}

					</div>{/*cierra ---> container_left */}


					<div className="container_left" style={{ height: "30px", }}>{/**-------------------- **/}
						<label style={{ fontSize: "0.9em" }}>Registración:</label>
					</div>

					<div className="container_left">{/**abre container_left **/}

						<div style={{ width: "24%" }} className="input-number">
							{/*<input name="csp_23_registracion_legajo" id="id_csp_23" type="text" inputmode="numeric"  onChange={e => onChange(e)}></input>*/}
							<input name="csp_23_registracion_legajo" id="id_csp_23" type="text" maxLength="4" inputmode="numeric" pattern="[0-9]*" value={csp_23_registracion_legajo === 0 || csp_23_registracion_legajo === null ? '' : csp_23_registracion_legajo} onChange={e => onChange(e)}></input> {/*value={csp_23_registracion_legajo}*/}
							<label alt="Legajo" for="id_csp_23">Legajo</label>
						</div>
						<div style={{ width: "1%" }} />
						<div style={{ width: "24%" }} className="input-number">
							{/*<input name="csp_24_registracion_folio" id="id_csp_24" type="text" inputmode="numeric" onChange={e => onChange(e)}></input>*/}
							<input name="csp_24_registracion_folio" id="id_csp_24" type="text" maxLength="4" inputmode="numeric" pattern="\d*" value={csp_24_registracion_folio === 0 || csp_24_registracion_folio === null ? '' : csp_24_registracion_folio} onChange={e => onChange(e)}></input>  {/*value={csp_24_registracion_folio}*/}
							<label alt="Folio" for="id_csp_24">Folio</label>
						</div>
						<div style={{ width: "1%" }} />
						<div style={{ width: "24%" }} className="input-number">
							<DatePicker
								id="id_csp_25"
								title="Fecha de registración"
								name="csp_25_registracion_fecha"
								dateFormat="dd/MM/yyyy"
								className="form-control input_data_picker"
								wrapperClassName="datePicker"
								selected={csp_25_registracion_fecha}
								onChange={(date) => handleChangeDate({ name: "csp_25_registracion_fecha", value: date })}
							/>
							<label className="label-fecha" alt="Fecha" for="id_csp_25">Fecha</label>
						</div>

					</div>{/* ---cierra container_left ---*/}


					<div className="container_left">{/*---> container_left*/}
						<div className="input-number">
							<DatePicker
								id="id_csp_26"
								title="Fecha de control"
								name="csp_26_fecha_control"
								dateFormat="dd/MM/yyyy"
								className="form-control input_data_picker"
								selected={csp_26_fecha_control}
								onChange={(date) => handleChangeDate({ name: "csp_26_fecha_control", value: date })}
							/>
							<label alt="Fecha de control" for="id_csp_26">Fecha de control:</label>
						</div>
					</div>{/*cierra ---> container_left*/}

					<div className="container_left" style={{ height: "auto" }}>{/*---> container_left*/}
						<div className="input-container">
							<textarea
								title="Observaciones sobre el seguimiento de los proyectos"
								id="id_csp_27"
								type="text"
								maxLength="500"
								name="csp_27_observaciones_csp"
								ref={textAreaObs_csp_27}
								//value={csp_27_observaciones_csp}
								onChange={e => onChange(e)}
								rows={5}
							/>
							<label alt="Observaciones sobre el seguimiento de los proyectos" for="id_csp_27">Observaciones sobre el seguimiento de los proyectos</label>
							<p align="right" style={{ height: "5px", fontSize: "0.9em" }}>Caracteres restantes: {maxObserv_27}</p>
						</div>
					</div>
					{/* ------ cierra MENSURA -----*/}

				</div>

				<div id="csp_col2_1" className="container_div_column">

					<h6 className="mt-4 text-left subtitulos">FINANCIAMIENTO DE INFRAESTRUCTURAS</h6>

					<div className="container_left">
						<div className="input-container">
							<SelectInput
								label="Actor encargado obras"
								name="csp_2_actor_encargado_obras_infra"
								//defaultValue= {csp_2_actor_encargado_obras_infra}
								onChange={e => onChange(e)}
								options={optionsActorEncargadoObras}
								className="form-control my-3"
							/>
							<label alt="Actor encargado obras">Actor encargado obras</label>
						</div>
					</div>{/*cierra ---> container_left CL-B*/}

					<div className="container_left" >{/*---> container_left*/}
						<Form.Check type="checkbox">
							<Form.Check.Input name="csp_3_financiamiento_publico_obras_infra" checked={csp_3_financiamiento_publico_obras_infra} type="checkbox" onChange={e => onChange(e)} />
							<Form.Check.Label>{`Financiamiento público de las obras de infraestructura`}</Form.Check.Label>
						</Form.Check>
					</div>

					<div id="div_hidden" style={divVisible === true ? { display: 'block' } : { display: 'none' }}>

						<div className="container_left">
							<div className="input-container">
								<SelectInput
									placeholder=""
									name="csp_4_jurisdiccion_infra"
									onChange={e => onChange(e)}
									//defaultValue={csp_4_jurisdiccion_infra}
									options={optionsJurisdiccionCSP}
								/>
								<label alt="Jurisdicción" for="id_csp_4_jurisdiccion_infra">Jurisdicción</label>
							</div>
						</div>{/*cierra ---> container_left*/}

						<div className="container_left" style={{ height: "auto", marginTop: "23px" }}>{/*---> container_left*/}
							<div className="input-container">
								<textarea
									title="Organismo de financiamiento"
									type="text"
									id="id_csp_5_organismo_financiamiento_infra"
									maxLength="500"
									name="csp_5_organismo_financiamiento_infra"
									//value={csp_5_organismo_financiamiento_infra}
									onChange={e => onChange(e)}
								//className="form-control my-3"
								/>
								<label alt="Organismo de financiamiento" for="id_csp_5_organismo_financiamiento_infra">Organismo de financiamiento</label>
							</div>
						</div>{/*cierra ---> container_left*/}

						<div className="container_left">
							<div className="input-number">
								<DatePicker
									id="dp_control"
									name="csp_6_fecha_control_infra"
									title="Fecha de control"
									dateFormat="dd/MM/yyyy"
									className="form-control input_data_picker"
									selected={csp_6_fecha_control_infra}
									//onChange={handleFecha_CSP6}
									onChange={(date) => handleChangeDate({ name: "csp_6_fecha_control_infra", value: date })}
								/>
								<label alt="Fecha de control" for="dp_control">Fecha de control</label>
							</div>
						</div>

						<div className="container_left" style={{ height: "auto", marginTop: "23px" }}>{/*---> container_left*/}
							<div className="input-container">
								<textarea
									title="Descripción Obras de Infraestructura financiadas"
									type="text"
									id="id_csp_7"
									maxLength="500"
									name="csp_7_descripcion_obras_infra"
									//value={csp_7_descripcion_obras_infra}
									onChange={e => onChange(e)}
								/>
								<label alt="Descripción Obras de Infraestructura financiadas" for="id_csp_7">Descripción Obras de Infraestructura financiadas</label>
							</div>
						</div>

					</div> {/*cierra id="div_hidden"*/}

					{/*<div className="container_left">
								<h6 className="mt-4 text-left subtitulos">FINANCIAMIENTO DE VIVIENDAS</h6>
							</div>*/}

					<div className="container_left">{/*---> container_left*/}
						<Form.Check type="checkbox">
							<Form.Check.Input name="csp_8_financiamiento_publico_obras_viv" checked={csp_8_financiamiento_publico_obras_viv} type="checkbox" onChange={e => onChange(e)} />
							<Form.Check.Label>{`Financiamiento público de viviendas`}</Form.Check.Label>
						</Form.Check>
					</div>{/*cierra ---> container_left*/}

					<div id="div_hidden2" style={divVisible2 === true ? { display: 'block' } : { display: 'none' }}>
						<div className="container_left">{/*---> container_left*/}
							<div className="input-container">
								<SelectInput
									name="csp_9_jurisdiccion_viv"
									id="id_csp_9"
									onChange={e => onChange(e)}
									//defaultValue={csp_9_jurisdiccion_viv}
									options={optionsJurisdiccionCSP}
									className="form-control my-3"
								/>
								<label alt="Jurisdicción" for="id_csp_9">Jurisdicción</label>
							</div>
						</div>{/*cierra ---> container_left*/}

						<div className="container_left" style={{ height: "auto", marginTop: "23px" }}>{/*---> container_left*/}
							<div className="input-container">
								<textarea
									title="Organismo de financiamiento"
									type="text"
									id="id_csp_10"
									maxLength="500"
									name="csp_10_organismo_financiamiento_viv"
									//value={csp_10_organismo_financiamiento_viv}
									onChange={e => onChange(e)}
								//className="form-control my-3"
								/>
								<label alt="Organismo de financiamiento" for="id_csp_10">Organismo de financiamiento</label>
							</div>
						</div>{/*cierra ---> container_left*/}

						<div className="container_left">
							<div className="input-number">
								<DatePicker
									id="id_csp_11"
									title="Fecha de control"
									name="csp_11_fecha_control_viv"
									dateFormat="dd/MM/yyyy"
									className="form-control input_data_picker"
									selected={csp_11_fecha_control_viv}
									onChange={(date) => handleChangeDate({ name: "csp_11_fecha_control_viv", value: date })}
								/>
								<label alt="Fecha de control" for="id_csp_11">Fecha de control</label>
							</div>
						</div>{/*cierra container_left NUEVO */}

						<div className="container_left" style={{ minHeight: "100px", height: "auto", marginTop: "23px" }}>{/*---> container_left*/}
							<div className="input-container">
								<textarea
									title="Descripción obras de infraestructura financiadas"
									type="text"
									id="id_csp_12"
									maxLength="500"
									name="csp_12_descripcion_obras_viv"
									//value={csp_12_descripcion_obras_viv}
									onChange={e => onChange(e)}
								/>
								<label alt="Descripción obras de infraestructura financiadas" for="id_csp_12">Descripción Obras de Infraestructura financiadas</label>
							</div>
						</div>{/*cierra container_left NUEVO */}
					</div>{/*cierra ---> div_hidden2*/}

					{/*<div className="container_left">
								<h6 className="mt-4 text-left subtitulos">FINANCIAMIENTO DE EQUIPAMIENTOS COMUNITARIOS</h6>
							</div>*/}

					<div className="container_left">{/*---> container_left*/}
						<Form.Check type="checkbox">
							<Form.Check.Input name="csp_13_financiamiento_publico_obras_equip" checked={csp_13_financiamiento_publico_obras_equip} type="checkbox" onChange={e => onChange(e)} />
							<Form.Check.Label>{`Financiamiento público de equipamientos comunitarios`}</Form.Check.Label>
						</Form.Check>
					</div>{/*cierra ---> container_left*/}

					<div id="div_hidden3" style={divVisible3 === true ? { display: 'block' } : { display: 'none' }}>
						<div className="container_left">{/*---> container_left*/}
							<div className="input-container">
								<SelectInput
									name="csp_14_jurisdiccion_equip"
									id="id_csp_14"
									onChange={e => onChange(e)}
									//defaultValue={csp_14_jurisdiccion_equip}
									options={optionsJurisdiccionCSP}
									className="form-control my-3"
								/>
								<label alt="Jurisdicción" for="id_csp_14">Jurisdicción</label>
							</div>
						</div>{/*cierra ---> container_left*/}

						<div className="container_left" style={{ height: "126px", marginTop: "23px" }}>{/*---> container_left*/}
							<div className="input-container">
								<textarea
									title="Organismo de financiamiento"
									type="text"
									name="csp_15_organismo_financiamiento_equip"
									id="id_csp_15"
									maxLength="500"
									//value={csp_15_organismo_financiamiento_equip}
									onChange={e => onChange(e)}
								//className="form-control my-3"
								/>
								<label alt="Organismo de financiamiento" for="id_csp_15">Organismo de financiamiento</label>
							</div>
						</div>{/*cierra ---> container_left*/}

						<div className="container_left">
							<div className="input-number">
								<DatePicker
									id="id_csp_16"
									title="Fecha de control"
									name="csp_16_fecha_control_equip"
									dateFormat="dd/MM/yyyy"
									className="form-control input_data_picker"
									selected={csp_16_fecha_control_equip}
									onChange={(date) => handleChangeDate({ name: "csp_16_fecha_control_equip", value: date })}
								/>
								<label alt="Fecha de control" for="id_csp_16">Fecha de control</label>
							</div>
						</div>{/*cierra container_left NUEVO */}

						<div className="container_left" style={{ height: "126px", marginTop: "23px" }}>{/*---> container_left*/}
							<div className="input-container">
								<textarea
									title="Descripción obras de infraestructura financiadas"
									type="text"
									id="id_csp_17"
									maxLength="500"
									name="csp_17_descripcion_obras_equip"
									//value={csp_17_descripcion_obras_equip}
									onChange={e => onChange(e)}
								/>
								<label alt="Descripción obras de infraestructura financiadas" for="id_csp_17">Descripción Obras de Infraestructura financiadas</label>
							</div>
						</div>
					</div>{/*cierra ---> div_hidden3*/}

					{/* -----SEGUIMIENTO cambios  ----*/}
					<div className="container_left">
						<h6 className="mt-4 text-left subtitulos">SEGUIMIENTO DE OBRAS Y EDIFICACIÓN</h6>
					</div>
					<div className="container_left">{/*---> container_left*/}
						<div className="input-container">
							<SelectInput
								name="csp_28_presencia_apertura_calles"
								id="id_csp_28"
								//defaultValue={csp_14_jurisdiccion_equip}
								options={optionsSeguimientoCSP}
								className="form-control my-3"
								onChange={e => onChange(e)}
							/>
							<label alt="Presencia de apertura de calles" for="id_csp_28">Presencia de apertura de calles</label>
						</div>
					</div>

					<div className="container_left">{/*---> container_left*/}
						<div className="input-number-45">
							<input title="Parcelas edificadas" type="number" name="csp_29_parc_edificadas" id="id_parc_edificadas_csp_29" onChange={e => onChange(e)} /> {/*value={cantidad_lotes}*/}
							<label alt="Parcelas edificadas" for="id_parc_edificadas_csp_29">Parcelas edificadas</label>
						</div>
						<div style={{ width: "4%" }}></div>
						<div className="input-number-45">
							<input
								title="Porcentaje parcelas edificadas"
								id="id_csp_30"
								type="number"
								name="csp_30_porcentaje_parc_edificadas"
								step="0.01"
								value={csp_30_porcentaje_parc_edificadas}
								onChange={e => onChange(e)}
								disabled
							/>
							<label alt="Porcentaje parcelas edificadas" for="id_csp_30">Porcentaje parcelas edificadas</label>
						</div>{/*cierra ---> input-number-45 */}
					</div>
					<p align="left" style={mensajeError === true ? { height: "auto", fontSize: "0.9em", color: "red" } : { display: 'none' }}>ERROR: La cant. de parcelas edificadas no puede superar la cant.de lotes adjudicables ({lotesAdjudicables})</p>

					<div className="container_left" style={{ height: "auto" }}>{/*---> container_left*/}
						<div className="input-container">{/*ref={textAreaObs_csp_27}*/}
							<textarea
								title="Observaciones sobre el seguimientos de obras y edificación"
								id="id_csp_31"
								type="text"
								maxLength="500"
								name="csp_31_observ_seguimiento_obras_y_edificacion"
								onChange={e => onChange(e)}
								rows={5}
							/>
							<label alt="Observaciones sobre el seguimientos de obras y edificación" for="id_csp_31">Observaciones sobre el seguimientos de obras y edificación</label>
							<p align="right" style={{ height: "5px", fontSize: "0.9em" }}>Caracteres restantes: {maxObserv_31}</p>
						</div>
					</div>

					<div className="container_left">{/*---> container_left*/}
						<div className="input-number">
							<DatePicker
								id="id_csp_32"
								title="Fecha de control"
								name="csp_32_fecha_control_seguimiento_obras"
								dateFormat="MM/yyyy"
								className="form-control input_data_picker"
								selected={csp_32_fecha_control_seguimiento_obras}
								onChange={(date) => handleChangeDate({ name: "csp_32_fecha_control_seguimiento_obras", value: date })}
							/>
							<label alt="Fecha de control" for="id_csp_32">Fecha de control</label>
						</div>
					</div>


				</div>
			</div>
		</Fragment>
	)
};

export default Pestaña3_Exp;
