import React, {Fragment, useState, useEffect } from 'react';
import { Collapse, Row, Col } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import addDays from 'date-fns/addDays';

import '../../table_report.css';

import Select from 'react-select';

import dotenv from "dotenv";

import TablaInf1_en_proceso from './tablaInf1_en_proceso';
import TablaInf1_en_procesoTotal from './tablaInf1_en_procesoTotal';
import TablaInf1_finalizados from './tablaInf1_finalizados';
import TablaInf1_finalizadosTotal from './tablaInf1_finalizadosTotal';
//import LeafletMap from "./map";

//const baseURL = process.env.REACT_APP_API_URL;

const Date_Report = () => {

	const [datosS, setDatos] = useState(null);//Estado para 'en proceso' ()
	const [datosRP1_en_procesoTotal, setDatosRP1_en_procesoTotal] = useState(null);
	const [datosRP1_finalizados, setDatosRP1_finalizados] = useState(null);
	const [datosRP1_finalizadosTotal, setDatosRP1_finalizadosTotal]= useState(null);
	//console.log(datosRP1_en_procesoTotal);
	const [loading, setLoading] = useState(true);

	//01-08-2022 - agregado: poner fecha por default
	//04-abr-2024: tenía esta fecha (2014-01-02) para que muestre 01-01-2014
	const [startDate, setStartDate] = useState(new Date('2014-01-02'));
  	const [endDate, setEndDate] = useState(null);

	let _fechaInicio = null;
	if (startDate === null) {
		_fechaInicio = null;
	} else if (startDate !== null || startDate !== ""){
		_fechaInicio = new Date(startDate);
		_fechaInicio.setMinutes(_fechaInicio.getMinutes() + _fechaInicio.getTimezoneOffset())
	}

	//01-08-2022: agregado. Cuando la fecha de inicio cambia, resetear la fecha final
	useEffect(()=>{
		setEndDate(null);
	}, [startDate]);

  	const onChange = (dates) => {
    	const [start, end] = dates;
    	setStartDate(start);
    	setEndDate(end);
  	};
	
	//****useEffect en proceso (por municipio y totales)
	useEffect(()=>{
		
		if(endDate !== null){
			getDate(startDate, endDate);
		} else {
			setDatos(null);
		}

	}, [endDate]); //quitar dateRange

	useEffect(()=>{
		//console.log(dateRange[0]);
		//console.log(dateRange[1]);
		if(endDate !== null){
			getDateRP1_en_procesoTotal(startDate, endDate);
		} else {
			setDatosRP1_en_procesoTotal(null);
		}
	}, [endDate]);

	//****useEffect finalizados (por municipio y totales)
	useEffect(()=>{
		//console.log(dateRange[0]);
		//console.log(dateRange[1]);
		if(endDate !== null){
			getDateRP1_finalizados(startDate, endDate);
		} else {
				setDatosRP1_finalizados(null);
		}

	}, [endDate]);

	useEffect(()=>{

		if(endDate !== null){
			getDateRP1_finalizadosTotal(startDate, endDate);
		} else {
			setDatosRP1_finalizadosTotal(null);
		}
	}, [endDate]);


		//*** funciones para en proceso (por municipio y totales)
	const getDate = async (startDate, endDate) => {
		//console.log(startDate);
		//console.log(endDate);
		const urlReporte = `/api/api_serv/reportes/reporte1/?start=${startDate}&end=${endDate}`;

		const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token };

		const res = await fetch(urlReporte,{ headers })
		.then(res => res.json())
		.then(res => setDatos(res))
		.catch(err => console.log(err))
		.finally(() => setLoading(false));
	}

	const getDateRP1_en_procesoTotal = async (startDate, endDate) => {
		//console.log("EJECUTA la FUNCION");
		//console.log(startDate);
		//console.log(endDate);
		const urlRP1_en_procesoTotal = `/api/api_serv/reportes/reporte1_en_procesoTotal/?start=${startDate}&end=${endDate}`;
		const headers = {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			jwt_token: localStorage.token
		};
		const res = await fetch(urlRP1_en_procesoTotal,{ headers })
		.then(res => res.json())
		.then(res => setDatosRP1_en_procesoTotal(res))
		.catch(err => console.log(err));
		//.finally(() => setLoading(false));
	}

	//*** funciones para finalizados (por municipio y totales)
	const getDateRP1_finalizados = async (startDate, endDate) => {
		const urlRP1_Finalizados = `/api/api_serv/reportes/reporte1_Finalizados/?start=${startDate}&end=${endDate}`;
		const headers = {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			jwt_token: localStorage.token
		};
		const res = await fetch(urlRP1_Finalizados,{ headers })
		.then(res => res.json())
		.then(res => setDatosRP1_finalizados(res))
		.catch(err => console.log(err));
	}

	const getDateRP1_finalizadosTotal = async (startDate, endDate) => {
		const urlRP1_finalizadosTotal = `/api/api_serv/reportes/reporte1_FinalizadosTotal/?start=${startDate}&end=${endDate}`;
		const headers = {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			jwt_token: localStorage.token
		};
		const res = await fetch(urlRP1_finalizadosTotal,{ headers })
		.then(res => res.json())
		.then(res => setDatosRP1_finalizadosTotal(res))
		.catch(err => console.log(err));
	}


	const tableReporte1 = () => { //***Crear tabla desde funcion y no desde componente
		//console.log(endDate);
		if (endDate === null) {
			return(
				<p>Seleccionar rango de fechas...</p>
			)
		} else if(endDate !== null) {
			return (
				<Fragment>
					<h5>Proyectos y lotes iniciados por municipio</h5>
					<tbody>
		        <tr>
		          <th>Partido</th>
		          <th>Cantidad lotes</th>
		          <th>Proyectos</th>
		        </tr>
						{datosS.map((item, i) => (
	            <tr key={i}>
		            <td>{item.partido_nombre}</td>
		            <td>{item.sum}</td>
		            <td>{item.count}</td>
	            </tr>
            ))}
					</tbody>
				</Fragment>
			)
		}
	}//** cierra tableReporte1

  return (
		<Fragment>
			<div id="id_selector_fecha" className="container_row">

				<div id="row_B1" className="flexbox-row">

					<div style={{width:"25%"}} className="container_inicio"></div>{/*div vacio*/}

					<div style={{width:"25%"}} className="container_inicio">
						<div style={{alignItems:"right", width:"95%"}} >
							<p className="subtitulos">Desde</p>
						</div>
						<div className="input-container">
							<DatePicker
								name="intervalo_fecha"
								selected={_fechaInicio}
								className="form-control"
								id="fecha_desde"
								onChange={(date) => setStartDate(date)}
								startDate={startDate}
								endDate={endDate}
								locale="es-AR"
								dateFormat="MM/yyyy"
								isClearable={true}
								required
							/>
						</div>
					</div>

					<div style={{width:"25%"}} className="container_inicio">
						<div style={{alignItems:"right", width:"95%"}} >
							<p className="subtitulos">Hasta</p>
						</div>
						<div className="input-container">
							<DatePicker
								style={{width:"80%"}}
								name="intervalo_fecha_end"
								selected={endDate}
								className="form-control"
								//onChange={ (date) => handleChangeDate(date) }
								onChange={(date) => setEndDate(date)}
								startDate={startDate}
								endDate={endDate}
								locale="es-AR"
								dateFormat="MM/yyyy"
								isClearable={true}
							/>
						</div>
					</div>

					<div style={{width:"25%"}} className="container_inicio"></div>{/*div vacio*/}
				</div>
			</div>



			{/*<br/>
			<Row className="g-5">
				<Col></Col>
				<Col></Col>
				<Col>Desde</Col>
				<Col>
			    <DatePicker
						name="intervalo_fecha"
			      selected={startDate}
						onChange={(date) => setStartDate(date)}
			      startDate={startDate}
			      endDate={endDate}
						locale="es-AR"
						dateFormat="dd/MM/yyyy"
						isClearable={true}
						//shouldCloseOnSelect={false}
			    />
				</Col>
				<Col></Col>
				<Col></Col>
				<Col></Col>
			</Row>
			<Row className="g-5">
				<Col></Col>
				<Col></Col>
				<Col>Hasta</Col>
				<Col>
					<DatePicker
						name="intervalo_fecha_end"
						selected={endDate}
						//onChange={ (date) => handleChangeDate(date) }
						onChange={(date) => setEndDate(date)}
						startDate={startDate}
						endDate={endDate}
						locale="es-AR"
						dateFormat="dd/MM/yyyy"
						isClearable={true}
						//shouldCloseOnSelect={false}
					/>
				</Col>
				<Col></Col>
				<Col></Col>
				<Col></Col>
			</Row>*/}

			<div id="id_tablas" className="container_row">

				<div id="informes_col1" className="container_div_column">{/*columna1*/}

					<p className="subtitulos">Iniciados (según fecha de caratulación)</p>
					<TablaInf1_en_procesoTotal datosRP1_en_procesoTotal={datosRP1_en_procesoTotal}/>
					<TablaInf1_en_proceso datosRP1_en_proceso={datosS}/>

				</div>

				<div id="informes_col2" className="container_div_column">{/*columna1*/}
					<p className="subtitulos">Lotes generados (afectados y  rectificados)</p> {/*cambio 14-03-24 -Afectados con resolución (según fecha de resolución)*/}
					<TablaInf1_finalizadosTotal datosRP1_finalizadosTotal={datosRP1_finalizadosTotal}/>
					<TablaInf1_finalizados datosRP1_finalizados={datosRP1_finalizados}/>
				</div>

			</div>{/*cierra id_tablas*/}


		</Fragment>
  );
}

export default Date_Report;
