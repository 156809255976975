import React, {Fragment,  useState, useEffect, useContext} from "react";
import { SessionContext } from '../context/sessionContext';
//import { useHistory } from 'react-router-dom';

//import Table from 'react-bootstrap/Table';
import { Table, Button } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
//import EditExpediente from "./editExpediente";
import EditExpediente_Update from "./editExpediente_Update";
import Informe from "./reportes/informe";

///const TablaExp = ({ editValue, edit, setEdit, loading, datosS, ...props})=> {
//role
const TablaExp = ({ edit, setEdit, loading, datosS, setDatos, viewInforme, setViewInforme, ...props})=> {

	const { getProfile, cambioOriginal, sesionT } = useContext(SessionContext);

	/*useEffect(() => {
    		// copio la lista con [...list] y la ordeno con sort()
		const list = datosS.features[0];
		//console.log(list);
    		const sortedList = [...list].sort((a, b) => (a.expediente > b.expediente ? 1 : a.expediente < b.expediente ? -1 : 0))
    		// actualizo el estado con la nueva lista ya ordenada
		//console.log(sortedList);
    		setDatos(sortedList)
  	}, [])*/


	//**17-nov**Prueba ordenar datos********
	/*compareBy(key, ascending) {
    		let reverse = ascending ? 1 : -1;
    		return function (a, b) {
      			if (a[key] < b[key]) return -1 * reverse;
      			if (a[key] > b[key]) return 1 * reverse;
      			return 0;
    		};
  	}*/

	/*const sort_lists = (key, list, inverse) =>
  	inverse
    	? [...list].sort((b, a) => (a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0))
    	: [...list].sort((a, b) => (a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0))*/

	const [orderExp, setOrderExp]= useState(null);
	const [orderPartido, setOrderPartido]= useState(null);
	const [orderAlias, setOrderAlias]= useState(null);
	const [orderPrograma, setOrderPrograma]= useState(null);
	const [orderTipo, setOrderTipo] = useState(null);
	const [orderEstado, setOrderEstado] = useState(null);
	const [orderPrioritario, setOrderPrioritario] = useState(null);

	///console.log(orderExp);

	//SOLO PRUEBA, no cumple función en la app
	const sort_lists = (key, list ) => {
		//console.log(key);
		//console.log(list);
		return [...list].sort((b, a) => (a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0))
        //: [...list].sort((a, b) => (a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0))
	}


	const [sortedField, setSortedField] = useState(null);//campo por el que se ordena

	const [sortedProducts, setSortedProducts]= useState(null);

	useEffect(() => {
		//console.log("cambio order de columna Exp");
		if(datosS.features.length > 0){
			const list = datosS.features[0];
			let sortedList = [...list];
			if( orderExp === 'asc'){
				let sortedListN = sortedList.sort((a, b) => (a.expediente > b.expediente ? 1 : a.expediente < b.expediente ? -1 : 0))
				setDatos({features: [sortedListN]});
			} else {
				let sortedListN = sortedList.sort((a, b) => (b.expediente > a.expediente ? 1 : b.expediente < a.expediente ? -1 : 0))
				setDatos({features: [sortedListN]});
			}
		}
	}, [orderExp])

	useEffect(() => {
	  //console.log("cambio order de columna Partido");
	  if(datosS.features.length > 0){
	    const list = datosS.features[0];
	    let sortedList = [...list];
	    if( orderPartido === 'asc'){
        let sortedListN = sortedList.sort((a, b) => (a.partido_nombre > b.partido_nombre ? 1 : a.partido_nombre < b.partido_nombre ? -1 : 0))
        setDatos({features: [sortedListN]});
	    } else {
        let sortedListN = sortedList.sort((a, b) => (b.partido_nombre > a.partido_nombre ? 1 : b.partido_nombre < a.partido_nombre ? -1 : 0))
        setDatos({features: [sortedListN]});
	    }
	  }
  }, [orderPartido])

	useEffect(() => {
                //console.log("cambio order de columna Alias");
                if(datosS.features.length > 0){
                        const list = datosS.features[0];
                        let sortedList = [...list];
                        if( orderAlias === 'asc'){
                                let sortedListN = sortedList.sort((a, b) => (a.nombre_proyecto > b.nombre_proyecto ? 1 : a.nombre_proyecto < b.nombre_proyecto ? -1 : 0))
                                setDatos({features: [sortedListN]});
                        } else {
                                let sortedListN = sortedList.sort((a, b) => (b.nombre_proyecto > a.nombre_proyecto ? 1 : b.nombre_proyecto < a.nombre_proyecto ? -1 : 0))
                                setDatos({features: [sortedListN]});
                        }
                }
        }, [orderAlias])

	useEffect(() => {
                //console.log("cambio order de columna Programa");
                if(datosS.features.length > 0){
                        const list = datosS.features[0];
                        let sortedList = [...list];
                        if( orderPrograma === 'asc'){
                                let sortedListN = sortedList.sort((a, b) => (a.programa > b.programa ? 1 : a.programa < b.programa ? -1 : 0))
                                setDatos({features: [sortedListN]});
                        } else {
                                let sortedListN = sortedList.sort((a, b) => (b.programa > a.programa ? 1 : b.programa < a.programa ? -1 : 0))
                                setDatos({features: [sortedListN]});
                        }
                }
        }, [orderPrograma])

	useEffect(() => {
                //console.log("cambio order de columna Tipo Operatoria");
                if(datosS.features.length > 0){
                        const list = datosS.features[0];
                        let sortedList = [...list];
                        if( orderTipo === 'asc'){
                                let sortedListN = sortedList.sort((a, b) => (a.tipo > b.tipo ? 1 : a.tipo < b.tipo ? -1 : 0))
                                setDatos({features: [sortedListN]});
                        } else {
                                let sortedListN = sortedList.sort((a, b) => (b.tipo > a.tipo ? 1 : b.tipo < a.tipo ? -1 : 0))
                                setDatos({features: [sortedListN]});
                        }
                }
        }, [orderTipo])

	useEffect(() => {
                //console.log("cambio order de columna Estado");
                if(datosS.features.length > 0){
                        const list = datosS.features[0];
                        let sortedList = [...list];
                        if( orderEstado === 'asc'){
                                let sortedListN = sortedList.sort((a, b) => (a.estado > b.estado ? 1 : a.estado < b.estado ? -1 : 0))
                                setDatos({features: [sortedListN]});
                        } else {
                                let sortedListN = sortedList.sort((a, b) => (b.estado > a.estado ? 1 : b.estado < a.estado ? -1 : 0))
                                setDatos({features: [sortedListN]});
                        }
                }
        }, [orderEstado])

	useEffect(() => {
	  //console.log("cambio order de columna Prioritario");
	  if(datosS.features.length > 0){
	          const list = datosS.features[0];
	          let sortedList = [...list];
	          if( orderPrioritario === 'asc'){
	                  let sortedListN = sortedList.sort((a, b) => (a.tramite_prioritario > b.tramite_prioritario ? 1 : a.tramite_prioritario < b.tramite_prioritario ? -1 : 0))
	                  setDatos({features: [sortedListN]});
	          } else {
	                  let sortedListN = sortedList.sort((a, b) => (b.tramite_prioritario > a.tramite_prioritario ? 1 : b.tramite_prioritario < a.tramite_prioritario ? -1 : 0))
	                  setDatos({features: [sortedListN]});
	          }
	  }
  }, [orderPrioritario])


	useEffect(() => {
		//console.log("cambio columna de orden")
		if(sortedField != null){
			//console.log(sortedField.columna);
			let column = sortedField.columna;

			if(column === 'Expediente' && orderExp === 'asc'){
				setOrderExp('desc');
			} else {
				setOrderExp('asc');
			}

			if(column === 'Partido' && orderPartido === 'asc'){
                                setOrderPartido('desc');
                        } else {
                                setOrderPartido('asc');
                        }

			if(column === 'Alias' && orderAlias === 'asc'){
                                setOrderAlias('desc');
                        } else {
                                setOrderAlias('asc');
                        }

			if(column === 'Programa' && orderPrograma === 'asc'){
                                setOrderPrograma('desc');
                        } else {
                                setOrderPrograma('asc');
                        }

			if(column === 'Tipo Operatoria' && orderTipo === 'asc'){
                                setOrderTipo('desc');
                        } else {
                                setOrderTipo('asc');
                        }

			if(column === 'Estado' && orderEstado === 'asc'){
                                setOrderEstado('desc');
                        } else {
                                setOrderEstado('asc');
                        }

			if(column === 'Prioritario' && orderPrioritario === 'asc'){
                                setOrderPrioritario('desc');
                        } else {
                                setOrderPrioritario('asc');
                        }
		}

		//setDatos({features: [sortedListN]});
	}, [sortedField])

	/*if(datosS.features.length > 0){

		//setSortedProducts(datosS.features[0]);
		//console.log(sortedProducts);
	}*/

	/*if (sortedField !== null) {
    		sortedProducts.sort((a, b) => {
      	if (a[sortedField] < b[sortedField]) {
        	return -1;
      	}
      	if (a[sortedField] > b[sortedField]) {
        	return 1;
      	}
      	return 0;
    		});
  	}*/

	/*const [datosInicial, setDatosInicial] = useState(datosS);
	//console.log(datosInicial);
	const sort_lists = (key, list, inverse) =>
  	inverse
    	? [...datosInicial].sort((b, a) => (a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0))
    	: [...datosInicial].sort((a, b) => (a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0))

	const [datosInicial, setDatosInicial] = useState(datosS);

	useEffect(() => {
    		setDatosInicial(sort_lists('expediente', datosInicial))
  	}, [])*/

	//--17-nov--

	//**18-nov**Pagination
	//- count Cantidad de registros total ---> sale de datosS
	//- pageSize - cantidad de items por pagina ---> 3, 6, 9 --> definir en el codigo
	//-Cantidad de paginas ---> total ("count") / cantidad items (pageSize)

	/*useEffect(() => {
		//console.log("Cambiar registros que se muestran");
	}, [pageSize])*/

	const [page, setPage] = useState(1);//pagina donde está posicionado, por default 1
	const [pageSize, setPageSize] = useState(10);//cantidad de registros por pagina. Inicial en 3, puede cambiar a 6 o 9.
	const [count, setCount] = useState(0);// Cantidad de paginas.  ---> Total registros / resgistros por pagina
	const pageSizes = [10];//[3, 6, 9];

	//console.log(count);

	useEffect(() => {
  		actualizarCount()
  	}, [datosS])

	useEffect(() => {
  		actualizarCantPaginas()
  	}, [page, pageSize])

	const actualizarCantPaginas = () => {
		//console.log("cambio page o pageSize");
		if(datosS.features.length > 0){
			setCount(Math.ceil(datosS.features[0].length / pageSize));
			//setPage(1);
		}
	}

	const actualizarCount = () =>{
		//console.log("actualizar counts");
		if(datosS.features.length > 0){
			//console.log(datosS.features[0].length);
			setCount(Math.ceil(datosS.features[0].length / pageSize));
			setPage(1);// se agrega para ir siempre a la primer pagina, cuando hay un cambio de datos
		}
  }

	const handlePageSizeChange = (event) => {
		setPageSize(event.target.value);
		setPage(1);//si cambia la cantidad de items, colocar siempre en la primer pagina
  };

	const handlePageChange = (event, value) => {
		//console.log("cambio de pagina");
	  setPage(value);
		setCount(count);
  };

	const rowInforme = (registro, index)=>{
		setEdit(false);
		setViewInforme(true);
		setRegistroEdicion(registro);
	}

	//---cierra Pagination----


	//**#1 - recuperar los datos desde la base (En principio todos)
	//**#2 - con los datos crear la estructura de la tabla.

	//**1 - necesito una url
	//const [datosS, setDatos] = useState({features: []});

	//** definir estado para loading
	//const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const [idEdicion, setIdEdicion] = useState(null);

	const [registroEdicion, setRegistroEdicion] = useState(null);
	
	//quitar "Tipo operatoria" - 29-nov2022
	const columnas = ["ID exp.", "Expediente", "Partido", "Alias", "Programa", "Estado", "Nomenclatura síntesis ", "Cant. de lotes"];

  	const getHeader = (columnas)=>{
		if(datosS.features.length > 0){

			{/*return <th key="1a" style={{width: "100px"}} onClick={() => setSortedField("ID exp")}>ID exp</th>*/}

			return columnas.map((columna, index) => {
				return <th key={index} onClick={() => setSortedField({columna})}>{columna}</th>
			})
		}
  	}
	/*<button type="button" >+ </button>*/

  	const getRowsData = (datosS, pageSize, page) => {

		if(datosS.features.length > 0){

			const endPointer = pageSize * page;
			const startPointer = endPointer - pageSize;
			//console.log(startPointer);
			//console.log(endPointer);

			var newDatos = datosS.features[0].slice(startPointer, endPointer);
			//console.log(newDatos); //25-FEB-2022: LOS registros a mostrar en la tabla --> hacer doble click en uno para editar

			//return datosS.features[0].map(registro => {
			return newDatos.map((registro, i) => {

				var idTR = registro.id;
				var id_Exp = registro.id_exp_prueba;
				var fecha_carga = registro.fecha_carga;
				//var encargado = registro.encargado;
				var datos_05_responsable = registro.datos_05_responsable;
				var expediente = registro.expediente;
				var partido = registro.partido_nombre;
				var alias = registro.nombre_proyecto;
				var programa = registro.programa;
				//var tipo_operatoria = registro.tipo;
				var estado = registro.estado;
				var nomenclatura = registro.datos_18_sintesis_nomenclatura;
				var cantidad_lotes = registro.cantidad_lotes;				
				//var prioritario = registro.tramite_prioritario;

				var rowClick = async (e)=>{
					e.preventDefault();
					//console.log('double click row');
					const idSelectTR = e.target.parentElement.id;
					//console.log(e.target);

					try{
						const x = 'double click';
						await getProfile(x);
						//console.log("tablaExp 2 - sesionT", sesionT);

						if(sesionT === 'no_token'){
							//console.log("no_token ----> volver a loguear");
							alert('volver a loguear');
									setEdit(false);
						} else if( sesionT === 'Token valido'){
							//console.log("414--> ","token valido" );
							if(registro ===null || registro ===undefined){
								//console.log("NO TIENE DATOS");
							} else {
								setRegistroEdicion(registro);
								//console.log("Tiene DATOS");
								setEdit(true); //CAMBIAR ESTADO EDIT ---> true
								cambioOriginal(0); //12-11-2022 - al ingresar al form de edicion, cambiar el estado de los buttons de guardar a desactivado
								setViewInforme(false);
							}

						}
					} catch(err){
						console.error(err);
					}


				}

				return <tr id={idTR} onDoubleClick={rowClick} >
						<td>{id_Exp}</td>
						<td>{expediente}</td>
						<td>{partido}</td>
						<td>{alias}</td>
						<td>{programa}</td>
						{/*<td>{tipo_operatoria}</td>*/}
						<td>{estado}</td>
						<td>{nomenclatura}</td>
						<td>{cantidad_lotes}</td>
						{/*<td>{prioritario}</td>*/}
						{/* <td><Button>informe</Button></td> */}
						{<td><Button onClick={() => rowInforme(registro, i)} >informe</Button></td>}
					</tr>;
			})
		}
	} //cierra function---->getRowsData

	const cancelEdit = (e) => {
		e.preventDefault();
		//console.log(e);
		if(edit){
			setEdit(false);
			cambioOriginal(0);
		} else if(viewInforme){
			setViewInforme(false);
		}
	}

	/*const estadoEdit = (estado)=>{
		setEdit(estado)
	}*/

	if (loading === true) {
		return "Loading...";
	} else if (error) {
		return "Error!";
	//} else {
	} else if (loading === false && edit === false){
		if (viewInforme){
			//console.log("SEEEE");
			return (
				<>
					<button type="button" className="btn btn-success btn-block" onClick={cancelEdit}>Cancel</button>
					<Informe registro={registroEdicion}/>
				</>
			)
		} else {

		return(
			<>
				<div id="tabla-expediente" style={{ border:"4px" }} className="">

					<div  className="flexbox-row">
						<div className="container">
							<h4 className="mt-5 text-center subtitulos">Tabla Expedientes</h4><br />
						</div>
					</div>

					<div className="flexbox-row">
						<div className="container_inicio"></div>
						<div className="container_inicio" style={{display:"none", align:"left"}}>
							{"Items por Página: "}
							<select onChange={handlePageSizeChange} style={{ color:"rgb(131,131,131)" }} value={pageSize}>
								{pageSizes.map((size) => (
									<option key={size} value={size}>
									{size}
									</option>
								))}
							</select>
						</div>
						<div className="container_inicio"></div>
					</div>

					<div id="itemsp_pagina" className="flexbox-row">
						<div className="container_inicio"></div>	{/* DIV para ocupar espacio*/}
						<div className="container_inicio">
							<Pagination
								count={count}
								page={page}
								style={{ color:"rgb(131,131,131)" }}
								variant="outlined"
								shape="rounded"
								onChange={handlePageChange}
							/>
						</div>
						<div className="container_inicio"></div> {/* DIV para ocupar espacio*/}
					</div>{/* cierra id itemsp_pagina */}

				 	{/* DIV PARA LA TABLA */}
					<div  className="flexbox-row"> {/*  className="col-md-8 offset-md-1" */}
						<div> {/* className="table-responsive" */}
							{/* <Table style={{ transform: "scale(0.8)" }} stripped bordered hover responsive="md" > */}
							<Table className="tableExp" stripped bordered hover responsive="md" >
								{/*<caption />*/}
								<thead >
									<tr>{getHeader(columnas)}</tr> {/*style={{'backgroundColor':'rgb(0,174,195)', 'color':'white'}}*/}{/*backgroundColor:"cyan"*/}
								</thead>
								<tbody>
									{getRowsData(datosS, pageSize, page)}
								</tbody>
							</Table>
						</div>

					</div>

				</div> {/* cierra div id=tabla-expediente */}
			</>
		);
		}
	} else if (edit === true) {

		return (
			<>
				{edit ? (
					<>
						<div id="id_button_cancel" style={{height: "0px", background:"white", position: "sticky", top: "2px"}} className="">
							<button type="button" className="btn btn-success btn-block button_verde" onClick={cancelEdit}>Cancelar</button> {/*backgroundColor: "#592673", border:"#592673"*/}
						</div>

						<EditExpediente_Update registro={registroEdicion} edit={edit} setEdit={setEdit} /> {/*role={role}*/}

					</>
				) : null}
			</>
		)
	} /*else if (viewInforme2){
		return (
			<>
			{//console.log("MOSTRAR INFORME")}
			</>
		)
	}*/



		/*//console.log(idEdicion);
		return(
			<Fragment>
				<div className="container">
					<button type="button" className="btn btn-success btn-block" onClick={cancelEdit}>Cancel</button>
					<EditExpediente_Update registro={registroEdicion} edit={edit} setEdit={setEdit} />
				</div>
			</Fragment>
		);
	} else if (edit === false && viewInforme === true){
		//console.log("MOSTRAR INFORME ---> crear componente y pasarle los datos del row");
		return (
			<Fragment>
				<div className="container">
					<button type="button" className="btn btn-success btn-block" >Cancel</button>

					<Reporte_3 />

				</div>
			</Fragment>
		);
	}*/

}

export default TablaExp;

/*
} else if (edit === true)
	//console.log(idEdicion);
	return(
		<Fragment>
			<div className="container">
				<button type="button" className="btn btn-success btn-block" onClick={cancelEdit}>Cancel</button>
				<EditExpediente_Update registro={registroEdicion} edit={edit} setEdit={setEdit} />
			</div>
		</Fragment>
	);
} else if (edit === false && viewInforme === true){
	//console.log("MOSTRAR INFORME ---> crear componente y pasarle los datos del row");
	return (
		<Fragment>
			<div className="container">
				<button type="button" className="btn btn-success btn-block" >Cancel</button>

				<Reporte_3 />

			</div>
		</Fragment>
	);
}
*/
