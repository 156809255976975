import React, { useRef, useState, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import InputBusqueda from './inputBusqueda';
import Toolbar from "./toolbar";

const style = {
  width: "100%",
  height: "400px"
};

const LeafletMapProyectos =() => {

	const [selectedFeature, setSelectedFeature] = useState(null)

	const mapRef = useRef(null);
	useEffect(() => {
		const osm = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
                );
		mapRef.current = L.map('map', {
                        center: [-27.8419, -65.0315],
                        zoom: 7,
                        layers: [ osm ]
		});
        }, [])
	
	const layerRef = useRef(null);

	useEffect(() => {
		layerRef.current = L.layerGroup().addTo(mapRef.current);
                //layerRef.current.bringToFront(mapRef.current);

                const overlayMaps = {"Point": layerRef.current};

                L.control.layers(overlayMaps).addTo(mapRef.current);
	}, [])

	const geoJsonRef = useRef(null)
	const toolbar = useRef(null)

	useEffect(() => {

		/*if(!document.getElementById('customT')){


        	} else {
                	var toolbar = document.getElementById('customT');
                	//console.log(toolbar);

			toolbar.onmouseover = function(e){
				L.DomEvent.disableClickPropagation(toolbar);
				//console.log(e)
			}
			//toolbar.onmouseout = onFuera;

			function onDentro(){
				//console.log('dentro');
				//mapRef.panning.disable();
				//stopPropagation();
			}
	
			function onFuera(){
				//console.log('fuera');
			}
        	}*/
		//console.log(document.getElementById('customT'));
		/*var datos = datosS;
		//console.log('Cargar Geojson');
		//console.log(datos);

		function select(layer) {
			selected = layer;
			//console.log(selected);
		}*/

		/*function styleEarthquakePoints(feature){
                	return {
                        	radius: 5,
				fillColor: getColor(feature.properties.id, selectedFeature),
                        	color: 'black',
                        	weight: 1,
                        	opacity: 0.8,
                        	fillOpacity: 0.6,
				interactive:true,
                	};
		}

		const getColor = (featureName, selectedFeature) => {
            		return featureName === selectedFeature ? '#555555' : '#ff7800'
        	}

		function zoomObjeto(e){
			//console.log(e.target);
			var coords = e.target._latlng;
			//console.log(coords);
			mapRef.current.setView(coords, 18);

		}*/

		/*const onEachFeature = (feature, layer) => {
            		layer.on(
				{
					'mouseover': function (e) {
  		    			},
  		    			'mouseout': function (e) {
  		    			},
  					'click': function (e) {
						e.target.setStyle({
							fillColor: 'blue',
							weight: 1.5,
						})
						const id = feature.properties.id;

						setSelectedFeature(id);//***IMPORTANTE

						if (document.getElementById("tableId")) {
							var t = document.getElementById("tableId");
							t.querySelector("tr").style.color="white";
						}
						var x = document.getElementById(id).style.color="white";
						var custom = document.getElementById("customT");
						//console.log(custom);
						//console.log(x);
						//console.log(document.getElementById(id));

						zoomObjeto(e)
  					}
				}
			)
        	}*/

		/*if(geoJsonRef.current != null) {
            		geoJsonRef.current.clearLayers()
        	} else {
			//console.log("entra en else");
		}

		geoJsonRef.current = null;
		const selected = null;

		geoJsonRef.current = L.geoJSON(datos, {
                	pointToLayer: function (feature, latlng) {
                        	return L.circleMarker(latlng, styleEarthquakePoints(feature));
                	},
			onEachFeature: onEachFeature
		}).on('click', function(e){

		}).addTo(mapRef.current);

		geoJsonRef.current.bringToFront();

		function zoomToFeature(layer){
			var zoomLevel = mapRef.current.getZoom();
                        //console.log(zoomLevel);
			const bounds = layer.getBounds();
			//console.log('BOUNDS');

			if (bounds.isValid()) {mapRef.current.fitBounds(layer.getBounds())}

		}

		zoomToFeature(geoJsonRef.current);

		//console.log('Seleccionado');
		//console.log(selectedFeature);*/

	}, []);//

	return <div id='map' style={style}><InputBusqueda /></div>
}

export default LeafletMapProyectos;

