import React, { Fragment } from "react";

import Select_3_Ada from "./select_3_ada";

const Reporte_3_ada = ({openReporte_3_ada}) => {
  return (
    <Fragment>
      <div style={{ width:"100%" }}>
        {/*<h5 className="mt-5 text-center">Tramitación ADA</h5>*/}
        
        <Select_3_Ada openReporte_3_ada={openReporte_3_ada}/>
      </div>
    </Fragment>
  );
}

export default Reporte_3_ada;
