import React, {useState, Fragment} from "react";
import { Collapse } from "react-bootstrap";
import MyImage from '../provincia_icon_Footer.png';
import ImagePie from '../pie_inicio-01.png';

const Footer_login = () => {
	const [open, setOpen] = useState(false);

	return (
	<Fragment>
		<div className="container_login"
			style={{ height:"260px"}}
			onDoubleClick={() => setOpen(!open)}
			aria-controls="example-collapse-text"
			aria-expanded={open}
			className="footer"
		>
			{/*<p style={{height:"20px"}}></p>*/}
			{/*<div className="container" style={{width:"1000px", height:"200px", background:"white"}}>
				<img className="logo_footer_provincia" src={ImagePie} alt="" />
			</div>*/}
			<p style={{height:"20px"}} ></p>
			<div className="div_logo_footer">
				<img src={ImagePie} alt="" />
			</div>
			{/*<Collapse in={open}>
				<div>
					Gráficos
					<p>DOS</p>
				</div>
			</Collapse>*/}
  	</div>
	</Fragment>
	)
};

export default Footer_login;
