import React, {useState} from "react";
import io from "socket.io-client";

//import { SOCKET_URL } from "config";

//export const socket = io(SOCKET_URL);

let socket;

const ENDPOINT = "http://localhost:5003";
//const socket = socketIOClient(ENDPOINT);

//const socket = io(ENDPOINT);

const initiateSocketConnection = (user) => {
	socket = io(ENDPOINT);

	//const [username, setUsername] = (""); 
	if (socket) socket.emit('userLogueado', user); // cuando desde inicio se registra una nueva conexion, emite el evento con el dato del usuario
	//console.log(`Connect Socket...`);

	socket.on('my broadcast', usuarios => { // esta a la escucha de 'my broadcast'
		//console.log("recibiendo mensaje desde servidor");
		//console.log(usuarios);
		//setUsername(usuarios);
		//return usuarios;
		//return usuarios(null, usuarios);
	});
	//console.log(io.engine.clientsCount );
}


const escucharUsuariosConectados = (msg) => {
	socket.on('usuariosConectado', msg => {
		//console.log(msg);
	});
}

// por ahora no se usa
const escucharPing = () => {
	socket.on('ping', () => {
		//console.log("ESCUCHANDO....");
		socket.emit('pong');
	})
}

const subscribeToChat = (cb) => {
	const body = 'Mensaje desde React';
	socket.emit('nuevo mensaje', body);// emite evento nuevo mensaje ---> recibe server 

	socket.on('my broadcast', msg => { // esta a la escucha de 'my broadcast'
		//console.log("recibiendo mensaje desde servidor");
		//console.log(msg); //recibe: server
    		//return cb(null, msg);
		//return msg;
  	});
}

/*socket.on('sendToClient', function (data) {
    //console.log(data);

    socket.emit('receivedFromClient', { my: 'data' });
});*/

const sendToClient = (data) => {
	//console.log(data);
	socket.emit('receivedFromClient', { my: data });
}

//https://deepinder.me/creating-a-real-time-chat-application-with-react-hooks-socket-io-and-nodejs
const disconnectSocket = () => {
	//console.log('Disconnecting socket...');
  	if(socket) socket.disconnect();
}

const SocketContext = React.createContext();

export {initiateSocketConnection, escucharUsuariosConectados, escucharPing, subscribeToChat, sendToClient, disconnectSocket, SocketContext }
//export socket;

