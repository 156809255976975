import React, { Fragment } from "react";

import Select_4_Resolucion from "./select_4_resolucion";

const Reporte_4_resoluciones= ({openReporte_4_res}) => {
  return (
    <Fragment>
      <div style={{ width:"100%" }}>
        {/*<h5 className="mt-5 text-center">Tramitación Resolución</h5>*/}
        {/*<hr />*/}
        <Select_4_Resolucion openReporte_4_res= {openReporte_4_res}/>
      </div>
    </Fragment>
  );
}

export default Reporte_4_resoluciones;
