import React, { useEffect, useState, useContext } from "react";
import { SessionContext } from '../context/sessionContext';
import {Link, useHistory} from "react-router-dom";
import { Nav,  Navbar, NavDropdown, Container, Form, Button } from "react-bootstrap";

import Modal from 'react-bootstrap/Modal';

import ImageNav from '../marca_botonera-01.png';
import Login_form from "./login_form";
import Change_passw from "./change_passw";
//import Ventana_Modal from "./modal";

import { toast } from "react-toastify";
import dotenv from "dotenv";

const baseURL = process.env.REACT_APP_API_URL;

const Navigation = ({setAuth, activo }) => { //name, role, setRole,

	const { roleA, setRoleA, codValidez, usuario } = useContext(SessionContext);

	const [showChangePass, setShowChangePass] = useState(false);
	//console.log(showChangePass);

	//console.log("STORAGE", localStorage.validezT);//#PENDIENTE 29AGOS2023

	useEffect(()=>{
		if(codValidez === 0 || localStorage.validezT === 0){ //#PENDIENTE 29AGOS2023
			setShowChangePass(true)
		}
	}, [])

	const role = roleA.roleA;
	//console.log("roleA--->", role)
	
	const username = roleA.username;
	//console.log(roleA);

	const [inicioDisabled, setInicioDisabled] = useState(true); //14-06-2022
	const [isDisabled, setIsDisabled] = useState(false); //14-06-2022
	const [reporteDisabled, setReporteDisabled] = useState(false); //14-06-2022
	
	const [original, setOriginal] = useState(0);
	const [show, setShow] = useState(false);

	const handleShow = newShow =>{
		setShow(newShow)
	}

  	const handleClose = () => {
		setShow(false);
		setOriginal(0);
	};

	const handleCloseChange = () => {
		setShowChangePass(false);
		setOriginal(0);
	};

	/*
	const checkAuthenticated = async () => {
    try {
      //const res = await fetch("http://localhost:5003/authentication/verify", {//ORIGINAL 8001
      const res = await fetch(`${baseURL}/checkAuth`, {
      method: "POST",
      headers: { jwt_token: localStorage.tokenT, roleT: localStorage.roleT }
      });

      const parseRes = await res.json();
      console.log("Desde navigation --> res.json");
      console.log(parseRes);

			setName(parseRes.username);
    } catch (err) {
    console.error(err.message);
    }
  };

	//la funcion en useEffect se ejecuta una sola vez ----> []
  	useEffect(() => {
    		checkAuthenticated();
  	}, []);*/

	//Inicio
	const handleClickInicio = () => {
		//alert("INICIO");
		/*setInicioDisabled(true);
		setIsDisabled(false);
		setReporteDisabled(false);*/
	};

	//Nuevo exp.
	const handleClickExp = () => {
		//alert("EXP")
		/*setInicioDisabled(false);
		setIsDisabled(true);
		setReporteDisabled(false);*/
	};

	const handleClickReporte =()=>{
		//alert("REPORTE")
		/*setInicioDisabled(false);
		setIsDisabled(false);
		setReporteDisabled(true);*/
	}

	const menuNav_Admin = () =>{
		return(
			<>
			<header className="header">
				{/* <Container> */}
				<Navbar collapseOnSelect  expand="lg" >

					<Navbar.Brand as={Link} to="/inicio" className="mx-3"><img src={ImageNav} style={{border:"0"}} alt="" /></Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-nabvar-nav"/>
					
					<Navbar.Collapse id="responsive-nabvar-nav">
						<Form inline className="mx-3" >						
							<span style={{margin : "10px"}}>GESTION DE PROYECTOS</span><br />
							{inicioButton()}
							{newExp_Button()}
							{reportes_Button()}							
							{/*monitor_Button()*/}							
						</Form>

						<Form inline className="mx-3" >
							<span style={{margin : "10px"}}>REPORTES</span><br />
							{mapas_Button()}
							{graficos_Button()}
							{informes_partido_Button()}						
						</Form>
					</Navbar.Collapse>

					{/*<Form inline className="mx-3" >						
						<NavDropdown title="Administración" id="collasible-nav-dropdown">
							<NavDropdown.Item as={Link} to="/page2">Pagina2</NavDropdown.Item>
						</NavDropdown>						
					</Form>*/}					

					<Form inline className="mx-3">
						<div>
							Usuario: {username} - Rol: {role} {authButton()}
						</div>
					</Form>
				</Navbar>
				{/* </Container> */}
			</header>
			</>
		)
	};
	//<Form inline className="mx-3" style={username==="javi1" || username==="Pablo González"?{ height: "auto"}:{display:'none'}}>
	//style={username==="javi1" || username==="Pablo González"?{ height: "auto"}:{display:'none'}}
	//Logueado como usuario: {username} - Rol: {role}

	const menuNav_User_Edit = () =>{
		return(
			<>
			<header className="header">
				{/* <Container> */}
				<Navbar collapseOnSelect  expand="lg" >

					<Navbar.Brand as={Link} to="/inicio" className="mx-3"><img src={ImageNav} alt="" /></Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-nabvar-nav"/>

					<Navbar.Collapse id="responsive-nabvar-nav">
						<Form inline className="mx-3" style={{ height: "auto"}}>						
							<span style={{margin : "10px"}}>GESTION DE PROYECTOS</span><br />
							{inicioButton()}
							{newExp_Button()}
							{reportes_Button()}				
														
						</Form>

						<Form inline className="mx-3" style={{ height: "auto"}}>
							<span style={{margin : "10px"}}>REPORTES</span><br />
							{mapas_Button()}
							{graficos_Button()}
							{informes_partido_Button()}						
						</Form>
					</Navbar.Collapse>					
					
					<Form inline className="mx-3">
						<div>
							Usuario: {username} - Rol: {role} {authButton()}
						</div>
					</Form>
				</Navbar>
				{/* </Container> */}
			</header>
			</>
		)
	};  

	const logout = async e => {
		e.preventDefault();
		try {
			localStorage.removeItem("tokenT");
			localStorage.removeItem("roleT");
			localStorage.removeItem("username");
			localStorage.removeItem("validezT");
			localStorage.removeItem("tokenActualizacion"); //<------------01-dic-2023
			setAuth(false);
			setRoleA({roleA:"user", username:""}); //---->>>>>>>>>>>>>>><<
			//setRole("user");// cambiar estado de hijo --> a padre
			toast.success("Logout successfully");
		} catch (err) {
			console.error(err.message);
		}
	};

	const login = async e => {
		e.preventDefault();
		try{
			//alert('Ventana login');
			setShow(true);
		} catch (err){
			console.error(err.message);
		}
	}

	const history = useHistory();

	const routeInicio = () =>{
		let path = `inicio`;
		history.push(path);
	}

	const routeExp = () =>{
		let path = `expediente`;
		history.push(path);
	}

	const routeReportes = () =>{
		let path = `tramitaciones`;
		history.push(path);
	}

	const routeMonitor = () =>{
		let path = `monitor`;
		history.push(path);
	}

	const routeMapas = () =>{
		let path = `mapas`;
		history.push(path);
	}
	
	const routeGraficos = () =>{
		let path = `graficos`;
		history.push(path);
	}

	const routeInfPartido = () =>{
		let path = `informes_partido`;
		history.push(path);
	}


	const authButton = () => {
		if(username !== 'sesion expirada'){
			return <Button variant="secondary" onClick={e => logout(e)}>Logout</Button>
		} else {
			return <Button variant="secondary" onClick={e => login(e)}>Login</Button>
		}
  	}

	//<a href="https://stackoverflow.com" style="text-decoration: none;">
	const inicioButton = () => {
		return <Button variant="secondary" className={activo==="inicio"?"buttonActivo":"buttonInactivo"} onClick={routeInicio}>INICIO</Button>
	}

	const newExp_Button = () => {
		return <Button variant="secondary" className={activo==="expediente"?"buttonActivo":"buttonInactivo"} onClick={routeExp}>NUEVO EXPTE</Button>
	}

	const reportes_Button = () => {
		return <Button variant="secondary" className={activo==="reportes"?"buttonActivo":"buttonInactivo"} onClick={routeReportes}>TRAMITACIONES</Button>
	}
	//13-mar-2023 CAMBIAR solo texto etiqueta (MONITOR ----> REPORTES)
	const monitor_Button = () => {
		return <Button variant="secondary" className={activo==="monitor"?"buttonActivo":"buttonInactivo"} onClick={routeMonitor}>REPORTES</Button>
	}

	const mapas_Button = () => {
		return <Button variant="secondary" className={activo==="mapasA"?"buttonActivo":"buttonInactivo"} onClick={routeMapas}>MAPAS E INFORMES</Button>
	}

	const graficos_Button = () => {
		return <Button variant="secondary" className={activo==="graficosA"?"buttonActivo":"buttonInactivo"} onClick={routeGraficos}>GRAFICOS</Button>
	}

	const informes_partido_Button = () => {
		return <Button variant="secondary" className={activo==="inf_partidosA"?"buttonActivo":"buttonInactivo"} onClick={routeInfPartido}>POR PARTIDO</Button>
	}

	if(codValidez===0 && username !== "sesion expirada"){
		//---->01-dic-2023: Solo si la sesion NO expiró tener la opción de cambiar contraseña. Solo se puede cambiar cuando el codValidez = 0
		return(
			<>
				<header className="header">
					{menuNav_User_Edit()}
				</header>
				<Modal
					id="id_modal_F"
					show={showChangePass}

					onHide={handleCloseChange}
					backdrop="static"
					keyboard={false}
					className="id_modal_2"
					style={{top: "200", height:"70%"}}
				>
					<Modal.Header style={{background: "rgb(0,174,195)", color:"white"}} closeButton>
						<Modal.Title>Cambiar contraseña</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Change_passw showChangePass={showChangePass} setShowChangePass={handleShow}/>
					</Modal.Body>
					<Modal.Footer />
				</Modal>
			</>
		);
	} else if(role==='visualizador' && codValidez===1){
		return(
			<>
				<header className="header">
					{menuNav_User_Edit()}
				</header>
			</>
		);
	} else if(role==='edit'&& codValidez===1){
		return(
			<>
				<header className="header">
					{menuNav_User_Edit()}
				</header>
			</>
		);
	} else if(role==='admin' && username==='sesion expirada'){ //{ backgroundColor:"rgb(163,216,231)"}:{backgroundColor:"rgb(50,85,155)"}
		return(
			<>
				<header className="header">
					{menuNav_Admin()}
				</header>
				<Modal
					id="id_modal_1"
					show={show}
					onHide={handleClose}
					backdrop="static"
					keyboard={false}
					className="id_modal_1"
					style={{top: "200", height:"200px"}}
				>
					<Modal.Header style={{background: "rgb(0,174,195)", color:"white"}} closeButton>
						<Modal.Title>Sesión finalizó</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Login_form />
					</Modal.Body>
					<Modal.Footer>
						<Button className="btn btn-success btn-block button_verde"  >
							Aceptar
						</Button>
						<Button className="btn btn-success btn-block button_verde" >
							Cancelar
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	} else {
		return(
			<>
				<header className="header">
					{menuNav_User_Edit()}
				</header>
				<Modal
					id="id_modal_1"
					show={show}

					onHide={handleClose}
					backdrop="static"
					keyboard={false}
					className="id_modal_2"
					style={{top: "200", height:"50%"}}
				>
					<Modal.Header style={{background: "rgb(0,174,195)", color:"white"}} closeButton>
						<Modal.Title>Sesión expiró - Login</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Login_form show={show} setShow={handleShow}/>
					</Modal.Body>
					<Modal.Footer />
				</Modal>
			</>
		);
	}

}

export default Navigation;
