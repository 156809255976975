import React, { useRef, useState, useEffect, useContext } from 'react';
import { SessionContext } from '../context/sessionContext';
import { toast } from "react-toastify";
import L from 'leaflet';
import Draw from 'leaflet-draw';
//import { Map, TileLayer, FeatureGroup } from "react-leaflet";
//import { EditControl } from "react-leaflet-draw";
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

//import InputBusqueda from './inputBusqueda';

const style = {
    width: "100%",
    height: "100%"
};

const eventHandlers = {    
    onDrawStart: 'draw:drawstart',
    onDrawStop: 'draw:drawstop',    
};

//const BING_KEY = 'AtfyQFLwAqMac5Le45E3ZNuHoolIPOnbS5lneKbIaWJI60e9cBfcvxZeBKjU-tH2';

const sin_base = L.tileLayer('http://sin_base.com/{z}/{x}/{y}',{
    maxZoom: 20
});

const argenMap = L.tileLayer('https://wms.ign.gob.ar/geoserver/gwc/service/tms/1.0.0/capabaseargenmap@EPSG%3A3857@png/{z}/{x}/{-y}.png', {
    attribution: '<a href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a> | <a href="http://www.ign.gob.ar/AreaServicios/Argenmap/IntroduccionV2" target="_blank">Instituto Geográfico Nacional</a> + <a href="http://www.osm.org/copyright" target="_blank">OpenStreetMap</a>',
    minZoom: 3,
    maxZoom: 20
});

const osm = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    maxZoom: 20
});

const CartoDB_DarkMatter = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png', {
	attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>',
	subdomains: 'abcd',
	maxZoom: 20
});

const gMapsSat = L.tileLayer('https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    {
      minZoom: 2,
      maxZoom: 20,
      id: "google.satellite"
    }
);

//https://carto.arba.gov.ar/cartoArba/ProxyMap?LAYERS=carto%3AMacizos%2Ccarto%3AParcelas%20Rurales%2Ccarto%3AParcelas%2Ccarto%3ASubparcelas%2Ccarto%3AEquipamiento_Comunitario%2Ccarto%3AEspacio%20Verde%2Ccarto%3ACotas%2Ccarto%3ACotas_sp%2Ccarto%3ASeccion%2Ccarto%3ACircunscripcion%2Ccarto%3APartidos%2Ccarto%3ACalles%2Ccarto%3ALimites%2Ccarto%3ACuerpos_de_agua%2Ccarto%3ARed_Ferroviaria%2Ccarto%3Aign_cursos_de_agua&TRANSPARENT=TRUE&FORMAT=image%2Fpng8&BUFFER=0&STYLES=Carto_Macizos_label%2CCarto_Parcelas_Rurales_label%2CCarto_Parcelas_label%2CCarto_Subparcelas_label%2CCarto_Parcelas_label%2Ccarto%3ACarto_Espacio_Verde_label%2CCotas%2CCarto_Cotas_Subparcelas%2CCarto_Seccion_label%2CCarto_Circunscripcion_label%2CPartidos_label%2CCarto_Calles_label%2CLimite_label%2Cempty%2Cempty%2Cempty&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&SRS=EPSG%3A900913&BBOX=-6717198.6013846,-4431845.8952876,-6711953.1103192,-4428141.0880734&WIDTH=1098&HEIGHT=775&tiled=true&gwc=false&namedgrouped=&namedgroupedtransparent=&maptransparent=false&viewparams=xmin%3A-6717198.6013846%3Bymin%3A-4431845.8952876%3Bxmax%3A-6711953.1103192%3Bymax%3A-4428141.0880734%3Bepsg%3A900913&intscale=13541&tilesorigin=-20037508.34%2C-20037508.34
const layer_ARBA = L.tileLayer.wms('https://carto.arba.gov.ar/cartoArba/ProxyMap?', {
    layers: 'carto:Partidos',
    transparent: true,
    format: 'image/png8',
    styles:['carto:Partidos','carto:empty'],
    request: 'GetMap',   
    crs: L.CRS.EPSG4326,    
    opacity: 1,
    tiled:true,
    gwc:true,
    namedgrouped:'carto:Carto_Grupo',
    namedgroupedtransparent: 'carto:Carto_Grupo_transparent',
    maptransparent:false,
    viewparams:'xmin:-63.67037287773794;ymin:-41.063632155155055;xmax:-56.46478067123993;ymax:-33.291679965530854;epsg:4326',
    intscale:1733371    
});
//viewparams:'minx=-63.67037287773794 miny=-41.063632155155055 maxx=-56.46478067123993 maxy=-33.291679965530854;epsg:4326'
//&intscale=6933486&tilesorigin=-20037508.34,-20037508.34'

//http://geo.arba.gov.ar/geoserver/idera/wms
//https://ide.transporte.gob.ar/geoserver/observ/wms
const layer_1101 = L.tileLayer.wms('https://geo.arba.gov.ar/geoserver/idera/wms?', {
    layers: 'idera:Grupo IDERA',
    STYLES: '',
    VERSION: '1.1.1',
    tiled: true,
    crs: L.CRS.EPSG4326,
    format: 'image/png',
    transparent: true,
    tilesorigin: [-180, 90],
    opacity: 1,
    maxZoom: 20
});

//http://181.171.117.68/geoserver/wms?SERVICE=WMS&request=getCapabilities
const layer_4101 = L.tileLayer.wms('http://181.171.117.68/geoserver/wms?', {
    layers: 'capabase',
    crs: L.CRS.EPSG4326,
    format: 'image/png',
    transparent: true,
    opacity: 1
});


//URBASIG http://urbasig.gob.gba.gob.ar/geoserver/urbasig/wms?
const layer_2101 = L.tileLayer.wms('http://urbasig.gob.gba.gob.ar/geoserver/urbasig/wms?', {
    layers: 'urbasig:_zonificacion',
    crs: L.CRS.EPSG4326,
    format: 'image/png',
    transparent: true,
    opacity: 1
});

const layer_3101 = L.tileLayer.wms('http://urbasig.gob.gba.gob.ar/geoserver/urbasig/wms?', {    
    layers: 'urbasig:areas_ley_8912',
    crs: L.CRS.EPSG4326,
    format: 'image/png',
    transparent: true,
    opacity: 1
});

const geojsonTemp = {
    fillColor: "green",
    color: "green",
    weight: 2,
    opacity: 1,
    fillOpacity: 0.8
};

const geojsonTempB = {
    fillColor: "green",
    color: "green",
    weight: 2,
    opacity: 0.8,
    fillOpacity: 0.05
};

const geojsonTempC = {
    fillColor: "yellow",
    color: "yellow",
    weight: 2,
    opacity: 0.8,
    fillOpacity: 0.05
};

//PRUEBA harcodeada, no se agrega al mapa, solo cuando se activa en el menu de capas

const datosTextHARD = `{"type":"FeatureCollection","totalFeatures":"unknown","features":[{"type":"Feature","id":"Seccion_Catastral.3768","geometry":{"type":"MultiPolygon","coordinates":[[[[-60.187174,-36.96339],[-60.206022,-36.94794],[-60.200912,-36.943938],[-60.22006,-36.928529],[-60.215907,-36.92524],[-60.212861,-36.922831],[-60.210131,-36.920702],[-60.205094,-36.916803],[-60.203863,-36.915988],[-60.200009,-36.912984],[-60.200006,-36.912829],[-60.198862,-36.911979],[-60.190229,-36.904857],[-60.190105,-36.904842],[-60.188028,-36.903178],[-60.186682,-36.902086],[-60.185172,-36.900899],[-60.182158,-36.898533],[-60.175127,-36.89292],[-60.153422,-36.87569],[-60.115379,-36.845489],[-60.108292,-36.85135],[-60.096263,-36.860665],[-60.096104,-36.860536],[-60.086356,-36.8682],[-60.086555,-36.868389],[-60.093916,-36.874178],[-60.095521,-36.875435],[-60.098041,-36.877389],[-60.100562,-36.879285],[-60.105981,-36.883463],[-60.107303,-36.884472],[-60.104151,-36.887046],[-60.129042,-36.906794],[-60.133994,-36.910668],[-60.138987,-36.91462],[-60.132749,-36.919712],[-60.137726,-36.923663],[-60.156514,-36.908467],[-60.161513,-36.912458],[-60.164268,-36.910164],[-60.179125,-36.92182],[-60.184126,-36.925742],[-60.181076,-36.928267],[-60.186148,-36.932189],[-60.17978,-36.937384],[-60.169836,-36.929487],[-60.16378,-36.934441],[-60.163113,-36.935149],[-60.158115,-36.939056],[-60.158032,-36.939251],[-60.159161,-36.940075],[-60.160324,-36.941019],[-60.162848,-36.943068],[-60.162135,-36.943669],[-60.187174,-36.96339]]]]},"geometry_name":"geom","properties":{"cca":"078020K","ara3":49977.69,"sag":"ARBA"}}],"crs":{"type":"name","properties":{"name":"urn:ogc:def:crs:EPSG::4326"}}}`;
const datosHARD = JSON.parse(datosTextHARD);
/*
const requiredHARD=L.geoJson(datosHARD, {style: geojsonTemp});
*/
//let requiredHARD=L.geoJson({ "type": "FeatureCollection", "features": [ { "type": "Feature", "properties": {}, "geometry": { "coordinates": [ [ [ -62.28381380389676, -38.7242032562095 ], [ -62.28168891928466, -38.72530064031854 ], [ -62.2797136744346, -38.72406316340791 ], [ -62.28076115276396, -38.722452076448405 ], [ -62.28381380389676, -38.7242032562095 ] ] ], "type": "Polygon" } } ] }, {style: geojsonTemp});
//let requiredHARD=L.geoJson(null, {style: geojsonTemp});

//let tempLayer = L.geoJson(null, {style: geojsonTemp});

function gardenStyle(feature) {
    return {
        fillColor: "yellow",
        fillOpacity: 1,
        color: '#B04173',
    };
}

//let editableLayers = L.geoJson(null, {style: gardenStyle});

let geoJsonLayer = L.geoJson();


const Map_Expedientes =({partido, circ, secc, partidoGEO}) => {

    console.log("partidoGEO", partidoGEO);

    function geojsonTempPartido(feature) {
        return {
            fillColor: "green",
            fillOpacity: 0.25,
            color: 'green',
        };
    }

    //estilo de prueba, funcionó para los poligonos a editar
    function AAStyle(feature) {
        return {
            fillColor: "#00AEC3",
            fillOpacity: 1,
            color: '#B04173',
        };
    }

    const mapRef = useRef(null);
    console.log(secc);
    //const layerRef = useRef(null); //PARA layerGroup

    const geoJsonRef = useRef(null);
    const editableLayers = useRef(null);
    //console.log(editableLayers)

    const customParams = useRef(null);    
    const toolbar = useRef(null);    
    
    //const editableLayers= useRef(null);    

    //const layerGeoJson = L.geoJson(null, {style: geojsonTemp});

    //editableLayers=L.geoJson(null, {style: geojsonTemp});    
    
    const { geom, cambioGeom } = useContext(SessionContext);
    
    //----ESTADOS----
    const [textGEOM, setTextGEOM]=useState(JSON.stringify(geom));
    
    const [featuresFILTER, setCharacterFILTER] = useState(null);

    const [addLAYER, setAddLAYER]=useState([]);

    // ***add 7-agos-2023******* Manejar geojson de partido para mostrar en el mapa

    const [dataPartido, setDataPartido]=useState(null); 

    useEffect(()=>{ // ***add 7-agos-2023

        if(partidoGEO !== null ){
            setDataPartido(partidoGEO); //lo que recibe
        }

    }, [partidoGEO]);    

    useEffect(()=>{
        if(dataPartido !== null){           
            const partidoL=L.geoJson(dataPartido, {style: geojsonTempPartido}).addTo(mapRef.current);             

            //console.log("EDITABLE --->", addLAYER);

            if(addLAYER.length < 1){
                console.log("EDITABLE --->", addLAYER);
                mapRef.current.fitBounds(partidoL.getBounds());

            }
        }

    }, [dataPartido])

    //***cierra manejo capa partido */

    ///*FUNCIONA, POR AHORA NO USAR
    //const [newParcel, setNewParcel] = useState([]);

    //CASO 1: Ideal (que lleguen los 3 datos completos 114 01 0A)- armo la cadena y consulto contra url Secciones

    let partidoQuery = '000';
    if(partido.length===1){
        partidoQuery = `00${partido}`;
    } else if(partido.length===2){
        partidoQuery = `0${partido}`;
    } else {
        partidoQuery = `${partido}`;
    }
    //console.log("PARTIDO QUERY--->", partidoQuery);

    let circQuery = '00';

    if(circ === null || circ === ''){
        circQuery= '00';
    } else if(circ.length===1){
        circQuery = `0${circ}`;
    } else {
        circQuery = `${circ}`;
    }
    console.log("QUERY CIRC--->", circQuery);

    let seccQuery = '00';
    if(secc === null || secc === ''){
        seccQuery = '00';
    } else if(secc.length===1){
        seccQuery = `0${secc}`;
    } else {
        seccQuery = `${secc}`;
    }
    console.log("QUERY SECC--->", seccQuery);

    let cadenaFilter;
    let typeName; //typeName=idera:Seccion_Catastral

    //let urlQuery;

    if(circQuery==='00'){
        cadenaFilter=`${partidoQuery}`;
        typeName=`typeName=idera:Departamento`;
        console.log("A");    

    } else if(circQuery!=='00' && seccQuery === '00'){
        cadenaFilter=`${partidoQuery}${circQuery}`;
        typeName=`typeName=idera:Circunscripcion`;
        console.log("B"); 

    } else if(circQuery!=='00' && seccQuery !== '00'){
        cadenaFilter=`${partidoQuery}${circQuery}${seccQuery}`;
        typeName=`typeName=idera:Seccion_Catastral`;
        console.log("C"); 
    }
    console.log("CADENA QUERY---->", cadenaFilter);

    //CQL_FILTER=cca LIKE '078020K'
    let cqlFilter= `CQL_FILTER=cca LIKE '${cadenaFilter}'`;

    let cqlFilterPartido= `CQL_FILTER=cca LIKE '${partidoQuery}'`;


    

    const savePolygon= (geojsonDatos)=>{
        //console.log("RECIBE DATOS PARA GUARDAR---->", geojsonDatos)
        //setPolygonFINAL(geojsonDatos)
        //setGeom(geojsonDatos);      
       
        cambioGeom(geojsonDatos);
    } 
    
    //-------------EFECTOS--------

    useEffect(() => {   

        mapRef.current = L.map('map', {
            center: [-37.265645, -60.067551],
            zoom: 7,
            //layers: [ bingLayer ]
        });
        //getData();        
    }, []);
    

    //ULTIMO PASO: if featuresFILTER ya tiene datos (distinto de null), agregar a geoJsonRef y luego la capa al mapa
    //hacer zoom
    //para que no se vea el poligono, limpiar la capa
    useEffect(()=>{ 

        if(featuresFILTER !== null){
            //console.log("CAMBIO featuresFILTER---->2DO");

            let datos = featuresFILTER;
            let editable = editableLayers.current;

            geoJsonRef.current = L.geoJSON(datos, {style: geojsonTempB}).addTo(mapRef.current);
            //console.log("3RO...QUE TIENE?", geoJsonRef.current)
            //console.log("4TO----->", geoJsonRef.current.getBounds().isValid())

            if(editable.getBounds().isValid() === true){
                mapRef.current.fitBounds(editableLayers.current.getBounds());

            } else if(geoJsonRef.current.getBounds().isValid() === true){
                mapRef.current.fitBounds(geoJsonRef.current.getBounds());
            } else if(partido !== null && partido > 0){
                
                toast.success(`Haciendo zoom al partido ${partido}`);
                //getSoloPartido()                
                
            } else if(partido === null || partido === 0){
                toast.success("No se puedo obtener la capa");
            }
        }        

    }, [featuresFILTER]);

    /*useEffect(()=>{
        if(textGEOM !== `{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{}}]}`){        
        //if(textGEOM !== `{"type":"FeatureCollection","features":[]}`){
            alert("ALERT 1 - nuevo")
            let datos = geomPoly;
            console.log("TIENE que agrrgar lo que viene en geomPoly", datos)
            //datos.addTo(layerRef.current)
            //layerRef.current = L.geoJSON(datos, {style: geojsonTemp}).addTo(mapRef.current);

            //console.log("HARDC", datosHARD );
            //console.log("DATOS", datos);

            //let temp1= L.geoJSON(datos, {style: geojsonTemp});

            //***************************************
            //NO FUNCIONA ---> pasandole datos
            //editableLayers.current.addLayer(datos, {style: geojsonTemp});

            //FUNCIONA ---> pero no aparece como editable
            //editableLayers.current = L.geoJSON(datos, {style: geojsonTemp}).addTo(mapRef.current);

            //***************************
            //console.log("3RO...QUE TIENE?", editableLayers.current)            
            
        } else{
            //editableLayers.current = L.geoJSON();
        }

    }, [geomPoly]) //solo cuando la geom de los poligonos*/

    useEffect(()=>{
        if(textGEOM !== `{"type":"FeatureCollection","features":[]}`){
            //console.log("QUE CARGUE!!!!", addLAYER);
            //alert("ALERT 2 - nuevo")

            if(addLAYER !== null){

                return addLAYER.map((feature, index) => {
                    
                    editableLayers.current.addLayer(feature, {style: gardenStyle});
                    //console.log(editableLayers.current)
                    mapRef.current.fitBounds(editableLayers.current.getBounds()); //ACÁ SI...funciona zoom a todos los poligonos

                    editableLayers.current.eachLayer((layer) => {
                        if (layer) {
                            console.log(layer);
                        }
                    })
                })                
            }            
        }
    }, [addLAYER]) 
  

    //NUEVA PRUEBA solo la primera vez
    //**** B */
    useEffect(()=>{
        console.log("cuando carga -- AddLAYER") 
        if(textGEOM !== `{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{}}]}`){
        
            //alert("ALTER 4 - MAPEAR DESDE PRUEBA #341");        
            let datos = geom; 
            //console.log(datos);

            if(datos.features.length > 0){

                return datos.features.map((feature, index) => {                    
                    var geoJsonLayerA = L.geoJson(null, {style: AAStyle}).addTo(mapRef.current);//poner el estilo acá funciona
                    geoJsonLayerA.on('layeradd', function (e) {
                        var type = e.layerType,
                        layer = e.layer;                        
                        
                        setAddLAYER(addLAYER => [...addLAYER, layer]);//
                    });
                    geoJsonLayerA.addData(feature); //usar feature
                    //mapRef.current.fitBounds(geoJsonLayerA.getBounds()); //FUNCIONA, PERO NO HACER ACÁ
                    geoJsonLayerA.clearLayers();
                })
            }  
            console.log("#389 OSO AA-002");
                       
        }  
    }, []);

    /*BACKUP Useeffect
    useEffect(()=>{
        console.log("cuando carga -- AddLAYER") 
        if(textGEOM !== `{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{}}]}`){
        
            alert("ALTER 4 - MAPEAR DESDE PRUEBA #341");           

            let datos = geom; 
            console.log(datos);

            let geoJsonLayerB = L.geoJson().addTo(mapRef.current);

            geoJsonLayerB.on('layeradd', function (e) {
                //console.log(e.layer)
                var type = e.layerType,
                layer = e.layer;

                setAddLAYER(addLAYER => [...addLAYER, layer]);
            });

            if(datos.features.length > 0){
                geoJsonLayerB.addData(datos, {style:geojsonTempC});
                geoJsonLayerB.clearLayers();
            }
                       
        }  
    }, []);
    */
       
    //****LLAMAR SOLO CUANDO FALLA EL BOUND***(5-abril-2023)
    //nota: armar una para consultar la circ si falla el limite de la secc (PENDIENTE)
    const getSoloPartido=async()=>{
        let urlB=`https://geo.arba.gov.ar/geoserver/idera/ows?`;
        let parametersB=`service=WFS&version=1.0.0&request=GetFeature&typeName=idera:Departamento&maxFeatures=10&outputFormat=application/json&${cqlFilterPartido}&SRSNAME=EPSG:4326`;
        let urlWFS=`${urlB}${parametersB}`;
        const res= await fetch(urlWFS);
        const data= await res.json(); 
        setCharacterFILTER(data);
    }

    const getData = async ()=>{    
        let urlB=`https://geo.arba.gov.ar/geoserver/idera/ows?`;
        let parametersB=`service=WFS&version=1.0.0&request=GetFeature&${typeName}&maxFeatures=50&outputFormat=application/json&${cqlFilter}&SRSNAME=EPSG:4326`;
        let urlWFS=`${urlB}${parametersB}`;      
        const response= await fetch(urlWFS);
        //console.log(response)
        
        if (response.ok) {
            const res = await response.json()
            .then(res => {
                setCharacterFILTER(res);			
            })
            .catch(err => console.log(err))
            .finally(() => setCharacterFILTER(null));
            //return res
        } else {
            throw new Error('Network response was not ok.')
        }
        
    } 

    /*useEffect(()=>{
        //console.log("DESPUES DE EDITAR------>", layerGeoJSON)
    }, [layerGeoJSON])*/

    /*
    const addPolygon = (jsonL) => {
        //console.log("ACA hacer carga de los features");

        //const len_jsonL = jsonL.length;
        const lastMarker = polygonData[polygonData.length - 1];

        var textoG = JSON.stringify(jsonL);//.geometry
        //console.log(textoG);
        setPolygonData([...polygonData, textoG]);
    }*/  
	   

    useEffect(()=>{
        if(geoJsonRef.current!==null){
            mapRef.current.addLayer(geoJsonRef.current);
        }

    }, [geoJsonRef.current])

    useEffect(()=>{
        if(osm ){
            mapRef.current.removeLayer(osm);
            osm.addTo(mapRef.current);
            //mapRef.current.removeLayer(layer_1101); //<-------------------
            //layer_1101.addTo(mapRef.current); // <-------------------------
        }        
    })
    
    useEffect(()=>{
        if(layer_2101 ){
            mapRef.current.removeLayer(layer_2101);
            //osm.addTo(mapRef.current);
            //mapRef.current.removeLayer(layer_1101); //<-------------------
            //layer_1101.addTo(mapRef.current); // <-------------------------
        }
        
    })

    useEffect(()=>{
        if(layer_1101 ){
            mapRef.current.removeLayer(layer_1101);
            //osm.addTo(mapRef.current);
            //mapRef.current.removeLayer(layer_1101); //<-------------------
            //layer_1101.addTo(mapRef.current); // <-------------------------
        }
        
    })

	useEffect(() => {
                
        //layer_1101.addTo(mapRef.current); // <---------------------------------
        geoJsonLayer.addTo(mapRef.current);
        //requiredHARD.addTo(mapRef.current);     

        editableLayers.current = new L.FeatureGroup();
        editableLayers.current.addTo(mapRef.current);

        editableLayers.current.on('layeradd', function (e) {
            
            var type = e.layerType,
            layer = e.layer;
            console.log(layer);
            //editableLayers.current.addLayer(layer);

            editableLayers.current.addLayer(layer);
            console.log(editableLayers.current);
        });

        mapRef.current.on('draw:deleted', function (e) {
            /*var deletedLayers = e.layers._layers;
            for (var layer in deletedLayers) {
               console.log(deletedLayers[layer]);
            }*/
            let layerGeoJSON = editableLayers.current.toGeoJSON();
            console.log(layerGeoJSON)
        });

        mapRef.current.on('draw:deletestart', function(e) {
            //alert("started");            
            let layerGeoJSON = editableLayers.current.toGeoJSON(); //editableLayers.current.
            console.log(layerGeoJSON);
            savePolygon(layerGeoJSON);
        });

        //draw:deletestop
        mapRef.current.on('draw:deletestop', function (e) {
            //console.log(e);
            //console.log(e.target);
            let layerGeoJSON = editableLayers.current.toGeoJSON(); //editableLayers.current.
            //console.log(layerGeoJSON);
            savePolygon(layerGeoJSON)
        })

        mapRef.current.on('draw:editstart', function (e) {
            //console.log(e);
            //console.log(e.target);
            //console.log("started editing")
        });        
        
        mapRef.current.on('draw:edited', function (e) {
            //console.log(e);
            //console.log(e.target);
            let layerGeoJSON = editableLayers.current.toGeoJSON(); //editableLayers.current.
            //console.log(layerGeoJSON);
            savePolygon(layerGeoJSON)
        })

        mapRef.current.on("draw:drawstart", function (event) {
            //console.log("event here", event);
        });

        mapRef.current.on("draw:drawstop", function (event) {
            //console.log("event here STOP", event);

            let layerGeoJSON = editableLayers.current.toGeoJSON(); // editableLayers.current.
            //console.log("TERMINO DE DIBUJAR----->",layerGeoJSON);
            savePolygon(layerGeoJSON);
            //var type = e.layerType,
            //layer = e.layer;
            //console.log("LAYER--->",layer);
        });

        mapRef.current.on('draw:created', function (e) {
            //console.log('Creando');
            var type = e.layerType,
            layer = e.layer;
            //console.log(layer);
        
            var featureId = L.stamp(layer);
            layer.feature = {
                'type': 'Feature',
                'properties': {                    
                    'feature_type': e.layerType
                }
            };
            ////'_leaflet_id': featureId,
        
            var jsonL = layer.toGeoJSON();
            //console.log(jsonL);

            //https://javascript.plainenglish.io/how-to-add-to-an-array-in-react-state-3d08ddb2e1dc
            //NO USAR
            //setNewParcel(newParcel => [...newParcel, jsonL]); // <---- FUNCION CONTENEDORA (wrapper function)

            /*let layerGeoJSON = editableLayers.current.toGeoJSON();
            //console.log(layerGeoJSON);
            savePolygon(layerGeoJSON);*/
            
        });


        /*mapRef.current.on(L.Draw.Event.DELETESTART, function(e) {
            console.log("delete start");
        });
        mapRef.current.on(L.Draw.Event.DELETESTOP, function(e) {
            console.log("delete stop");
        });
        mapRef.current.on(L.Draw.Event.DELETED, function(e) {
            var layers = e.layers;
            console.log("LAYERS--->", layers);
            layers.eachLayer(function(layer) {
              var featureId = L.stamp(layer);
              if (!layer.feature) {
                layer.feature = {
                  'type': 'Feature'
                };
              }
              if (!layer.feature.properties) {
                layer.feature.properties = {};
              }
              layer.feature.properties._leaflet_id = featureId;
              layer.feature.properties.layerId = layer.options.layerId;
              if (typeof layer.getRadius === 'function') {
                layer.feature.properties.radius = layer.getRadius();
              }
            });
            
            console.log("deleted shapes: " + JSON.stringify(layers.toGeoJSON()));
          
          });*/

        mapRef.current.on(L.Draw.Event.CREATED, function(e) {
            var type = e.layerType,
              layer = e.layer;
              console.log(layer);
            if (type === "marker") {
              layer.bindPopup("A popup!");
            }
            console.log("AQUI event CREATED---->",layer );
            
            //mapRef.current.addLayer(editableLayers.current);

            editableLayers.current.addLayer(layer, {style: gardenStyle}); // editableLayers.current.

            //NOTA: lo agrega con el ESTILO, pero no deja editar!!
            /*
            var jsonL = layer.toGeoJSON();
            //console.log(jsonL);
            editableLayers.current = L.geoJSON(jsonL, {style: geojsonTemp}).addTo(mapRef.current);
            */
                      
        });

        
        //*****NOTA: Revisar con el código sin comentar error. Comentando sigue editando*****
        mapRef.current.on(L.Draw.Event.EDITED, function(e) {
            //e.layers.eachLayer(layer => //console.log('got a layer!', layer));
            console.log("EVENT EDIT - tira el error")
            var type = e.layerType,
            layer = e.layer;
            //if(layer !== null){
                //editableLayers.current.addLayer(layer);
            //}            
        });           

        const baseMaps = {
            "<span style='color: gray'>Sin capa base</span>": sin_base,
            "<span style='color: gray'>OpenStreetMap</span>": osm,
            "<span style='color: gray'>ArgenMap</span>": argenMap,
            "<span style='color: gray'>Carto</span>": CartoDB_DarkMatter,
            //"<span style='color: gray'>Bing</span>": bingLayer,
            "<span style='color: gray'>Google Satelital</span>": gMapsSat            
        };        

        const overlayMaps = {
            "Polygon": editableLayers.current,
            //"ARBA_prueba": layer_ARBA,
            "ARBA": layer_1101,
            "ARBA base": layer_4101,
            "Zonificación según usos": layer_2101,
            "Areas - Ley 8912/77" : layer_3101,
            //"Query": geoJsonRef,
            //"Prueba": requiredHARD,
            //"Temp": tempLayer  -----> NO se agrega la capa con la que se hizo la busqueda          
        };
        L.control.layers(baseMaps, overlayMaps, {position: 'topleft'}).addTo(mapRef.current);

        const options = {
            position: "topleft",
            draw: {
                polyline: false,
                polygon: {
                    title: 'Dibujar nuevo poligono',
                    icon: new L.DivIcon({
                        iconSize: new L.Point(10, 10),
                        className: 'leaflet-div-icon leaflet-editing-icon'
                    }),
                    shapeOptions: {
                        clickable: true,
                        color: "dodgerblue",
                    },
                    allowIntersection: false, // Restricts shapes to simple polygons 
                    drawError: {
                        color: '#e1e100', // Color the shape will turn when intersects 
                        message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect 
                    }
                },
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false
            },
            edit: {
                featureGroup: editableLayers.current //REQUIRED!! //editableLayers.current.                
            },
        };
          
        const drawControl = new L.Control.Draw(options);

        drawControl.addTo(mapRef.current);

	}, [])

	
	

	return (
        <div 
            id='map'
            style={style}>
            {/*<InputBusqueda />*/}
            
        </div>
    )
    
}

export default Map_Expedientes;