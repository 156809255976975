import React, {Fragment,  useState, useEffect} from "react";
import { Table, Button } from "react-bootstrap";

import '../../table_report.css';

const TablaRep1_finalizados = ({datosRP1_finalizados}) => {
  //console.log("CARGAR datos de finalizados en tabla");
  //console.log(datosRP1_finalizados);

  if (datosRP1_finalizados === null) {
    return(
      <p></p>
    )
  } else if(datosRP1_finalizados !== null){
    return(
      <Fragment>
        <h4></h4>
        <p className="subtitulos">Proyectos y lotes finalizados por municipios</p>
        <div>
          <Table className="tableExp" stripped bordered hover responsive="md" >
          <thead>
            <tr style={{backgroundColor:"rgb(0,174,195)", color:"white"}}>
              <th>Partido</th>
              <th>Cantidad lotes</th>
              <th>Proyectos</th>
            </tr>
          </thead>
          <tbody>
            {datosRP1_finalizados.map((item, i)=>(
              <tr key={i}>
                <td>{item.partido_nombre}</td>
                <td>{item.sum}</td>
                <td>{item.count}</td>
              </tr>
            ))}
          </tbody>
          </Table>
        </div>
      </Fragment>
    )
  }
};

export default TablaRep1_finalizados;
