import { FaEye, FaFileDownload} from 'react-icons/fa';

const posts = [
  {id: 1, title: 'Programa Lotes con Servicios - Instructivo', icon: <FaFileDownload />, path:'/Programa Lotes con Servicios - Instructivo.pdf', document: 'Programa Lotes con Servicios - Instructivo.pdf'},
  {id: 2, title: 'Programa Familia Propietaria - Instructivo', icon: <FaFileDownload />, path:'/Programa Familia Propietaria - Instructivo.pdf', document: 'Programa Familia Propietaria - Instructivo.pdf'},
  {id: 3, title: 'Informe de Gestión 2020-2023', icon: <FaFileDownload />, path:'./Dirección de Producción de Hábitat - Informe de Gestión 2020-2023.pdf', document: 'Dirección de Producción de Hábitat - Informe de Gestión 2020-2023.pdf'}
];

const posts2 = [
  {id: 1, title: 'Programa Lotes con Servicios - Instructivo', icon: <FaEye />, path:'/Programa Lotes con Servicios - Instructivo.pdf', document: 'ProgramA Lotes con Servicios - Instructivo.pdf'},
  {id: 2, title: 'Programa Familia Propietaria - Instructivo', icon: <FaEye />, path:'/Programa Familia Propietaria - Instructivo.pdf', document: 'Programa Familia Propietaria - Instructivo.pdf'},
  {id: 3, title: 'Informe de Gestión 2020-2023', icon: <FaEye />, path:'./Dirección de Producción de Hábitat - Informe de Gestión 2020-2023.pdf', document: 'Dirección de Producción de Hábitat - Informe de Gestión 2020-2023.pdf'}
];
export {posts, posts2};