import React, { Fragment, useState, useEffect } from "react";
import { Collapse, Form, Button } from "react-bootstrap";

import Navigation from "../navigation";
import Footer from "../footer";

import Reporte_3_ada from "./reporte_3_ada";
import Reporte_4_resoluciones from "./reporte_4_resoluciones";
import Reporte_5_visado from "./reporte_5_visado";
import Reporte_6_cpu from "./reporte_6_cpu";

const Reports = ({ setAuth, ...props }) => {

  const [name, setName] = useState("");
  const [role, setRole] = useState("user");

  //***chequear token *** //24-06-2022
	const getProfile  = async  () => {
    try {
      const res = await fetch(`/api/authentication/checkAuth`, { //`${baseURL}authentication/checkAuth`
      method: "POST",
      headers: { 'Authorization': localStorage.tokenT, roleT: localStorage.roleT, username:localStorage.username }
      });

      const parseRes = await res.json();      

      setName(parseRes.username);
      setRole(parseRes.role);      

    } catch (err) {
    console.error(err.message);
    }
  };

  useEffect(() => {
      getProfile();
  }, []);
	//*** cierra chequear token ****//24-06-2022

  const [activo, setActivo] = useState("reportes"); //15-06-2022
  
  const [openReporte_3_ada, setOpenR3_ada] = useState(true);
  const [openReporte_4_res, setOpenR4_res] = useState(false);
  const [openReporte_5_visado, setOpenR5_visado] = useState(false);
  const [openReporte_6_cpu, setOpenR6_cpu] = useState(false);

  const handleReporte_3_ada = () => {
		setOpenR3_ada(true);
    setOpenR4_res(false);
    setOpenR5_visado(false);
    setOpenR6_cpu(false);
	}

  const handleReporte_4_res = () => {		
    setOpenR3_ada(false);
    setOpenR4_res(true);
    setOpenR5_visado(false);
    setOpenR6_cpu(false);
	}

  const handleReporte_5_visado = () => {		
    setOpenR3_ada(false);
    setOpenR4_res(false);
    setOpenR5_visado(true);
    setOpenR6_cpu(false);
	}

  const handleReporte_6_cpu = () => {		
    setOpenR3_ada(false);
    setOpenR4_res(false);
    setOpenR5_visado(false);
    setOpenR6_cpu(true);
	}

  //NUEVO
  const reporteButton = () => {
    return (
      <Fragment>      

        <Button
          variant="secondary"
          onClick={handleReporte_3_ada}
          size="sm" type="info"
          aria-controls="example-collapse-textR3a"
          aria-expanded={openReporte_3_ada}
          disabled={openReporte_3_ada ? true : false}
        >
          TRAMITACIÓN ADA
        </Button>
        <Button
          variant="secondary"
          onClick={handleReporte_4_res}
          size="sm" type="info"
          aria-controls="example-collapse-textR4r"
          aria-expanded={openReporte_4_res}
          disabled={openReporte_4_res ? true : false}
        >
          TRAMITACIÓN RESOLUCIÓN
        </Button>
        <Button
          variant="secondary"
          onClick={handleReporte_5_visado}
          size="sm" type="info"
          aria-controls="example-collapse-textR5v"
          aria-expanded={openReporte_5_visado}
          disabled={openReporte_5_visado ? true : false}
        >
          TRAMITACIÓN VISADO
        </Button>
        <Button
          variant="secondary"
          onClick={handleReporte_6_cpu}
          size="sm" type="info"
          aria-controls="example-collapse-textR5v"
          aria-expanded={openReporte_6_cpu}
          disabled={openReporte_6_cpu ? true : false}
        >
          TRAMITACIÓN CPU
        </Button>
      </Fragment>
    )

  }// cierra reporteButton

  return (
    <Fragment>
      <Navigation setAuth={setAuth} name={name} role={role} setRole={setRole} activo={activo}/>
      <div className="main">

        <div style={{width:"100%", height:"130px", border: "0px solid yellow", position: "sticky", top: "0px" }} className="div_stick">

          <div  style={{borderTop: "1px solid white", background: "", height: "130px", position: "sticky", top: "10px"}} className="">{/*position:"sticky", top:"0px",*/}

            <h4 className="mt-5 text-center">Tramitaciones</h4>

            <div className="d-flex">
              <div style={{position:"absolute", bottom:"5px"}} className="align-right-menu">
                {reporteButton()}
              </div>
            </div>{/* cierra d-flex*/}

          </div> {/* cierra linea 587*/}

        </div> {/* cierra div_stick*/}


        <div className="">

          <Form id='id_reportes' style={{ width:"100%" }}>
            <div className="" style={{ width:"100%" }}>

              <Collapse in={openReporte_3_ada} dimension="width">
                <div id="example-collapse-textReporte3">
                  <Reporte_3_ada openReporte_3_ada={openReporte_3_ada}/>
                </div>
              </Collapse>

              <Collapse in={openReporte_4_res} dimension="width">
                <div id="example-collapse-textReporte4">
                  <Reporte_4_resoluciones openReporte_4_res= {openReporte_4_res}/>
                </div>
              </Collapse>

              <Collapse in={openReporte_5_visado} dimension="width">
                <div id="example-collapse-textReporte5">
                  <Reporte_5_visado role={role} name={name} openReporte_5_visado= {openReporte_5_visado}/>
                </div>
              </Collapse>

              <Collapse in={openReporte_6_cpu} dimension="width">
                <div id="example-collapse-textReporte3">
                  <Reporte_6_cpu openReporte_6_cpu= {openReporte_6_cpu}/>
                </div>
              </Collapse>

            </div>
          </Form>

        </div>
      </div> {/* cierra main */}

      <Footer />
    </Fragment>
  );
};

export default Reports;
