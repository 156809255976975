import React, { Fragment, useRef, useState, useEffect, useContext } from 'react';
import { Button} from "react-bootstrap";
import { SessionContext } from '../context/sessionContext';
import { toast } from "react-toastify";
import L from 'leaflet';
import LegendData from './legend';

import SelectInput from './selectInput';
import Draw from 'leaflet-draw';
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

const style = {
    width: "100%",
    height: "100%"
};

const eventHandlers = {    
    onDrawStart: 'draw:drawstart',
    onDrawStop: 'draw:drawstop',    
};

//const BING_KEY = 'AtfyQFLwAqMac5Le45E3ZNuHoolIPOnbS5lneKbIaWJI60e9cBfcvxZeBKjU-tH2';

const sin_base = L.tileLayer('http://sin_base.com/{z}/{x}/{y}',{
    maxZoom: 20
});

const argenMap = L.tileLayer('https://wms.ign.gob.ar/geoserver/gwc/service/tms/1.0.0/capabaseargenmap@EPSG%3A3857@png/{z}/{x}/{-y}.png', {
    attribution: '<a href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a> | <a href="http://www.ign.gob.ar/AreaServicios/Argenmap/IntroduccionV2" target="_blank">Instituto Geográfico Nacional</a> + <a href="http://www.osm.org/copyright" target="_blank">OpenStreetMap</a>',
    minZoom: 3,
    maxZoom: 20
});

const osm = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    maxZoom: 20
});

const CartoDB_DarkMatter = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png', {
	attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>',
	subdomains: 'abcd',
	maxZoom: 20
});

const gMapsSat = L.tileLayer('https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    {
      minZoom: 2,
      maxZoom: 20,
      id: "google.satellite"
    }
);

//https://carto.arba.gov.ar/cartoArba/ProxyMap?LAYERS=carto%3AMacizos%2Ccarto%3AParcelas%20Rurales%2Ccarto%3AParcelas%2Ccarto%3ASubparcelas%2Ccarto%3AEquipamiento_Comunitario%2Ccarto%3AEspacio%20Verde%2Ccarto%3ACotas%2Ccarto%3ACotas_sp%2Ccarto%3ASeccion%2Ccarto%3ACircunscripcion%2Ccarto%3APartidos%2Ccarto%3ACalles%2Ccarto%3ALimites%2Ccarto%3ACuerpos_de_agua%2Ccarto%3ARed_Ferroviaria%2Ccarto%3Aign_cursos_de_agua&TRANSPARENT=TRUE&FORMAT=image%2Fpng8&BUFFER=0&STYLES=Carto_Macizos_label%2CCarto_Parcelas_Rurales_label%2CCarto_Parcelas_label%2CCarto_Subparcelas_label%2CCarto_Parcelas_label%2Ccarto%3ACarto_Espacio_Verde_label%2CCotas%2CCarto_Cotas_Subparcelas%2CCarto_Seccion_label%2CCarto_Circunscripcion_label%2CPartidos_label%2CCarto_Calles_label%2CLimite_label%2Cempty%2Cempty%2Cempty&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&SRS=EPSG%3A900913&BBOX=-6717198.6013846,-4431845.8952876,-6711953.1103192,-4428141.0880734&WIDTH=1098&HEIGHT=775&tiled=true&gwc=false&namedgrouped=&namedgroupedtransparent=&maptransparent=false&viewparams=xmin%3A-6717198.6013846%3Bymin%3A-4431845.8952876%3Bxmax%3A-6711953.1103192%3Bymax%3A-4428141.0880734%3Bepsg%3A900913&intscale=13541&tilesorigin=-20037508.34%2C-20037508.34
const layer_ARBA = L.tileLayer.wms('https://carto.arba.gov.ar/cartoArba/ProxyMap?', {
    layers: 'carto:Partidos',
    transparent: true,
    format: 'image/png8',
    styles:['carto:Partidos','carto:empty'],
    request: 'GetMap',   
    crs: L.CRS.EPSG4326,    
    opacity: 1,
    tiled:true,
    gwc:true,
    namedgrouped:'carto:Carto_Grupo',
    namedgroupedtransparent: 'carto:Carto_Grupo_transparent',
    maptransparent:false,
    viewparams:'xmin:-63.67037287773794;ymin:-41.063632155155055;xmax:-56.46478067123993;ymax:-33.291679965530854;epsg:4326',
    intscale:1733371    
});
//viewparams:'minx=-63.67037287773794 miny=-41.063632155155055 maxx=-56.46478067123993 maxy=-33.291679965530854;epsg:4326'
//&intscale=6933486&tilesorigin=-20037508.34,-20037508.34'

//http://geo.arba.gov.ar/geoserver/idera/wms
//https://ide.transporte.gob.ar/geoserver/observ/wms
const layer_1101 = L.tileLayer.wms('https://geo.arba.gov.ar/geoserver/idera/wms?', {
    layers: 'idera:Grupo IDERA',
    STYLES: '',
    VERSION: '1.1.1',
    tiled: true,
    crs: L.CRS.EPSG4326,
    format: 'image/png',
    transparent: true,
    tilesorigin: [-180, 90],
    opacity: 1,
    maxZoom: 20
});

//***desde idehab */

//http://181.171.117.68/geoserver/wms?SERVICE=WMS&request=getCapabilities
const layer_4101 = L.tileLayer.wms('http://181.171.117.68/geoserver/wms?', {
    layers: 'capabase',
    crs: L.CRS.EPSG4326,
    format: 'image/png',
    transparent: true,
    opacity: 1
});
//http://181.171.117.68/geoserver/wms?&BBOX=-58.05450439453126%2C-35.02577930422151%2C-57.919235229492195%2C-34.85184724809422&CRS=EPSG%3A4326&FEATURE_COUNT=1&FORMAT=image%2Fpng&HEIGHT=618&X=353&INFO_FORMAT=text%2Fhtml&Y=396&LAYERS=publico%3Avillas_y_asentamientos_confirmados_rppvap&QUERY_LAYERS=publico%3Avillas_y_asentamientos_confirmados_rppvap&REQUEST=GetFeatureInfo&SERVICE=WMS&STYLES=&VERSION=1.1.1&WIDTH=394

const layer_5101_villas = L.tileLayer.wms('http://181.171.117.68/geoserver/wms?', {
    layers: 'villas_y_asentamientos_confirmados_rppvap',
    crs: L.CRS.EPSG4326,
    format: 'image/png',
    transparent: true,
    opacity: 1
});

//** cierra idehab */


//URBASIG http://urbasig.gob.gba.gob.ar/geoserver/urbasig/wms?
const layer_2101 = L.tileLayer.wms('http://urbasig.gob.gba.gob.ar/geoserver/urbasig/wms?', {
    layers: 'urbasig:_zonificacion',
    crs: L.CRS.EPSG4326,
    format: 'image/png',
    transparent: true,
    opacity: 1
});

const layer_3101 = L.tileLayer.wms('http://urbasig.gob.gba.gob.ar/geoserver/urbasig/wms?', {    
    layers: 'urbasig:areas_ley_8912',
    crs: L.CRS.EPSG4326,
    format: 'image/png',
    transparent: true,
    opacity: 1
});

const geojsonTempC = {
    fillColor: "yellow",
    color: "yellow",
    weight: 2,
    opacity: 0.8,
    fillOpacity: 0.05
};

//PRUEBA harcodeada, no se agrega al mapa, solo cuando se activa en el menu de capas
function gardenStyle(feature) {
    return {
        fillColor: "yellow",
        fillOpacity: 1,
        color: '#B04173',
    };
}

let geoJsonLayer = L.geoJson(null, {style: gardenStyle});

//const baseURL = process.env.REACT_APP_API_URL;

///// ***********
const Map_View_Expedientes =({categoriaSelect, partido, circ, secc, datosS}) => {

    console.log("PARTIDO---->", partido)
    console.log(typeof(partido));

    const [partidoRECIBIDO, setPartidoRECIBIDO] = useState(partido);

    const [partidoGEO, setPartidoGEO ]= useState(null);
    console.log(partidoGEO);

    const urlPartido = `/api/api_serv/query/partidos/?partidoNro=${partido}`;

    let geomJSON;

    const getDatosPartido = async (url) => {

		const headers = {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'			
		};
		const res = await fetch(url,{ headers })
		.then(res => res.json())
        .then(res=>{
            console.log(res)
            setPartidoGEO(res)
            
        })
		//.then(res => setdatos_Inf2_finalizadosTotal(res))
		.catch(err => console.log(err));
	}

    useEffect(()=>{
        if(partidoRECIBIDO > 0){
            console.log("NO EJECUTAR----->!!!!")
            getDatosPartido(urlPartido);
        }       

    }, [partidoRECIBIDO]) //solo la primera vez

    const layerHARD = useRef(null);

    useEffect(()=>{
        console.log(partidoGEO)
        if(partidoGEO === null){
            console.log("no hacer nada")
        } else {

            let geojson = partidoGEO[0].geojson;
            console.log(geojson)

            //let geomHard = JSON.parse(geojson[0].geom); 
            let geomHard = JSON.parse(geojson);           

            let datos = geomHard;
            
            console.log("CAMBIO partidoGEO---->2DO", datos);
            
            setCharacterFILTER(datos);            

        }
        
    }, [partidoGEO])

    //PENDIENTE: probar usar directamente el geoJSON generado en select_inf_detallado -----> exportGEOJSON
    //PENDIENTE: Estilos DIV informacion
    //PENDIENTE: Estilos Legenda
   
    const [datosUrba, setDatosUrba]=useState(null);
    console.log("datosUrba", datosUrba);// VER como procesar estos datos para mostrar la info. Es lo que viene del backend (consulta a urbasig)
    const [datosVillas, setDatosVillas] = useState(null);
    console.log(datosVillas);
    
    //ESTADOS para datos que vienen de URBASIG. Cunado se asignan valores mostrar en el DIV
    const [urbaPartido, setUrbaPartido]= useState(null);    
    const [urbaDescripcion, setUrbaDescripcion]= useState(null);
    const [urbaDesignacion, setUrbaDesignacion]= useState(null);
    const [ urbaUsoDominante, setUrbaUsoDominante]= useState(null);
    const [ urbaUsoComplementario, setUrbaUsoComplementario]= useState(null);
    const [ urbaFOS , setUrbaFOS]= useState(null);
    const [ urbaFOT, setUrbaFOT]= useState(null);
    const [ urbaSM, setUrbaSM]= useState(null);
    const [ urbaLM, setUrbaLM]= useState(null);
    const [ urbaHmax, setUrbaHmax]= useState(null);
    const [ urbaDensidad, setUrbaDensidad]= useState(null);
    const [ urbaArea, setUrbaArea]= useState(null);
    const [ urbaZona, setUrbaZona]= useState(null);
    const [ urbafechaAC, setUrbafechaAc]= useState(null);

    useEffect(()=>{        
        if(datosUrba !== null && datosUrba.length > 0 && buttonInfo === 'activo'){
            setUrbaPartido(datosUrba[0].properties.distrito)
            setUrbaDescripcion(datosUrba[0].properties.descripcio)
            setUrbaDesignacion(datosUrba[0].properties.designacio)
            setUrbaUsoDominante(datosUrba[0].properties.ud) //----> hdp
            setUrbaUsoComplementario(datosUrba[0].properties.uc)
            setUrbaFOS(datosUrba[0].properties.fos)
            setUrbaFOT(datosUrba[0].properties.fota)//---> hay 3 FOTs (fota, fotp,fotp_prem)
            setUrbaSM(datosUrba[0].properties.sm)
            setUrbaLM(datosUrba[0].properties.lm)
            setUrbaHmax(datosUrba[0].properties.hmax)
            setUrbaDensidad(datosUrba[0].properties.dena)// ---> ???
            setUrbaArea(datosUrba[0].properties.u_c_r) //----> ???
            setUrbaZona(datosUrba[0].properties.zonas)
            setUrbafechaAc(datosUrba[0].properties.fecha_ac)

            setVisibleDIVUrba('block');
        } else {
            setVisibleDIVUrba('none');
            //setVisibleDIV('none');
        }
    }, [datosUrba]) //
    
    //**ESTADOS para Villas y Asentamientos */    
    const [villasCodFolio, setVillasCodFolio]= useState(null);
    const [villasNombre, setVillasNombre]= useState(null);
    const [villasTipo, setVillasTipo]= useState(null);

    useEffect(()=>{        
        if(datosVillas !== null && datosVillas.length > 0){
            setVillasCodFolio(datosVillas[0].properties.cod_folio)
            setVillasNombre(datosVillas[0].properties.nombre);
            setVillasTipo(datosVillas[0].properties.tipo);

            setVisibleDIVVillas('block');

        } else if(datosVillas === null || datosVillas.length === 0){
            setVillasCodFolio(null)
            setVillasNombre(null)
            setVillasTipo(null)

            setVisibleDIVVillas('none');
        }
    }, [datosVillas])    
    //**cierra estados villas */

    const [buttonInfo, setButtonInfo]= useState('inactivo');
    console.log("UNO - ButtonInfo", buttonInfo);

    //urlM, layers, layers, BBOX, HEIGHT, WIDTH, X, Y
    const getDatosUrba = async (layers, q_layers, BBOX, HEIGHT, WIDTH, X, Y) => {

        console.log(layers);         
        //NOTA: ----> pasar solo layers[0] y q_layers[0]
        const url = `/api/api_serv/info/urbasig?layers=${layers[0]}&query_layers=${q_layers[0]}&bbox=${BBOX}&height=${HEIGHT}&width=${WIDTH}&x=${X}&y=${Y}`; //defir la url dentro de la function

		const res = await fetch(url)
		.then(res => res.json())
		.then(res => {
            //console.log(res);
			setDatosUrba(res);			
		})
		//.then(res => setDatosOriginales(res))
		.catch(err => console.log(err));
	}
    
    const getDatosVillas = async (layers, q_layers, BBOX, HEIGHT, WIDTH, X, Y) => {

        console.log(layers);         
        //NOTA: ----> pasar solo layers[1] y q_layers[1]
        const urlVillas = `/api/api_serv/info/villas?layers=${layers[1]}&query_layers=${q_layers[1]}&bbox=${BBOX}&height=${HEIGHT}&width=${WIDTH}&x=${X}&y=${Y}`; //defir la url dentro de la function

		const res = await fetch(urlVillas)
		.then(res => res.json())
		.then(res => {            
			setDatosVillas(res);			
		})		
		.catch(err => console.log(err));
	}    

    const partidoStyle = (feature) => {
        return {
            fillColor: "yellow",
            fillOpacity: 1,
            color: '#B04173',
        };
    }
    
    //PRUEBAS: para cargar partido, por ahora no funciona (06agost2023)
    const [pruebaAA, setPruebaAA]= useState(null); 
    console.log(pruebaAA);

    //const layerHARD = useRef(null);
    //layerHARD.current = L.geoJSON(null, {style: partidoStyle})

    // ??? pruebas para mapear partido****************

    useEffect(()=>{
        let partidoOriginal = '000';
        if(partido.length===1){
            partidoOriginal = `00${partido}`;
            console.log(partidoOriginal)
            
        } else if(partido.length===2){
            partidoOriginal = `0${partido}`;
        } else {
            partidoOriginal = `${partido}`;
        }

        //getPartidoAA(partidoOriginal); //POR AHORA NO USAR, error en geoservicio

    },[]);//EJECUTAR SOLO LA PRIMERA VEZ QUE CARGA

    const buttonClick = (e)=>{

		e.preventDefault(); 
        //e.stopPropagation();

        if(buttonInfo === 'activo'){
            setDatosUrba(null);
            setButtonInfo('inactivo');
            setVisibleDIVUrba('none');
            console.log('info desactivada');
            setVisibleDIVFloating('none'); //Al desactivar info, ocultar el DIV floating?
            
        }else {
            //alert('activar Info');
            setButtonInfo('activo');
        }
	}    

    /*const getPartidoAA=async(partidoOriginal)=>{

        let partidoBusqueda = partidoOriginal;
        let cqlFilterPartido= `CQL_FILTER=cca LIKE '${partidoBusqueda}'`;

        let urlB=`https://geo.arba.gov.ar/geoserver/idera/ows?`;
        let parametersB=`service=WFS&version=1.0.0&request=GetFeature&typeName=idera:Departamento&maxFeatures=1&outputFormat=application/json&${cqlFilterPartido}&SRSNAME=EPSG:4326`;
        let urlWFS=`${urlB}${parametersB}`;
        const res= await fetch(urlWFS);

        const data= await res.json(); 
        console.log("DATAAA---->5TO", data);
        setPruebaAA(data);
    }*/

    const getPartidoAA = async (partidoOriginal) => {
        
        //NOTA: ----> pasar solo layers[0] y q_layers[0]
        const url = `/api/api_serv/fetch/partidos?partidoOriginal=${partidoOriginal}`; //defir la url dentro de la function

		const res = await fetch(url)
		.then(res => res.json())
		.then(res => {
            console.log("RESPUESTA partido",res);
			//setDatosUrba(res);			
		})
		//.then(res => setDatosOriginales(res))
		.catch(err => console.log(err));
	}

    useEffect(()=>{
        if(pruebaAA !== null){
            console.log("CARGAR POLY PARTIDO")
            //layerHARD.current.addData(pruebaAA)// pruebaAA
        }
    }, [pruebaAA]);

    //*** En esta seccion se define el estado y segun el poligono seleccionado se cambian los estados de los valores a cambiar */
    
    const [selectPolygon, setSelectPolygon]= useState(null);//en la function click se le va a cambiar el estado
    //console.log(selectPolygon)

    const [expedienteDIV, setExpDIV]=useState(null);//desde acá van al DIV que se muestra en el mapa al seleccionar un poligono
    const [programaDIV, setProgramaDIV]=useState(null);
    const [estadoDIV, setEstadoDIV]=useState(null);
    const [partidoDIV, setPartidoDIV]=useState(null);//hasta acá
    const [aliasDIV, setAliasDIV]=useState(null);
    const [nomenclaDIV, setNomenclaDIV]=useState(null);
    const [cantLotesDIV, setCantLotesDIV]=useState(null);
    const [tipoDIV, setTipoDIV] =useState(null);

    useEffect(()=>{
        if(selectPolygon !== null){
            setVisibleDIVInfo('block')
            
            console.log("MOSTRAR DIV CON LOS DATOS del poligono seleccionado");
            //console.log(selectPolygon.programa);
            setProgramaDIV(selectPolygon.programa);
            setEstadoDIV(selectPolygon.estado);
            setExpDIV(selectPolygon.expediente);
            setPartidoDIV(selectPolygon.partido);

            setAliasDIV(selectPolygon.alias);
            setNomenclaDIV(selectPolygon.nomenclaSintesis);
            setCantLotesDIV(selectPolygon.cantLotes);
            setTipoDIV(selectPolygon.tipo);

        } else if ( selectPolygon === null){
            setVisibleDIVInfo('none');
        }

    },[selectPolygon])
    //**cierra seccion  */    

    //**abre seccion Estados para DIVs () -06agos2023-*/

    const [visibleDIVFloating, setVisibleDIVFloating]= useState('block'); //div contenedor de los otros DIVs, por defecto apagado
    console.log(visibleDIVFloating);
    /* CAMBIA de estado cuando:
        - alguno de los otros divs cambia a block, se prende.
        -cuando TODOS los otros divs están apagadaos, se apaga.
        
        CREAR un useEffect que maneje esos cambios

    */
    
    const [visibleDIVUrba, setVisibleDIVUrba] = useState('none');//div datos URBASIG, capa wms 
    console.log("visibleDIVUrba--->",visibleDIVUrba)
    const [visibleDIVVillas, setVisibleDIVVillas] = useState('none');//div datos VILLAS

    const [visibleDIVInfo, setVisibleDIVInfo] = useState('none');// div con la info de los proyectos de generacion de suelo (capa vectorial)

    //**cierra estados DIVs */
    useEffect(()=>{
        console.log("manejar el cambio del DIV floating");
        if(visibleDIVUrba==='none' && visibleDIVVillas === 'none' && visibleDIVInfo === 'none'){
            setVisibleDIVFloating('none')
        } else {
            setVisibleDIVFloating('block')
            console.log("TRES - ")
        }

    }, [visibleDIVUrba, visibleDIVVillas, visibleDIVInfo])

    useEffect(()=>{
        if(visibleDIVFloating==='none'){
            setVisibleDIVInfo('none')

            setAliasDIV(null);
            setNomenclaDIV(null);
            setCantLotesDIV(null);
            setTipoDIV(null);
        }

    }, [visibleDIVFloating])

    //***cierra Effects para DIVs */

    var selectedH = null;

    const highlightFeature = (e) => {
        //setVisibleDIVInfo('none') 
        let layer = e.target; 
        console.log(layer); 
        //e.target.setStyle(selectedStyle);
        selectedH= e.target;        
        
        //console.log(layer);
        console.log(layer.feature.geometry.properties);

       // if (selected)
        //setSelectPolygon(layer.feature.geometry.properties); /// cuando cambia----> mostrar DIV
        //layer.bindPopup(layer.feature.geometry.properties.expediente);
    }     

    // PARA manejar seleccion de proyectos *************************

    const [selected_MM, setSelected_MM] = useState([]) 
    console.log("SELECCIONADO MM---->", selected_MM );
    //const [proyectoInfo, setProyectoInfo] = useState("ver");
    
    useEffect(()=>{
        console.log("SELECCIONADO---->", selected_MM)

        if(selected_MM.length === 1){

            console.log("PRIMERA SELECCION"); //UNO
            setVisibleDIVFloating('block');
            let target = selected_MM[0];

            target.setStyle({
                weight: 5,
                color: "yellow"
            });

            setSelectPolygon(target.feature.geometry.properties)
            mapRef.current.fitBounds(target.getBounds());

        } else if(selected_MM.length > 1 && selected_MM[0] === selected_MM[1]){

            console.log("SE SELECCIONO EL MISMO POLIGONO, RESET");
            setVisibleDIVFloating('none');

            let target = selected_MM[0];

            let layer_id = target._layers;
            //console.log(layer_id);
            //console.log(typeof(layer_id));
            let clave = Object.keys(layer_id)
            //console.log(clave);
            let claveL = clave[0];
            //console.log(layer_id[clave])
            //console.log(layer_id["claveL"])

            let optionsL = layer_id[claveL];
            //console.log(optionsL);
            //console.log(optionsL.options.color)
            let colorL = optionsL.options.color;

            if(colorL === 'yellow'){
                
                geoJsonLayerH.resetStyle(target);
                setSelectPolygon(null)

            //NOTA: --->SI CAMBIA EL COLOR DE LA LEYENDA Y ESTILOS, CAMBIAR ESTOS COLORES
            } else if(colorL === '#207AB6' || colorL === '#F6E620' || colorL === '#E31A1C' || colorL === '#E77148' || colorL === '#987DB7' || colorL === 'gray'){

                target.setStyle({
                    weight: 5,
                    color: "yellow"
                });
                setSelectPolygon(target.feature.geometry.properties)
                setVisibleDIVFloating('block');
                mapRef.current.fitBounds(target.getBounds());

            }

        } else if(selected_MM.length > 1 && selected_MM[0] !== selected_MM[1]){

            console.log("SON DISTINTOS, PINTAR EL ULTIMO, RESET EL PREVIOUS");

            let target = selected_MM[1];            

            geoJsonLayerH.resetStyle(target);

            let target_Ultimo = selected_MM[0];

            target_Ultimo.setStyle({
                weight: 5,
                color: "yellow"
            });
            setSelectPolygon(target_Ultimo.feature.geometry.properties)
            mapRef.current.fitBounds(target_Ultimo.getBounds());
        }

    }, [selected_MM]);    

    function onEachFeature(feature, layer) {        
               
        layer.on({                        
            'click': function (e) {
                console.log(layer);
                
                var layer_id = e.target._layers;
                //console.log(layer_id);
                //console.log(typeof(layer_id));
                var clave = Object.keys(layer_id)
                //console.log(clave);
                var claveL = clave[0];
                //console.log(layer_id[clave])
                //console.log(layer_id[clave])
                //console.log(layer_id["claveL"])

                var optionsL = layer_id[clave];           
                
                console.log("ESTADO A - Seleccionar")

                setSelected_MM(selected_MM => [ layer , ...selected_MM  ]);
                
                //console.log(selected_MM);          
                
                console.log("selected " + e.target.feature.geometry.properties.expediente);
                //console.log(e.target._leaflet_id)
                //console.log(e.target);

                e.target.feature.geometry.properties.selected = true;                           
            }            
        });
    }

    function programaStyle(feature) { 
        switch (feature.geometry.properties.programa) {
            case 'LOTES CON SERVICIOS': return { weight: 3, fillColor: "#207AB6", color: "#207AB6", fillOpacity: 0.25 };
            case 'PLAN FAMILIA PROPIETARIA': return {weight: 3, fillColor: "#F6E620", color: "#F6E620", fillOpacity: 0.25 };
            case 'REGIMEN DE INTEGRACIÓN SOCIOURBANA': return {weight: 3, fillColor: "#E31A1C", color: "#E31A1C", fillOpacity: 0.25 };
        }
    }

    function estadoStyle(feature) {                
        switch (feature.geometry.properties.estado) {            
            case 'EN PROCESO': return { weight: 3, fillColor: "#E77148", color: "#E77148", fillOpacity: 0.25 };//#E77148
            case 'AFECTADO CON RESOLUCIÓN': return { weight: 3, fillColor: "#987DB7", color: "#987DB7", fillOpacity: 0.25 };
            case 'INACTIVOS': return { weight: 3, fillColor: "gray", color: "gray", fillOpacity: 0.25 };
        }
    }
    
    //las capas para mostrar los proyectos, sin datos, pero indicando el estilo
    const geoJsonLayerH = L.geoJson(null, { style: programaStyle, onEachFeature: onEachFeature});    
    const geoJsonLayerEstado = L.geoJson(null, { style: estadoStyle, onEachFeature: onEachFeature});

    const geoJsonPARTIDO = useRef(null);
    geoJsonPARTIDO.current = L.geoJson(null, {style: gardenStyle});
    //console.log(geoJsonPARTIDO.current)

    const mapRef = useRef(null);

    const layerRef = useRef(null); //PARA layerGroup    
 

    //**** 25-jul-2023 capa con los expedientes */

    const [datosLayer, setDatosLayer]=useState(null);
    console.log(datosLayer);

    useEffect(()=>{
        //layerRef.current.clearLayers();
        const respuesta= datosS.map((element, index) => {
            //return console.log(index, element.geom_geojson)
            
            if(element.geom_geojson === null || element.geom_geojson === "{\"type\":\"GeometryCollection\",\"geometries\":[]}" || element.geom_geojson === '{"type":"GeometryCollection","geometries":[]}'){
                //console.log("NO TIENE DATOS");
                return {"type":"GeometryCollection", "properties": {},"geometries":[]};
            } else {
                //console.log("TIENE DATOS");
                //console.log(index, element.geom_geojson)
                
                let objA = {};                

                let geom = JSON.parse(element.geom_geojson);
                let expedienteA = element.expediente;
                let estadoA = element.estado;
                let programaA = element.programa;
                let partidoA= element.partido_nombre;
                let aliasA = element.nombre_proyecto; //ALIAS
                let nomenclaSintesisA = element.datos_18_sintesis_nomenclatura;
                let cantLotesA = element.cantidad_lotes;
                let tipoA = element.tipo;

                let propiedades =  { properties: {
                        expediente: `${expedienteA}`,
                        programa: `${programaA}`, 
                        estado: `${estadoA}`, 
                        partido: `${partidoA}`,
                        alias: `${aliasA}`,
                        nomenclaSintesis: `${nomenclaSintesisA}`,
                        cantLotes: `${cantLotesA}`,
                        tipo: `${tipoA}`
                    } };

                //REVISAR asignacion (4-agos-2023)
                Object.assign(objA, geom, propiedades)                
                //console.log(objA);                
                
                return objA;                
            }            
        });
        console.log(respuesta);

        const nuevoG = {
            "type": "FeatureCollection",
            "features": respuesta	
        }
        //***NOTA: le asigna a datosLayer un nuevo estado con la geometria y algunas propiedades para el mapeo.
        setDatosLayer(nuevoG)
        //geoJsonLayerH.addData(nuevoG);

    }, [])

    useEffect(()=>{ //**4-agos-2023 cargar datos en distintas capas segun seleccion */

        if(datosLayer !== null){
            if(categoriaSelect==='AA'){
                //console.log("ENTRA AA")                 
                mapRef.current.removeLayer(geoJsonLayerEstado);              
                geoJsonLayerH.addData(datosLayer);
                mapRef.current.addLayer(geoJsonLayerH);
            } else if(categoriaSelect==='BB'){
                //console.log("ENTRA BB")
                mapRef.current.removeLayer(geoJsonLayerH);
                geoJsonLayerEstado.addData(datosLayer);
                mapRef.current.addLayer(geoJsonLayerEstado);
            }            
        }        

    }, [datosLayer, categoriaSelect])

    
    const editableLayers = useRef(null);    

    const customParams = useRef(null);    
    const toolbar = useRef(null);    
          
    
    const { geom, cambioGeom } = useContext(SessionContext);
    //alert(JSON.stringify(geom));
    //console.log("DEFAULTV_en_GEOM", geom);

    //----ESTADOS----
    const [textGEOM, setTextGEOM]=useState(JSON.stringify(geom)); 
    //console.log("textGEOM", textGEOM);
    //console.log(typeof(textGEOM));
    const [featuresFILTER, setCharacterFILTER] = useState(null);
    //const [polygonFINAL, setPolygonFINAL] = useState(null);
    
    //const [geomPoly, setGeomPoly]=useState(geom);

    const [addLAYER, setAddLAYER]=useState([]);

    ///*FUNCIONA, POR AHORA NO USAR
    const [newParcel, setNewParcel] = useState([]);    
    

    //CASO 1: Ideal (que lleguen los 3 datos completos 114 01 0A)- armo la cadena y consulto contra url Secciones

    let partidoQuery = '000';
    if(partido.length===1){
        partidoQuery = `00${partido}`;
    } else if(partido.length===2){
        partidoQuery = `0${partido}`;
    } else {
        partidoQuery = `${partido}`;
    }
    //console.log("PARTIDO QUERY--->", partidoQuery);

    let circQuery = '00';

    if(circ === null || circ === ''){
        circQuery= '00';
    } else if(circ.length===1){
        circQuery = `0${circ}`;
    } else {
        circQuery = `${circ}`;
    }
    //console.log("QUERY CIRC--->", circQuery);

    let seccQuery = '00';
    if(secc === null || secc === ''){
        seccQuery = '00';
    } else if(secc.length===1){
        seccQuery = `0${secc}`;
    } else {
        seccQuery = `${secc}`;
    }
    //console.log("QUERY SECC--->", seccQuery);

    let cadenaFilter;
    let typeName; //typeName=idera:Seccion_Catastral

    //let urlQuery;

    if(circQuery==='00'){
        cadenaFilter=`${partidoQuery}`;
        typeName=`typeName=idera:Departamento`;
        //console.log("A");    

    } else if(circQuery!=='00' && seccQuery === '00'){
        cadenaFilter=`${partidoQuery}${circQuery}`;
        typeName=`typeName=idera:Circunscripcion`;
        //console.log("B"); 

    } else if(circQuery!=='00' && seccQuery !== '00'){
        cadenaFilter=`${partidoQuery}${circQuery}${seccQuery}`;
        typeName=`typeName=idera:Seccion_Catastral`;
        //console.log("C"); 
    }
    console.log("CADENA QUERY---->", cadenaFilter);

    //CQL_FILTER=cca LIKE '078020K'
    let cqlFilter= `CQL_FILTER=cca LIKE '${cadenaFilter}'`;

    let cqlFilterPartido= `CQL_FILTER=cca LIKE '${partidoQuery}'`;
    

    const savePolygon= (geojsonDatos)=>{             
       
        cambioGeom(geojsonDatos);
    } 
    
    //-------------EFECTOS--------

    useEffect(() => {   

        mapRef.current = L.map('map', {
            center: [-37.265645, -60.067551],
            zoom: 7,
            //layers: [ bingLayer ]
        });
        //getData();
        //getSoloPartido();
        
        geoJsonLayerH.addTo(mapRef.current);
        geoJsonLayerH.bringToFront();
        geoJsonLayerEstado.addTo(mapRef.current);
        geoJsonLayerEstado.bringToFront();
        
    }, []);
        
    //ULTIMO PASO: if featuresFILTER ya tiene datos (distinto de null), agregar a geoJsonPARTIDO y luego la capa al mapa
    //hacer zoom
    //para que no se vea el poligono, limpiar la capa
    useEffect(()=>{ 

        if(featuresFILTER !== null){
            
            let datos = featuresFILTER;
            let editable = editableLayers.current;

            geoJsonPARTIDO.current.addData(datos)
            console.log(geoJsonPARTIDO.current)

            // ??????????? geoJsonPARTIDO.current = L.geoJSON(datos, {style: geojsonTempB}).addTo(mapRef.current);
            //console.log("3RO...QUE TIENE?", geoJsonPARTIDO.current)
            //console.log("4TO----->", geoJsonPARTIDO.current.getBounds().isValid())

            if(editable.getBounds().isValid() === true){
                mapRef.current.fitBounds(editableLayers.current.getBounds());

            } else if(geoJsonPARTIDO.current.getBounds().isValid() === true){
                mapRef.current.fitBounds(geoJsonPARTIDO.current.getBounds());
                geoJsonPARTIDO.current.bringToFront();
                console.log("----> opcion A");
                //getSoloPartido() ---> NO: bucle infinito
            } else if(partido !== null && partido > 0){
                
                toast.success(`Haciendo zoom al partido ${partido}`);
                console.log("----> opcion B");
                getSoloPartido()                
                
            } else if(partido === null || partido === 0){
                toast.success("No se puedo obtener la capa");
            }
        }        

    }, [featuresFILTER]);

    useEffect(()=>{
        if(textGEOM !== `{"type":"FeatureCollection","features":[]}`){
            //console.log("QUE CARGUE!!!!", addLAYER);
            //alert("ALERT 2 - nuevo")

            if(addLAYER !== null){

                return addLAYER.map((feature, index) => {
                    
                    editableLayers.current.addLayer(feature, {style: gardenStyle});
                    console.log(editableLayers.current)

                    editableLayers.current.eachLayer((layer) => {
                        if (layer) {
                            console.log(layer);
                        }
                    })
                })
                //editableLayers.current.addLayer(addLAYER, {style: geojsonTemp});
            }            
        }
    }, [addLAYER])    
    

    //NUEVA PRUEBA solo la primera vez
    //**** B */
    useEffect(()=>{ 
        if(textGEOM !== `{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{}}]}`){

            let datos = geom; 
            console.log(datos);

            let geoJsonLayerB = L.geoJson().addTo(mapRef.current);

            geoJsonLayerB.on('layeradd', function (e) {
                //console.log(e.layer)
                var type = e.layerType,
                layer = e.layer;

                setAddLAYER(addLAYER => [...addLAYER, layer]);
            });

            if(datos.features.length > 0){
                geoJsonLayerB.addData(datos, {style:geojsonTempC});
                geoJsonLayerB.clearLayers();
            }         
        }  
    }, []);
       
    //****LLAMAR SOLO CUANDO FALLA EL BOUND***(5-abril-2023)
    //nota: armar una para consultar la circ si falla el limite de la secc (PENDIENTE)
    const getSoloPartido=async()=>{
        let urlB=`https://geo.arba.gov.ar/geoserver/idera/ows?`;
        let parametersB=`service=WFS&version=1.0.0&request=GetFeature&typeName=idera:Departamento&maxFeatures=10&outputFormat=application/json&${cqlFilterPartido}&SRSNAME=EPSG:4326`;
        let urlWFS=`${urlB}${parametersB}`;
        const res= await fetch(urlWFS);

        const data= await res.json(); 
        //console.log("DATAAA---->5TO", data);
        setCharacterFILTER(data);
    }

    const getData = async ()=>{     
        
        let urlB=`https://geo.arba.gov.ar/geoserver/idera/ows?`;
        
        //let parametersB=`service=WFS&version=1.0.0&request=GetFeature&typeName=idera:Seccion_Catastral&maxFeatures=50&outputFormat=application/json`;
        //&CQL_FILTER=cca LIKE '078020K'
        let parametersB=`service=WFS&version=1.0.0&request=GetFeature&${typeName}&maxFeatures=50&outputFormat=application/json&${cqlFilter}&SRSNAME=EPSG:4326`;
        //console.log(parametersB);        

        let urlWFS=`${urlB}${parametersB}`;        

        const response= await fetch(urlWFS);
        //console.log(response)
        
        if (response.ok) {
            const res = await response.json()
            .then(res => {
                setCharacterFILTER(res);			
            })
            .catch(err => console.log(err))
            .finally(() => setCharacterFILTER(null));
            //return res
        } else {
            throw new Error('Network response was not ok.')
        }
        
    }   
    
    useEffect(()=>{
        if(osm ){
            mapRef.current.removeLayer(osm);
            osm.addTo(mapRef.current);
            //mapRef.current.removeLayer(layer_1101); //<-------------------
            //layer_1101.addTo(mapRef.current); // <-------------------------
        }        
    })
       

    useEffect(()=>{
        if(layer_1101 ){
            mapRef.current.removeLayer(layer_1101);
            //osm.addTo(mapRef.current);
            //mapRef.current.removeLayer(layer_1101); //<-------------------
            //layer_1101.addTo(mapRef.current); // <-------------------------
        }        
    })       

    useEffect(()=>{

        if(buttonInfo === 'activo'){
            mapRef.current.on('click', function (e) {
                //setVisibleDIV('none')
                
                var countLayers = 1;
                //console.log(e);
                var coord=e.latlng.toString().split(',');
                var lat=coord[0].split('(');
                var long=coord[1].split(')');
                //console.log("you clicked the map at LAT: "+ lat[1]+" and LONG:"+long[0])
    
                let layerSS = e.target._layers;
                //console.log(layerSS);
    
                if( countLayers > 0){
                    var pixelPosition = e.layerPoint; //new Array(3),
                    var _layers = this._layers,
                          layers = new Array(3),
                          versions = [],
                          styles = [];
                
                        for (var x in _layers) {
                            var _layer = _layers[x];
                            //console.log(_layer)
                            if(_layer.feature){
                                //console.log("FEATURE", _layer._leaflet_id)
                                //console.log( _layer.feature.geometry.properties.alias)
                                //console.log( _layer.feature.geometry.properties.programa)
                            }
                            if (_layer.wmsParams && _layer.wmsParams.layers ==='urbasig:_zonificacion') {                                

                                //layers.push(_layer.wmsParams.layers);
                                layers.splice(0,1, _layer.wmsParams.layers);

                                versions.push(_layer.wmsParams.version);
                                styles.push(_layer.wmsParams.styles);
                                console.log("LAYERS --->",layers);
                                console.log("LAYERS 0--->",layers[0]);

                            } else if(_layer.wmsParams && _layer.wmsParams.layers ==='villas_y_asentamientos_confirmados_rppvap'){
                                layers.splice(1,1, _layer.wmsParams.layers);

                                versions.push(_layer.wmsParams.version);
                                styles.push(_layer.wmsParams.styles);                                
                                console.log("LAYERS --->",layers);
                                console.log("LAYERS 1--->",layers[1]);

                            }
                        }
                
                    var clickedLatLng = mapRef.current.layerPointToLatLng(pixelPosition);                    
                    
                    var sw = mapRef.current.options.crs.project(mapRef.current.getBounds().getSouthWest());
                    var ne = mapRef.current.options.crs.project(mapRef.current.getBounds().getNorthEast());
                    var BBOX = sw.x + "," + sw.y + "," + ne.x + "," + ne.y;                   

                    var WIDTH = mapRef.current.getSize().x;
                    var HEIGHT = mapRef.current.getSize().y;
                    var X = Math.trunc(mapRef.current.layerPointToContainerPoint(e.layerPoint).x);
                    var Y = Math.trunc(mapRef.current.layerPointToContainerPoint(e.layerPoint).y);
                                                                     
    
                    //**NOTA: 04-agos-2023: Solo cuando la capa urbasig:zonif... está prendida debe poder llamar a getDatosUrba */
                    if(layers[0] ==='urbasig:_zonificacion'){
                        const dDD = getDatosUrba (layers, layers, BBOX, HEIGHT, WIDTH, X, Y);
                        //console.log(dDD);
                        //setDatosUrba(dDD);
                        setVisibleDIVUrba('block');
                        console.log("DOS - layers[0] Urba");
                    } else if(layers[0] !=='urbasig:_zonificacion'){
                        setVisibleDIVUrba('none');
                        //setDatosUrba(null);
                    }

                    if(layers[1] === 'villas_y_asentamientos_confirmados_rppvap'){
                        const fFF = getDatosVillas(layers, layers, BBOX, HEIGHT, WIDTH, X, Y);
                        //console.log(fFF);
                        setDatosVillas(fFF);
                    } else if(layers[1] !== 'villas_y_asentamientos_confirmados_rppvap'){
                        console.log("Villas APAGADA")
                        setVisibleDIVVillas('none');
                        setDatosVillas(null)
                    }                   
                        
                }//cierra if countLayers
            });
        } else if(buttonInfo === 'inactivo'){
            console.log("INACTIVO----> ocultar DIV")
            mapRef.current.off('click')
            //setDatosUrba(null);
            setVisibleDIVUrba('none');            
        }

    }, [buttonInfo])
   

	useEffect(() => {
                        
        //layer_1101.addTo(mapRef.current); // <---------------------------------
        geoJsonLayer.addTo(mapRef.current);
        //requiredHARD.addTo(mapRef.current);     

        editableLayers.current = new L.FeatureGroup();
        editableLayers.current.addTo(mapRef.current);

        //layerHARD.current.addTo(mapRef.current).bringToFront(); //harcodeada para prueba
        geoJsonPARTIDO.current.addTo(mapRef.current).bringToFront();
        
        editableLayers.current.on('layeradd', function (e) {
            
            var type = e.layerType,
            layer = e.layer;
            console.log(layer);

            editableLayers.current.addLayer(layer);
            console.log(editableLayers.current);
        });

        mapRef.current.on('draw:deleted', function (e) {
            
            let layerGeoJSON = editableLayers.current.toGeoJSON();
            console.log(layerGeoJSON)
        });

        mapRef.current.on('draw:deletestart', function(e) {
                        
            let layerGeoJSON = editableLayers.current.toGeoJSON(); //editableLayers.current.
            console.log(layerGeoJSON);
            savePolygon(layerGeoJSON);
        });

        //draw:deletestop
        mapRef.current.on('draw:deletestop', function (e) {
            //console.log(e);
            //console.log(e.target);
            let layerGeoJSON = editableLayers.current.toGeoJSON(); //editableLayers.current.
            //console.log(layerGeoJSON);
            savePolygon(layerGeoJSON)
        })

        mapRef.current.on('draw:editstart', function (e) {
            
        });        
        
        mapRef.current.on('draw:edited', function (e) {
            
            let layerGeoJSON = editableLayers.current.toGeoJSON(); //editableLayers.current.
            savePolygon(layerGeoJSON)
        })

        mapRef.current.on("draw:drawstart", function (event) {

        });

        mapRef.current.on("draw:drawstop", function (event) {

            let layerGeoJSON = editableLayers.current.toGeoJSON(); // editableLayers.current.
            savePolygon(layerGeoJSON);
            
        });

        mapRef.current.on('draw:created', function (e) {
            var type = e.layerType,
            layer = e.layer;
        
            var featureId = L.stamp(layer);
            layer.feature = {
                'type': 'Feature',
                'properties': {                    
                    'feature_type': e.layerType
                }
            };            
        
            var jsonL = layer.toGeoJSON();            
            
        });        

        mapRef.current.on(L.Draw.Event.CREATED, function(e) {
            var type = e.layerType,
              layer = e.layer;
              console.log(layer);
            if (type === "marker") {
              layer.bindPopup("A popup!");
            }
            console.log("AQUI event CREATED---->",layer );
            
            //mapRef.current.addLayer(editableLayers.current);

            editableLayers.current.addLayer(layer, {style: gardenStyle}); // editableLayers.current.

            //NOTA: lo agrega con el ESTILO, pero no deja editar!!
            /*
            var jsonL = layer.toGeoJSON();
            //console.log(jsonL);
            editableLayers.current = L.geoJSON(jsonL, {style: geojsonTemp}).addTo(mapRef.current);
            */
                      
        });

        
        //*****NOTA: Revisar con el código sin comentar error. Comentando sigue editando*****
        mapRef.current.on(L.Draw.Event.EDITED, function(e) {
            //e.layers.eachLayer(layer => //console.log('got a layer!', layer));
            console.log("EVENT EDIT - tira el error")
            var type = e.layerType,
            layer = e.layer;
            //if(layer !== null){
                //editableLayers.current.addLayer(layer);
            //}            
        });           

        const baseMaps = {
            "<span style='color: gray'>Sin capa base</span>": sin_base,
            "<span style='color: gray'>OpenStreetMap</span>": osm,
            "<span style='color: gray'>ArgenMap</span>": argenMap,
            "<span style='color: gray'>Carto</span>": CartoDB_DarkMatter,
            //"<span style='color: gray'>Bing</span>": bingLayer,
            "<span style='color: gray'>Google Satelital</span>": gMapsSat            
        };        

        const overlayMaps = {
            //"Polygon": geoJsonLayerH,
            //"Estado": geoJsonLayerEstado,
            "Partido": geoJsonPARTIDO.current,
            //** 27-jul-2023 "Partido": geoJsonPARTIDO.current,
            //** 27-jul-2023 "PARTIDO_prueba": layerHARD.current,
            //"ARBA_prueba": layer_ARBA,
            "ARBA": layer_1101,
            "ARBA base": layer_4101,
            "Zonificación según usos": layer_2101,
            "Areas - Ley 8912/77" : layer_3101,
            "Villas y asentamientos" : layer_5101_villas
            
            //"Prueba": requiredHARD,
            //"Temp": tempLayer  -----> NO se agrega la capa con la que se hizo la busqueda          
        };
        L.control.layers(baseMaps, overlayMaps, {position: 'topleft'}).addTo(mapRef.current);

        const options = {
            position: "topleft",
            /*draw: {
                polyline: false,
                polygon: {
                    title: 'Dibujar nuevo poligono',
                    icon: new L.DivIcon({
                        iconSize: new L.Point(10, 10),
                        className: 'leaflet-div-icon leaflet-editing-icon'
                    }),
                    shapeOptions: {
                        clickable: true,
                        color: "dodgerblue",
                    },
                    allowIntersection: false, // Restricts shapes to simple polygons 
                    drawError: {
                        color: '#e1e100', // Color the shape will turn when intersects 
                        message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect 
                    }
                },
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false
            },
            edit: {
                featureGroup: editableLayers.current //REQUIRED!! //editableLayers.current.                
            }*/
        };
        
        /* **27-ju-2023
        const drawControl = new L.Control.Draw(options);
        drawControl.addTo(mapRef.current);
        */

	}, [])

	const optionsCategoria = [
        {value: "AA", label: "AA"},
        {value: "BB", label: "BB"}
    ]
        
    const onChangeAA = (e) =>{
        console.log("select");
    }
	

	return (
        <Fragment>
        {/*<div className="selectButton leaflet-control leaflet-bottom leaflet-left">            
            <SelectInput  className="leaflet-control" style={{zIndex : 2001}} onChange={e => onChangeAA(e)} options={optionsCategoria}/>            
        </div>*/}
            <div id='map' style={style} >
                
                <div className="leaflet-top leaflet-left leaflet-control" style={{display : 'block'}}>
                    <button className={buttonInfo==="inactivo"?"leaflet-control refreshButton":"leaflet-control refreshButtonInactivo"} onClick={buttonClick}>Info</button>
                </div>                
                
                <LegendData className="leaflet-top leaflet-left" categoriaSelect={categoriaSelect}/>
                
            
                <div className="floatingCapas" style={{display: visibleDIVFloating}}> 
                    <div style={{margin : "10px"}}>                  
                                
                        <div className="leaflet-control" style={{display: visibleDIVUrba}}>
                            <h6><b>Zonificación según usos</b></h6>                   
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Partido:</span> {urbaPartido}</p>                        
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Descripción:</span> {urbaDescripcion}</p> 
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Designación:</span> {urbaDesignacion}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Uso dominante:</span> {urbaUsoDominante}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Uso complementario:</span> {urbaUsoComplementario}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> FOS:</span> {urbaFOS}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> FOT:</span> {urbaFOT}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> SM:</span> {urbaSM}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> LM:</span> {urbaLM}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Hmax:</span> {urbaHmax}</p> 
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Densidad:</span> {urbaDensidad}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Area:</span> {urbaArea}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Zona:</span> {urbaZona}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Última actualización:</span> {urbafechaAC}</p>
                        </div>

                        <div style={{display : visibleDIVVillas}}>
                            <h6><b>Villas y asentamientos</b></h6>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Cod. folio:</span> {villasCodFolio}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Nombre:</span> {villasNombre}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Tipo:</span> {villasTipo}</p>                            
                        </div>
                    
                        <div className="leaflet-control info" style={{display : visibleDIVInfo}}>
                            {/*<h6>Programa: {programaDIV}</h6>
                            <h6>Expediente: {expedienteDIV}</h6>
                            <h6>Estado: {estadoDIV}</h6>
                            <h6>Partido: {partidoDIV}</h6>*/}
                            <h6><b>Generación de suelo urbano</b></h6>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Alias:</span> {aliasDIV}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Nomenclatura sintesis:</span> {nomenclaDIV}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Cantidad de lotes:</span> {cantLotesDIV}</p>
                            <p style={{color: "rgb(131,131,131)"}}> <span className="subtitulos"> Tipo:</span> {tipoDIV}</p>
                        </div>

                    </div> 
                    
                </div>
            </div>
        </Fragment>
    )    
} 

export default Map_View_Expedientes;