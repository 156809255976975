import React, { useRef, useState, useEffect, useContext } from 'react';
import { SessionContext } from '../context/sessionContext';
import { toast } from "react-toastify";
import L from 'leaflet';
import Draw from 'leaflet-draw';
//import { Map, TileLayer, FeatureGroup } from "react-leaflet";
//import { EditControl } from "react-leaflet-draw";
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

//import InputBusqueda from './inputBusqueda';

const style = {
    width: "100%",
    height: "100%"
};

const eventHandlers = {    
    onDrawStart: 'draw:drawstart',
    onDrawStop: 'draw:drawstop',    
};

//const BING_KEY = 'AtfyQFLwAqMac5Le45E3ZNuHoolIPOnbS5lneKbIaWJI60e9cBfcvxZeBKjU-tH2';

const sin_base = L.tileLayer('http://sin_base.com/{z}/{x}/{y}',{
    maxZoom: 20
});

const argenMap = L.tileLayer('https://wms.ign.gob.ar/geoserver/gwc/service/tms/1.0.0/capabaseargenmap@EPSG%3A3857@png/{z}/{x}/{-y}.png', {
    attribution: '<a href="http://leafletjs.com" title="A JS library for interactive maps">Leaflet</a> | <a href="http://www.ign.gob.ar/AreaServicios/Argenmap/IntroduccionV2" target="_blank">Instituto Geográfico Nacional</a> + <a href="http://www.osm.org/copyright" target="_blank">OpenStreetMap</a>',
    minZoom: 3,
    maxZoom: 20
});

const osm = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    maxZoom: 20
});

const CartoDB_DarkMatter = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png', {
	attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>',
	subdomains: 'abcd',
	maxZoom: 20
});

const gMapsSat = L.tileLayer('https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    {
      minZoom: 2,
      maxZoom: 20,
      id: "google.satellite"
    }
);

//http://geo.arba.gov.ar/geoserver/idera/wms
//https://ide.transporte.gob.ar/geoserver/observ/wms
const layer_1101 = L.tileLayer.wms('https://geo.arba.gov.ar/geoserver/idera/wms?', {
    layers: 'idera:Grupo IDERA',
    STYLES: '',
    VERSION: '1.1.1',
    tiled: true,
    crs: L.CRS.EPSG4326,
    format: 'image/png',
    transparent: true,
    tilesorigin: [-180, 90],
    opacity: 1,
    maxZoom: 20
});

const layer_4101 = L.tileLayer.wms('http://181.171.117.68/geoserver/wms?', {
    layers: 'capabase',
    crs: L.CRS.EPSG4326,
    format: 'image/png',
    transparent: true,
    opacity: 1
});

//URBASIG http://urbasig.gob.gba.gob.ar/geoserver/urbasig/wms?
const layer_2101 = L.tileLayer.wms('http://urbasig.gob.gba.gob.ar/geoserver/urbasig/wms?', {
    layers: 'urbasig:_zonificacion',
    crs: L.CRS.EPSG4326,
    format: 'image/png',
    transparent: true,
    opacity: 1
});

const layer_3101 = L.tileLayer.wms('http://urbasig.gob.gba.gob.ar/geoserver/urbasig/wms?', {    
    layers: 'urbasig:areas_ley_8912',
    crs: L.CRS.EPSG4326,
    format: 'image/png',
    transparent: true,
    opacity: 1
});

const geojsonTemp = {
    fillColor: "green",
    color: "green",
    weight: 2,
    opacity: 1,
    fillOpacity: 0.8
};

function geojsonTempB(feature) {
    return {
        fillColor: "red",
        fillOpacity: 1,
        color: 'green',
    };
}

//PRUEBA harcodeada, no se agrega al mapa, solo cuando se activa en el menu de capas
const datosTextHARD = `{"type":"FeatureCollection","totalFeatures":"unknown","features":[{"type":"Feature","id":"Seccion_Catastral.3768","geometry":{"type":"MultiPolygon","coordinates":[[[[-60.187174,-36.96339],[-60.206022,-36.94794],[-60.200912,-36.943938],[-60.22006,-36.928529],[-60.215907,-36.92524],[-60.212861,-36.922831],[-60.210131,-36.920702],[-60.205094,-36.916803],[-60.203863,-36.915988],[-60.200009,-36.912984],[-60.200006,-36.912829],[-60.198862,-36.911979],[-60.190229,-36.904857],[-60.190105,-36.904842],[-60.188028,-36.903178],[-60.186682,-36.902086],[-60.185172,-36.900899],[-60.182158,-36.898533],[-60.175127,-36.89292],[-60.153422,-36.87569],[-60.115379,-36.845489],[-60.108292,-36.85135],[-60.096263,-36.860665],[-60.096104,-36.860536],[-60.086356,-36.8682],[-60.086555,-36.868389],[-60.093916,-36.874178],[-60.095521,-36.875435],[-60.098041,-36.877389],[-60.100562,-36.879285],[-60.105981,-36.883463],[-60.107303,-36.884472],[-60.104151,-36.887046],[-60.129042,-36.906794],[-60.133994,-36.910668],[-60.138987,-36.91462],[-60.132749,-36.919712],[-60.137726,-36.923663],[-60.156514,-36.908467],[-60.161513,-36.912458],[-60.164268,-36.910164],[-60.179125,-36.92182],[-60.184126,-36.925742],[-60.181076,-36.928267],[-60.186148,-36.932189],[-60.17978,-36.937384],[-60.169836,-36.929487],[-60.16378,-36.934441],[-60.163113,-36.935149],[-60.158115,-36.939056],[-60.158032,-36.939251],[-60.159161,-36.940075],[-60.160324,-36.941019],[-60.162848,-36.943068],[-60.162135,-36.943669],[-60.187174,-36.96339]]]]},"geometry_name":"geom","properties":{"cca":"078020K","ara3":49977.69,"sag":"ARBA"}}],"crs":{"type":"name","properties":{"name":"urn:ogc:def:crs:EPSG::4326"}}}`;
const datosHARD = JSON.parse(datosTextHARD);

let requiredHARD=L.geoJson(null, {style: geojsonTemp});

let tempLayer = L.geoJson(null, {style: geojsonTemp});

let geoJsonLayer = L.geoJson();


const Map_ExpedientesEdit =({partido, circ, secc, partidoGEO}) => {

    console.log("partidoGEO", partidoGEO);    

    function gardenStyle(feature) {
        return {
            fillColor: "yellow",
            fillOpacity: 1,
            color: '#B04173',
        };
    }

    //estilo de prueba, funcionó para los poligonos a editar
    function AAStyle(feature) {
        return {
            fillColor: "#00AEC3",
            fillOpacity: 0.75,
            color: '#B04173',
        };
    }

    function geojsonTempPartido(feature) {
        return {
            fillColor: "green",
            fillOpacity: 0.25,
            color: 'green',
        };
    }

    const mapRef = useRef(null);    

    const geoJsonRef = useRef(null);
    //geoJsonRef.current = L.geoJSON(null, {style: geojsonTempPartido});

    const editableLayers = useRef(null); // es la capa que va a recibir los poligonos (AA-001)
         
    const { geomEdit, cambioGeomEdit } = useContext(SessionContext);
    console.log(geomEdit);
    
    //----ESTADOS----

    const vacioG = `{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{}}]}`;    
    const [textGEOM, setTextGEOM]=useState(JSON.stringify(geomEdit));        
        
    const [featuresFILTER, setCharacterFILTER] = useState(null);
    //const [polygonFINAL, setPolygonFINAL] = useState(null);
    
    const [geomPoly, setGeomPoly]=useState(geomEdit);

    const [addLAYER, setAddLAYER]=useState([]);

    // ***add 7-agos-2023******* Manejar geojson de partido para mostrar en el mapa

    const [dataPartido, setDataPartido]=useState(null); 

    useEffect(()=>{ // ***add 7-agos-2023

        if(partidoGEO !== null ){
            setDataPartido(partidoGEO); //lo que recibe
        }

    }, [partidoGEO]);    

    useEffect(()=>{
        if(dataPartido !== null){           
            const partidoL=L.geoJson(dataPartido, {style: geojsonTempPartido}).addTo(mapRef.current);             

            //console.log("EDITABLE --->", addLAYER);

            if(addLAYER.length < 1){
                console.log("EDITABLE --->", addLAYER);
                mapRef.current.fitBounds(partidoL.getBounds());

            }
        }

    }, [dataPartido])

    //***cierra manejo capa partido */    

    ///*FUNCIONA, POR AHORA NO USAR
    //const [newParcel, setNewParcel] = useState([]);       
       
     
    //NOTA: en la edición hace falta la conversión de integer a string <------(19-abr-2023)
    console.log("PARTIDO-_-A>", partido);
    let partidoQuery = '000';
    if(partido === null){
        console.log("ES NULL");
    } else {
        console.log("NO ES NULL");
        let partido_T = partido.toString();

        
        if(partido_T.length===1){
            partidoQuery = `00${partido_T}`;
        } else if(partido_T.length===2){
            partidoQuery = `0${partido_T}`;
        } else {
            partidoQuery = `${partido_T}`;
        }
    }

    //CASO 1: Ideal (que lleguen los 3 datos completos 114 01 0A)- armo la cadena y consulto contra url Secciones    

    let circQuery = '00';
    if(circ === null || circ === ''){
        circQuery= '00';
    } else if(circ.length===1){
        circQuery = `0${circ}`;
    } else {
        circQuery = `${circ}`;
    }
    console.log("QUERY CIRC--->", circQuery);

    let seccQuery = '00';

    if(secc === null || secc === ''){
        seccQuery = '00';
    } else if(secc.length===1){
        seccQuery = `0${secc}`;
    } else {
        seccQuery = `${secc}`;
    }
    console.log("QUERY SECC--->", seccQuery);

    let cadenaFilter;
    let typeName; //typeName=idera:Seccion_Catastral

    //let urlQuery;

    if(circQuery==='00'){
        console.log("----> A")
        cadenaFilter=`${partidoQuery}`;
        typeName=`typeName=idera:Departamento`;    

    } else if(circQuery!=='00' && seccQuery === '00' || seccQuery === ''){
        console.log("----> B")
        cadenaFilter=`${partidoQuery}${circQuery}`;
        typeName=`typeName=idera:Circunscripcion`;     

    } else if(circQuery!=='00' && seccQuery !== '00'){
        console.log("----> C")
        cadenaFilter=`${partidoQuery}${circQuery}${seccQuery}`;
        typeName=`typeName=idera:Seccion_Catastral`;
    }
    //console.log("CADENA QUERY---->", cadenaFilter);

    //CQL_FILTER=cca LIKE '078020K'
    let cqlFilter= `CQL_FILTER=cca LIKE '${cadenaFilter}'`;

    let cqlFilterPartido= `CQL_FILTER=cca LIKE '${partidoQuery}'`;    

    const savePolygon= (geojsonDatos)=>{
        //console.log("RECIBE DATOS PARA GUARDAR---->", geojsonDatos)
        //setPolygonFINAL(geojsonDatos)
        //setGeom(geojsonDatos);      
       
        cambioGeomEdit(geojsonDatos);
    } 

    //-------------EFECTOS--------

    useEffect(() => { 
        mapRef.current = L.map('map', {
            center: [-37.265645, -60.067551],
            zoom: 7
        });
        //getData();  // -------> OSO (07-agos-2023) QUE no se ejecute      
    }, []);
        
    //ULTIMO PASO: if featuresFILTER ya tiene datos (distinto de null), agregar a geoJsonRef y luego la capa al mapa
    //hacer zoom
    //para que no se vea el poligono, limpiar la capa
    useEffect(()=>{ 
        
        if(featuresFILTER !== null){
            
            let datos = featuresFILTER;
            let editable = editableLayers.current;
            console.log("CAMBIO featuresFILTER---->2DO", datos);            

            geoJsonRef.current = L.geoJSON(null, {style: geojsonTempPartido}).addTo(mapRef.current).bringToBack();
            if(geoJsonRef.current){
                geoJsonRef.current.addData(datos);
            }
            //geoJsonRef.current.addData(datos, {style: geojsonTempPartido});            
            
            if(editable.getBounds().isValid() === true){
                mapRef.current.fitBounds(editableLayers.current.getBounds());

            } else if(geoJsonRef.current.getBounds().isValid() === true){
                mapRef.current.fitBounds(geoJsonRef.current.getBounds());
            } else if(partido !== null && partido > 0){
                
                toast.success(`Haciendo zoom al partido ${partido}`);
                //getSoloPartido()  //-------> OSO (7-agos-2023)              
                
            } else if(partido === null || partido === 0){
                toast.success("No se puedo obtener la capa");
            }         
        }        

    }, [featuresFILTER]);

    //***ESTE QUEDA (7-agos-2023)********
    useEffect(()=>{
        if(textGEOM === vacioG || textGEOM === null){
        
        } else {
            console.log("QUE CARGUE!!!!", addLAYER);

            if(addLAYER !== null){

                return addLAYER.map((feature, index) => {
                    //**NOTA: agrega los poligono como layers (addLayer), con estilo y hace zoom a la extension de la capa editable
                    editableLayers.current.addLayer(feature, {style: geojsonTemp});
                    mapRef.current.fitBounds(editableLayers.current.getBounds()); //ACÁ SI...funciona zoom a todos los poligonos
                                        
                })
            }
        }
    }, [addLAYER])
        

    /*useEffect(()=>{
        if(textGEOM === vacioG || textGEOM === null){        
            
        } else {
            
            //editableLayers.current.clearLayers();
            //alert("MAPEAR #314"); // ---> ingresos para updata

            setGeomPoly(geomEdit); //le pasamos geom

            //setGeomPoly(`{"type": "FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":${geomEdit}}]}`)
            
            //editableLayers.current.addData(geom);
            //console.log("")

            //LLAMAR FUNCTION
            //updateGEOM();
        }

    }, [])*/

    //NUEVA PRUEBA solo la primera vez
    useEffect(()=>{ 
        console.log("cuando carga -- AddLAYER")
        if(textGEOM === vacioG || textGEOM === null){

        } else {        
            let datos = geomEdit;
            
            if(datos.features.length > 0){

                return datos.features.map((feature, index) => {                    
                    var geoJsonLayerA = L.geoJson(null, {style: AAStyle}).addTo(mapRef.current);//poner el estilo acá funciona
                    geoJsonLayerA.on('layeradd', function (e) {
                        var type = e.layerType,
                        layer = e.layer;                        
                        
                        setAddLAYER(addLAYER => [...addLAYER, layer]);//
                    });
                    geoJsonLayerA.addData(feature); //usar feature
                    //mapRef.current.fitBounds(geoJsonLayerA.getBounds()); //FUNCIONA, PERO NO HACER ACÁ
                    geoJsonLayerA.clearLayers();
                })
            }  
            console.log("#389 OSO AA-002");
        }  
    }, []);
       
    //****LLAMAR SOLO CUANDO FALLA EL BOUND***(5-abril-2023)
    //nota: armar una para consultar la circ si falla el limite de la secc (PENDIENTE)
    // ***por ahora NO USAR ------- (07-agos-2023)****
    const getSoloPartido=async()=>{
        let urlB=`https://geo.arba.gov.ar/geoserver/idera/ows?`;
        let parametersB=`service=WFS&version=1.0.0&request=GetFeature&typeName=idera:Departamento&maxFeatures=10&outputFormat=application/json&${cqlFilterPartido}&SRSNAME=EPSG:4326`;
        let urlWFS=`${urlB}${parametersB}`;
        const res= await fetch(urlWFS);
        const data= await res.json();         
        setCharacterFILTER(data);
    }

    const getData = async ()=>{   
        let urlB=`https://geo.arba.gov.ar/geoserver/idera/ows?`;      
        let parametersB=`service=WFS&version=1.0.0&request=GetFeature&${typeName}&maxFeatures=50&outputFormat=application/json&${cqlFilter}&SRSNAME=EPSG:4326`;
        let urlWFS=`${urlB}${parametersB}`;    
        const response= await fetch(urlWFS);
        if (response.ok) {
            const res = await response.json()
            .then(res => {
                setCharacterFILTER(res);			
            })
            .catch(err => console.log(err))            
        } else {
            throw new Error('Network response was not ok.')
        }                
    } 
    //***cierra seccion de funciones que no se usan */   

    /*
    const addPolygon = (jsonL) => {
        //console.log("ACA hacer carga de los features");

        //const len_jsonL = jsonL.length;
        const lastMarker = polygonData[polygonData.length - 1];

        var textoG = JSON.stringify(jsonL);//.geometry
        //console.log(textoG);
        setPolygonData([...polygonData, textoG]);
    }*/	   

    /*useEffect(()=>{
        if(geoJsonRef.current!==null){
            mapRef.current.addLayer(geoJsonRef.current);
        }

    }, [geoJsonRef.current])*/

    useEffect(()=>{
        if(osm ){
            mapRef.current.removeLayer(osm);
            osm.addTo(mapRef.current);
            //mapRef.current.removeLayer(layer_1101); //<-------------------
            //layer_1101.addTo(mapRef.current); // <-------------------------
        }        
    })

    useEffect(()=>{
        if(layer_1101 ){
            mapRef.current.removeLayer(layer_1101);            
        }        
    }, [])
    
    useEffect(()=>{
        if(layer_2101){
            mapRef.current.removeLayer(layer_2101);            
        }        
    })    

	useEffect(() => {
        //layer_1101.addTo(mapRef.current); // <---------------------------------
        geoJsonLayer.addTo(mapRef.current);
        requiredHARD.addTo(mapRef.current);
        //geoJsonRef.current.addTo(mapRef.current);
        editableLayers.current = new L.FeatureGroup();
        editableLayers.current.addTo(mapRef.current); 
        
        // CUEVA - uno: agrega 
        //POR AHORA NO QUEDA
        /*editableLayers.current.on('layeradd', function (e) {
            // do something with e.layer
            
            var type = e.layerType,
            layer = e.layer;
            console.log("CUEVA -uno")

            editableLayers.current.addLayer(layer, {style: geojsonTemp});
        });*/

        //draw:deletestop
        mapRef.current.on('draw:deletestop', function (e) {
            //console.log(e);
            //console.log(e.target);
            let layerGeoJSON = editableLayers.current.toGeoJSON(); //editableLayers.current.
            //console.log(layerGeoJSON);
            savePolygon(layerGeoJSON)
        })

        mapRef.current.on('draw:editstart', function (e) {
            //console.log(e);
            //console.log(e.target);
            //console.log("started editing")
        });        
        
        mapRef.current.on('draw:edited', function (e) {
            //console.log(e);
            //console.log(e.target);
            let layerGeoJSON = editableLayers.current.toGeoJSON(); //editableLayers.current.
            //console.log(layerGeoJSON);
            savePolygon(layerGeoJSON)
        })

        mapRef.current.on("draw:drawstart", function (event) {
            //console.log("event here", event);
        });

        mapRef.current.on("draw:drawstop", function (event) {
            //console.log("event here STOP", event);

            let layerGeoJSON = editableLayers.current.toGeoJSON(); // editableLayers.current.
            //console.log("TERMINO DE DIBUJAR----->",layerGeoJSON);
            savePolygon(layerGeoJSON);
            //var type = e.layerType,
            //layer = e.layer;
            //console.log("LAYER--->",layer);
        });

        mapRef.current.on('draw:created', function (e) {

            var type = e.layerType,
            layer = e.layer;
        
            var featureId = L.stamp(layer);
            layer.feature = {
                'type': 'Feature',
                'properties': {                    
                    'feature_type': e.layerType
                }
            };
        
            var jsonL = layer.toGeoJSON();

            //https://javascript.plainenglish.io/how-to-add-to-an-array-in-react-state-3d08ddb2e1dc
            //NO USAR
            //setNewParcel(newParcel => [...newParcel, jsonL]); // <---- FUNCION CONTENEDORA (wrapper function)

            /*let layerGeoJSON = editableLayers.current.toGeoJSON();
            //console.log(layerGeoJSON);
            savePolygon(layerGeoJSON);*/
            
        });

        mapRef.current.on(L.Draw.Event.CREATED, function (e) {
            var type = e.layerType,
              layer = e.layer;
              
            if (type === "marker") {
              layer.bindPopup("A popup!");
            }
            console.log("AL DIBUJAR AGREGA ACÁ?")            

            editableLayers.current.addLayer(layer, {style: geojsonTemp}); // editableLayers.current.

            //NOTA: lo agrega con el ESTILO, pero no deja editar!!
            /*
            var jsonL = layer.toGeoJSON();
            //console.log(jsonL);
            editableLayers.current = L.geoJSON(jsonL, {style: geojsonTemp}).addTo(mapRef.current);
            */
                      
        });

        /*
        *****NOTA: Revisar con el código sin comentar error. Comentando sigue editando*****
        mapRef.current.on(L.Draw.Event.EDITED, e => {
            //e.layers.eachLayer(layer => //console.log('got a layer!', layer));
            //console.log("EVENT EDIT - tira el error")
            var type = e.layerType,
            layer = e.layer;
            //if(layer !== null){
                editableLayers.current.addLayer(layer);
            //}            
        });*/           

        const baseMaps = {
            "<span style='color: gray'>Sin capa base</span>": sin_base,
            "<span style='color: gray'>OpenStreetMap</span>": osm,
            "<span style='color: gray'>ArgenMap</span>": argenMap,
            "<span style='color: gray'>Carto</span>": CartoDB_DarkMatter,
            //"<span style='color: gray'>Bing</span>": bingLayer,
            "<span style='color: gray'>Google Satelital</span>": gMapsSat            
        };        

        const overlayMaps = {            
            "Polygon": editableLayers.current, 
            "ARBA": layer_1101,
            "ARBA base": layer_4101,
            "Zonificación según usos": layer_2101,
            "Areas - Ley 8912/77" : layer_3101,
            //"Query": geoJsonRef.current
            //"Prueba": requiredHARD,
            //"Temp": tempLayer  -----> NO se agrega la capa con la que se hizo la busqueda          
        };
        L.control.layers(baseMaps, overlayMaps, {position: 'topleft'}).addTo(mapRef.current);

        const options = {
            position: "topleft",
            draw: {
                polyline: false,
                polygon: {
                    title: 'Dibujar nuevo poligono',
                    icon: new L.DivIcon({
                        iconSize: new L.Point(10, 10),
                        className: 'leaflet-div-icon leaflet-editing-icon'
                    }),
                    shapeOptions: {
                        clickable: false,
                        color: "dodgerblue",
                    },
                    allowIntersection: false, // Restricts shapes to simple polygons 
                    drawError: {
                        color: '#e1e100', // Color the shape will turn when intersects 
                        message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect 
                    }
                },
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false
            },
            edit: {
                featureGroup: editableLayers.current, //REQUIRED!! //editableLayers.current.                
            },
        };
          
        const drawControl = new L.Control.Draw(options);

        drawControl.addTo(mapRef.current);

	}, [])	

	return (
        <div 
            id='map'
            style={style}>
            {/*<InputBusqueda />*/}
            
        </div>
    )
    
}

export default Map_ExpedientesEdit;