import React, {Fragment, useState, useEffect, useContext } from 'react';
import { SessionContext } from '../../context/sessionContext';
import { Table, Row, Col, Button} from "react-bootstrap";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Modal from 'react-bootstrap/Modal';

import Select from 'react-select';
import SelectInput from "../selectInput";

import {optionsRP2_Todos, optionsTitular, optionsTramitacionADA_Todos, optionsTramitacionADA } from "../../datos/options";

import { toast } from "react-toastify";

const Select_3_Ada = ({openReporte_3_ada}) => {

	const url = `/api/api_serv/reportes/reporte3_ADA/`;

	useEffect(() => {
		if (openReporte_3_ada === true && datosS === null) {
			getDatos(url);	//console.log("_____________________LLAMANDO DATOS RESOLUCION");			
	
		} else if (openReporte_3_ada === false) {
			console.log("NO SE DIO CLICK - es false??", openReporte_3_ada)
		}
	},[openReporte_3_ada]);

	const { roleA, sesionT } = useContext(SessionContext);
  	const name = roleA.username;

	//******23-08-2022
	const onChange = (e) => {
		setEditRegistro({...editRegistro, [e.target.name] : e.target.value});
		setOriginal(1);
	}

	const handleFecha1 = (item) => {
		
		const fecha = item.value;
		
		setEditRegistro({ ...editRegistro, [item.name]: fecha });
		setOriginal(1);
	}

	const [original, setOriginal] = useState(0); //estado original en 0; si cambia a uno habilitar el boton de guardar

	const [show, setShow] = useState(false);

  	const handleClose = () => {
		setShow(false);
		setOriginal(0);
	};

	const [editRegistro, setEditRegistro] = useState({
		id_exp_pruebaE : "",
		t_127_tramitacion_ada_prefac_h_E : "",
		expediente_prefac_h_t_E : "",
		fecha_envio_prefac_h_t_E : null,
		fecha_emision_prefac_h_t_E : null,
		t_131_observaciones_prefact_h_E : ""
	});
	//console.log(editRegistro);
	const {
		id_exp_pruebaE,
		t_127_tramitacion_ada_prefac_h_E, expediente_prefac_h_t_E,
	 	fecha_envio_prefac_h_t_E, fecha_emision_prefac_h_t_E,
	 	t_131_observaciones_prefact_h_E
	} = editRegistro;
	//console.log(t_127_tramitacion_ada_prefac_h_E, expediente_prefac_h_t_E, fecha_envio_prefac_h_t_E );

	const guardarEditRegistro = async e => {
		e.preventDefault();
		//alert("GUARDAR EDICION desde FORM");
		//console.log(editRegistro);

		try {
			const body = {
				editRegistro,
				name
			}

			const response = await fetch(
				`/api/authentication/reporteADA_edit_expediente`, //`${baseURL}authentication/reporteADA_edit_expediente`
				{
				method: "POST",
				headers: {
					"Content-type": "application/json"
				},
				body: JSON.stringify(body)
				}
			);

			const parseRes = await response.json();

			if (parseRes.expediente){
				toast.success(`Expediente ${parseRes.expediente} actualizado!!`);
					setShow(false);
					getDatos(url);//30-08-2022: si hubo cambio, volver a llamar
					//setEdit(false); //20-04-2022
			} else {
				//console.log("error");
				toast.error(parseRes.message);
			}

		} catch(err){
			console.error(err.message);
		}
	}

	//*****cierra 23-08-2022

	const [datosS, setDatos] = useState(null);// ESTADO para recibir datos de la consulta

	const [filtro, setFiltro] = useState({ //PROBAR con 2 filtros
		t_127_tramitacion_ada_prefac_h: "",
		expediente: "",
		partido: 0,
		nombre_proyecto: "",
		expediente_prefac_h_t: "",
		fecha_envio_prefac_h_t: "",
		fecha_emision_prefac_h_t: "",
		t_131_observaciones_prefact_h: ""
	});
	const {t_127_tramitacion_ada_prefac_h, expediente, partido, nombre_proyecto, expediente_prefac_h_t, fecha_envio_prefac_h_t, fecha_emision_prefac_h_t, t_131_observaciones_prefact_h} = filtro;
	
	const [datosOriginal, setDatosOriginales] = useState(null);
	//console.log(datosOriginal);

	const [datosFiltrado, setDatosFiltrado] = useState(null);

	const [search, setSearch] = useState(""); //posible borrar
	const [searchPartido, setSearchPartido] = useState(0);
	const [searchTramADA, setSearchTramADA] = useState("");

	const [searchEstado, setSearchEstado] = useState("");
	const [searchPrograma, setSearchPrograma] = useState("");
	const [searchTipo, setSearchTipo] = useState("");
	//const [searchTitular, setSearchTitular] = useState("");

	//const [campoBusqueda, setCampoBusqueda] = useState([]);
	//console.log(campoBusqueda);

	const [arrayBusqueda, setArrayBusqueda]= useState({
		//"expB" : "",
		"partidoB" : 0,
		"tramitacionADA_B": "",
		//"estadoB" : "",
		//"aliasB" : "",
		//"programaB" : "",
		//"tipoB" : "",
		//"titularB" : "",
	});
	//console.log(arrayBusqueda);

	//**SELECCIONAR campos tabla madre***

	const [municipioMadre, setMunicipioMadre] = useState("");

	const [datosMadre, setDatosMadre] = useState(null);

	//activo: true son los que mostraria inicialmente.
	const [camposInicial, setCamposInicial] = useState([
		{label:'Partido', activo:'true', campo:'partido_nombre'},
		{label:'Expediente', activo:'true', campo:'expediente'},
		{label:'Estado', activo:'false', campo:'estado'},
		{label:'Alias', activo:'true', campo:'nombre_proyecto'},
		{label:'Programa', activo:'false', campo:'programa'},
		{label:'Tipo', activo:'false', campo:'tipo'},
		{label:'Titular', activo:'false', campo:'titular_tierra'},
		{label:'Cantidad lotes', activo:'false', campo:'cantidad_lotes'},
		{label:'Lotes captados municipio', activo:'false', campo:'lotes_adquiridos_municipio'},
		{label:'Nomenclatura catastral', activo:'false', campo:'datos_18_sintesis_nomenclatura'},
	]);

	//const [columnasMadre, setColumnasMadre] = useState(["Partido", "Expediente", "Estado", "Alias", "Programa", "Tipo", "Titular", "Cantidad lotes", "Lotes captados municipio", "Nomenclatura catastral"]);
	//console.log(columnasMadre);

	//---campos tabla madre------
	//**04-05 --> Funciona pero NO USAR
	/*
	useEffect(()=>{
		//console.log("ARRAY de CAMPOS BUSQUEDA");
		//console.log(campoBusqueda);
	}, [campoBusqueda])
	*/


	//***TODO PARA SEARCH***********
	//****************************04-05***CADA VEZ que CAMBIA arrayBusqueda

	useEffect(()=>{
		//console.log("HACER BUSQUEDA CON MULTIPLES VARIABLES"); //sobre datos Originales

		if(datosS !== null){

			const regexExp = new RegExp(arrayBusqueda.expB, 'i');
			//console.log(regexExp);
			//const REGEX = /a|b/g;
			//const REGEX = /[0-59]/g;
			//console.log(REGEX);

			var rxPartido = /\d{1}/ ;
			//console.log(arrayBusqueda.partidoB);
			//console.log(typeof(arrayBusqueda.partidoB));

			var xF = parseInt(arrayBusqueda.partidoB);
			//console.log(typeof(xF));
			//console.log(`${xF}`);
			//console.log(xF);
			var zF = `${xF}`;

			//const regexPartido = new RegExp(arrayBusqueda.partidoB{2});
			const uno = /^d/;
			//console.log(uno);
			//const regexPartido = new RegExp(/^`${arrayBusqueda.partidoB}/);
			//const regexPartido = new RegExp(arrayBusqueda.partidoB); //  /^d/
			var regexPartido =  new RegExp(`^${arrayBusqueda.partidoB}{1,3}`); //   /^d{1,3}/ ;
			//let uno = new RegExp(`^${arrayBusqueda}{${nro}}`);


			if(xF === 0 ){
				regexPartido = /./;
			} else if(xF > 0 && xF < 10){
				//console.log("PARA Alsina");
				const arrayB1 = `${arrayBusqueda.partidoB}`;
				regexPartido = new RegExp(`^${arrayB1}{1,3}`);
			} else if(xF > 9 && xF < 99) {
				//console.log("PARA Benito Juarez");
				const arrayB2 = `${arrayBusqueda.partidoB}`;
				regexPartido =  new RegExp(`^${arrayB2}{1,3}`);
			} else {
				regexPartido = new RegExp(`^${arrayBusqueda.partidoB}{1,3}`);
			}

			//console.log(regexPartido);

			var regexTramitADA = new RegExp(`${arrayBusqueda.tramitacionADA_B}$`, 'i');


			//const rxPartido = /\arrayBusqueda.partidoB{2}/;
			//const regexPartido =  /[0-9]{1,3}/ ; //     /\d{1,3};
			//const regexPartido = /\d{1,3}/g;
			//const partidoBusqueda = arrayBusqueda.partidoB

			//const regexPartido = /partidoBusqueda/;

			const regexEstado = new RegExp(arrayBusqueda.estadoB, 'i');
			//console.log(regexEstado);
			const regexAlias= new RegExp(arrayBusqueda.aliasB, 'i');
			//console.log(regexAlias);
			const regexPrograma= new RegExp(arrayBusqueda.programaB, 'i');
			//console.log(regexPrograma);
			const regexTipo= new RegExp(arrayBusqueda.tipoB, 'i');
			//console.log(regexTipo);
			const regexTitular= new RegExp(arrayBusqueda.titularB, 'i');
			//console.log(regexTitular);


			const filtradoP = datosOriginal.filter((item, index) =>{

				//VERIFICAR solo donde sean iguales para evitar que tome por ejemplo 1 para (todos los que empiezan con 1)1, 11, 120....
				if(parseInt(arrayBusqueda.partidoB) ===0){

					/*//console.log(regexTramitADA.test(item.t_127_tramitacion_ada_prefac_h));
					//console.log("--->Partido es cero");
					//console.log(/./.test(item.partido));*/

					return /./.test(item.partido) && regexTramitADA.test(item.t_127_tramitacion_ada_prefac_h)

				} else if(item.partido === parseInt(arrayBusqueda.partidoB)){

					/*//console.log("partido y array son iguales");
					//console.log(item.partido);
					//console.log(parseInt(arrayBusqueda.partidoB));
					//console.log(regexPartido.test(item.partido));*/

					return regexPartido.test(item.partido) && regexTramitADA.test(item.t_127_tramitacion_ada_prefac_h)
				}
			})
			//console.log(filtradoP);
			setDatos(filtradoP);
		}

	},[arrayBusqueda, datosOriginal]);


	//***Expediente***
	//https://bobbyhadz.com/blog/react-value-prop-on-input-should-not-be-null
	/* *** NO HACER FILTRADO POR EXPEDIENTE **** 04-06-2022
	const handleSearch = (e) => {
		//console.log("Search Expediente");

		//console.log(e.target.name);
		if(e.target.value !== null){
			//Con el nuevo setArrayBusquda, el que sigue no haria falta
    	setSearch(e.target.value); //HACE cambiar search---> ver el useEffect

			//4-05***Funciona, pero por ahora no usar, pensar utilidad
			var camposB = campoBusqueda.includes("expediente");
			if(!camposB){
				setCampoBusqueda([...campoBusqueda, "expediente"]);
			}
			setArrayBusqueda({...arrayBusqueda,  "expB":e.target.value} )
			//-------04-05:Funciona pero no usar, por ahora-----
		}
  };*/

	//***NO EJECUTAR
	/*
	useEffect(()=>{
		if(datosS !== null){
			//if(datosFiltrado !== null){
				//https://stackoverflow.com/questions/33347806/regex-through-array-of-objects
				const regex = new RegExp(search, 'i');
				//console.log(regex);

				const filterDatos = datosOriginal.filter((item) =>{ //**cambiar datosS x datosOriginal
					//var busqueda = item.estado;
					//item.expediente.includes(search)
					//item.expediente.match(new RegExp(search, "i")).map(name => {return <p>{name}</p>})
					//console.log(regex.test(item.expediente));
					return regex.test(item.expediente)
				});
				//console.log(filterDatos);
				//setDatos(filterDatos); //NO cambiar estado
				//setDatosFiltrado(filterDatos); // si guardo datos acá, para la busqueda de alias deberia buscar en datosFiltrados)
		}
	}, [search]);
	*/
	//---Expediente---

	//***Estado***
	/*
	const handleOnChangeEstado = (e) => {
		//console.log("CHANGE ESTADO");
		if(e.value){

			if(e.value !== null){
				setSearchEstado(e.value);
				setArrayBusqueda({...arrayBusqueda,  "estadoB":e.value} );
				setFiltro({...filtro,  "estado":e.value} );
			}
		} else if( e.value === ""){
			setSearchEstado(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			setArrayBusqueda({...arrayBusqueda,  "estadoB":e.value} );
			setFiltro({...filtro,  "estado":e.target.value} )

		} else if (e.target.value){
			if(e.target.value !== null){
				setSearchEstado(e.target.value);
				setArrayBusqueda({...arrayBusqueda,  "estadoB":e.target.value} )
				setFiltro({...filtro,  "estado":e.target.value} )
			}
		} else {
			//console.log("____SELECCIONADO SIN VALOR");
			setSearchEstado(/./);
			setArrayBusqueda({...arrayBusqueda,  "estadoB":""} )
			setFiltro({...filtro,  "estado":""} )
		}
	}
	*/
	//---Estado---

	//***Partido***
	const handleOnChangePartido = (e) => {

		if(e.value){
			//console.log(e.value);

			if(e.value !== null){
				setSearchPartido(e.value);
				setArrayBusqueda({...arrayBusqueda,  "partidoB":e.value} )
				setFiltro({...filtro,  "partido":e.value} )
			}
		} else if( e.value === ""){
			setSearchPartido(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			setArrayBusqueda({...arrayBusqueda,  "partidoB":e.value} )
			setFiltro({...filtro,  "partido":e.value} )

		} else if (e.target.value){
			//console.log(e.target);
			if(e.target.value !== null){
				//console.log("VALUE PARTIDO");
				//console.log(e.target.value);
				if(e.target.value === 0){
					setSearchPartido(e.target.value);
					setArrayBusqueda({...arrayBusqueda,  "partidoB":0} )
					setFiltro({...filtro,  "partido":0} )
				} else {
					setSearchPartido(e.target.value);
					setArrayBusqueda({...arrayBusqueda,  "partidoB":e.target.value} )
					setFiltro({...filtro,  "partido":e.target.value} )
				}

			}
		} else {
			setSearchPartido(/./);
			setArrayBusqueda({...arrayBusqueda,  "partidoB":0} )
			setFiltro({...filtro,  "partido":0} )
		}
	}
	//---Partido---

	//***Tramitacion ADA*** POR AHORA NO FILTRAR POR ALIAS 04-06-2022

	const handleOnChangeTramitacionADA = (e) => {
		//console.log("TRAMITACION ADA ---> cambio");
		if(e.value){
			if(e.value !== null){
				setArrayBusqueda({...arrayBusqueda,  "tramitacionADA_B":e.value} )
			}
		} else if( e.value === ""){
			//setSearchTitular(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			setArrayBusqueda({...arrayBusqueda,  "tramitacionADA_B":e.value} )

		} else if (e.target.value){
			if(e.target.value !== null){
				setArrayBusqueda({...arrayBusqueda,  "tramitacionADA_B":e.target.value} )
			}
		} else {
			setArrayBusqueda({...arrayBusqueda,  "tramitacionADA_B":""} )
		}
	};

	/*
	useEffect(()=>{
		if(datosS !== null){

			if(datosFiltrado !== null){
				const regex = new RegExp(searchAlias, 'i');

				const filterDatos = datosFiltrado.filter((item) =>{ //**cambiar datosS x datosOriginal
					return regex.test(item.alias)
				});
				setDatos(filterDatos);
				setDatosFiltrado(filterDatos)
			} else {
				const regex = new RegExp(searchAlias, 'i');

				const filterDatos = datosOriginal.filter((item) =>{ //**cambiar datosS x datosOriginal
					return regex.test(item.alias)
				});
				//setDatos(filterDatos);
				//setDatosFiltrado(filterDatos)
			}

		}
	}, [searchAlias]);
	*/

	//--TRAmitacion ADA---

	//***Programa*** //ver diferencia si usa <select> o <SelectInput>
	/*
	const handleOnChangePrograma = (e) => {

		if(e.value){

			if(e.value !== null){
				setSearchEstado(e.value);
				setArrayBusqueda({...arrayBusqueda,  "programaB":e.value} )
				setFiltro({...filtro,  "programa":e.value} )
			}
		} else if( e.value === ""){
			setSearchEstado(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			setArrayBusqueda({...arrayBusqueda,  "programaB":e.value} )
			setFiltro({...filtro,  "programa":e.value} )

		} else if (e.target.value){
			if(e.target.value !== null){

				if(e.target.value === 0){
					setSearchPrograma(e.target.value);
					setArrayBusqueda({...arrayBusqueda,  "programaB":""} )
					setFiltro({...filtro,  "programa":""} )
				} else {
					setSearchPrograma(e.target.value);
					setArrayBusqueda({...arrayBusqueda,  "programaB":e.target.value} )
					setFiltro({...filtro,  "programa":e.target.value} )
				}
			}
		} else {
			setSearchEstado(/./);
			setArrayBusqueda({...arrayBusqueda,  "programaB":""} )
			setFiltro({...filtro,  "programa":""} )
		}
	} //---cierra handleOnChangePrograma -----
	*/

	/*
	useEffect(()=>{ //***** NO EJECUTAR
		if(datosS !== null){
			const regex = new RegExp(searchPrograma, 'i');

			const filterDatos = datosOriginal.filter((item)=>{
				return regex.test(item.programa);
			});
			setDatos(filterDatos);
		}
	}, [searchPrograma]);*/
	//---Programa---

	//***Tipo***
	/*
	const handleOnChangeTipo = (e) => {

		if(e.value){
			if(e.value !== null){
				setSearchTipo(e.value);
				setArrayBusqueda({...arrayBusqueda,  "tipoB":e.value} )
				setFiltro({...filtro,  "tipo":e.value} )
			}
		} else if( e.value === ""){
			setSearchTipo(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			setArrayBusqueda({...arrayBusqueda,  "tipoB":e.value} )
			setFiltro({...filtro,  "tipo":e.value} )

		} else if (e.target.value){
			if(e.target.value !== null){

				if(e.target.value === 0){
					setSearchTipo(e.target.value);
					setArrayBusqueda({...arrayBusqueda,  "tipoB":""} )
					setFiltro({...filtro,  "tipo":""} )
				} else {
					setSearchTipo(e.target.value);
					setArrayBusqueda({...arrayBusqueda,  "tipoB":e.target.value} )
					setFiltro({...filtro,  "tipo":e.target.value} );
				}

			}
		} else {
			setSearchTipo(/./);
			setArrayBusqueda({...arrayBusqueda,  "tipoB":""} )
			setFiltro({...filtro,  "tipo":""} )
		}
	}
	*/

	/*
	useEffect(()=>{ //**** NO EJECUTAR
		if(datosS !== null){
			const regex = new RegExp(searchTipo, 'i');

			const filterDatos = datosOriginal.filter((item)=>{
				return regex.test(item.tipo);
			});
			setDatos(filterDatos);
		}
	}, [searchTipo]);*/
	//---Tipo---

	//***Titular*** //NO USAR 04-06-2022
	/*
	const handleOnChangeTitular = (e) => {
		if(e.value){
			if(e.value !== null){
				setSearchTitular(e.value);
				setArrayBusqueda({...arrayBusqueda,  "titularB":e.value} )
			}
		} else if( e.value === ""){
			setSearchTitular(/./); //https://lenguajejs.com/javascript/caracteristicas/expresiones-regulares/
			setArrayBusqueda({...arrayBusqueda,  "titularB":e.value} )

		} else if (e.target.value){
			if(e.target.value !== null){
				setSearchTitular(e.target.value);
				setArrayBusqueda({...arrayBusqueda,  "titularB":e.target.value} )
			}
		} else {
			setSearchTitular(/./);
			setArrayBusqueda({...arrayBusqueda,  "titularB":/./} )
		}
	}*/
	//---Titular---

	//**Hacer QUERY, mostrar tabla sintesis***
	/*
	const onchangeSelectMunicipio = (item) => {

		const filterMunicipio = item.value;
		setMunicipioMadre(filterMunicipio);

		var url = item.url;
		const urlM = `${baseURL}api/expediente/todos/?prioritario=${prioritario}&programa=${programa}&partido=${partido}&tipo=${tipo}&estado=${estado}&datos_05_responsable=${datos_05_responsable}`;
		//const urlM = `${baseURL}api/reportes/reporte2_municipios/?filterMunicipio=${filterMunicipio}`;
		getDatos(urlM);
	};*/

	//**consultar los datos para el reporte2
	const getDatos = async (urlM) => {

		const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', jwt_token: localStorage.token }

		const res = await fetch(urlM, { headers })
		.then(res => res.json())
		.then(res => {setDatos(res)
		setDatosOriginales(res)
		})
		//.then(res => setDatosOriginales(res))
		.catch(err => console.log(err));
	}

	const columnas = ["Tramitación ADA","Expediente","Partido","Alias", "Expediente ADA", "Fecha envío", "Fecha emisión", "Observaciones"];

	const getHeader = (columnas)=>{

		return columnas.map((columna, index) => {
			if (columna === "Tramitación ADA") {
				return <th>
					{columna}
				</th>
			} else if (columna === "Expediente") {
				return <th> {columna} </th>
			} else if (columna === "Partido") {
				return <th>
					{columna}
				</th>
			} else if (columna === "Alias") {
				return <th style={{ width: "100px" }}> {columna} </th>
			} else {
				return <th>{columna}</th>
			}
		})
	}

	const getRowsData = (datosS)=> {
		//console.log("EJECUTAR AL INICIO -- REPORTE 3");
		if(datosS !== null){

			return datosS.map((registro, index )=> {

				var soloFechaEnvio = '';
				var dateEdicionEnvio = '';

				if(registro.fecha_envio_prefac_h_t !== null){

					let date = registro.fecha_envio_prefac_h_t;				
					let newFecha = new Date(date);
					newFecha.setMinutes(newFecha.getMinutes() + newFecha.getTimezoneOffset())

					let fecha = newFecha.toLocaleString().split(',')[0];

					//****PARA la tabla;
					soloFechaEnvio= fecha;
					//***PARA el formulario de edicion
					dateEdicionEnvio = new Date(date);
					dateEdicionEnvio.setMinutes(dateEdicionEnvio.getMinutes() + dateEdicionEnvio.getTimezoneOffset())

				} else {
					var soloFechaEnvio = '';
					var dateEdicionEnvio = '';
				}

				var soloFechaEmision = '';
				var dateEdicionEmision = '';

				if(registro.fecha_emision_prefac_h_t !== null){

					let date = registro.fecha_emision_prefac_h_t;
					let newFecha = new Date(date);
					newFecha.setMinutes(newFecha.getMinutes() + newFecha.getTimezoneOffset())

					let fecha = newFecha.toLocaleString().split(',')[0];

					//****PARA la tabla;
					soloFechaEmision = fecha;
					//***PARA el formulario de edicion
					dateEdicionEmision =  new Date(date);
					dateEdicionEmision.setMinutes(dateEdicionEmision.getMinutes() + dateEdicionEmision.getTimezoneOffset())

				} else {
					var soloFechaEmision = '';
					var dateEdicionEmision = '';
				}

				var tramitacion_ada = registro.t_127_tramitacion_ada_prefac_h;
				var expediente = registro.expediente;
				var estado = registro.estado;
				var nombre_proyecto = registro.alias;//nombre_proyecto
				var expediente_ada = registro.expediente_prefac_h_t;
				var fecha_envio = soloFechaEnvio; //_fecha_envio_prefac_h_t
				var fecha_emision = soloFechaEmision; //registro.fecha_emision_prefac_h_t;
				var observaciones_ada = registro.t_131_observaciones_prefact_h;

				var rowClick = (e)=>{
					e.preventDefault();

					//console.log('double click row - ABRIR VENTANA EDICION');
					setShow(true);
					//setEditRegistro({...editRegistro,  expediente: registro.expediente, estadoE: estado} )
					setEditRegistro({
						...editRegistro,
						id_exp_pruebaE: registro.id_exp_prueba,
						t_127_tramitacion_ada_prefac_h_E: tramitacion_ada,
						fecha_envio_prefac_h_t_E: dateEdicionEnvio,
						fecha_emision_prefac_h_t_E : dateEdicionEmision,
						expediente_prefac_h_t_E: expediente_ada,
						t_131_observaciones_prefact_h_E: observaciones_ada
					})
				}

				return <tr id={index} onDoubleClick={rowClick} >
					<td>{tramitacion_ada}</td>
					<td>{expediente}</td>
					<td>{registro.partido_nombre}</td>
					<td>{nombre_proyecto}</td>
					<td>{expediente_ada}</td>
					<td>{fecha_envio}</td>
					<td>{fecha_emision}</td>
					<td>{observaciones_ada}</td>
				</tr>;
			})

		}
	}

	if (datosS === null) {

		return(
			<Fragment>
				<p>INICIO hacer consulta</p>

				<div id="tabla_TODO" style={{ padding:"2px", width:"100%" }} className="">
					<div className="" style={{ width:"100%", overflow:"scroll"}} >
						{/*<Table stripped bordered hover responsive="md" >
							<thead >
								<tr>{getHeader(columnas)}</tr>
							</thead>
						</Table>*/}



						<Table stripped bordered hover responsive="md" >
							<thead >
								<tr style={{backgroundColor:"rgb(0,174,195)", color:"white"}}>{getHeader(columnas)}</tr>
							</thead>
							<tbody>
								{ getRowsData(datosS) }
							</tbody>
						</Table>

					</div>
				</div>

			</Fragment>
		)
	} else {
		//console.log("YA NO ES NULL");
		return (
			<Fragment>
				<br/>

				<div className="" style={{ width:"100%" }}> {/*div A, border:"2px solid #333" */}
					<div>
						<ReactHTMLTableToExcel
							//id="uno-table-xls-button"
							className="btn btn-info text_button"
							table="ada-to-xls"
							filename="tramitaciones_ada"
							sheet="tablexls"
							buttonText="Descargar XLS"
						/>
					</div>
					{/*`buscar: Partido:{partido || '____'}--Estado:{estado  || '____'} -- priorit:{prioritario  || '____'}-- Programa:{programa || '____'}--  Tipo:{tipo  || '____'}-- `*/}
				</div>{/* cierra div A*/}



				<div id="tabla_TODO" style={{ padding:"2px", width:"100%" }} className=""> {/*, border:"2px solid blue" */}
					<div className="" style={{ width:"100%", overflow:"scroll"}} > {/*border:"2px solid red",*/}

						<div className="container_left">
							<div className="input-container" style={{width: "25%"}}>
								<SelectInput
									name="partido"
									options={ optionsRP2_Todos }
									onChange={e=>handleOnChangePartido(e)}
								/>
								<label alt="Partido" for="id_partido">Partido</label>
							</div>
							<div className="input-container" style={{width: "1%"}} />
							<div className="input-container" style={{width: "25%"}}>
								<SelectInput
									name="tramitacion_ADA"
									id="id_tramitacion_ADA"
									options={ optionsTramitacionADA_Todos }
									onChange={e=>handleOnChangeTramitacionADA(e)}
								/>
								<label alt="Tramitación ADA" for="id_tramitacion_ADA">Tramitación ADA</label>
							</div>
						</div>{/*cierra container_left*/}


						<Table className="tableExp" id="ada-to-xls" stripped bordered hover responsive="md" >
							<thead >
								<tr style={{backgroundColor:"rgb(0,174,195)", color:"white"}}>{getHeader(columnas)}</tr>
							</thead>
							<tbody>
								{ getRowsData(datosS) }
							</tbody>
						</Table>

					</div>
				</div>

				<Modal
					id="id_modal_1"
					show={show}
					onHide={handleClose}
					backdrop="static"
					keyboard={false}
					className="id_modal_1"
					style={{top: "200"}}
				>
					<Modal.Header style={{background: "rgb(0,174,195)", color:"white"}} closeButton>
						<Modal.Title>Edición {/*id_exp_pruebaE*/}</Modal.Title>
					</Modal.Header>
					<Modal.Body>

						<div>
							<div className="container_left">
								<h6 className="mt-4 text-left subtitulos">PREFACTIBILIDAD HIDRÁULICA</h6>
							</div>

							<div className="container_left">
								<div className="input-container">
									{/*<span className="span_selectEdit">{t_127_tramitacion_ada_prefac_h ? "Tramitación ADA": ""}</span>*/}
									<SelectInput
										name="t_127_tramitacion_ada_prefac_h_E"
										id="id_t_127_tramitacion_ada_prefac_h"
										defaultValue = {t_127_tramitacion_ada_prefac_h_E}
										onChange={e => onChange(e)}
										options={optionsTramitacionADA}
									/>
									<label alt="Tramitación ADA" for="id_t_127_tramitacion_ada_prefac_h">Tramitación ADA</label>
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}

							<div className="container_left">
								<div className="input-container">
									<DatePicker
										title="Fecha de envío"
										name="fecha_envio_prefac_h_t_E"
										id="id_fecha_envio_prefac_h_t"
										dateFormat="dd/MM/yyyy"
										className="form-control"
										selected={fecha_envio_prefac_h_t_E}
										onChange={ (date) => handleFecha1({ name: "fecha_envio_prefac_h_t_E", value: date })}
									/>
									<label className="label-fecha" alt="Fecha de envío" for="id_fecha_envio_prefac_h_t">Fecha de envío</label>
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}

							<div className="container_left">
								<div className="input-container">
									<input
										title="Expediente ADA"
										type="text"
										id="id_expediente_prefac_h_t"
										maxLength="35"
										value = {expediente_prefac_h_t_E}
										name="expediente_prefac_h_t_E"
										onChange={e => onChange(e)}
									/>
									<label alt="Expediente ADA" for="id_expediente_prefac_h_t">Expediente ADA</label> {/* 128 */}
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}

							<div className="container_left">
								<div className="input-container">
									<DatePicker
										title="Fecha de emisión"
										name="fecha_emision_prefac_h_t_E"
										id="id_fecha_emision_prefac_h_t"
										dateFormat="dd/MM/yyyy"
										className="form-control"
										selected={fecha_emision_prefac_h_t_E}
										onChange={ (date) => handleFecha1({ name: "fecha_emision_prefac_h_t_E", value: date })}
									/>
									<label alt="Fecha de emisión" for="id_fecha_emision_prefac_h_t">Fecha de emisión</label>
								</div>{/*cierra ---> input-container */}
							</div>{/*cierra ---> container_left */}

							<div className="container_left" style={{ height: "auto"}}>
								<div className="input-container">
									<textarea
										title="Observaciones tramitación ADA"
										type="text"
										id="id_observaciones_prefact_h"
										maxLength="100"
										name="t_131_observaciones_prefact_h_E"
										value={t_131_observaciones_prefact_h_E}
										onChange={e => onChange(e)}
									/>
									<label alt="Observaciones tramitación ADA" for="id_observaciones_prefact_h">Observaciones tramitación ADA </label>
									{/*<p align="right" style={{ fontSize: "0.9em"}}>Caracteres restantes:</p>*/}  {/*maxObservTramADA*/}
								</div>{/*cierra ---> input-container */}
							</div>

						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className="btn btn-success btn-block button_verde" onClick={guardarEditRegistro} disabled={original ===0 ? true : false }>	Guardar </Button>
						<Button className="btn btn-success btn-block button_verde" onClick={handleClose}> Cancelar </Button>
					</Modal.Footer>
				</Modal>

			</Fragment>
		);
	}
}

export default Select_3_Ada;
//{datosS.filter(item => item.expediente.match(new RegExp("AD", "i")).map(filteredPerson => (
