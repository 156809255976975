import React, {Fragment,  useState, useEffect} from "react";
import { Table, Button } from "react-bootstrap";

import '../../table_report.css';

const TablaInf1_en_procesoTotal = ({datosRP1_en_procesoTotal}) => {
  //console.log("CARGAR datos en proceso Total en tabla");
  //console.log(datosRP1_en_procesoTotal);

  if (datosRP1_en_procesoTotal === null) {
    return(
      <p>Seleccionar rango de fechas...</p>
    )
  } else if(datosRP1_en_procesoTotal !== null){
    return(
      <Fragment>
        <h4></h4>
        <p className="subtitulos">Proyectos y lotes iniciados</p>
        <div>
          <Table className="tableExp" stripped bordered hover responsive="md" >
          <thead>
            <tr style={{backgroundColor:"rgb(0,174,195)", color:"white"}}>
              <th>Total lotes</th>
              <th>Total proyectos</th>
            </tr >
          </thead>
          <tbody>
            {datosRP1_en_procesoTotal.map((item, i)=>(
              <tr key={i}>
                <td>{item.suma_lotes}</td>
                <td>{item.suma_proyectos}</td>
              </tr>
            ))}
          </tbody>
          </Table>
        </div>
      </Fragment>
    )
  }
};


export default TablaInf1_en_procesoTotal;
