import React, { Fragment, useState, useEffect, useContext } from "react";
import { FaExclamationTriangle} from 'react-icons/fa';
import { SessionContext } from '../context/sessionContext';
import { Link, Redirect } from "react-router-dom";

import { Collapse, Form, Button, Row, Col } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import DatosEditExp from "./datosEditExp";
import TramiteEditExp from "./tramiteEditExp";
import Pestaña3_EditExp from "./pestaña3_EditExp";

import SelectInput from "./selectInput";

import { toast } from "react-toastify";

const Grupo_EditExpediente = ({ editRegistro, edit, setEdit }) => {

	const { getProfile, roleA, setRoleA, original, cambioOriginal, sesionT, geomEdit, cambioGeomEdit } = useContext(SessionContext);
  	
  	const name = roleA.username;
	const role = roleA.roleA;
	
	const [show, setShow] = useState(false);

  	const handleClose = () => setShow(false);//cierra la ventana, pero no elimina el expediente
  	const handleShow = () => setShow(true);//
	
	const [geomB, setGeomB]=useState("false");

	//***********EFECTOS */
	//25-abr-2023
	useEffect(()=>{
		setInputs({ ...inputs, datos_22_geom: JSON.stringify(geomEdit) });
		setGeomB("true");		
	}, [geomEdit])

	//**06-10-2022** verificar token al entrar a la edicion deexpediente
  	useEffect(() => {
		const pagina = 'acceso_pagina';
		//getProfile(pagina);
		if(sesionT === 'no_token'){
			alert('volver a loguear');
			setEdit(false);
		} else if( sesionT === 'Token valido'){
			//console.log("59--> ","token valido" );
			setEdit(true);
		}
		
  	}, []);
	//*** cierra chequear token ****//22-09-2022

	//***Guardar y Continuar*** 06/10/2022
  	const redirect_Cont = async e => {
		//console.log(e.target);
		try{
			const x = 'continuar';
			//await getProfile(x);
			if(sesionT === 'no_token'){
				alert('volver a loguear');
			} else if( sesionT === 'Token valido'){
				//console.log("82--> ","token valido" );
				//saveSeguir(e);// si el token es válido, ejecutar saveExit
				saveSeguir_prueba(e);
			}
		} catch(err){
			console.error(err);
		}
	}

	//**Recuperar el valor recibido en editRegistro, para la pestaña de Datos--->Paso "A"
	const { id_exp_prueba, nombre_proyecto, expediente,
		fecha_carga, fecha_caratulacion,
		fecha_actualizacion, //add 25-abr-2024
		datos_05_responsable, tramite_prioritario, avanzados, programa, estado, tipo, partido, localidad, cantidad_lotes,
		cantidad_lotes_adjudicables, porc_lotes_municipales, lotes_adquiridos_municipio,
		datos_18_sintesis_nomenclatura,
		titular_tierra, plano_oficial, area, zonificacion, nro_ordenanza_vigente, decreto_promulgatorio,
		sistema_agua, eliminacion_excretas, observaciones_generales,
		datos_19_partido_nomencla,
		datos_20_circ_nomencla,
		datos_21_secc_nomencla,
		datos_22_geom
	} = editRegistro;
	
  	const { array_csp } = editRegistro;
	
	const {
		csp_1_tamaño_medio_lotes_residenciales,
		csp_2_actor_encargado_obras_infra,
		csp_3_financiamiento_publico_obras_infra,
		csp_4_jurisdiccion_infra,
		csp_5_organismo_financiamiento_infra,
		csp_6_fecha_control_infra,
		csp_7_descripcion_obras_infra,
		csp_8_financiamiento_publico_obras_viv,
		csp_9_jurisdiccion_viv,
		csp_10_organismo_financiamiento_viv,
		csp_11_fecha_control_viv,
		csp_12_descripcion_obras_viv,
		csp_13_financiamiento_publico_obras_equip,
		csp_14_jurisdiccion_equip,
		csp_15_organismo_financiamiento_equip,
		csp_16_fecha_control_equip,
		csp_17_descripcion_obras_equip,
		csp_18_pl_mensura_estado,
		csp_19_pl_mensura_partido,
		csp_20_pl_mensura_orden,
		csp_21_pl_mensura_año,
		csp_22_pl_mensura_fecha,
		csp_23_registracion_legajo,
		csp_24_registracion_folio,
		csp_25_registracion_fecha,
		csp_26_fecha_control,
		csp_27_observaciones_csp,
		csp_28_presencia_apertura_calles,
		csp_29_parc_edificadas,
		csp_30_porcentaje_parc_edificadas,
		csp_31_observ_seguimiento_obras_y_edificacion,
		csp_32_fecha_control_seguimiento_obras
	} = array_csp[0]	

	//**23-03-22: destructuring los datos catastralaes recibidos en setEditRegistro
	const { nom_catastro } = editRegistro;
	
	const [formRows, setFormRows] = useState([{
		nomenclatura_catastral:"0000000000000000000000000000000000000000000000000",
		partida_inmobiliaria:"",//"000000",
		inscripcion_dominial:"",//"",
		nomencla_partido:"",//"000",
		nomencla_circ:"",//"00",
		nomencla_secc:"",//"00",
		nomencla_chacra_nro:"", nomencla_chacra_l:"",//"000",
		nomencla_quinta_nro:"", nomencla_quinta_l:"",//"000",
		nomencla_fracc_nro:"", nomencla_fracc_l:"",//"000",
		nomencla_manz_nro:"", nomencla_manz_l:"",//"000",
		nomencla_parc_nro:"", nomencla_parc_l:"",//"0000",
		solicitado:""
	}]);

	useEffect(()=>{
		//console.log("CAMBIO NOM");
		if(nom_catastro !== null){
			setFormRows(nom_catastro)
		} else {
			//console.log("ES NULL, NO CAMBIAR");
		}
	}, [nom_catastro]);

	//console.log(formRows);

	//**17-03-22 - Recuperar el valor recibido en editRegistro, para la pestaña de Trámites--->Paso "A"
	const {
		solicitud_adhesion_t, //101
		certif_urbanistico_municipal_t, //102
		certif_no_inundabilidad_t, //103
		anteproyecto_urbano_t, //104
		titulo_propiedad_t, //105
		informe_dominio_t, //106
		anotaciones_personales_t,//107
		admisibilidad_inicial_t,//108
		prefactibilidad_urbanistica_t,//109
		prefactibilidad_hidraulica_t,//110
		memoria_descriptiva_obras_t,//111
		energia_electrica_t, //112
		agua_potable_t, //113
		eliminacion_excretas_t, //114
		apertura_calles_t, //115
		acta_constitutiva_t, //116
		certif_vigencia_persona_juridica_t, //117
		plano_mensura_t, //118
		convenio_urbanistico_t, //119
		ordenanza_municipal_t, //120
		memoria_descriptiva_procedimientos_t, //121
		certif_tecnico_urbanistico_t, //122
		resolucion_afectacion_t, //123
		visado_plano_t, //124
		observaciones_internas_t, //125
		observaciones_reporte_t, //126
		fecha_envio_prefac_h_t, //127
		expediente_prefac_h_t, //128
		fecha_emision_prefac_h_t, //129
		fecha_visado_plano_t, //130
		fecha_envio_resol_t, //131
		fecha_emision_resol_t, //132
		numero_especial_resol_t, //133
		t_127_tramitacion_ada_prefac_h,
		t_131_observaciones_prefact_h,
		t_132_tramitacion_resol,
		t_136_observaciones_resol,
		t_137_tramitacion_visado,
		t_139_fecha_entrega, //139 - ADD 20-09-2022
    	t_140_observaciones_tramitacion, //140 - ADD 20-09-2022
		t_144_proyecto_hidraulico, //144 -- add 22-12-2022
		t_145_cpu_tramitacion, //145 --add 14-JUN-2024
		t_146_cpu_fecha_envio, //146 --add 14-JUN-2024
		t_147_cpu_fecha_emision, //147 --add 14-JUN-2024
		t_148_cpu_nro_gdeba, //148 --add 14-JUN-2024
		t_149_cpu_observaciones, //149 --add 14-JUN-2024
		
	} = editRegistro;
	//console.log("HIDRULICO --->" ,t_144_proyecto_hidraulico);

	//** 18-03-22: manejo de estado solo para fechas **
	let _fecha_carga = null;
	if(fecha_carga === null){
		_fecha_carga = null;
	} else if (fecha_carga !== null || fecha_carga !== ""){		
		_fecha_carga =  new Date(fecha_carga);
		_fecha_carga.setMinutes(_fecha_carga.getMinutes() + _fecha_carga.getTimezoneOffset())
		//console.log(_fecha_carga);
	}

	let _fecha_caratulacion = null;
	if(fecha_caratulacion === null){
		_fecha_caratulacion = null;
	} else if (fecha_caratulacion !== null || fecha_caratulacion !== ""){		
		_fecha_caratulacion =  new Date(fecha_caratulacion);
		_fecha_caratulacion.setMinutes(_fecha_caratulacion.getMinutes() + _fecha_caratulacion.getTimezoneOffset())
		//console.log(_fecha_caratulacion);
	}

	let _fecha_actualizacion = null;
	if(fecha_actualizacion === null){
		_fecha_actualizacion = null;
	} else if (fecha_actualizacion !== null || fecha_actualizacion !== ""){		
		_fecha_actualizacion =  new Date(fecha_actualizacion);
		_fecha_actualizacion.setMinutes(_fecha_actualizacion.getMinutes() + _fecha_actualizacion.getTimezoneOffset())
		//console.log(_fecha_actualizacion);
	}

	let _127_fecha_envio_prefac_h_t = null;
	if (fecha_envio_prefac_h_t === null) {
		//console.log("ES NULL");
		_127_fecha_envio_prefac_h_t = null;
	} else if (fecha_envio_prefac_h_t !== null || fecha_envio_prefac_h_t !== "") {
		_127_fecha_envio_prefac_h_t = new Date(fecha_envio_prefac_h_t);
		_127_fecha_envio_prefac_h_t.setMinutes(_127_fecha_envio_prefac_h_t.getMinutes() + _127_fecha_envio_prefac_h_t.getTimezoneOffset())
	}

	let _129_fecha_emision_prefac_h_t = null;
	if (fecha_emision_prefac_h_t === null) {
		_129_fecha_emision_prefac_h_t = null;
	} else if (fecha_emision_prefac_h_t !== null || fecha_emision_prefac_h_t !== "") {
		_129_fecha_emision_prefac_h_t = new Date(fecha_emision_prefac_h_t);
		_129_fecha_emision_prefac_h_t.setMinutes(_129_fecha_emision_prefac_h_t.getMinutes() + _129_fecha_emision_prefac_h_t.getTimezoneOffset())
	}

	let _130_fecha_visado_plano_t = null;
	if (fecha_visado_plano_t === null) {
		//console.log("ES NULL");
		_130_fecha_visado_plano_t = null;
	} else if (fecha_visado_plano_t !== null || fecha_visado_plano_t !== ""){
		//console.log("NO TIENE QUE ENTRAR SI NO ES NULL");
		_130_fecha_visado_plano_t = new Date(fecha_visado_plano_t);
		_130_fecha_visado_plano_t.setMinutes(_130_fecha_visado_plano_t.getMinutes() + _130_fecha_visado_plano_t.getTimezoneOffset())
	}

	let _131_fecha_envio_resol_t = null;
	if (fecha_envio_resol_t === null) {
		//console.log("ES NULL");
		_131_fecha_envio_resol_t = null;
	} else if (fecha_envio_resol_t !== null || fecha_envio_resol_t !== ""){
		_131_fecha_envio_resol_t = new Date(fecha_envio_resol_t);
		_131_fecha_envio_resol_t.setMinutes(_131_fecha_envio_resol_t.getMinutes() + _131_fecha_envio_resol_t.getTimezoneOffset())
	}

 	let _132_fecha_emision_resol_t = null;
	if (fecha_emision_resol_t === null) {
		//console.log("ES NULL");
		_132_fecha_emision_resol_t = null;
	} else if (fecha_emision_resol_t !== null || fecha_emision_resol_t !== "" ){
		_132_fecha_emision_resol_t = new Date(fecha_emision_resol_t);
		_132_fecha_emision_resol_t.setMinutes(_132_fecha_emision_resol_t.getMinutes() + _132_fecha_emision_resol_t.getTimezoneOffset())
		//console.log("CAMBIO?", _132_fecha_emision_resol_t)
	}

	let _t_139_fecha_entrega = null;
	if (t_139_fecha_entrega === null) {
		_t_139_fecha_entrega = null;
	} else if (t_139_fecha_entrega !== null || t_139_fecha_entrega !== "" ){
		_t_139_fecha_entrega = new Date(t_139_fecha_entrega);
		_t_139_fecha_entrega.setMinutes(_t_139_fecha_entrega.getMinutes() + _t_139_fecha_entrega.getTimezoneOffset())
	}


	let _csp_25_registracion_fecha = null;
	if (csp_25_registracion_fecha === null) {
		_csp_25_registracion_fecha = null;
	} else if (csp_25_registracion_fecha !== null || csp_25_registracion_fecha !== "" ){
		_csp_25_registracion_fecha = new Date(csp_25_registracion_fecha+"T00:00:00");
	}

	//+++ 02-jun-2024
	let _csp_32_fecha_control_seguimiento_obras=null;
	if(csp_32_fecha_control_seguimiento_obras===null){
		_csp_32_fecha_control_seguimiento_obras = null;
	}else if(csp_32_fecha_control_seguimiento_obras !==null || csp_32_fecha_control_seguimiento_obras !== ""){
		_csp_32_fecha_control_seguimiento_obras = new Date(csp_32_fecha_control_seguimiento_obras);
		_csp_32_fecha_control_seguimiento_obras.setMinutes(_csp_32_fecha_control_seguimiento_obras.getMinutes()+ _csp_32_fecha_control_seguimiento_obras.getTimezoneOffset())
	}
	//--cierra manejo fechas 02-jun-2024

	//+++ 14-jun-2024
	let _t_146_cpu_fecha_envio=null;
	if(t_146_cpu_fecha_envio===null){
		_t_146_cpu_fecha_envio=null;
	} else if(t_146_cpu_fecha_envio !== null || t_146_cpu_fecha_envio !== ""){
		_t_146_cpu_fecha_envio = new Date(t_146_cpu_fecha_envio);
		_t_146_cpu_fecha_envio.setMinutes(_t_146_cpu_fecha_envio.getMinutes()+_t_146_cpu_fecha_envio.getTimezoneOffset())
	}

	let _t_147_cpu_fecha_emision=null;
	if(t_147_cpu_fecha_emision===null){
		_t_147_cpu_fecha_emision=null;
	} else if(t_147_cpu_fecha_emision !== null || t_147_cpu_fecha_emision !== ""){
		_t_147_cpu_fecha_emision = new Date(t_147_cpu_fecha_emision);
		_t_147_cpu_fecha_emision.setMinutes(_t_147_cpu_fecha_emision.getMinutes()+_t_147_cpu_fecha_emision.getTimezoneOffset())
	}
	//--cierra manejo de fechas 14-jun-2024

	//++++ 16-08-2022
	let _csp_6_fecha_control_infra = null;
	if (csp_6_fecha_control_infra === null) {
		_csp_6_fecha_control_infra = null;
	} else if (csp_6_fecha_control_infra !== null || csp_6_fecha_control_infra !== ""){
		_csp_6_fecha_control_infra =  new Date(csp_6_fecha_control_infra+"T00:00:00");
	}

	let _csp_11_fecha_control_viv = null;
	if (csp_11_fecha_control_viv === null) {
		_csp_11_fecha_control_viv = null;
	} else if (csp_11_fecha_control_viv !== null || csp_11_fecha_control_viv !== ""){
		_csp_11_fecha_control_viv = new Date(csp_11_fecha_control_viv+"T00:00:00");
	}

	let _csp_16_fecha_control_equip = null;
	if (csp_16_fecha_control_equip === null) {
		_csp_16_fecha_control_equip = null;
	} else if (csp_16_fecha_control_equip !== null || csp_16_fecha_control_equip !== ""){
		_csp_16_fecha_control_equip = new Date(csp_16_fecha_control_equip+"T00:00:00");
	}

	let _csp_22_pl_mensura_fecha = null;
	if (csp_22_pl_mensura_fecha === null) {
		_csp_22_pl_mensura_fecha = null;
	} else if (csp_22_pl_mensura_fecha !== null || csp_22_pl_mensura_fecha !== ""){
		_csp_22_pl_mensura_fecha = new Date(csp_22_pl_mensura_fecha+"T00:00:00");
	}

	/*let _csp_25_registracion_fecha = null;
	if (csp_25_registracion_fecha === null) {
		_csp_25_registracion_fecha = null;
	} else if (csp_25_registracion_fecha !== null || csp_25_registracion_fecha !== ""){
		_csp_25_registracion_fecha = new Date(csp_25_registracion_fecha+"T00:00:00");
		//console.log(_csp_25_registracion_fecha);
	}*/

	let _csp_26_fecha_control = null;

	if (csp_26_fecha_control === null) {
		_csp_26_fecha_control = null;
	} else if (csp_26_fecha_control !== null || csp_26_fecha_control !== ""){
		_csp_26_fecha_control = new Date(csp_26_fecha_control+"T00:00:00");
	}

	//}
	//---cierra manejo fechas 16-08-2022

	

  //ESTADOS inputs --> pestaña Datos
	const [inputs, setInputs] = useState({
		estadoExp : id_exp_prueba,
		nombre_proyecto: nombre_proyecto,
		//fecha_carga: new Date().toLocaleDateString('es-AR'), //NO mostrar la fecha actual
		fecha_carga: _fecha_carga, //new Date().toLocaleDateString('es-AR'),
		fecha_caratulacion: _fecha_caratulacion, //new Date(fecha_caratulacion),
		fecha_actualizacion : _fecha_actualizacion,
		datos_05_responsable:datos_05_responsable,
		expediente: expediente,
		tramite_prioritario: eval(tramite_prioritario), //check --> tramite_prioritario: tramite_prioritario,
		avanzados: eval(avanzados),
		programa: programa,
		estado: estado,
		tipo: tipo,
		partido: partido,// estado inicial tiene que ser un numero para que no de error en la base. Espera un integer
		localidad: localidad,
		cantidad_lotes: cantidad_lotes,
		cantidad_lotes_adjudicables: cantidad_lotes_adjudicables,
		porc_lotes_municipales: porc_lotes_municipales,
		lotes_adquiridos_municipio: lotes_adquiridos_municipio,
		datos_18_sintesis_nomenclatura: datos_18_sintesis_nomenclatura,
		titular_tierra: titular_tierra,
		plano_oficial: plano_oficial,
		area: area,
		zonificacion: zonificacion,
		nro_ordenanza_vigente: nro_ordenanza_vigente,
		decreto_promulgatorio: decreto_promulgatorio,
		sistema_agua: sistema_agua,
		eliminacion_excretas: eliminacion_excretas,
		observaciones_generales: observaciones_generales,

		datos_19_partido_nomencla:datos_19_partido_nomencla,
		datos_20_circ_nomencla: datos_20_circ_nomencla,
		datos_21_secc_nomencla: datos_21_secc_nomencla,
		datos_22_geom: geomEdit
	});
	//console.log(inputs);

	// NO USAR!!! ENVIAR dato que tiene cargado ----> Pasos "B" (en datosEditExp -->"C")
	const [inputs_send, setInputsSend] = useState({
    	nombre_proyecto_i : nombre_proyecto,
		expediente_i: expediente,
		fecha_carga_i: fecha_carga,
		responsable_i : datos_05_responsable
  	});

	//**11jul24 */
	const [errorD, setErrorD] = useState(false); 

	const recibirErrorD = (newError)=>{
		setErrorD(newError)
	}

	const [errorCSP, setErrorCSP] = useState(false); 

	const recibirErrorCSP = (newError)=>{
		setErrorCSP(newError)
	}

	const [resultError, setResultError] = useState();

	useEffect(()=>{
		if(errorD === true || errorCSP === true){
			setResultError(true)
		} else {
			setResultError(false)
		}

	}, [errorD, errorCSP])
	//--cierra 11jul24

	const handleChangeRow = (newRow) =>{
		setFormRows(newRow)
	}

	//estados de tramites
	const [inputsTram, setInputsTram] = useState({
		solicitud_adhesion_t: eval(solicitud_adhesion_t), //101
		certif_urbanistico_municipal_t: eval(certif_urbanistico_municipal_t), //102
		certif_no_inundabilidad_t: eval(certif_no_inundabilidad_t), //103
		anteproyecto_urbano_t: eval(anteproyecto_urbano_t), //104
		titulo_propiedad_t: eval(titulo_propiedad_t), //105
		informe_dominio_t: eval(informe_dominio_t), //106
		anotaciones_personales_t: eval(anotaciones_personales_t), //107
		admisibilidad_inicial_t: eval(admisibilidad_inicial_t), //108
		prefactibilidad_urbanistica_t: eval(prefactibilidad_urbanistica_t), //109
		prefactibilidad_hidraulica_t: eval(prefactibilidad_hidraulica_t), //110
		memoria_descriptiva_obras_t: eval(memoria_descriptiva_obras_t), //111
		energia_electrica_t: eval(energia_electrica_t), //112
		agua_potable_t: eval(agua_potable_t), //113
		eliminacion_excretas_t: eval(eliminacion_excretas_t), //114
		apertura_calles_t: eval(apertura_calles_t), //115
		acta_constitutiva_t: eval(acta_constitutiva_t), //116
		certif_vigencia_persona_juridica_t: eval(certif_vigencia_persona_juridica_t), //117
		plano_mensura_t: eval(plano_mensura_t), //118
		convenio_urbanistico_t: eval(convenio_urbanistico_t), //119
		ordenanza_municipal_t: eval(ordenanza_municipal_t), //120
		memoria_descriptiva_procedimientos_t: eval(memoria_descriptiva_procedimientos_t), //121
		certif_tecnico_urbanistico_t: eval(certif_tecnico_urbanistico_t), //122
		resolucion_afectacion_t: eval(resolucion_afectacion_t), //123
		visado_plano_t: eval(visado_plano_t), //124
		observaciones_internas_t: observaciones_internas_t,//125
		observaciones_reporte_t: observaciones_reporte_t,//126
		//fecha_envio_prefac_u_t:"",
		//expediente_prefac_u_t:"",
		//fecha_emision_prefac_u_t:"",

		fecha_envio_prefac_h_t: _127_fecha_envio_prefac_h_t, //127 se agregan las fechas a los inputs
		expediente_prefac_h_t: expediente_prefac_h_t, //128
		fecha_emision_prefac_h_t: _129_fecha_emision_prefac_h_t, //129 se agregan las fechas a los inputs

		fecha_visado_plano_t: _130_fecha_visado_plano_t, //130 se agregan las fechas a los inputs, previa validacion

		fecha_envio_resol_t: _131_fecha_envio_resol_t, //131 se agregan las fechas a los inputs
		fecha_emision_resol_t: _132_fecha_emision_resol_t, //132 se agregan las fechas a los inputs
		numero_especial_resol_t: numero_especial_resol_t, //133
		t_127_tramitacion_ada_prefac_h: t_127_tramitacion_ada_prefac_h,
		t_131_observaciones_prefact_h: t_131_observaciones_prefact_h,
		t_132_tramitacion_resol: t_132_tramitacion_resol,
		t_136_observaciones_resol: t_136_observaciones_resol,
		t_137_tramitacion_visado: t_137_tramitacion_visado,
		t_139_fecha_entrega: _t_139_fecha_entrega, //139 - ADD 20-09-2022
    	t_140_observaciones_tramitacion: t_140_observaciones_tramitacion, //140 - ADD 20-09-2022
		t_144_proyecto_hidraulico: eval(t_144_proyecto_hidraulico), //144 -- add 22-12-2022

		t_145_cpu_tramitacion: t_145_cpu_tramitacion, //145 --add 14-JUN-2024
		t_146_cpu_fecha_envio: _t_146_cpu_fecha_envio, //146 --add 14-JUN-2024
		t_147_cpu_fecha_emision: _t_147_cpu_fecha_emision, //147 --add 14-JUN-2024
		t_148_cpu_nro_gdeba: t_148_cpu_nro_gdeba, //148 --add 14-JUN-2024
		t_149_cpu_observaciones: t_149_cpu_observaciones, //149 --add 14-JUN-2024
	});

	const [inputsCSP, setInputsCSP] = useState({
		csp_1_tamaño_medio_lotes_residenciales : csp_1_tamaño_medio_lotes_residenciales,
		csp_2_actor_encargado_obras_infra : csp_2_actor_encargado_obras_infra,
		csp_3_financiamiento_publico_obras_infra: eval(csp_3_financiamiento_publico_obras_infra), 	//------check
		csp_4_jurisdiccion_infra : csp_4_jurisdiccion_infra,
		csp_5_organismo_financiamiento_infra : csp_5_organismo_financiamiento_infra,
		csp_6_fecha_control_infra : _csp_6_fecha_control_infra,//date
		csp_7_descripcion_obras_infra : csp_7_descripcion_obras_infra,
		csp_8_financiamiento_publico_obras_viv: eval(csp_8_financiamiento_publico_obras_viv), 	//-----check
		csp_9_jurisdiccion_viv : csp_9_jurisdiccion_viv,
		csp_10_organismo_financiamiento_viv : csp_10_organismo_financiamiento_viv,
		csp_11_fecha_control_viv: _csp_11_fecha_control_viv, //date
		csp_12_descripcion_obras_viv : csp_12_descripcion_obras_viv,
		csp_13_financiamiento_publico_obras_equip: eval(csp_13_financiamiento_publico_obras_equip), 	//-----check
		csp_14_jurisdiccion_equip : csp_14_jurisdiccion_equip,
		csp_15_organismo_financiamiento_equip : csp_15_organismo_financiamiento_equip,
		csp_16_fecha_control_equip: _csp_16_fecha_control_equip, //date
		csp_17_descripcion_obras_equip : csp_17_descripcion_obras_equip,
		csp_18_pl_mensura_estado : csp_18_pl_mensura_estado,
		csp_19_pl_mensura_partido : csp_19_pl_mensura_partido,
		csp_20_pl_mensura_orden : csp_20_pl_mensura_orden,
		csp_21_pl_mensura_año : csp_21_pl_mensura_año,
		csp_22_pl_mensura_fecha: _csp_22_pl_mensura_fecha, //date
		csp_23_registracion_legajo : csp_23_registracion_legajo,
		csp_24_registracion_folio : csp_24_registracion_folio,
		csp_25_registracion_fecha: _csp_25_registracion_fecha, //date _csp_25_registracion_fecha
		csp_26_fecha_control: _csp_26_fecha_control, //date
		csp_27_observaciones_csp : csp_27_observaciones_csp,
		csp_28_presencia_apertura_calles : csp_28_presencia_apertura_calles,
		csp_29_parc_edificadas : csp_29_parc_edificadas,
		csp_30_porcentaje_parc_edificadas : csp_30_porcentaje_parc_edificadas,
		csp_31_observ_seguimiento_obras_y_edificacion : csp_31_observ_seguimiento_obras_y_edificacion,
		csp_32_fecha_control_seguimiento_obras : _csp_32_fecha_control_seguimiento_obras
	});
	//console.log(inputsCSP);
	//console.log(inputsCSP.csp_25_registracion_fecha);

	/*useEffect(()=>{
		//console.log("CAMBIO CSP");
		if(array_csp !== null){
			setInputsCSP(array_csp[0])
		} else {
			//console.log("CSP es NULL, NO CAMBIAR");
		}
	}, [array_csp]);*/

	//funciones para mover DAtosExp  a otro componente¨***
	const cargaExpediente = (newExpediente) => {
		//console.log("FUNCTION cargaExpediente - grupo_editexpediente");
		//console.log(newExpediente);
		setInputs(newExpediente)
	}

	const cargaTramite = (newTramite) => {
		setInputsTram(newTramite)
		//console.log("EJECUTAR cargaTramite");
	}

	const cargaCSP=(newCSP) => {
		setInputsCSP(newCSP)
		//console.log("EJECUTAR cargaCSP");
	}

	/* ---> MANEJAR etado global con useContext --- 12-oct-2022
	const cambiarOriginal= (newCambios) => {
		setOriginal(newCambios);
	}*/

	//funcion para recibir cambio de estado en checkbox de hijo a padre  - 15-03-2022
	/*
	const estadoChecks = (ch) => {
		setCheckTram(ch)
	}
	*/

	//const {observaciones_internas_t, observaciones_reporte_t}= inputsTram;
	//console.log(inputsTram);

	//const [optionsPartido, setOptionsPartido] = useState([]);// cambia estado desde getPartidos ----> setOptionsPartido
	//console.log(optionsPartido);

	/*const { nombre_proyecto, fecha_carga, encargado, expediente, programa, estado, tipo, partido, localidad, cantidad_lotes, cantidad_lotes_adjudicables, porc_lotes_municipales, lotes_adquiridos_municipio, titular_tierra, plano_oficial, area, zonificacion, nro_ordenanza_vigente, decreto_promulgatorio, sistema_agua, eliminacion_excretas, observaciones_generales } = inputs;*/

	//const {fecha_caratulacion} = inputsFecha;
	//console.log(fecha_caratulacion);

	/*
	*** USAR para cargar select desde datos externos
	const getPartidos = async () => {
		const urlPartidos = `http://geo.arba.gov.ar/datoabierto/partidos`;

		const res = await fetch(urlPartidos)
		.then(res => res.json())
		.then(res => setOptionsPartido(res))
		.catch(err => console.log(err));
	}

	useEffect(() => {
                getPartidos();
        },[]);*/

	const calcularLotes = () => {
		//console.log("CALCULAR...");
		//console.log(inputs.porc_lotes_municipales);
		//console.log(inputs.cantidad_lotes_adjudicables);

		var porcentaje = inputs.porc_lotes_municipales;
		var cantidad = inputs.cantidad_lotes_adjudicables;

		var value_lotes_adquiridos_munic = (porcentaje * cantidad)/100;
		//console.log(value_lotes_adquiridos_munic);

		setInputs({ ...inputs, lotes_adquiridos_municipio: value_lotes_adquiridos_munic});
	}

	const onChange = e => {
		//console.log("ACA??")
		//console.log(e.target);
		if(e.target.name != "prioritario"){
			//console.log("CHANGE");
			//console.log(e.target.name);
			//console.log(e.target.value);


    } else {
    	setInputs({ ...inputs, [e.target.name]: e.target.value });
		}

		/*if(e.target.name === "porc_lotes_municipales"){
			//setInputs({ ...inputs, [e.target.name]: e.target.value });
			//console.log("CALCULAR valor de lotes adquiridos municipio");

			var cant_lotes_adj= inputs.cantidad_lotes_adjudicables;

			//calcularLotes()
		}*/

		//console.log(inputs);
	}

	const onChange2 = e => {
		//console.log("SE TIENE QUE EJECUTAR??");
		if(tramite_prioritario == true){
			//setPrioritario(false);
			//console.log("ahora false");
		} else if(tramite_prioritario == false){
			//setPrioritario(true);
			//console.log("ahora true");
		}
	}

	const onSubmitForm = async e => {
    		e.preventDefault();
			console.log("ACÁ ???")

    		/*try {

			const body = { nombre_proyecto, expediente};

      			const response = await fetch(
        			//"http://localhost:5003/authentication/new_expediente",
				`${baseURL}authentication/new_expediente`,
        			{
          				method: "POST",
          				headers: {
            					"Content-type": "application/json"
          				},
          				body: JSON.stringify(body)
        			}
			);

      			const parseRes = await response.json();

			if (parseRes.id){
				toast.success(`Expediente ${parseRes.expediente} creado correctamente!!`);
			} else {
				//console.log("error");
				toast.error(parseRes);
			}

    		} catch (err) {
      			console.error(err.message);
    		}*/
	};

	/***AGREGADO 04-08-2022 ****/
	/*function myFunction() {
	  let text = "Press a button!\nEither OK or Cancel.";
	  if (confirm(text) == true) {
	    text = "You pressed OK!";
	  } else {
	    text = "You canceled!";
	  }
	  document.getElementById("demo").innerHTML = text;
	}*/


	const borrarRegistro_SI = async e => {
		e.preventDefault();
		
		const { estadoExp,
			nombre_proyecto, expediente,
			fecha_carga, fecha_caratulacion,
			datos_05_responsable, tramite_prioritario, avanzados,
			programa, estado,
			tipo, partido,
			localidad, cantidad_lotes,
			cantidad_lotes_adjudicables,
			porc_lotes_municipales, lotes_adquiridos_municipio,
			datos_18_sintesis_nomenclatura,
			titular_tierra, plano_oficial,
			area, zonificacion,
			nro_ordenanza_vigente, decreto_promulgatorio,
			sistema_agua, eliminacion_excretas,
			observaciones_generales,
			//nameB,//ADD 01-nov-2022 para identificar usuario
		} = inputs; //---> viene de Datos Generales
		//console.log(inputs);

		const {
			solicitud_adhesion_t,
			certif_urbanistico_municipal_t, certif_no_inundabilidad_t, anteproyecto_urbano_t,
			titulo_propiedad_t, memoria_descriptiva_obras_t, energia_electrica_t, prefactibilidad_urbanistica_t,
			agua_potable_t, eliminacion_excretas_t, apertura_calles_t, acta_constitutiva_t, certif_vigencia_persona_juridica_t,
			plano_mensura_t, convenio_urbanistico_t, ordenanza_municipal_t, memoria_descriptiva_procedimientos_t, informe_dominio_t,
			anotaciones_personales_t, admisibilidad_inicial_t, prefactibilidad_hidraulica_t, certif_tecnico_urbanistico_t,
			resolucion_afectacion_t, visado_plano_t,
			observaciones_internas_t,
			observaciones_reporte_t,
			fecha_envio_prefac_h_t, expediente_prefac_h_t, fecha_emision_prefac_h_t,
			fecha_envio_resol_t, fecha_emision_resol_t, numero_especial_resol_t,
			t_127_tramitacion_ada_prefac_h,
			t_131_observaciones_prefact_h,
			t_132_tramitacion_resol,
			t_136_observaciones_resol,
			t_137_tramitacion_visado,
			fecha_visado_plano_t,
			t_139_fecha_entrega,
			t_140_observaciones_tramitacion,
			t_144_proyecto_hidraulico, //144 -- add 22-12-2022
			t_145_cpu_tramitacion, //145 --add 14-JUN-2024
			t_146_cpu_fecha_envio, //146 --add 14-JUN-2024
			t_147_cpu_fecha_emision, //147 --add 14-JUN-2024
			t_148_cpu_nro_gdeba, //148 --add 14-JUN-2024
			t_149_cpu_observaciones, //149 --add 14-JUN-2024
		}= inputsTram;

		try {
			const body = {
				name,
				inputs,
				inputsTram,
				inputsCSP,
				formRows
			}
			//console.log(body);
			//console.log(body.inputs.estadoExp);

			const response = await fetch(
				`/api/authentication/borrar_expediente`,
				{
				method: "POST",
				headers: {
					"Content-type": "application/json"
				},
				body: JSON.stringify(body)
				}
			);
			//console.log(response);
			const parseRes = await response.json();
			//console.log(parseRes);

			/*if (parseRes.id === 'error'){
				toast.error('Ocurrio un problema al borrar el expediente');

			} else*/ if (parseRes.expediente){
				//console.log('8');
				toast.success(`Expediente ${parseRes.expediente} eliminado!!`);
				setShow(false);
				setEdit(false); //20-04-2022

			} else {
				toast.error('Ocurrio un problema al borrar el expediente');
			}

		} catch(err){
			console.error(err.message);
		}

	}//cierra borrarRegistro - 04-agos-2022


	const saveSeguir_prueba = async (e) => {
		e.preventDefault();
		//console.log('Save y seguir - prueba');

		const { estadoExp,
			nombre_proyecto, expediente,
			fecha_carga, fecha_caratulacion,// fecha_actualizacion se calcula en el server
			datos_05_responsable, tramite_prioritario, avanzados,
			programa, estado,
			tipo, partido,
			localidad, cantidad_lotes,
			cantidad_lotes_adjudicables,
			porc_lotes_municipales, lotes_adquiridos_municipio,
			datos_18_sintesis_nomenclatura,
			titular_tierra, plano_oficial,
			area, zonificacion,
			nro_ordenanza_vigente, decreto_promulgatorio,
			sistema_agua, eliminacion_excretas,
			observaciones_generales,
			datos_19_partido_nomencla, datos_20_circ_nomencla, datos_21_secc_nomencla,
			datos_22_geom
		} = inputs; //---> viene de Datos Generales

		const {
			solicitud_adhesion_t,
			certif_urbanistico_municipal_t, certif_no_inundabilidad_t, anteproyecto_urbano_t,
			titulo_propiedad_t, memoria_descriptiva_obras_t, energia_electrica_t, prefactibilidad_urbanistica_t,
			agua_potable_t, eliminacion_excretas_t, apertura_calles_t, acta_constitutiva_t, certif_vigencia_persona_juridica_t,
			plano_mensura_t, convenio_urbanistico_t, ordenanza_municipal_t, memoria_descriptiva_procedimientos_t, informe_dominio_t,
			anotaciones_personales_t, admisibilidad_inicial_t, prefactibilidad_hidraulica_t, certif_tecnico_urbanistico_t,
			resolucion_afectacion_t, visado_plano_t,
			observaciones_internas_t,//125
			observaciones_reporte_t,//126
			fecha_envio_prefac_h_t, expediente_prefac_h_t, fecha_emision_prefac_h_t,
			fecha_envio_resol_t, fecha_emision_resol_t, numero_especial_resol_t,
			t_127_tramitacion_ada_prefac_h,
			t_131_observaciones_prefact_h,
			t_132_tramitacion_resol,
			t_136_observaciones_resol,
			t_137_tramitacion_visado,
			fecha_visado_plano_t,
			t_139_fecha_entrega, //139 - ADD 20-09-2022
	    	t_140_observaciones_tramitacion,
			t_144_proyecto_hidraulico, //144 -- add 22-12-2022
			t_145_cpu_tramitacion, //145 --add 14-JUN-2024
			t_146_cpu_fecha_envio, //146 --add 14-JUN-2024
			t_147_cpu_fecha_emision, //147 --add 14-JUN-2024
			t_148_cpu_nro_gdeba, //148 --add 14-JUN-2024
			t_149_cpu_observaciones, //149 --add 14-JUN-2024
		}= inputsTram;

		const body = {
			estadoExp, //es el id_exp_prueba
			nombre_proyecto, expediente,
			fecha_carga, fecha_caratulacion,
			datos_05_responsable, tramite_prioritario, avanzados,
			programa, estado,
			tipo, partido, localidad, cantidad_lotes, cantidad_lotes_adjudicables,
			porc_lotes_municipales, lotes_adquiridos_municipio,
			datos_18_sintesis_nomenclatura,
			titular_tierra, plano_oficial, area, zonificacion, nro_ordenanza_vigente, decreto_promulgatorio,
			sistema_agua, eliminacion_excretas,
			observaciones_generales,
			datos_19_partido_nomencla, datos_20_circ_nomencla, datos_21_secc_nomencla,
			datos_22_geom,
			name,
			//nomencla
			formRows,
			//Tramitacion //AGEGADO 08-04-2022
			solicitud_adhesion_t, //101
			certif_urbanistico_municipal_t,// 102
			certif_no_inundabilidad_t,// 103
			anteproyecto_urbano_t, //104
			titulo_propiedad_t, //105

			informe_dominio_t, //106
			anotaciones_personales_t, //107
			admisibilidad_inicial_t, //108
			prefactibilidad_urbanistica_t, //109
			prefactibilidad_hidraulica_t, //110

			memoria_descriptiva_obras_t, //111
			energia_electrica_t, //112
			agua_potable_t, //113
			eliminacion_excretas_t, //114
			apertura_calles_t, //115
			acta_constitutiva_t, //116
			certif_vigencia_persona_juridica_t, //117
			plano_mensura_t, //118
			convenio_urbanistico_t, //119
			ordenanza_municipal_t, //120
			memoria_descriptiva_procedimientos_t, //121
			certif_tecnico_urbanistico_t, //122
			resolucion_afectacion_t, //123
			visado_plano_t, //124
			observaciones_internas_t, //125
			observaciones_reporte_t, //126
			fecha_envio_prefac_h_t, //127
			expediente_prefac_h_t, //128
			fecha_emision_prefac_h_t, //129
			fecha_visado_plano_t, //130
			fecha_envio_resol_t, //131
			fecha_emision_resol_t, //132
			numero_especial_resol_t, //133
			t_127_tramitacion_ada_prefac_h,
			t_131_observaciones_prefact_h,
			t_132_tramitacion_resol,
			t_136_observaciones_resol,
			t_137_tramitacion_visado,
			t_139_fecha_entrega, //139 - ADD 20-09-2022
			t_140_observaciones_tramitacion, //140 - ADD 20-09-2022
			t_144_proyecto_hidraulico, //144 -- add 22-12-2022
			t_145_cpu_tramitacion, //145 --add 14-JUN-2024
			t_146_cpu_fecha_envio, //146 --add 14-JUN-2024
			t_147_cpu_fecha_emision, //147 --add 14-JUN-2024
			t_148_cpu_nro_gdeba, //148 --add 14-JUN-2024
			t_149_cpu_observaciones, //149 --add 14-JUN-2024

			inputsCSP
		};

		const url = `/api/authentication/edit_simplificada`;

		const headers = {  'Content-Type': 'application/json', 'Authorization': localStorage.tokenT, roleT: localStorage.roleT, username:localStorage.username }

		const options = {
			method: "POST",
			headers: headers,
			body: JSON.stringify(body)
		}

		let response = await fetch(url, {
			method: 'POST',
			body: JSON.stringify(body),
			headers:{ Accept: "application/json", 'Content-Type': 'application/json', 'Authorization': localStorage.tokenT, roleT: localStorage.roleT, username:localStorage.username }
		});
		
		if(response.ok){
			const parseRes = await response.json();
			
			if (parseRes.id === undefined){ //cuando el estado es 'nuevo' y se ingresa un expediente que ya existe
				//console.log('1');
				toast.error(`El expediente ya existe, intente nuevamente.`); //NO SALIR, para permitir cambiar el nro de expediente

			} else if (parseRes.id !== parseRes.id_exp_prueba && parseRes.id !== 'error'){
				//console.log('2');
				toast.success(`Se actualizo el expediente ${parseRes.expediente}.`);
				cambioOriginal(0);
				if (e.target.id ==="but_save_e2"){ // SI EL EVENTO SE PRIDUCE DESDE ESTE ID ---> cambia el estado a Edit = false 05-08-2022
					setEdit(false); //20-04-2022
				}

			} else if (parseRes.id !== undefined && parseRes.id === parseRes.id_exp_prueba ){
				//console.log('3');
				//console.log(parseRes.expediente);
				toast.success(`Expediente ${parseRes.expediente} creado correctamente!!`);
				cambioOriginal(0)
				if (e.target.id ==="but_save_e2"){
					setEdit(false); //20-04-2022 // SI EL EVENTO SE PRIDUCE DESDE ESTE ID ---> cambia el estado a Edit = false 05-08-2022
				}

			} else if (parseRes.id=== 'error' && parseRes.message === 'Ha ocurrido un error'){
				//console.log('6');
				toast.error(`Ocurrió un error al editar el expediente.`);

			} else if (parseRes.id=== 'error' && parseRes.message === 'Ha ocurrido un error'){
				//console.log('7');
				toast.error(`Ocurrió un error, por favor intente nuevamente. Si el problema continua comunicarse con un administrador`);
			} else {
				//console.log('4');
				//console.log("error");
				toast.error(parseRes);
			}
		} else {
			//console.log('Respuesta de red OK pero respuesta de HTTP no OK');
		}
	}

	const handleTramite = () => {
		setOpenE(false);
		setOpenT(true);
		setOpenP3(false);
	}

	const handleExpediente = () => {
		setOpenE(true);
		setOpenT(false);
		setOpenP3(false);
	}

	const handlePestaña3 = () => {
		setOpenE(false);
		setOpenT(false);
		setOpenP3(true);
	}

	const submButton = () => {
		return (
			<Fragment>
				<Button
					variant="secondary" onClick={handleExpediente}
					size="sm" type="info"
					aria-controls="example-collapse-textT"
					aria-expanded={openExpediente}
					disabled={openExpediente ? true : false}
				>
					DATOS GENERALES
				</Button>

				<Button
					variant="secondary" onClick={handleTramite}
					size="sm" type="info"
					aria-controls="example-collapse-textEx"
					aria-expanded={openTramite}
					/*className= {openTramite===true ? "button_false" : "button_true"}*/
					disabled={openTramite ? true : false}
				>
					TRAMITACIÓN DEL EXPEDIENTE
				</Button>

				<Button
					variant="secondary" onClick={handlePestaña3}
					size="sm" type="info"
					aria-controls="example-collapse-textP3"
					aria-expanded={openPestaña3}
					disabled= {openPestaña3 ? true : false}
				>
					CARACTERÍSTICAS Y SEGUIMIENTO DEL PROYECTO
				</Button>
			</Fragment>
		)
	}


	const [openCatastro, setOpenC] = useState(false);

	const [openExpediente, setOpenE] = useState(true);
	const [openTramite, setOpenT] = useState(false);
	const [openPestaña3, setOpenP3] = useState(false);

  return (
		<Fragment>
			{/* <div className=""> */}
			<div style={{width:"100%", height:"130px", border: "0px solid yellow", position: "sticky", top: "0px" }} className="div_stick" > {/*+A -div_stick border: "4px solid yellow", background:"green", */}

				<div id="id_b1" style={{borderTop: "1px solid white", background: "", height: "130px", position: "sticky", top: "10px"}} className="">{/* +B */}
    			<h4 className="mt-5 text-center">Actualizar Expediente</h4>

					<div  className="d-flex "> {/* quité el flexbox-row para que NO alinee al centro */}
						<div style={{position:"absolute", bottom:"5px"}} className="align-right-menu">
							{submButton()}
						</div>
					</div>
				</div>{/* -B - cierra id_b1*/}

			</div>{/* -A -cierra div_stick */}

			<Modal
				id="id_modal_1"
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				className="id_modal_1"
				style={{top: "200"}}
			>
				<Modal.Header style={{background: "rgb(0,174,195)", color:"white"}} closeButton>
					<Modal.Title>Confirmar</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Borrar expediente? {expediente}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button className="btn btn-success btn-block button_verde" onClick={borrarRegistro_SI}>
						Aceptar
					</Button>
					<Button className="btn btn-success btn-block button_verde" onClick={handleClose}>
						Cancelar
					</Button>
				</Modal.Footer>
			</Modal>


		  <Form id='my-form' onSubmit={onSubmitForm}>
				{/* Abre el div pestaña Datos Generales- probar llamar componente desde otro archivo */}
				{/* Pestaña Datos del expediente */}
				{/* 12-10-2022 - Manejar con useContext----> setOriginal={cambiarOriginal}*/}
			  <div>
				  <Collapse in={openExpediente} dimension="width">
				    <div style={{marginTop:"20px", marginBottom:"10px"}} id="example-collapse-textEx">
							{/* NO USAR DatosExp!!!! */}
							<DatosEditExp
								name={name}
								inputs={inputs} setInputs={cargaExpediente}
								formRows={formRows} setFormRows={handleChangeRow}
								setErrorD={recibirErrorD}
								nom_catastro = {nom_catastro}
								inputs_send={inputs_send}
							/>
				    </div>
				  </Collapse>
			  </div>

				{/* Pestaña Tramitacion del expediente */}
				{/* 12-10-2022 - Manejar con useContext----> setOriginal={cambiarOriginal}*/}
				<div >
				  <Collapse in={openTramite} dimension="width">
				    <div style={{marginTop:"20px", marginBottom:"10px"}} id="example-collapse-textT">
							{/* NO USAR TramiteExp!!!! */}
				    	<TramiteEditExp
								inputsTram={inputsTram} setInputsTram={cargaTramite} inputs={inputs}
							/>
				    </div>
				  </Collapse>
			  </div>

				{/* div pestaña3 colapsada - importar component */}
				{/* 12-10-2022 - Manejar con useContext----> setOriginal={cambiarOriginal}*/}
				<div  >
				  <Collapse in={openPestaña3} dimension="width">
				    <div style={{marginTop:"20px", marginBottom:"10px"}} id="example-collapse-textP3">
				    	<Pestaña3_EditExp
							 	inputsCSP={inputsCSP} setInputsCSP={cargaCSP} lotesAdjudicables={inputs.cantidad_lotes_adjudicables}
								 setErrorCSP={recibirErrorCSP}
							/>
				    </div>
				  </Collapse>
			  </div>

				<div style={{background: "white", width:"100%", height:"45px", borderBottom: "4px solid white", position: "sticky", bottom: "0px"}} className="d-flex">{/*13-08-22*/}
					<div className="flexbox-container">
					  <Button type="button" form='my-form'  id="but_save_c1" style={{ }} disabled={ role ==="visualizador" || role==="user" || original ===0 || sesionT ==='no_token' || resultError===true ? true : false } className="btn btn-success btn-block button_verde" onClick={redirect_Cont} >Guardar y Continuar</Button> {/*backgroundColor: "#592673", border:"#592673"*/}
						<Button type="button" id="but_save_e2" style={{ }} disabled={ role ==="visualizador" || role==="user" || original ===0 || sesionT ==='no_token'|| resultError===true ? true : false } className="btn btn-success btn-block button_verde" onClick={redirect_Cont} >Guardar y Salir</Button> {/* backgroundColor: "#592673", border:"#592673" */}
						<Button type="button" id="but_del_e1" style={{ }} disabled={ sesionT ==='no_token' || resultError===true ? true : false } className={ role==="admin" && sesionT !=="no_token" ? "btn btn-success btn-block button_rojo" : "btn hidden_button"} onClick={handleShow} >Borrar</Button> {/* backgroundColor: "red", border: "red"*/}
						{resultError === true
						?
						<p><Button type="button" id="but_new_save_e2" className="btn btn-success btn-block button_rojo" ><FaExclamationTriangle /></Button>
							<a align="left" style={{ height: "auto", fontSize: "0.9em", color: "red" }}>
								{` - Verificar datos`}
								{errorD === true && errorCSP === false ? ` en pestaña: Datos generales` : ``}
								{errorD === false && errorCSP === true ? ` en pestaña: Características y seguimiento del proyecto` : ``}
								{errorD === true && errorCSP === true ? ` en pestañas: Datos generales - Características y seguimiento del proyecto` : ``}</a>
						</p>
						:
						<a></a>}
				  </div>
				</div>

	    {/* <Button form='my-form-2' type="submit" className="btn btn-success btn-block" >Submit</Button> */}
    </Form>

		{/* </div> */}
  </Fragment>
  );
};

export default Grupo_EditExpediente;
