import React, { Fragment } from "react";

import Select_5_Visado from "./select_5_visado";

const Reporte_5_visado = ({openReporte_5_visado}) => {
  return (
    <Fragment>
      <div style={{ width:"100%" }}>
        {/*<h5 className="mt-5 text-center">Tramitación Visado</h5>*/}
        {/*<hr />*/}
        <Select_5_Visado openReporte_5_visado= {openReporte_5_visado}/>
      </div>
    </Fragment>
  );
}

export default Reporte_5_visado;
