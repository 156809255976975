import React, {Fragment, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

//import { toast } from "react-toastify"; 

const SelectGobLocal = (props) => {

	//console.log(props);
	
	const prov_id = props.prov_id;
	const prov_nombre = props.prov_nombre;
	const depto_id = props.depto_id;
	const depto_nombre = props.depto_nombre;
	const gobLocal_id = props.gobLocal_id;
	const gobLocal_nombre = props.gobLocal_nombre

	const [datoProv, setDatoProv] = useState("");
	const [valueProv, setValueProv] = useState("");

	const [datoDepto, setDatoDepto] = useState("");
        const [valueDepto, setValueDepto] = useState("");

	const [datoGL, setDatoGL] = useState("");
        const [valueGL, setValueGL] = useState("");

	/*const getDatoProv = (prov_id, prov_nombre) => {
		//console.log('Actualizar PROVINCIA');
		//console.log(prov_nombre);
	}*/

	useEffect(()=>{
		setDatoProv(prov_nombre);
		setValueProv(prov_id);

		setDatoDepto(depto_nombre);
		setValueDepto(depto_id);

		setDatoGL(gobLocal_nombre);//se le pasa el nuevo dato
		setValueGL(gobLocal_id);

		//getDatoProv(prov_id, prov_nombre); //**** Por ahora manejarlo con useState
		
	}, [gobLocal_id]); //**cada vez que cambia Gob Local ID

	const [selects, setSelects] = useState({
    		prov: "",
    		depto: "",
    		gobL: ""
  	});
	const { prov, depto, gobL } = selects;

	const onChange = e =>
    		setSelects({ ...selects, [e.target.name]: e.target.value });

	function handleSubmit(e){
    		e.preventDefault();
		//console.log(e);
    		//console.log(prov,depto, gobL)
		const body = {prov,depto, gobL }
	}

	//https://frendly.dev/posts/adjacent-jsx-elements-must-be-wrapped-in-an-enclosing-tag
	return (
		<Fragment>
    		<div id="id_div_respuestaNroGL">
			<p>Gobierno Local:{valueGL}</p>
			<form onSubmit={handleSubmit}>
		        <p>Provincia</p>
		        <select onChange="" value="" name = "prov">
				<option value={valueProv}>{datoProv}</option>
		        </select>
			<p>Departamento</p>
			<select onChange="" value="" name = "depto">
				<option value={valueDepto}>{datoDepto}</option>
			</select>
			<p>Gobierno Local</p>
                        <select onChange="" value="" name = "gobL">
                                <option value={valueGL}>{datoGL}</option>
                        </select>
		        <button type="submit">Submit</button>
		      </form>
    		</div>
		<div id="id_div_selectGL">

		</div>
		</Fragment>
	);
};

export default SelectGobLocal;
