import React, { Fragment } from "react";
import Navigation from "./navigation";
import LeafletMapProyectos from "./map_proyectos";

const Page1 =  ({ setAuth, role, setRole }) => {
    return (
	<Fragment>
		<Navigation setAuth={setAuth} role={role} setRole={setRole}/>
      		<div>
        		<h6> Prueba Geo - página 1</h6>
      		</div>
		<LeafletMapProyectos />
	</Fragment>
    );
}

export default Page1;
