import React, { useState, Fragment } from "react";
import Navigation from "./navigation";
import Footer from "./footer";

import Informe_1_sintesis from "./monitor/informe_1_sintesis";


const Informes_partido = ({ setAuth, setRole }) => {

  const [role, setUno] = useState("a1");
  const [activo, setActivo] = useState("inf_partidosA"); //15-06-2022

  return (
    <Fragment>
      
      <Navigation setAuth={setAuth} role={role} setRole={setRole} activo={activo}/>
      <div className="main">
        <h4 className="mt-5 text-center">Informes por partido</h4>
        <div>
          <Informe_1_sintesis />
        </div>
      </div>
      <Footer/>    
    
    </Fragment>
  );
}

export default Informes_partido;