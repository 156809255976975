import React, { Fragment, useState, useEffect, useContext } from "react";
import { SessionContext } from '../context/sessionContext';
import { Link, Redirect } from "react-router-dom";
//import Navigation from "./navigation";

import { Form, Button, Row, Col } from "react-bootstrap";

import SelectInput from "./selectInput";

import Grupo_EditExpediente from "./grupo_editexpediente";

//import {optionsEncargado, optionsPrograma, optionsEstado, optionsTipo, optionsPartido} from "../datos/options";

import dotenv from "dotenv";
import { toast } from "react-toastify";

//import {Button} from "./editExpediente.styled";
//const baseURL = process.env.REACT_APP_API_URL;

const EditExpediente_Update = ( {registro, edit, setEdit } ) => { // {role} ** -----> recibo el registro que se edita
	
	//Lo que recibo
	const { id_exp_prueba, nombre_proyecto, fecha_carga, encargado, expediente, tramite_prioritario, avanzados, programa, estado, tipo, partido, localidad, geom_as_geojson }= registro;
	//console.log(tramite_prioritario);
	//console.log(id_exp_prueba);
  	//console.log(geom_as_geojson);
	//console.log(typeof(geom_as_geojson));

	const { geomEdit, cambioGeomEdit } = useContext(SessionContext);
	//console.log(geomEdit);
	//console.log(typeof geomEdit);

	useEffect (()=>{
		if(geom_as_geojson !== '{"type":"GeometryCollection","geometries":[]}'){
			//alert("TIENE DATOS");
			let dos_geom = JSON.parse(geom_as_geojson);
			//console.log("DOS GEOM", dos_geom.geometries[0].coordinates);

			const geometriesS = dos_geom.geometries;

			const respuesta = geometriesS.map((row, i)=>{
				return {"type": "Feature", "properties": {}, "geometry":{"type":"Polygon","coordinates":row.coordinates}}
			});
			//console.log("RESPUESTA", respuesta)

			const nuevoG = {
				"type": "FeatureCollection",
				"features": respuesta		
			}
			//console.log(nuevoG)
			//console.log(JSON.stringify(nuevoG))
			cambioGeomEdit(nuevoG)
		} else {
			//alert("VIENE VACIO");
			let text_G = `{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{}}]}`;
			//cambioGeomEdit(text_G);
			cambioGeomEdit({"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{}}]});
		}
	}, [geom_as_geojson])

	const [indiceE, setIndice] = useState(encargado);// encargado ---> pasar el value o estado ""
	const [indicePrograma, setPrograma] = useState(""); //programa
	const [indiceEstado, setEstado] = useState(""); //estado
	const [indiceTipo, setTipo] = useState(""); //tipo
	const [indicePartido, setPartido] = useState(""); //partido
	//const [indiceAvanzados, setAvanzados] = useState(""); //partido

	//console.log(indiceE);
	//**pruebas estado Programa
	const estadoIndicePrograma	= (newPrograma) => {
		setPrograma(newPrograma);
	}

	//**** indice Programa***
	/*useEffect(() => {
		obtenerPrograma()
		obtenerEstado()
		obtenerTipo()
		obtenerPartido()
		obtenerAvanzados()
	}, [])*/

	const obtenerPrograma = (programa) => {
    //console.log("Obtener PROGRAMA");
		setPrograma(programa);
    //console.log(programa);
	}

	//*** indice Estado ****
  const obtenerEstado = () => {
    var valor = estado;
    setEstado(valor);
  }

	const obtenerTipo = () => {
		var valorT = tipo;
		setTipo(valorT);
	}

	//***indice Partido**
	const obtenerPartido = () => {
		var valorP = partido;
		setPartido(valorP);
	}

	/*const obtenerAvanzados = (avanzados) => {
		setAvanzados(avanzados);
	}*/

	//************Agregar State Inputs******
	/*const [inputs, setInputs] = useState({
		nombre_proyecto_i : nombre_proyecto,
		encargado_i: encargado,
		expediente_i: expediente,
		//prioritario_i: tramite_prioritario
		programa_i: programa,
		estado_i: estado,
		tipo_i: tipo,
		partido_i: partido,
		//avanzados_i: avanzados
	});*/

	//const { nombre_proyecto_i, encargado_i, expediente_i, programa_i, estado_i, tipo_i, partido_i} = inputs;

	//*** Tramite prioritario******** Caso Checkbox*************
	//const [prioritario_i, setPrioritario] = useState(tramite_prioritario);//el valor recibido
	//const [avanzados_i, setAvanzados]= useState(avanzados);

	//***-------------------------***

	//TIENE QUE CARGAR LOS DATOS Existente para ese registro.
				//26-feb-2022

	/*const onChange = e => {
		if(e.target.name != "prioritario_i" || e.target.name != "avanzados_i"){
    	setInputs({ ...inputs, [e.target.name]: e.target.value });
		}
		if(e.target.name == "encargado_i"){
			//console.log("cambiar indice")
			setIndice(e.target.value);
		}
		if(e.target.name == "programa_i"){
			setPrograma(e.target.value);
		}
		if(e.target.name == "estado_i"){
			setEstado(e.target.value);
		}
		if(e.target.name == "tipo_i"){
    	setTipo(e.target.value);
    }
	}*/
	//console.log(inputs);	

  return (
	<Fragment>
		{/*<div> AAA </div>*/}
		<Grupo_EditExpediente editRegistro={registro} edit={edit} setEdit={setEdit} setPrograma={estadoIndicePrograma} /> {/* role={role}*/}
	</Fragment>
  );
};

export default EditExpediente_Update;
