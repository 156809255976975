import React, { Fragment, useState, useEffect } from "react";
//import Navigation from "./navigation";
import Table from "./table";

const Toolbar = ({datosS, mapa, layer}) => {

    	//console.log(datosS);
	//console.log(mapa);

	/*const capa = layer;
	const [layerS, setLayer]= useState({})*/
	
    	const datosR = datosS;
	const [datos, setDatos] = useState({features: []});
	

	useEffect(() =>{
			setDatos(datosR);
			//console.log(datos);

		},[{datosS}]
	);
    return (
	<Fragment>
      		<div id="customT">
        		<p>Custom Toolbar</p>
			<Table data={datos}  mapa={mapa} layer={layer}/>
			<ul>
                                {datos.features.map((item, id) => (
                                        <li key={id}>
                                                <a className="a_prueba" >{item.geometry.coordinates}</a>
                                        </li>
                                ))}
                        </ul>
      		</div>
	</Fragment>
    );
}

export default Toolbar;
