import React, { Fragment, useState, useEffect } from "react";
//import { toast } from "react-toastify";
import { Collapse, Form, Button } from "react-bootstrap";

import dotenv from  "dotenv";

import Navigation from "../navigation";
import Footer from "../footer";

//import SelectT from "../select";
import Informe_1_sintesis from "./informe_1_sintesis";
//import Reporte_2 from "./reporte_2";
import Informe_2_detallado from "./informe_2_detallado";
import Metabase from "./metabase";
//import Reporte_3_ada from "./reporte_3_ada";
//import Reporte_4_resoluciones from "./reporte_4_resoluciones";
//import Reporte_5_visado from "./reporte_5_visado";
//import Reporte_3 from "./reporte_3";

const baseURL = process.env.REACT_APP_API_URL;

const Monitor = ({ setAuth, ...props }) => {

  const [name, setName] = useState("");
  const [role, setRole] = useState("user");

  //***chequear token *** //24-06-2022
	const getProfile  = async  () => {
    try {
      const res = await fetch(`${baseURL}authentication/checkAuth`, {
      method: "POST",
      headers: { 'Authorization': localStorage.tokenT, roleT: localStorage.roleT, username:localStorage.username }
      });

      const parseRes = await res.json();
      //console.log("Desde REPORTS --> res.json");
      //console.log(parseRes);

      setName(parseRes.username);
			setRole(parseRes.role);
      //console.log(parseRes.username);
			//console.log(parseRes.role);

    } catch (err) {
    console.error(err.message);
    }
  };

  useEffect(() => {
      getProfile();
  }, []);
	//*** cierra chequear token ****//24-06-2022

  const [activo, setActivo] = useState("monitor");//09-mar-2023

  const [openInforme_1, setOpenINF1] = useState(true);
	const [openInforme_2_detallado, setOpenINF2_detallado] = useState(false);
  const [openMonitor_metabase, setOpenMonitor] = useState(false);
  
  const handleInforme_1 = () => {
		setOpenINF1(true);
    setOpenINF2_detallado(false);
    setOpenMonitor(false);		
	}

  const handleInforme_2_detallado = () => {
		setOpenINF1(false);
    setOpenINF2_detallado(true);
    setOpenMonitor(false);    
	}

  const handleMonitor_metabase = () => {
		setOpenINF1(false);
    setOpenINF2_detallado(false);
    setOpenMonitor(true);    
	}

  //NUEVO
  const reporteButton = () => {
    return (
      <Fragment>
        <Button
          variant="secondary"
          onClick={handleInforme_1}
          size="sm" type="info"
          aria-controls="example-collapse-textT"
          aria-expanded={openInforme_1}
          style={{ alignSelf: "flex-end" }}
          disabled={openInforme_1 ? true : false}
        >
          INFORMES SINTESÍS
        </Button>

        <Button
          variant="secondary"
          onClick={handleInforme_2_detallado}
          size="sm" type="info"
          aria-controls="example-collapse-textP3"
          aria-expanded={openInforme_2_detallado}
          disabled={openInforme_2_detallado ? true : false}
        >
          INFORMES DETALLADOS
        </Button>

        <Button
          variant="secondary"
          onClick={handleMonitor_metabase}
          size="sm" type="info"
          aria-controls="example-collapse-textR3a"
          aria-expanded={openMonitor_metabase}
          disabled={openMonitor_metabase ? true : false}
        >
          MONITOR
        </Button>         
      </Fragment>
    )

  }// cierra reporteButton

  return (
    <Fragment>
      <Navigation setAuth={setAuth} name={name} role={role} setRole={setRole} activo={activo}/>
      <div className="main">

        <div style={{width:"100%", height:"130px", zIndex: "9999", border: "0px solid yellow", position: "sticky", top: "0px" }} className="div_stick">

          <div  style={{borderTop: "1px solid white", background: "", height: "130px", position: "sticky", top: "10px"}} className="">{/*position:"sticky", top:"0px",*/}

            <h4 className="mt-5 text-center">Reportes</h4>

            <div className="d-flex">
              <div style={{position:"absolute", bottom:"5px"}} className="align-right-menu">
                {reporteButton()}
              </div>
            </div>{/* cierra d-flex*/} 
          </div> {/* cierra*/}

        </div> {/* cierra div_stick*/}

        <div className="">
              <Form id='id_monitor' style={{ width:"100%" }}>
                <div className="" style={{ width:"100%" }}>

                  <Collapse in={openInforme_1} dimension="width">
                    <div id="example-collapse-textReporte1">
                      <Informe_1_sintesis />
                    </div>
                  </Collapse>

                  <Collapse in={openInforme_2_detallado} dimension="width">
                    <div id="example-collapse-textReporte2">
                      <Informe_2_detallado />
                    </div>
                  </Collapse>

                  <Collapse in={openMonitor_metabase} dimension="width">
                    <div id="example-collapse-textReporte3">
                      <Metabase />
                    </div>
                  </Collapse>              

                </div>
              </Form>
            </div>


        

      </div> {/* cierra main */}

      <Footer />
    </Fragment>
  );
};

export default Monitor;
