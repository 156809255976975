import React, { useEffect, useState, Fragment } from "react";
import Navigation from "./navigation";

import dotenv from "dotenv";

const baseURL = process.env.REACT_APP_API_URL;

const Page2 =  ({ setAuth, role, setRole, ...props }) => {
				//console.log("ver ...props");
				//console.log({...props});
				//console.log(setAuth);
				const {roleT, username} = role;

  	/*const getProfile = async () => {
        	//console.log("ADMIN");
    		try {
      			//const res = await fetch("http://localhost:5003/role/", {//5000
			const res = await fetch(`${baseURL}role/`, {
        			method: "POST",
        			headers: { jwt_token: localStorage.token }
      			});

      			const parseData = await res.json();
        		//console.log(parseData);
      			setName(parseData.username);
        		//console.log("ver nombre");
        		//console.log({name});
			//console.log("ver role");
			//console.log(parseData.role);
			if(parseData.role !== "admin"){
				//console.log("sacar de acá");		
				 
			}
    		} catch (err) {
      			console.error(err.message);
    		}
  	};*/

	useEffect(() => {
    		//getProfile();
				//console.log("PAGE 2");// al cargar la pagina, posibilidad de hacer validaciones
  	}, []);


	return (
		<Fragment>
			<Navigation setAuth={setAuth} role={role} setRole={setRole}/>
      			<div>
        			<p> página 2 - ADMIN { username }</p>
      			</div>
		</Fragment>
    	);
}

export default Page2;
