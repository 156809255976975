import React, { Fragment, useRef, useState, useEffect, useContext } from 'react';

import L from 'leaflet';


const LegendData = ({categoriaSelect}) =>{

    const [categoria, setCategoria]=useState('AA');
    useEffect(()=>{
        if(categoriaSelect ==='AA'){
            setCategoria('AA')
        } else if(categoriaSelect ==='BB'){
            setCategoria('BB')
        } 
    }, [categoriaSelect]);
    console.log("CATEGORIA", categoria);

    if(categoria === 'AA'){
        return (
            <>
            <div className="legend">
                <div><b>PROGRAMA</b></div>
                <div style={{ "--color": '#207AB6' }}>Lotes con servicios</div>
                <div style={{ "--color": '#F6E620' }}>Plan familia propietaria</div>
                <div style={{ "--color": '#E31A1C' }}>Regimen de integración sociourbana</div>
                <div></div>                
            </div> 
            </>   
        );

    } else if(categoria === 'BB') {
        return (
            <>
            <div className="legend">
                <div><b>ESTADO</b></div>
                <div style={{ "--color": '#E77148' }}>En proceso</div>
                <div style={{ "--color": '#987DB7' }}>Afectado con resolución</div>                
                <div style={{ "--color": 'gray' }}>Inactivos</div> 
                <div></div>  
            </div>
            </>    
        );
    }
    

}

export default LegendData;