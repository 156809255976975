import React, {useState, useEffect, Fragment} from "react";
import { Document, Page, Text, View, Image, Font, StyleSheet } from "@react-pdf/renderer";
import Encabezado from './encabezado_A4.png';
import ImagePie from './pie_hoja_A4.png';

//Font.register({ family: 'Roboto' });

/*Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'http://fonts.cdnfonts.com/css/roboto' },
    { src: 'http://fonts.cdnfonts.com/css/roboto', fontStyle: 'italic' },
  ]
});*/

//'http://fonts.cdnfonts.com/css/roboto'

Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgVxMIzIXKMnyrYk.woff2'
});

//https://fonts.google.com/specimen/Encode+Sans
//'https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgVxMIzIXKMnyrYk.woff2'
// https://fonts.google.com/specimen/Roboto


const styles = StyleSheet.create({
  // page: {
  //   flexDirection: 'column',
  //   backgroundColor: 'white',
  //   marginBottom: '10px',
  // },
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 35,
    //height: "842px",
    //weight: "595px",
    //border: '2px solid yellow',
    flexDirection: 'column',
    flex:1,

  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
    height: 100,
    //border: '2px solid red',
    //height : "100px"
    //height: "15%",
  },

  footer: {
    //bottom: 0,
    //align-self: flex-end;
    fontSize: 12,
    marginTop: "auto",
    //paddingHorizontal: 35,
    //textAlign: 'center',
    color: 'grey',
    border: '2px solid red',
    //position: "absolute",
    //height: "120px",
    height: "15%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-around",
    alignContent: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 35,
    //alignItems: "flex-end",
    backgroundColor: "yellow"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 35
  },

  cuerpo: {
    //position: "relative",
    //border: '2px solid black',
    top: 0,
    bottom: 80,
    marginBottom: 10,
    //height: "70%",
    //height: "620px"
    //marginBottom: 200,
    //paddingBottom: 35,
  },

  title: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: 'center',
    color: "rgb(0,174,195)",
  },
  text_L10: {
    color: "rgb(33,37,41)",
    fontSize: 10,
    //margin:12,
    textAlign: "left",
  },
  text_R10: {
    color: "rgb(33,37,41)",
    fontSize: 10,
    //margin:12,
    textAlign: "right",
    fontFamily: "Roboto",
  },
  subtitleR: {
    color: "rgb(33,37,41)",
    //fontWeight: "italic",
    fontSize: 10,
    //margin: 12,
    textAlign: "right",
    //fontFamily: "italic font",
    fontFamily: "Roboto",

  },
  subtitleL: {
    color: "rgb(0,174,195)",
    fontWeight: "bold",
    fontSize: 12,
    margin: 0,//quitar tab ---> 12
    textAlign: "left",
  },

  subtitulos: {
  	color: "rgb(0,174,195)",
  	fontWeight: "bold",
    fontSize: 14,
  },

  textParrafo: {
    color: "rgb(33, 37, 41)",
    fontSize: "10px",
    lineHeight: "2px",
    textAlign: 'justify',
    fontFamily: "Roboto",
  },
  items: {
    color: "rgb(33, 37, 41)",
    fontSize: "10px",
    lineHeight: "2px",
    textAlign: "left",
    margin: 12,
  },

  footerPie_1: {
    border: '1px solid yellow',
    width: '30%',
    height: 80,
    color: "rgb(33, 37, 41)",
    fontSize: "8px",
    textAlign: "left",
    //fontSize: 8,
  },

  footerPie_2: {
    border: '4px solid green',
    width: '70%',
    height: "80px",
    marginLeft: "40px",
  },
  cell: {
    borderColor: "#cc0000",
    borderStyle: "solid",
    borderWidth: 2,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    alignSelf: "stretch"
  },

})

const DocuPDF = ({ informeTitle, registro }) => {
  //console.log(registro);

  //console.log(informeTitle);

  const {expediente, programa, area,
    solicitud_adhesion_t, certif_urbanistico_municipal_t, certif_no_inundabilidad_t,
    anteproyecto_urbano_t, titulo_propiedad_t,
    memoria_descriptiva_obras_t, energia_electrica_t, agua_potable_t,
    eliminacion_excretas_t, apertura_calles_t, acta_constitutiva_t,
    certif_vigencia_persona_juridica_t, plano_mensura_t,
    convenio_urbanistico_t, ordenanza_municipal_t, memoria_descriptiva_procedimientos_t, t_144_proyecto_hidraulico,
    observaciones_reporte_t,
    titular_tierra, tipo,
    sistema_agua, eliminacion_excretas
  }= registro;
  console.log(area);

  const [estadoPrograma, setEstadoPrograma] = useState("................");
  useEffect(()=>{
    if(programa === "LOTES CON SERVICIOS"){
      setEstadoPrograma("Programa Lotes con Servicios");
    } else if(programa === "PLAN FAMILIA PROPIETARIA"){
      setEstadoPrograma("Plan Familia Propietaria");
    } else if(programa === "REGIMEN DE INTEGRACIÓN SOCIOURBANA"){
      setEstadoPrograma("Régimen de Integración Sociourbana");
    }
  }, [programa]);
  //console.log("PROGRAMA ---->", estadoPrograma);

  const [regRecibido, setRegRecibido] = useState([]);
  //console.log(regRecibido);

  const [estadoActa, setEstadoActa ] = useState(false); //ESTE estado depende del valor que tenga titular_tierra
  //console.log(estadoActa);
  const [estadoCertVigencia, setCertVigencia ] = useState(false); //ESTE estado depende del valor que tenga certif_vigencia_persona_juridica_t
  //console.log(estadoCertVigencia);

  //si area--> Urbana, entonces Proyecto hidraulico no tienen que aparecer
  const [estadoArea, setEstadoArea]= useState(true);
  console.log(estadoArea);

  //SOLO TIENEN QUE APARECER SI estadoTipo es true.
  const [estadoTipo, setEstadoTipo] = useState(false);
  //console.log(estadoTipo);
  const [estadoAgua, setEstadoAgua] = useState(false);
  //console.log(estadoAgua);
  const [estadoExcretas, setEstadoExcretas] = useState(false);
  //console.log(estadoExcretas);

  useEffect(()=>{
    if(area==="COMPLEMENTARIA" || area==="RURAL" || area==="OTRO" || area ===null || area===""){
      setEstadoArea(true)
    } else {
      setEstadoArea(false);
    }

  }, [area])

  useEffect(()=>{
    if(
      titular_tierra === "" ||
      titular_tierra === "MUNICIPIO" ||
      titular_tierra === "PROVINCIA (IVBA u otros)" ||
      titular_tierra === "ESTADO NACIONAL" ||
      titular_tierra === "PRIVADOS PARTICULARES"||
      titular_tierra === "CONDOMINIO"||
      titular_tierra === "BANCO HIPOTECARIO" ||
      titular_tierra === "OTROS" ||
      titular_tierra === "SIN DATO"
    ){
      setEstadoActa(false);
      setCertVigencia(false);
      //console.log("ACTA y CERT. VIGENCIA ESTA DISABLED");
    } else {
      setEstadoActa(true);
      setCertVigencia(true);
      //console.log("ACTA y CERT. VIGENCIA ESTA HABILITADO");
    }
  },[titular_tierra]);

  useEffect(()=>{
    if( tipo === "" || tipo === "INMUEBLES DEL ESTADO"){ // 0, 1
      setEstadoTipo(false);
      //console.log("TIPO ESTA DISABLED");
    } else {
      setEstadoTipo(true);
      //console.log("TIPO ESTA HABILITADO");
    }
  }, [tipo]);

  //estadoAgua
  useEffect(()=>{
    if(sistema_agua !== "RED" ){
      setEstadoAgua(false);
    } else {
      setEstadoAgua(true);
    }
  }, [sistema_agua]);

  //estadoExcretas
  useEffect(()=>{
    if(eliminacion_excretas !== "RED"){
      setEstadoExcretas(false);
    } else {
      setEstadoExcretas(true);
    }
  }, [eliminacion_excretas])

  useEffect(()=>{
    setRegRecibido([
      {"name": "Nota de solicitud de adhesión al programa", "value": solicitud_adhesion_t},
      {"name": "Informe urbanístico municipal", "value":certif_urbanistico_municipal_t},
      {"name": "Certificado de no inundabilidad del predio", "value":certif_no_inundabilidad_t},
      {"name": "Anteproyecto urbano", "value":anteproyecto_urbano_t},
      {"name": "Título de propiedad y/o datos de inscripción dominial", "value":titulo_propiedad_t},

      {"name": "Memoria descriptiva de las obras a ejecutar", "value":memoria_descriptiva_obras_t},
      {"name": "Prefactibilidad de energía eléctrica", "value":energia_electrica_t},
      {"name": "Prefactibilidad de agua potable por red", "value":agua_potable_t},
      {"name": "Prefactibilidad de red cloacal", "value":eliminacion_excretas_t},
      {"name": "Factibilidad apertura de calles", "value":apertura_calles_t},
      {"name": "Acta constitutiva persona jurídica", "value":acta_constitutiva_t},
      {"name": "Certificado de vigencia de Persona Jurídica", "value":certif_vigencia_persona_juridica_t},
      {"name": "Plano de mensura y división", "value":plano_mensura_t},
      {"name": "Convenio urbanístico", "value":convenio_urbanistico_t},
      {"name": "Ordenanza municipal", "value":ordenanza_municipal_t},
      {"name": "Memoria descriptiva del procedimiento de adjudicación", "value":memoria_descriptiva_procedimientos_t},
      {"name": "Proyecto hidráulico", "value":t_144_proyecto_hidraulico}
    ])
  }, [registro]);
  //console.log(expediente);


  //*const lorem = `LOREM ipsum dolor sit amet, consectetur adipiscing elit.`;

  //const title = `<i>${lorem}</i>`;


  const fecha = new Date().toLocaleDateString('es-AR');

  const localidadVacio = "..................";

  const tab = '\u00A0';

  const sub1 = "El Municipio presentó la siguiente documentación:";
  const sub2 = "Documentación faltante a presentar:";
  const sub3_observ = "Observaciones";

  const texto3 = `${tab}${tab}${tab}${tab} Vienen las actuaciones por las cuales se solicita la afectación al ${estadoPrograma} de un proyecto de urbanización a desarrollarse en el inmueble de la localidad de ${registro.localidad ? registro.localidad : localidadVacio}, partido de ${registro.partido_nombre ? registro.partido_nombre : localidadVacio}, identificado catastralmente como ${registro.datos_18_sintesis_nomenclatura ? registro.datos_18_sintesis_nomenclatura : "........."}`;

  const exped = `${registro.expediente ? registro.expediente : localidadVacio}`;

  /*const texto3 = `Vienen las actuaciones por las cuales se solicita la afectación al Programa Lotes con
  Servicios de un proyecto de urbanización a desarrollarse en el inmueble de la localidad de
  ${registro.localidad ? registro.localidad : localidadVacio}, partido de ${registro.partido_nombre ? registro.partido_nombre : localidadVacio}, identificado catastralmente como
  ${registro.datos_18_sintesis_nomenclatura ? registro.datos_18_sintesis_nomenclatura : "..."}`;*/
  //por ahora no usar
  const ul_tiene = (regRecibido) =>{
    return (
        <View>
          <Text>
            <ul>
              {regRecibido.filter(item => item.value === 'true').map(filteredReg => (
                <li>
                  {filteredReg.name}
                </li>
              ))}
            </ul>
          </Text>
        </View>
    )
  }


  return (
    <Fragment>
      {registro ? (
        <>
          <Document>
          <Page size="A4" style={styles.body} wrap>
          {/*<Page
            size="A4"

            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",

            }}
          >*/}

            <View style={styles.header} fixed>
              <Image  src={Encabezado} />
            </View>
            {/*<View fixed style={{flexDirection: 'row', height: 70, paddingBottom: 10, justifyContent: 'space-between', borderBottom: '1 solid black'}}>
        			<Image src={Encabezado} style={{width: 75, height: 75, paddingBottom: 10}} />
        			<View style={{paddingTop: 20, alignItems: 'flex-end'}}>
        				<Text style={{fontSize: 14}}>Your Company Info Could Be Here!</Text>
        				<Text style={{fontSize: 10, paddingTop: 3}}>1234 NW Main Street</Text>
        				<Text style={{fontSize: 10, paddingTop: 3}}>Atlanta, GA</Text>
        			</View>
        		</View>*/}


            {/*<Text
              style={{
                color: "gray",
                fontStyle: "italic",
                fontSize: "14px",
                textAlign: 'center',
              }}
            >
              {informeTitle}
            </Text>*/}
            <View style={styles.cuerpo}>
              <View>
                <Text style={styles.text_R10}><p></p></Text>
              </View>

              <View>
                <Text >
                  <h6 style={styles.title}> {/*Titulo Estado de las actuaciones*/}
                    {informeTitle ? <b>{informeTitle.title}</b> : "..."}
                  </h6>
                </Text>
              </View>
              <Text><p></p>{`\n`}</Text>

              <View>
                <Text style={styles.text_L10}>{registro.nombre_proyecto} - {registro.partido_nombre}</Text>
                <Text><p></p>{`\n`}</Text>
              </View>

              <View>
                <Text style={styles.subtitleR}>
                  {informeTitle ? "Referente al Expediente" : "NO TENGO"} {exped}
                </Text>
              </View>

              <Text><p></p>{`\n`}</Text>

              <View>
                <Text style={styles.textParrafo} >
                  {texto3}
                </Text>
              </View>

              <Text><p></p>{`\n`}</Text>

              <Text style={styles.subtitleL}>{informeTitle ? sub1 : "NO TENGO"}</Text>
              <View style={styles.items}>
                <Text>{solicitud_adhesion_t==="true" ? "- Nota de solicitud de adhesión al programa" : "" }</Text>
                <Text>{certif_urbanistico_municipal_t==="true" ? "- Informe urbanístico municipal" : "" }</Text>
                <Text>{certif_no_inundabilidad_t==="true" ? "- Certificado de no inundabilidad del predio" : "" }</Text>
                <Text>{anteproyecto_urbano_t==="true" ? "- Anteproyecto urbano" : "" }</Text>
                <Text>{titulo_propiedad_t==="true" ? "- Título de propiedad y/o datos de inscripción dominial" : "" }</Text>
                <Text>{memoria_descriptiva_obras_t==="true" ? "- Memoria descriptiva de las obras a ejecutar" : "" }</Text>
                <Text>{energia_electrica_t==="true" ? "- Prefactibilidad de energía eléctrica" : "" }</Text>

                <Text>{ estadoAgua === true ? <Text>{agua_potable_t==="true" ? "- Prefactibilidad de agua potable por red" : "" }</Text> : ""}</Text>
                {/*<Text>{agua_potable_t==="true" ? "- Prefactibilidad de agua potable por red" : "" }</Text>*/}

                <Text>{ estadoExcretas === true ? <Text>{eliminacion_excretas_t==="true" ? "- Prefactibilidad de red cloacal" : "" }</Text> : ""}</Text>

                <Text>{apertura_calles_t==="true" ? "- Factibilidad apertura de calles" : "" }</Text>

                <Text>{ estadoActa === true ?  <Text>{acta_constitutiva_t==="true" ? "- Acta constitutiva persona jurídica" : "" }</Text> : ""}</Text>
                <Text>{ estadoCertVigencia === true ? <Text>{certif_vigencia_persona_juridica_t==="true" ? "- Certificado de vigencia de persona jurídica" : "" }</Text> : "" }</Text>

                <Text>{plano_mensura_t==="true" ? "- Plano de mensura y división" : "" }</Text>

                <Text>{ estadoTipo === true ? <Text>{convenio_urbanistico_t==="true" ? "- Convenio urbanístico" : "" }</Text> : "" }</Text>

                <Text>{ordenanza_municipal_t==="true" ? "- Ordenanza municipal" : "" }</Text>
                <Text>{memoria_descriptiva_procedimientos_t==="true" ? "- Memoria descriptiva del procedimiento de adjudicación" : "" }</Text>

                {/*<Text>{t_144_proyecto_hidraulico==="true" ? "- Proyecto hidráulicoOO" : "" }</Text>*/}
                <Text>{ estadoArea === true ? <Text>{t_144_proyecto_hidraulico==="true" ? "- Proyecto hidráulico" : "" }</Text> : "" }</Text>

              </View>


              <Text><br /></Text>

              <Text style={styles.subtitleL}>{informeTitle ? sub2 : "NO TENGO"}</Text>
              <View style={styles.items}>
                <Text>{solicitud_adhesion_t==="false" || solicitud_adhesion_t===null ? "- Nota de solicitud de adhesión al programa" : "" }</Text>
                <Text>{certif_urbanistico_municipal_t==="false" || certif_urbanistico_municipal_t===null ? "- Informe urbanístico municipal" : "" }</Text>
                <Text>{certif_no_inundabilidad_t==="false" || certif_no_inundabilidad_t===null ? "- Certificado de no inundabilidad del predio" : "" }</Text>
                <Text>{anteproyecto_urbano_t==="false" || anteproyecto_urbano_t===null ? "- Anteproyecto urbano" : "" }</Text>
                <Text>{titulo_propiedad_t==="false" || titulo_propiedad_t===null ? "- Título de propiedad y/o datos de inscripción dominial" : "" }</Text>
                <Text>{memoria_descriptiva_obras_t==="false" || memoria_descriptiva_obras_t===null ? "- Memoria descriptiva de las obras a ejecutar" : "" }</Text>
                <Text>{energia_electrica_t==="false" || energia_electrica_t===null ? "- Prefactibilidad de energía eléctrica" : "" }</Text>

                <Text>{ estadoAgua === true ? <Text>{agua_potable_t==="false" || agua_potable_t===null ? "- Prefactibilidad de agua potable por red" : "" }</Text> : ""}</Text>
                {/*<Text>{agua_potable_t==="false" || agua_potable_t===null ? "- Prefactibilidad de agua potable por red" : "" }</Text>*/}

                <Text>{ estadoExcretas === true ? <Text>{eliminacion_excretas_t==="false" || eliminacion_excretas_t===null ? "- Prefactibilidad de red cloacal" : "" }</Text> : ""}</Text>
                {/* <Text>{eliminacion_excretas_t==="false" || eliminacion_excretas_t===null ? "- Prefactibilidad de red cloacal" : "" }</Text> */}

                <Text>{apertura_calles_t==="false" || apertura_calles_t===null ? "- Factibilidad apertura de calles" : "" }</Text>

                <Text>{ estadoActa === true ?  <Text>{acta_constitutiva_t==="false" || acta_constitutiva_t===null ? "- Acta constitutiva persona jurídica" : "" }</Text> : ""}</Text>
                {/*<Text>{acta_constitutiva_t==="false" || acta_constitutiva_t===null ? "- Acta constitutiva persona jurídica" : "" }</Text>*/}

                <Text>{ estadoCertVigencia === true ? <Text>{certif_vigencia_persona_juridica_t==="false" || certif_vigencia_persona_juridica_t===null ? "- Certificado de vigencia de persona jurídica" : "" }</Text> : "" }</Text>
                {/* <Text>{certif_vigencia_persona_juridica_t==="false" || certif_vigencia_persona_juridica_t===null ? "- Certificado de vigencia de Persona Jurídica" : "" }</Text> */}

                <Text>{plano_mensura_t==="false" || plano_mensura_t===null ? "- Plano de mensura y división" : "" }</Text>

                <Text>{ estadoTipo === true ? <Text>{convenio_urbanistico_t==="false" || convenio_urbanistico_t===null ? "- Convenio urbanístico" : "" }</Text> : "" }</Text>
                {/*<Text>{convenio_urbanistico_t==="false" || convenio_urbanistico_t===null ? "- Convenio urbanístico" : "" }</Text>*/}

                <Text>{ordenanza_municipal_t==="false" || ordenanza_municipal_t===null ? "- Ordenanza municipal" : "" }</Text>
                <Text>{memoria_descriptiva_procedimientos_t==="false" || memoria_descriptiva_procedimientos_t===null ? "- Memoria descriptiva del procedimiento de adjudicación" : "" }</Text>
                {/*<Text>{t_144_proyecto_hidraulico==="false" || t_144_proyecto_hidraulico===null ? "- Proyecto hidráulicOO" : "" }</Text>*/}
                <Text>{ estadoArea === true ? <Text>{t_144_proyecto_hidraulico==="false" || t_144_proyecto_hidraulico===null? "- Proyecto hidráulico" : "" }</Text> : "" }</Text>
              </View>

              <Text style={styles.subtitleL}>Observaciones</Text>
              <View>
                <Text><p></p>{`\n`}</Text>
                <Text style={styles.textParrafo}>{observaciones_reporte_t}</Text>
              </View>

            </View>

            {/*<View style={styles.footer} fixed >
                <View styles={styles.footerPie_1}>
                  <Text>Subsecretaría de Hábitat de la Comunidad</Text>
                  <Text>Dirección Provincial de Producción de Hábitat</Text>
                  <Text>73 Nº 1568 esq 56 - 4º Piso</Text>
                  <Text>La Plata (1900), Buenos Aires</Text>
                  <Text>dproducciónhabitat@gmail.com</Text>
                  <Text>Tel. (0221) 427-2266 Int. 107</Text>
                  <Text>gba.gob.ar</Text>
                </View>
                <Image  style={styles.footerPie_2} src={ImagePie} />

            </View>*/}

            {/*<View style={styles.footer} fixed >
                <View styles={styles.cell}>
                  <Text >gba.gob.ar</Text>
                </View>
                <View styles={styles.cell}>
                  <Text >Imagen</Text>
                </View>
            </View>*/}


            <View fixed style={{flexDirection: 'row', alignItems: 'flex-end', marginTop: 'auto', height: 150, paddingBottom: 10, justifyContent: 'space-between' }}>

        			<View style={{paddingTop: 20, width: 200, alignItems: 'flex-start'}}>
        				<Text style={{fontSize: 9, paddingTop: 3}}><b>Subsecretaría de Hábitat de la Comunidad</b></Text>
        				<Text style={{fontSize: 8, paddingTop: 3}}>Dirección Provincial de Producción de Hábitat</Text>
        				<Text style={{fontSize: 8, paddingTop: 3}}>Diag. 73 Nº 1568 esq 56 - 4º Piso</Text>
                <Text style={{fontSize: 8, paddingTop: 3}}>La Plata (1900), Buenos Aires</Text>
                <Text style={{fontSize: 8, paddingTop: 3}}>dproduccionhabitat@gmail.com</Text>
                <Text style={{fontSize: 8, paddingTop: 3}}>Tel. (0221) 427-2266 Int. 129 /// gba.gob.ar</Text>                
        			</View>

              <View style={{paddingTop: 20, width: 320, alignItems: 'flex-start', paddingBottom: 20}}>
                <Image src={ImagePie} />
              </View>

        		</View>
          </Page>
        </Document>
      </>) : null}
    </Fragment>
  );
};

export default DocuPDF;

//NUevo footer: borderTop: '1 solid yellow'
// Views dentro de footer: border: '2px solid green',

/*style={{
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  padding: 10,
}}*/
